import styled from "styled-components";

export const Wrapper = styled.div`
  
  overflow: hidden;
  box-shadow: 0px 3px 6px #00000029;
  background-color: #000044;  
  padding: 10px 0;
  height: 70px;
  position: fixed;
  width: 100%;
  display: flex;
.sidebar-header{
  display: contents;
}
  .toggle-icon {
    color:#000000;
    margin-left:30px;
    font-size:28px;
    margin-top: 10px;
    color:#000000;
  }
  .store_name{
    font-weight:600;
    font-family:'Roboto';
    font-size:24px;
    margin-left:15px;
    margin-top:7px;
    color:white;
  }
  .store_name_title{
    font-weight:600;
    font-family:'Roboto';
    font-size:18px;
    margin-left:100px;
    text-align:center;
    margin-top:12px;
    font-style:italic;
    color:#6EDC8F;
  }
 
  .header_row{
    display:flex;
    flex-direction:row;
    position:absolute;
    right:0;
    margin-right:30px;
  }
  .header_icons {
    background:var(--lightblue);
    height:25px;
    width:25px;
    margin-right:10px;
    border-radius:4px;
    padding:1px;
    img {
      width:10px;
      height:10px;
      margin:5px 10px 10px 5px;
    }
  }
  .header-right{
  /*   position: relative;
    left: 60%;
    display:flex; */
    position: absolute; /*or fixed*/
    right: 25px;
    

    .section-one{
      border-right: 1px solid #00000029;
      margin: 0 10px;
      .icon{
        cursor: pointer;
        margin: 15px 10px;
        height: 15px;   
      }
    }
    .section-two{
      display:flex;
      .icon{
        height: 33px;
        margin: 6px;  
      }
      .profile{
        top: 10px;
        left: 5px;
        display:flex;
        flex-direction:row;
        .name{
          font-weight:700;
          font-size: 18px;
          color:white;
          cursor:pointer;

        }
        .role{
          margin-top: 2px;
          font-weight:500;
          font-size: 14px;  
          margin-left:5px;
          color:white;
        }
      }
      .logout{
        top: 10px;
        left: 5px;
        display:flex;
        flex-direction:row;
        color:#6EDC8F;
        margin-right:20px;

      }
      h6{
        margin-left:10px;
        font-size:18px;
      }
    }
    div{      
      line-height: 1px;
      position: relative;
      .name{
        color:#000000;
        font-size:14px;
      }
      .logout{
        font-size:14px;
        color: #2438a7;
        curson:pointer;
      }
      h6 {
        margin-left:10px;
      }
    }
  }
  .header_icons_user {
    background:#B2B2B2;
    height:35px;
    width:35px;
    margin-right:10px;
    border-radius:4px;
    padding:1px;
    img {
      width:20px;
      height:20px;
      margin:5px 10px 10px 5px;
    }
  }
  
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: var(--maxWidth);
  padding: 6px 0;
  margin: 0 auto;
  background:#FFFFFF;
  height:70px;
  color: var(--white);
  a {
    color: var(--white);
    text-decoration: none;
  }
`;

export const LCLogoImg = styled.img`
  width: 72px;
  height:72px;
  margin-left: 12px;
  @media screen and (max-width: 500px) {
    width: 40px;
  }
`;

export const SettlemintLogoImg = styled.img`
  width: 210px;
  @media screen and (max-width: 500px) {
    width: 140px;
  }
`;
