

import styled from "styled-components";

export const Wrapper = styled.div`
background:white;
margin:20px;
border-radius:6px;
width:70%;

.print_div {
  display:flex;
  flex-direction:row;
  background:#000044;
  color:white;
  border:#4EACFB;
  padding:2px;
  height:30px;
  margin:15px;
  width:30%;
  text-align:center;
  justify-content:center;
  align-item:center;
  box-shadow: 0px 2px 2px #00000030;
border-radius: 6px;
opacity: 1;
}
.div_Container {
  margin:20px;
  border:1px solid #00001F24;
  border-radius:12px;
  opacity:1;
  padding:10px;
  p{
    font-family:Roboto;
    color:#A09E9E;
    font-size:13px;
    font-weight:500;
    margin:20px;
  }  
}


.title{
  font-family:Roboto;
  font-size:15px;
  font-weight:600;
  margin-left:20px;
  margin-bottom:0 !important;
  margin-top:15px;

}
.subtitle{
  font-family:Roboto;
  font-size:24px;
  font-weight:600;
  margin-left:20px;
  margin-top:0 !important
}
.row_div {
    margin:10px;
  
  }
  
  .radiolabel {
    margin:4px;
}
 


.inputdiv {
    margin:20px 0px 5px;
}

select{
    border: 1px solid #00001F24;
    width: 90%;
    color:var(--black);
    font-family: 'ARIAL';
    height:45px;
    border-radius:6px;
    margin:10px;
    :focus {
        outline: none !important;
        border-color:none;  
      }
}




.check_input {
  height:20px;
  margin:10px;
  width: 12%;


}
.check_div{
  flex-direction: row;
   display: flex;
   margin:10px;
   p{
    color:black;
    margin-left:1px;
    margin-top:10px;
    font-weight:bold;
    font-size:14px;
    font-family:Roboto
   }

}
.add_button {
  display:flex;
  flex-direction:row;
  background:#000044;
  color:white;
  border:#4EACFB;
  padding:15px;
  height:50px;
  margin:15px;
  width:45%;
  text-align:center;
  justify-content:center;
  align-item:center;
  box-shadow: 0px 2px 2px #00000030;
border-radius: 6px;
opacity: 1;


  
}

.send_button {
   
    background:#000044;
    color:white;
    border:#4EACFB;
    padding:10px;
    height:40px;
    margin:15px;
    width:15%;
    text-align:center;
    justify-content:center;
    align-item:center;
    box-shadow: 0px 2px 2px #00000030;
  border-radius: 6px;
  opacity: 1;
  
}
.reject_button {
   
    background:white;
    color:#000044;
    border:1px solid #000044;
    padding:10px;
    height:40px;
    margin:15px;
    width:15%;
    text-align:center;
    justify-content:center;
    align-item:center;
    box-shadow: 0px 2px 2px #00000030;
  border-radius: 6px;
  opacity: 1;
  
}
  
input {
    border: 1px solid #00001F24;
    width: 100%;
    color:var(--black);
    font-family: 'ARIAL';
    height:45px;
    border-radius:6px;
    margin:10px;
  }
  input[type="file"] {
    display: none;
}


  :focus {
      outline: none !important;
      border-color:none;  
    }
  
   
`
