

import styled from "styled-components";

export const Wrapper = styled.div`
background:white;
margin:20px;
border-radius:6px;
.row_title{
  margin:10px;
  padding:20px 10px 0px;
  p{
    color:black;
    font-weight:500;
    font-size:24px;
    margin:10px 0px;
    font-family:Roboto;
  }
  h5{
    color:black;
    font-weight:500;
    font-size:24px;
    margin:10px -10px;
    font-family:Roboto;
  }
}
.alert-message{
  color: red;
  margin-left: 15px;
}

.tablelist {
  margin-right:5px;;
}
 
thead{
  color:black;
  background:#c0d3ef;
  border:1px solid #c0d3ef;
  border-radius:10px;
}
td{
  color:black;
  font-weight:400;
  font-size:17px;
  padding:10px;
  font-family:Roboto;
  text-transform: capitalize;
  height:50px;

}
th{
  font-size:16px;
  height:50px;
  padding:14px;
  font-weight:500;
  font-family:Roboto;

}
.table-head-content {
  padding: 13px 13px 0 13px;
  background: white;
  border-bottom: 1px solid #E2E2E2;   
}  

.row_div {
    margin:10px;
  }
  .add_button {
    display:flex;
    flex-direction:row;
    background:#6EDC8F;
    color:black;
    border:#6EDC8F;
    padding:15px;
    height:50px;
    margin:15px;
    text-align:center;
    justify-content:center;
    align-item:center;
    box-shadow: 0px 2px 2px #00000030;
  border-radius: 6px;
  opacity: 1;
  }
.title{
    color:black;
  font-weight:500;
  font-size:24px;
  margin: 20px;
  padding: 20px 0px 0px 0px;
}
.add_vendor_btn{
  color: black;
    margin-left: 15px;
    font-weight: bold;
    font-size: 14px;
    font-family: Roboto;
}
a{
  cursor: pointer;
    color: #000044;
}
.inputdiv {
    margin:20px 0px;
}
input,select {
    padding-left: 10px;
    border: 2px solid #DFDFDF;
    width: 100%;
    color:var(--black);
    font-family: 'ARIAL';
    height:45px;
    border-radius:6px;
    margin:15px;
  }
  :focus {
      outline: none !important;
      border-color:none;  
    }
    
   
`