import {
  AUTH_LOGIN_API_URL,
  GET_CATEGORY_API_URL,
  ADD_NEW_CATEGORY_API_URL,
  GET_SUB_CATEGORY_API_URL,
  SELECT_SUBCATEGORY_CATID_WISE,
  ADD_NEW_SUB_CATEGORY_API_URL,
  UPDATE_NEW_SUB_CATEGORY_API_URL,
  GET_ASSET_API_URL,
  ADD_ASSET_API_URL,
  GET_ASSET_BY_ID_API_URL,
  UPDATE_ASSET_API_URL,
  GET_USERS_API_URL,
  GET_VENDORS_API_URL,
  ADD_VENDORS_API_URL,
  DELETE_VENDORS_API_URL,
  UPDATE_VENDORS_API_URL,
  GET_VENDORS_BY_ID_API_URL,
  UPLOAD_IMAGE_API_URL,
  ADD_USER_API_URL,
  GET_USER_BY_ID_API_URL,
  UPDATE_USER_BY_API_URL,

  UPDATE_VENDOR_ACTIVE_STATUS_API_URL,

  GET_SUBSTORE_API_URL,
  ADD_SUBSTORE_API_URL,
  GET_SUBSTORE_BY_ID_API_URL,
  UPDATE_SUBSTORE_API_URL,
  ADD_APPROVER_SUBSTORE_API_URL,
  REMOVE_APPROVER_SUBSTORE_API_URL,
  ADD_ADMIN_SUBSTORE_API_URL,
  REMOVE_ADMIN_SUBSTORE_API_URL,

  GET_APPROVERS_API_URL,
  GET_CENTRAL_STORE_ASSET_API_URL,
  SHOW_REQUEST_APPROVER_API_URL,

  ADD_ITEM_TO_ASSET_API_URL,
  UPDATE_ITEM_TO_ASSET_API_URL,
  DELETE_ITEM_TO_ASSET_API_URL,

  GET_REQUESTS_API_URL,
  ADD_REQUESTS_API_URL,
  GET_REQUESTS_API_URL_BY_ID,
  GET_APPROVERS_API_URL_BY_ASSET_TYPE,
  GET_APPROVERS_API_URL_BY_REQUEST_ID,
  GET_RECEIVED_REQUESTS_API_URL,
  SHOW_CONSUME_ASSET_VIEW,
  INSERT_CONSUME_ASSET,
  ADD_ITEM_IN_CENTRAL_CONSUME,
  INSERT_TRACONSUMED_BY_ADJUST,
  SUBSTORE_CONSUMED_ASSET_LIST,
  ISSUE_ASSET_TO_SUBSTORE,
  GET_CONSUMED_ASSET_QUANTITY,
  UPDATE_CONSUMED_RE_ORDER_AND_RED_ALERT,
  ISSUE_REQUESTS_API_URL_BY_APPROVED_ASSIGNED,
  ISSUE_REQUESTS_API_URL_BY_FIXED,
  ISSUE_REQUESTS_API_URL_BY_CONSUMABLE,
  GET_APPROVER_BY_STORE_ID_API_URL,
  GET_APPROVED_API_URL,
  GET_APPROVERS_BY_STORE_API_URL,
  GET_STORE_ADMIN_DASHBOARD,
  GET_APPROVER_DASHBOARD,
  GET_ADMIN_DASHBOARD,
  GET_CONSUMABLE_BATCH,
  ISSUE_REQUESTS_API_URL,
  PENDING_REQUESTS_API_URL,
  PENDING_OUTGOING_REQUESTS_API_URL,
  ISSUE_OUTGOING_REQUESTS_API_URL,
  STOCK_REGISTER_API_URL,
  APPROVER_DESIGNATION_API_URL,
  GET_UOM_API_URL,
  INSERT_UOM_API_URL,
  UPDATE_UOM_API_URL,
  DELETE_UOM_API_URL,
  INSERT_DESIGNATION_API_URL,
  UPDATE_DESIGNATION_API_URL,
  DELETE_DESIGNATION_API_URL,
  APPROVER_DESIGNATION_NS_API_URL,
  APPROVER_DESIGNATION_MS_API_URL,
  APPROVER_DESIGNATION_PRINCIPAL_API_URL,
  GET_PENDING_APPROVERS_BY_STORE_API_URL,
  GET_APPROVED_APPROVERS_BY_STORE_API_URL,
  GET_CONSUMED_ASSET_QUANTITY_ISSUE,
  GET_PENDING_APPROVERS_BY_STORE_API_URL_NS,
  GET_PENDING_APPROVERS_BY_STORE_API_URL_MS,
  GET_PENDING_APPROVERS_BY_STORE_API_URL_PRINCIPAL,
  GET_APPROVED_APPROVERS_BY_STORE_API_URL_NS,
  GET_APPROVED_APPROVERS_BY_STORE_API_URL_MS,
  GET_APPROVED_APPROVERS_BY_STORE_API_URL_PRINCIPAL,
  GET_STORE_ADMIN_ALL_DASHBOARD,
  APPROVERS_ALL_RECORDS,
  APPROVER_BY_STORE_ID_PENDING,
  APPROVER_BY_STORE_ID_PENDING_NS,
  APPROVER_BY_STORE_ID_PENDING_MS,
  APPROVER_BY_STORE_ID_PENDING_PRINCIPAL,
  APPROVER_BY_STORE_ID_APPROVED,
  APPROVER_BY_STORE_ID_APPROVED_NS,
  APPROVER_BY_STORE_ID_APPROVED_MS,
  APPROVER_BY_STORE_ID_APPROVED_PRINCIPAL,
  STOCK_REGISTER_API_URL_ALL,
  STOCK_REGISTER_ASSET_ID,
  SHOW_TRAN_ADJEST_LOG,
  SHOW_TRAN_CONSUME_LOG,
  TRANSACTION_LOG_API_URL,
  TRANSACTION_DETAILS_API_URL,
  SHOWBILLDETAILS_INFORMATION
} from "./config";
import axios from 'axios';

const apiSettings = {
  loginUser: async (mode, emailID, password) => {
    const result = await (
      await fetch(AUTH_LOGIN_API_URL + '?mode=' + mode + '&emailID=' + emailID + '&password=' + password, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },
  getCategories: async () => {
    const result = await (
      await fetch(GET_CATEGORY_API_URL, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },
  addCategory: async (mode, categoryName) => {
    const result = await (
      await fetch(ADD_NEW_CATEGORY_API_URL + '?mode=INSERTCATEGORY&categoryName=' + categoryName, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },
  updateCategory: async (categoryName, catID) => {
    let url = ADD_NEW_CATEGORY_API_URL + '?mode=UPDATECATEGORY&categoryName=' + categoryName + '&catID=' + catID;
    const result = await (
      await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },
  getSubCategories: async () => {
    const result = await (
      await fetch(GET_SUB_CATEGORY_API_URL, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },
  getSubCategoriesByCatID: async (catID) => {
    const result = await (
      await fetch(SELECT_SUBCATEGORY_CATID_WISE + '&catID=' + catID, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },

  addSubCategory: async (subCategoryName, catID) => {
    const result = await (
      await fetch(ADD_NEW_SUB_CATEGORY_API_URL + '&subCategoryName=' + subCategoryName + '&catID=' + catID, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },
  updateSubCategory: async (subCategoryName, catID, subCatID) => {
    const result = await (
      await fetch(UPDATE_NEW_SUB_CATEGORY_API_URL + '&subCatID=' + subCatID + '&subCategoryName=' + subCategoryName + '&catID=' + catID, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },

  getAsset: async () => {
    const result = await (
      await fetch(GET_ASSET_API_URL, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },

  addAsset: async (assetName, assetType, categoryID, assetSpacs, otherNotes, assetSubcategoryID, UOM) => {
    let url = ADD_ASSET_API_URL + "&assetName=" + assetName + "&assetType=" + assetType + "&categoryID=" + categoryID +
      "&assetSpacs=" + assetSpacs + "&otherNotes=" + otherNotes + "&assetSubcategoryID=" + assetSubcategoryID + "&UOM=" + UOM;
    const result = await (
      await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },

  updateAsset: async (assetName, assetType, categoryID, assetSpacs, otherNotes, assetSubcategoryID, assetID = "", UOM) => {
    let url = UPDATE_ASSET_API_URL + "&assetID=" + assetID + "&assetName=" + assetName + "&assetType=" + assetType +
      "&categoryID=" + categoryID + "&assetSpacs=" + assetSpacs + "&otherNotes=" + otherNotes + "&assetSubcategoryID="
      + assetSubcategoryID + "&UOM=" + UOM;
    const result = await (
      await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },
  getAssetById: async (assetID) => {
    let url = GET_ASSET_BY_ID_API_URL + "&assetID=" + assetID;
    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },
  getUsers: async () => {
    const result = await (
      await fetch(GET_USERS_API_URL, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },
  getVendors: async () => {
    const result = await (
      await fetch(GET_VENDORS_API_URL, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },
  getUserById: async (userID) => {
    const result = await (
      await fetch(GET_USER_BY_ID_API_URL + "&userID=" + userID, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },
  addVendor: async (vendorName, vendorCno, vendorEmail, vendorAdd, status) => {
    let url = ADD_VENDORS_API_URL + "&vendorName=" + vendorName + "&vendorCno=" + vendorCno + "&vendorEmail=" + vendorEmail + "&vendorAdd=" + vendorAdd + "&activeFlag=" + status;
    const result = await (
      await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },
  updateVendor: async (vendorName, vendorCno, vendorEmail, vendorAdd, vendorId, status) => {
    let url = UPDATE_VENDORS_API_URL + "&vendorName=" + vendorName + "&vendorCno=" + vendorCno + "&vendorEmail=" + vendorEmail + "&vendorAdd=" + vendorAdd + "&vendorID=" + vendorId + "&activeFlag=" + status;
    const result = await (
      await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },
  updateVendorStatus: async (vendorId, status) => {
    let url = UPDATE_VENDOR_ACTIVE_STATUS_API_URL + "&activeFlag=" + status + "&vendorID=" + vendorId;
    const result = await (
      await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },
  uploadImage: async (formData) => {

    let result = axios.post(UPLOAD_IMAGE_API_URL, formData, {
    }).then(res => {
      return res;
    });
    return result;

  },
  addUser: async (firstName, lastName, email, mobile, designation, approver, userPic, signaturePic, password, substoreoptions, approversId) => {
    var url = ADD_USER_API_URL + "&userFname=" + firstName + "&userLname=" + lastName + "&userEmailId="
      + email + "&userCno=" + mobile + "&userDesignation=" + designation + "&approverDesiID=" + approver + "&profilePic="
      + userPic + "&signPic=" + signaturePic + "&password=" + password + '&storeIDs=' + substoreoptions + '&ApproverstoreIDs=' + approversId;
    const result = await (
      await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;


  },
  updateUser: async (firstName, lastName, email, mobile, designation, approver, userPic, signaturePic, password, userID) => {
    var url = UPDATE_USER_BY_API_URL + "&userFname=" + firstName + "&userLname=" + lastName + "&userEmailId="
      + email + "&userCno=" + mobile + "&userDesignation=" + designation + "&approverDesiID="
      + approver + "&profilePic=" + userPic + "&signPic=" + signaturePic + "&password=" + password + "&userID=" + userID;
    
    const result = await (
      await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },
  getSubstores: async () => {
    const result = await (
      await fetch(GET_SUBSTORE_API_URL, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },

  getVendorById: async (vendorId) => {
    let url = GET_VENDORS_BY_ID_API_URL + "&vendorID=" + vendorId;
    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },
  getSubstoreById: async (subStoreID) => {
    const result = await (
      await fetch(GET_SUBSTORE_BY_ID_API_URL + "&subStoreID=" + subStoreID, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },
  deleteVendor: async (vendorId) => {
    let url = DELETE_VENDORS_API_URL + "&vendorID=" + vendorId;
    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },

  addSubstore: async (subStoreName, subStoreType, approvers, admins) => {
    let url = ADD_SUBSTORE_API_URL + "&subStoreName=" + subStoreName + "&subStoreType=" + subStoreType + "&approvers=" + approvers + "&admins=" + admins
    const result = await (
      await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },

  updateSubstore: async (subStoreName, subStoreType, subStoreID) => {
    let url = UPDATE_SUBSTORE_API_URL + "&subStoreName=" + subStoreName + "&subStoreType=" + subStoreType + "&subStoreID=" + subStoreID;
    const result = await (
      await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },
  addRemoveApproverToSubstore: async (userID, storeId, type) => {
    let url = ADD_APPROVER_SUBSTORE_API_URL + "&storeId=" + storeId + "&userID=" + userID;
    if (type == 'remove') {
      url = REMOVE_APPROVER_SUBSTORE_API_URL + "&storeId=" + storeId + "&userID=" + userID;
    }
    const result = await (
      await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },
  addRemoveAdminToSubstore: async (userID, storeId, type) => {
    let url = ADD_ADMIN_SUBSTORE_API_URL + "&storeId=" + storeId + "&userID=" + userID;
    if (type == 'remove') {
      url = REMOVE_ADMIN_SUBSTORE_API_URL + "&storeId=" + storeId + "&userID=" + userID;
    }
    const result = await (
      await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },

  //get approvers
  getAprrovers: async () => {
    const result = await (
      await fetch(GET_APPROVERS_API_URL, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },
  //  get approved user list
  getAprrovedUsers: async () => {
    const result = await (
      await fetch(GET_APPROVED_API_URL, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },
  getCentralStoreAssets: async (StorID) => {
    const result = await (
      await fetch(GET_CENTRAL_STORE_ASSET_API_URL + "&StorID=" + StorID, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },
  getStorAsset: async (storeId, assetID) => {
    const query = `query{
        assets(where:{institutude_id:"11",storeId:"${storeId}", assetID:"${assetID}",isDeleted:"no"}) {
          id
          cid
          storeId
          assetID
          institutude_id
          description
          voucher_no
          mfDate
          expDate
          supplier
          price
          comment
          tax
          batchNo
      
        }
      }`;
    const result = await (
      await fetch('https://ukhospital-3f38.settlemint.com/subgraphs/name/ukhospital-9ca2', {
        method: "POST",
        headers: {
          "x-auth-token": "bpaas-96797e0afe0fFb916F9e69A105a9A5F0e60dD2EF",
        },
        body: JSON.stringify({ query })
      })
    ).json();
    return result;
  },
  getStorAssetItemById: async (storeId, assetID, itemId) => {
    const query = `query{
        assets(where:{institutude_id:"11",storeId:"${storeId}", assetID:"${assetID}",id:"${itemId}",isDeleted:"no"}) {
          id
          cid
          storeId
          assetID
          institutude_id
          description
          voucher_no
          mfDate
          expDate
          supplier
          price
          comment
          tax
          batchNo
          itemName
          billno
          billdate
          make
          model
          sno
         
      
        }
      }`;
    const result = await (
      await fetch('https://ukhospital-3f38.settlemint.com/subgraphs/name/ukhospital-9ca2', {
        method: "POST",
        headers: {
          "x-auth-token": "bpaas-96797e0afe0fFb916F9e69A105a9A5F0e60dD2EF",
        },
        body: JSON.stringify({ query })
      })
    ).json();
    return result;
  },
  addItemToAsset: async (assetID, toStoreID, voucher, price, tax, mfd, expirydate, supplier, comments, batchNo,
    billNo, billDate, make, model, serialNo) => {
    let url = ADD_ITEM_TO_ASSET_API_URL + "&assetID=" + assetID
      + "&toStoreID=" + toStoreID + "&description=" + comments + "&voucher_no=" + voucher
      + "&mfDate=" + mfd + "&expDate=" + expirydate + "&supplier=" + supplier + "&price=" + price + "&comment=" + comments + "&tax=" + tax + "&batchNo=" + batchNo + "&isDeleted=no"
      + "&billno=" + billNo + "&billdate=" + billDate + "&make=" + make + "&model=" + model + "&sno=" + serialNo;
    const result = await (
      await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },
  updateItemToAsset: async (assetID, toStoreID, voucher, price, tax, mfd, expirydate, supplier, comments, batchNo, itemUID,
    billNo, billDate, make, model, serialNo) => {
    let url = UPDATE_ITEM_TO_ASSET_API_URL + "assetID=" + assetID
      + "&toStoreID=" + toStoreID + "&description=" + comments + "&voucher_no=" + voucher
      + "&mfDate=" + mfd + "&expDate=" + expirydate + "&supplier=" + supplier + "&price=" + price + "&comment=" + comments + "&tax=" + tax + "&batchNo=" + batchNo + "&isDeleted=no&itemUID=" + itemUID + "&billno=" + billNo + "&billdate=" + billDate + "&make=" + make + "&model=" + model + "&sno=" + serialNo;
    const result = await (
      await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },
  getAprroversByID: async (userID) => {
    let url = SHOW_REQUEST_APPROVER_API_URL + "&ApproverID=" + userID;
    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },
  deleteItemToAsset: async (assetID, toStoreID, itemUID) => {
    let url = DELETE_ITEM_TO_ASSET_API_URL + "itemUID=" + itemUID + "&assetID=" + assetID + "&toStoreID=" + toStoreID;
    const result = await (
      await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },

  //getRequests
  getRequests: async (fromStorID, assetType,StartDate,EndDate) => {
    let url = GET_REQUESTS_API_URL + "&fromStorID=" + fromStorID + "&assetType=" + assetType+"&StartDate=" + StartDate+"&EndDate=" + EndDate;
    

    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },

  getRequestsPending: async (fromStorID, assetType,StartDate,EndDate) => {
    let url = PENDING_REQUESTS_API_URL + "&fromStorID=" + fromStorID + "&assetType=" + assetType+"&StartDate=" + StartDate+"&EndDate=" + EndDate;
    

    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },

  getRequestsIssue: async (fromStorID, assetType,StartDate,EndDate) => {
    let url = ISSUE_REQUESTS_API_URL + "&fromStorID=" + fromStorID + "&assetType=" + assetType+"&StartDate=" + StartDate +"&EndDate=" + EndDate;
    

    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },
  addRequest: async (fromStorID, toStorID, dataNewRequest, assetType, requestCreatorID) => {

    let url = ADD_REQUESTS_API_URL + "&fromStorID=" + fromStorID + "&toStorID=" + toStorID + "&dataNewRequest=" + dataNewRequest + "&assetType=" + assetType + "&requestCreatorID=" + requestCreatorID;
    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;

  },
  getRequestByRequestId: async (RequestID) => {
    let url = GET_REQUESTS_API_URL_BY_ID + "&requestID=" + RequestID;
    
    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  }
  ,
  getApproversByAssettype: async (approverID, assetType) => {
    let url = GET_APPROVERS_API_URL_BY_ASSET_TYPE + "&ApproverID=" + approverID + "&assetType=" + assetType;
    
    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },

 
  getApproversByStoreAll: async (approverID, assetType, storeId,StartDate,EndDate) => {
    let url = APPROVERS_ALL_RECORDS + "&ApproverID=" + approverID + "&assetType=" + assetType +"&StartDate="+StartDate+"&EndDate="+EndDate;
    
    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;


  },


  getApproversByStore: async (approverID, assetType, storeId,StartDate,EndDate) => {
    let url = GET_APPROVERS_BY_STORE_API_URL + "&ApproverID=" + approverID + "&assetType=" + assetType + "&fromStorID=" + storeId+"&StartDate="+StartDate+"&EndDate="+EndDate;
    
    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;


  },


  getPendingApproversByStore: async (approverID, assetType, storeId,StartDate,EndDate) => {
    let url =   GET_PENDING_APPROVERS_BY_STORE_API_URL+ "&ApproverID=" + approverID + "&assetType=" + assetType + "&fromStorID=" + storeId+"&StartDate="+StartDate+"&EndDate="+EndDate;
    
    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },

  getPendingApproversByStoreId: async (approverID, assetType, storeId,StartDate,EndDate) => {
    let url =   APPROVER_BY_STORE_ID_PENDING+ "&ApproverID=" + approverID + "&assetType=" + assetType + "&fromStorID=" + storeId+"&StartDate="+StartDate+"&EndDate="+EndDate;
    
    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },
  getPendingApproversByStoreNS: async (approverID, assetType, storeId,StartDate,EndDate) => {
    let url =   GET_PENDING_APPROVERS_BY_STORE_API_URL_NS+ "&ApproverID=" + approverID + "&assetType=" + assetType + "&fromStorID=" + storeId+"&StartDate="+StartDate+"&EndDate="+EndDate;
    
    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;


  },

  getPendingApproversByStoreNSId: async (approverID, assetType, storeId,StartDate,EndDate) => {
    let url =   APPROVER_BY_STORE_ID_PENDING_NS+ "&ApproverID=" + approverID + "&assetType=" + assetType + "&fromStorID=" + storeId+"&StartDate="+StartDate+"&EndDate="+EndDate;
    
    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;


  },
  getPendingApproversByStoreMS: async (approverID, assetType, storeId,StartDate,EndDate) => {
    let url =   GET_PENDING_APPROVERS_BY_STORE_API_URL_MS+ "&ApproverID=" + approverID + "&assetType=" + assetType + "&fromStorID=" + storeId+"&StartDate="+StartDate+"&EndDate="+EndDate;
    
    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;


  },

  getPendingApproversByStoreMSId: async (approverID, assetType, storeId,StartDate,EndDate) => {
    let url =   APPROVER_BY_STORE_ID_PENDING_MS+ "&ApproverID=" + approverID + "&assetType=" + assetType + "&fromStorID=" + storeId+"&StartDate="+StartDate+"&EndDate="+EndDate;
    
    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;


  },
  getPendingApproversByStorePrincipal: async (approverID, assetType, storeId,StartDate,EndDate) => {
    let url =   GET_PENDING_APPROVERS_BY_STORE_API_URL_PRINCIPAL+ "&ApproverID=" + approverID + "&assetType=" + assetType + "&fromStorID=" + storeId+"&StartDate="+StartDate+"&EndDate="+EndDate;
    
    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;


  },

  getPendingApproversByStorePrincipalId: async (approverID, assetType, storeId,StartDate,EndDate) => {
    let url =   APPROVER_BY_STORE_ID_PENDING_PRINCIPAL+ "&ApproverID=" + approverID + "&assetType=" + assetType + "&fromStorID=" + storeId+"&StartDate="+StartDate+"&EndDate="+EndDate;
    
    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;


  },



  
  getCompleteApproversByStore: async (approverID, assetType, storeId,StartDate,EndDate) => {
    let url =   GET_APPROVED_APPROVERS_BY_STORE_API_URL+ "&ApproverID=" + approverID + "&assetType=" + assetType + "&fromStorID=" + storeId+"&StartDate="+StartDate+"&EndDate="+EndDate;
    
    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;


  },

  getCompleteApproversByStoreId: async (approverID, assetType, storeId,StartDate,EndDate) => {
    let url =   APPROVER_BY_STORE_ID_APPROVED+ "&ApproverID=" + approverID + "&assetType=" + assetType + "&fromStorID=" + storeId+"&StartDate="+StartDate+"&EndDate="+EndDate;
    
    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;


  },

  getCompleteApproversByStoreNS: async (approverID, assetType, storeId,StartDate,EndDate) => {
    let url =   GET_APPROVED_APPROVERS_BY_STORE_API_URL_NS+ "&ApproverID=" + approverID + "&assetType=" + assetType + "&fromStorID=" + storeId+"&StartDate="+StartDate+"&EndDate="+EndDate;
    
    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;


  },

  getCompleteApproversByStoreNSId: async (approverID, assetType, storeId,StartDate,EndDate) => {
    let url =   APPROVER_BY_STORE_ID_APPROVED_NS+ "&ApproverID=" + approverID + "&assetType=" + assetType + "&fromStorID=" + storeId+"&StartDate="+StartDate+"&EndDate="+EndDate;
    
    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;


  },

  getCompleteApproversByStoreMS: async (approverID, assetType, storeId,StartDate,EndDate) => {
    let url =   GET_APPROVED_APPROVERS_BY_STORE_API_URL_MS+ "&ApproverID=" + approverID + "&assetType=" + assetType + "&fromStorID=" + storeId+"&StartDate="+StartDate+"&EndDate="+EndDate;
    
    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;


  },

  getCompleteApproversByStoreMSId: async (approverID, assetType, storeId,StartDate,EndDate) => {
    let url =   APPROVER_BY_STORE_ID_APPROVED_MS+ "&ApproverID=" + approverID + "&assetType=" + assetType + "&fromStorID=" + storeId+"&StartDate="+StartDate+"&EndDate="+EndDate;
    
    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;


  },


  getCompleteApproversByStorePrincipal: async (approverID, assetType, storeId,StartDate,EndDate) => {
    let url =   GET_APPROVED_APPROVERS_BY_STORE_API_URL_PRINCIPAL+ "&ApproverID=" + approverID + "&assetType=" + assetType + "&fromStorID=" + storeId+"&StartDate="+StartDate+"&EndDate="+EndDate;
    
    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;


  },

  getCompleteApproversByStorePrincipalId: async (approverID, assetType, storeId,StartDate,EndDate) => {
    let url =   APPROVER_BY_STORE_ID_APPROVED_PRINCIPAL+ "&ApproverID=" + approverID + "&assetType=" + assetType + "&fromStorID=" + storeId+"&StartDate="+StartDate+"&EndDate="+EndDate;
    
    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;


  },







  ////consumableasset apis
  getConsumedAssetList: async (storID) => {
    let url = SUBSTORE_CONSUMED_ASSET_LIST + '&StorID=' + storID;
    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },
  getConsumeAssetView: async (from_storeID, assetID,assetType) => {
    let url = SHOW_CONSUME_ASSET_VIEW + '&assetID=' + assetID + '&fromStorID=' + from_storeID + '&assetType='+assetType;
    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },

  getStockByAssetId: async (assetID) => {
    let url = STOCK_REGISTER_ASSET_ID + '&assetID=' + assetID ;
    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },
  getConsumeAssetQuantity: async (from_storeID, assetID) => {
    let url = GET_CONSUMED_ASSET_QUANTITY + '&assetID=' + assetID + '&fromStorID=' + from_storeID;
    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },
  getConsumeAssetQuantityIssue: async (from_storeID, assetID) => {
    let url = GET_CONSUMED_ASSET_QUANTITY_ISSUE + '&assetID=' + assetID + '&fromStorID=' + from_storeID;
    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },

  updateRedAndReOrder: async (assetID, from_storeID, re_order_level, red_alert_level) => {
    let url = UPDATE_CONSUMED_RE_ORDER_AND_RED_ALERT + '&assetID=' + assetID + '&fromStorID=' + from_storeID +
      '&reOrderlvl=' + re_order_level + '&redAlertlvl=' + red_alert_level 

    const result = await (
      await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },
  addIssuetoSubstore: async (assetID, from_storID, issue, qunatity_issue, batchNo, comments) => {
    let url = ISSUE_ASSET_TO_SUBSTORE + '&assetID=' + assetID + '&fromStorID=' + from_storID + '&toStoreID=' + issue + '&batchNo=' + batchNo
      + '&assetType=Consumable&Quantity=' + qunatity_issue + '&comment=' + comments;
    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },
  addAssetInCentralConsume: async (asset, to_storeID, mfd, expirydate, vendor, price, tax, comments, batchNo, qunatity, billNo, billDate, make, model, serialNo,voucher_no,assetType
  ) => {
    let url = ADD_ITEM_IN_CENTRAL_CONSUME + '&assetID=' + asset + '&toStoreID=' + to_storeID + "&mfDate=" + mfd + '&expDate=' + expirydate
      + '&supplier=' + vendor + '&price=' + price + '&comment=' + comments + '&tax=' + tax + '&batchNo=' + batchNo + '&assetType=' + assetType
      + '&Quantity=' + qunatity + "&billno=" + billNo + "&billdate=" + billDate + "&make=" + make + "&model=" + model + "&sno=" + serialNo +"&voucher_no=" +voucher_no;
    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },
  addConsumeAsset: async (assetID, storeID, patientID, consume_qty, no_patients, comments, batchNo, consume_date) => {
    let url = INSERT_CONSUME_ASSET + '&assetID=' + assetID + '&fromStorID=' + storeID + '&batchNo=' + batchNo
      + '&assetType=Consumable&Quantity=' + consume_qty + '&noOfPatient=' + no_patients + '&comment=' + comments
      + '&ConsumeAdjestDate=' + consume_date + '&patientIDs=' + patientID;
    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },
  adjustConsumeAsset: async (assetID, storeID, comments, batchNo,assetType, adjust_qty, adjust_date, value) => {
    let url = INSERT_TRACONSUMED_BY_ADJUST + '&assetID=' + assetID + '&fromStorID=' + storeID + '&batchNo=' + batchNo
      + '&assetType='+assetType+'&Quantity=' + adjust_qty + '&comment=' + comments + '&ConsumeAdjestDate=' + adjust_date + '&itemID=' + value;
    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },
  getApproverByRequestId: async (RquestID, ApproverID, assetType) => {
    let url = GET_APPROVERS_API_URL_BY_REQUEST_ID + "&requestID=" + RquestID + "&assetType=" + assetType + "&ApproverID=" + ApproverID;
    
    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;


  }
  ,

  getReceivedRequests: async (toStorID, assetType,StartDate,EndDate) => {
    let url = GET_RECEIVED_REQUESTS_API_URL + "&toStorID=" + toStorID + "&assetType=" + assetType+"&StartDate=" +StartDate+"&EndDate=" +EndDate;
    

    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },


  getReceivedCompletedRequests: async (toStorID, assetType,StartDate,EndDate) => {
    let url = ISSUE_OUTGOING_REQUESTS_API_URL + "&toStorID=" + toStorID + "&assetType=" + assetType+"&StartDate=" +StartDate+"&EndDate=" +EndDate;
    

    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },
  getReceivedPendingRequests: async (toStorID, assetType,StartDate,EndDate) => {
    let url = PENDING_OUTGOING_REQUESTS_API_URL + "&toStorID=" + toStorID + "&assetType=" + assetType+"&StartDate=" +StartDate+"&EndDate=" +EndDate;
    

    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },



  getRequestIssueByAssigned_Approved: async (mode, requestID, ApproverID, AssignTo, dataNewRequest, Quantity, comments, status) => {

    let url = ISSUE_REQUESTS_API_URL_BY_APPROVED_ASSIGNED + "?mode=" + mode + "&requestID=" + requestID + "&ApproverID=" + ApproverID + "&AssignTo=" + AssignTo
      + "&dataNewRequest=" + dataNewRequest + "&Quantity=" + Quantity + "&comments=" + comments + "&status=" + status;

    const result = await (
      await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },
  getRequestIssueByFixed: async (mode, fromStorID, toStoreID, Quantity, requestId, assetType, status, inventory, dataNewRequest, transectionHash, batchNo, itemData, comments) => {
    let url = ISSUE_REQUESTS_API_URL_BY_FIXED + "?mode=" + mode + "&fromStorID=" + fromStorID + "&toStoreID=" + toStoreID + "&Quantity=" + Quantity
      + "&requestId=" + requestId + "&assetType=" + assetType + "&comment=" + comments + "&status=" + status + "&inventory=" + inventory + "&dataNewRequest=" + dataNewRequest + "&batchNo=" + batchNo + "&transectionHash=" + transectionHash + "&itemData=" + itemData + "&comments=" + comments;
    
    const result = await (
      await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },
  getRequestIssueByConsumption: async (mode, fromStorID, toStoreID, Quantity, requestId, assetType, status, inventory, dataNewRequest, transectionHash, batchNo, comments) => {
    let url = ISSUE_REQUESTS_API_URL_BY_CONSUMABLE + "?mode=" + mode + "&fromStorID=" + fromStorID + "&toStoreID=" + toStoreID + "&Quantity=" + Quantity
      + "&requestId=" + requestId + "&assetType=" + assetType + "&comment=" + comments + "&status=" + status + "&inventory=" + inventory + "&dataNewRequest=" + dataNewRequest + "&batchNo=" + batchNo + "&transectionHash=" + transectionHash + "&comments=" + comments;
    const result = await (
      await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },
  getSubstoreApprover: async (id) => {
    let url = GET_APPROVER_BY_STORE_ID_API_URL + "&fromStorID=" + id;
    
    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;

  },
  getAdminDashboard: async () => {
    let url = GET_ADMIN_DASHBOARD;
    
    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;

  },
  getApproverDashboard: async (userID) => {
    let url = GET_APPROVER_DASHBOARD + "&userID=" + userID;
    
    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;

  },


  getStoreAdminAll: async (userID) => {
    let url = GET_STORE_ADMIN_ALL_DASHBOARD + "&userID=" + userID;
    
    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;

  },
  getConsumeBatch: async () => {
    let url = GET_CONSUMABLE_BATCH;
    
    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;

  },
  getStoreAdminDashboard: async (StorID) => {
    let url = GET_STORE_ADMIN_DASHBOARD + "&StorID=" + StorID;
    
    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },
  getStock: async (openingdate, closingdate, storid) => {
    let url = STOCK_REGISTER_API_URL + "&openingDate=" + openingdate + "&closingDate=" + closingdate + "&storeID=" + storid;
    
    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },
  getStockAll: async (openingdate, closingdate, storid) => {
    let url = STOCK_REGISTER_API_URL_ALL + "&openingDate=" + openingdate + "&closingDate=" + closingdate 
    
    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },

  getDesignations: async () => {
    let url = APPROVER_DESIGNATION_API_URL;
    
    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },
  getUOM: async () => {
    let url = GET_UOM_API_URL;
    
    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },
  insertUOM: async (data) => {
    let url = INSERT_UOM_API_URL+"&dataR=" +data;
    
    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },
  updateUOM: async (data,id) => {
    let url = UPDATE_UOM_API_URL+"&dataR=" +data +"&pid=" +id;
    
    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },
  deleteUOM: async (id) => {
    let url = DELETE_UOM_API_URL+"&pid=" +id;
    
    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },


  insertDesignation: async (data) => {
    let url = INSERT_DESIGNATION_API_URL+"&dataR=" +data;
    
    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },
  updateDesignation: async (data,id) => {
    let url = UPDATE_DESIGNATION_API_URL+"&dataR=" +data +"&pid=" +id;
    
    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },
  deleteDesignation: async (id) => {
    let url = DELETE_DESIGNATION_API_URL+"&pid=" +id;
    
    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },

  getApproverNS: async (id) => {
    let url = APPROVER_DESIGNATION_NS_API_URL
    
    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  },
  getTransactionAdjustment:async (fromStorID,StartDate,EndDate) => {
    let url = SHOW_TRAN_ADJEST_LOG + "&fromStorID=" + fromStorID+"&StartDate=" + StartDate +"&EndDate=" +EndDate;
    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  }
,
getTransactionConsumable: async (fromStorID,StartDate,EndDate) => {
  let url = SHOW_TRAN_CONSUME_LOG + "&fromStorID=" + fromStorID +"&StartDate=" + StartDate +"&EndDate=" +EndDate;
  

  const result = await (
    await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      }
    })
  ).json();
  return result;
},
getTransactionLogAdmin:async (StartDate,EndDate) => {
  let url = TRANSACTION_LOG_API_URL+"&StartDate=" + StartDate +"&EndDate=" +EndDate;
  const result = await (
    await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      }
    })
  ).json();
  return result;
}
,
getTransactionDetailsView: async (txhash) => {
  let url = TRANSACTION_DETAILS_API_URL + "tx=" + txhash ;
  const result = await (
    await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      }
    })
  ).json();
  return result;
},
  getApproverMS: async (id) => {
    let url = APPROVER_DESIGNATION_MS_API_URL
    
    const result = await (
      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      })
    ).json();
    return result;
  }
,

getApproverPrincipal: async (id) => {
  let url = APPROVER_DESIGNATION_PRINCIPAL_API_URL
  
  const result = await (
    await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      }
    })
  ).json();
  return result;
},
getBillInfo:async (billno) => {
  let url = SHOWBILLDETAILS_INFORMATION + "&billno=" + billno ;
  const result = await (
    await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      }
    })
  ).json();
  return result;
  }

};

export default apiSettings;

