import styled from "styled-components";

export const Wrapper = styled.div`

background:white;
margin:20px;
border-radius:6px;

.export_button {
  display:flex;
  flex-direction:row;
  background:#ea9ca1;

  color:black;
  border:#4EACFB;
  padding:15px;
  height:50px;
  margin: 10px 0px;
  width:100%;
  text-align:center;
  justify-content:center;
  align-item:center;
  box-shadow: 0px 2px 2px #00000030;
border-radius: 6px;
opacity: 1;
}
.alert-message{
    color: red;
    margin-left: 15px;
  }
tablelist {
    margin-right:5px;;
  }
  thead{
    color:black;
    background:#c0d3ef;
  border:1px solid #c0d3ef;
    border-radius:10px;
  }
  td{
    color:black;
    font-weight:400;
    font-size:16px;
    padding:15px;
    font-family:Roboto;
    text-transform: capitalize;
  }
  th{
    font-size:16px;
    height:50px;
    padding:14px;
    font-weight:500;
    font-family:Roboto;
  }
  .row_div {
    display:flex;
    flex-direction:row;
    padding: 8px;
    margin-left: 8px;
  }
  .input_div {
    border: 2px solid #DFDFDF;
    color:var(--black);
    font-family: 'ARIAL';
    border-radius:6px;
    height: 45px;
    width: 100%;
    background:white;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    input,select{
       border:none;
       height: unset;
       padding-left:5px;
       :focus {
         outline: none !important;
         border-color:none;  
       }
      }
     
  }
  .table-head-content {
    padding: 13px 13px 0 13px;
    background: white;
    border-bottom: 1px solid #E2E2E2;   
  }
`