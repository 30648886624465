import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Container, Row, Col } from "react-bootstrap";
import { Wrapper, RowInput, Label, Input, Switch } from "./FixedRequestAdd.styles"
import plus from "../../images/white_plus.png"
import { Title } from "../../GlobalStyle"
import { FaCheckCircle, FaSearch } from "react-icons/fa"
import { AiOutlinePlus, AiOutlineSearch } from "react-icons/ai";
import API_Auth from "../../API_Auth";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, Link, useParams } from "react-router-dom";
import { LoadingSpinner } from "../LoadingSpinner/index";
import Delete from "../../images/delete.png"


import "react-toastify/dist/ReactToastify.css";


const FixedRequestAdd = () => {

    const {
        isExpanded,
    } = useContext(UserContext);
    const navigate = useNavigate();
    const [assetType, setAssetType] = useState('Consumable')
    const [disableSubmit, setDisableSubmit] = useState(false);


    const [itemName, setitemName] = useState('');
    const [store_type, setstore_type] = useState('');
    const [quantity, setquantity] = useState('');
    const [comments, setcomments] = useState('');
    const [assetItems, setAssetItems] = useState('');
    const [stores, setStore] = useState('')
    let { StorID, SubstoreName } = useParams();
    const [isLoading, setIsLoading] = useState(false);

    const [storeErr, setStoreErr] = useState('');
    const [itemErr, setItemErr] = useState('');
    const [approverName, setApproverName] = useState([]);
    const [storeText, setStoreText] = useState('')
    const [itemsuggestions, setItemSuggestions] = useState('');
    const [duplicateAsset, setDuplicateAsset] = useState(true);

    const [itemText, setItemText] = useState('a');
    const [storeIdData, setStoreIdData] = useState('');

    const [assetItemText, setAssetItemText] = useState('')
    const [assetItemsuggestions, setAssetItemSuggestions] = useState('');

    const [itemAssetText, setItemAssetText] = useState('a');
    const [AssetIdData, setAssetIdData] = useState('')
    const [assetErr, setAssetErr] = useState('');
    const [assetIndex, setAssetIndex] = useState('');
    const [keyAsset, setKeyAsset] = useState(true);

    /*   const[error,setError]=useState(false);
      const[errorLabel,setErrorLabel]=useState(false); */


    useEffect(() => {


        const fetchStores = async () => {

            let storesdata = await API_Auth.getSubstores();

            let filteredarry = storesdata.filter(value => value.StoreID != StorID);
            filteredarry.sort((a, b) => a.StoreName.localeCompare(b.StoreName));
            setStore(filteredarry);

            let assets = await API_Auth.getAsset();
            assets.sort((a, b) => a.AssetName.localeCompare(b.AssetName))

            setAssetItems(assets)

            let approverNamesData = await API_Auth.getSubstoreApprover(StorID);
            setApproverName(approverNamesData.length != 0 ? approverNamesData : [])



        };
        // call the function
        fetchStores()
            // make sure to catch any error
            .catch(console.error);
    }, [])

    const [inputFields, setInputFields] = useState([{
        itemName: '',
        qunatity: '',
        comments: '',
        availableQunatity: '',
        UOM: "",
        id: 0,
        error: true,
        errorlabel: ""

    }]);


    const addInputField = () => {
        /*  setInputFields([...inputFields, {
             itemName: '',
             qunatity: '',
             comments: '',
             itemid: '',
             availableQunatity: '',
             UOM: "",
             error: true,
             errorlabel: ""
 
 
         }])  */
        setItemErr("");

        var data = inputFields;
        if (data.length != 0) {
            for (var i = 0; i < data.length; i++) {
                var feed = {
                    itemName: '',
                    qunatity: '',
                    comments: '',
                    itemid: '',
                    availableQunatity: '',
                    UOM: "",
                    id: i + 1,
                    error: true,
                    errorlabel: ""

                };
            }
        } else {
            var feed = {
                itemName: '',
                qunatity: '',
                comments: '',
                itemid: '',
                availableQunatity: '',
                UOM: "",
                id: 0,
                error: true,
                errorlabel: ""

            };
        }
        data.push(feed);
        setInputFields([...data]);
    }

    const handleChange = (index, evnt) => {

        const { name, value } = evnt.target;
        const list = [...inputFields];
        // list[index][name] = value;
        setAssetIndex(index);
        if (name === "qunatity") {


            list[index][name] = value
        }
        if (name === "comments") {
            console.log(value);
             // ✅ with regular expression
          
            list[index][name] = value
        }
        if (name === "itemName") {
            const assetItemsData = assetItems.filter(item => {
                return (item.AssetType.toLowerCase().includes(assetType.toLowerCase()))
            });
            if (value.length == 0) {
                setAssetItemSuggestions(assetItemsData);
                list[index][index] = index;
                list[index][name] = value;
            } else {
                const filterValue = value;

                list[index][name] = value;

                list[index][index] = index;

                const suggestdta = assetItemsData.filter(item => {
                    return item ?.AssetName ?.toLowerCase().includes(filterValue.toLowerCase())                    
                });
                /*   const suggestdta = assetItems.filter(item => {
                      return item ?.AssetName ?.toLowerCase().includes(filterValue.toLowerCase())
                      
                      }); */
                setItemAssetText('f')
                if (suggestdta.length == 0) {
                    setAssetErr('item not found');
                } else {
                    setAssetErr('');

                }
                setAssetItemSuggestions(suggestdta);
            }
        };
        setInputFields(list);
    }
    const handleInput = async (e) => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;
        if (name === "itemName") setitemName(value);
        if (name === "store_type") {
            // setstore_type(value);
            if (value.length == 0) {
                setItemSuggestions(stores);
                setStoreText('');
            } else {
                const filterValue = value;
                setStoreText(value);
                const suggestdta = stores.filter(item => {
                    return item ?.StoreName ?.toLowerCase().includes(filterValue.toLowerCase())
                });
                setItemText('f')
                if (suggestdta.length == 0) {
                    setStoreErr('Store not found');
                } else {
                    setStoreErr('');
                }
                setItemSuggestions(suggestdta);
            }
        }
        if (name === "comments") {

            setcomments(value);
        }
        if (name === "quantity") setquantity(value);
    };

    const handleTextChange = async (item) => {
        setStoreErr('');
        setStoreText(item.StoreName);
        setStoreIdData(item.StoreID);
        setItemText('a');

        /*   let approverNamesData = await API_Auth.getSubstoreApprover(item.StoreID);
          setApproverName(approverNamesData.length != 0 ? approverNamesData : []) */

        // setItemSuggestions([]);
    }

    const handleTextAssetChange = async (item, index) => {
        setItemErr('')

        let totalArray = await handleItems(item);
        /*  for (var i = 0; i < inputFields.length; i++) {
             if (parseInt(inputFields[i].itemid) === parseInt(item.id)) {
                inputFields[assetIndex].itemName = item.AssetName;
                inputFields[assetIndex].itemid = "";
                inputFields[assetIndex].UOM = item.UOM
                inputFields[assetIndex].error=false;
                inputFields[assetIndex].errorlabel="Duplicate Entry"
             }  else {
                // keyAsset = true;
                inputFields[assetIndex].itemName = item.AssetName;
                inputFields[assetIndex].itemid = item.id;
                inputFields[assetIndex].UOM = item.UOM
                inputFields[assetIndex].error=true;
                inputFields[assetIndex].errorlabel=""
             }
             break;
         }
      */
        if (totalArray) {
            inputFields[assetIndex].itemName = item.AssetName;
            inputFields[assetIndex].itemid = item.id;
            inputFields[assetIndex].UOM = item.UOM
            setItemErr('');
            setDuplicateAsset(false);
            inputFields[assetIndex].error = true;
            inputFields[assetIndex].errorlabel = "true"

        } else {
            inputFields[assetIndex].itemName = item.AssetName;

            //setItemErr('Please Select Another Item ..Item is already added');
            inputFields[assetIndex].error = false;
            inputFields[assetIndex].errorlabel = "Duplicate Entry"
            setDuplicateAsset(true);
        }





        let asset_det = await API_Auth.getConsumeAssetQuantity(storeIdData, item.id);
        inputFields[assetIndex].availableQunatity = asset_det.length != 0 ? asset_det[0].Quantity : '';
        //  inputFields[assetIndex].UOM = asset_det.length != 0 ? asset_det[0].UOM : ''



        setInputFields(inputFields)

        setItemAssetText('a')

        // setItemSuggestions([]);
    }


    const handleItems = async (item) => {
        let totalErrorArray = []

        /*   for (var i = 0; i < inputFields.length; i++) {
  
  
              if (inputFields[i].itemid === item.id) {
  
                  return false
  
              }
              return true;
          }
          return totalErrorArray; */


        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].itemid === item.id) {
                return false;
            }
        }
        return true;   // this means not unique

    }

    const renderSuggestions = () => {
        if (itemsuggestions.length == 0) {
            return null;
        }
        return (
            <ul className={itemsuggestions.length != 0 && itemText == 'f' ? "ul_search" : "ul_search_length"} >
                {itemsuggestions.length != 0 && itemText == 'f' &&
                    <>

                        {itemsuggestions.map((item, index) => {
                            return (
                                <li className="li_search" onClick={() => handleTextChange(item)}>{item.StoreName}</li>
                            )
                        })}
                    </>
                }
            </ul>
        )
    }

    const renderItemSuggestions = () => {
        if (assetItemsuggestions.length == 0) {
            return null;
        }
        return (
            <ul className={assetItemsuggestions.length != 0 && itemAssetText == 'f' ? "ul_search" : "ul_search_length"} >
                {assetItemsuggestions.length != 0 && itemAssetText == 'f' &&
                    <>

                        {assetItemsuggestions.map((item, index) => {
                            return (
                                <li className="li_search" onClick={() => handleTextAssetChange(item, index)}>{item.AssetName}</li>
                            )
                        })}
                    </>
                }
            </ul>
        )
    }
    const [checked, setChecked] = useState(false);


    const handleSendApproval = async () => {
        setDisableSubmit(true);

        var array = []
        for (var i = 0; i < inputFields.length; i++) {

            const result1 = inputFields[i]["comments"].replace(/_+/g, ' ');
            console.log("result1",result1); // 
            const result2 = result1.replace(/,+/g, ' ');
            console.log("result2",result2); // 


            let var_request = inputFields[i]["itemid"] + "_" + inputFields[i]["qunatity"] + "_'" + result2 + "'";
            array.push(var_request);
            console.log("array",array);
        }
        let text = array.toString();
        let error = false;

        if (approverName.length == 0) {
            toast.error("Approver is not defined.");
            error = true;
        }
        else {

            if (storeText == "") {

                setStoreErr("Please Select Store")
                error = true;
            }
            else if (itemsuggestions.length == 0) {
                setStoreErr('Store Not Found');
                error = true;


            } else if (inputFields.length == 0) {
                setItemErr("Please Add items");
                error = true;
            }
            if (inputFields.length != 0) {

                for (var i = 0; i < inputFields.length; i++) {

                    if (inputFields[i].itemName === "" || inputFields[i].qunatity === "") {
                        setItemErr("Please Enter All Fields");
                        error = true;
                    }
                }
            }
            if (duplicateAsset == true) {
                //  setItemErr("Please Select Another Item ..Item is already added");
                error = true;


            }

        }


        if (!error) {
            setIsLoading(true);
            let requestCreatorID = localStorage.getItem("UserID");
              let add_request_info = await API_Auth.addRequest(StorID, storeIdData, text, assetType, requestCreatorID);
            if (add_request_info[0].Flag == "Success") {
                setIsLoading(false);

                toast.success('Request added successfully');
                setTimeout(() => {
                    navigate("/sent-request/" + StorID);
                }, 2000)
            } else {
                setDisableSubmit(false);

                toast.error('Something went wrong');

            }
        } else {
            setDisableSubmit(false);

        }



    }

    const onValueChange = (event) => {
        /*  this.setState({
           selectedOption: event.target.value
         }); */
        setAssetType(event.target.value)
    }
    const handleDeleteItem = (data, index) => {
        var setValue = inputFields;
        const dataRemoved = setValue.filter((el, elindex) => {
            return el.id !== data.id;
        });

        setInputFields([...dataRemoved])


    }
    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            {isLoading && <LoadingSpinner />}

            <Row>
                <Col lg={4}>
                    <p className="title">{SubstoreName} Store</p>
                    <p className="subtitle">New Request</p>

                </Col>
                <Col lg={5}></Col>
            </Row>
            <hr />

            <Container>
                <Row>
                    <Col lg={5}>
                        <div className="inputdiv">
                            <label>To</label>
                            <br />

                            <div className="row_div_search">
                                <div className="input_div" >
                                    <input type="type" style={{ margin: 0, background: 'transparent' }}
                                        name="store_type"
                                        value={storeText}
                                        onChange={handleInput}
                                        placeholder="Search Store" />
                                    <AiOutlineSearch size={24} style={{ margin: 10, cursor: 'pointer' }} color="lightgray" />
                                </div>

                            </div>
                            {renderSuggestions()}
                            {/*  <select
                                value={store_type}
                                name="store_type"
                                onChange={handleInput} >
                                <option >Select</option>

                                {stores.length != 0 &&
                                    <>
                                        {stores.map((item, index) => {
                                            return (
                                                <option value={item.StoreID}>{item.StoreName}</option>
                                            )
                                        })}
                                    </>
                                }

                            </select> */}
                            {storeErr != '' && <p className="alert-message">{storeErr}</p>}

                        </div>
                    </Col>
                    <Col>
                        {approverName.length != 0 && <div className="inputdiv">
                            <label>New Approval?</label>
                            <div className="check_div">
                                <input type="checkbox" className="check_input" checked="true" />

                                {approverName.length != 0 &&
                                    <div style={{ flexDirection: 'row', display: 'flex' }}>
                                        {approverName.map((item, index) => {
                                            return (
                                                <div style={{ flexDirection: 'row', display: 'flex' }}>
                                                    <p>{item.ApproverName}, </p>
                                                </div>
                                            )
                                        })}
                                        <p>(HOD)</p>
                                    </div>}

                            </div>

                            {/* <select style={{marginTop:18}}  >
                                {approverName.map((item,index)=>{
                                    return(
                                        <option value="" >{item.ApproverName}</option>
                                    )
                                })}
                                </select>
                       */}  </div>
                        }
                    </Col>
                </Row>
                <Row style={{ marginTop: 20, marginBottom: 20, marginLeft: 2 }}>
                    <label style={{ marginBottom: 20 }}>Type</label>
                    <Col lg={3}>
                        <input type="radio" id="Consumable" name="radio" value="Consumable"
                            style={{ width: 25, height: 15, margin: 5 }}
                            checked={assetType === "Consumable"}

                            onChange={onValueChange}
                        />
                        <label for="Consumable" className="radiolabel">Consumable</label>
                    </Col>
                    <Col lg={3}>
                        <input type="radio" id="Consumable" name="radio" value="Fixed" style={{ width: 25, height: 15, margin: 5 }}
                            onChange={onValueChange}
                            checked={assetType === "Fixed"}

                        />
                        <label for="Consumable" className="radiolabel">Fixed</label>
                    </Col>
                </Row>
                <Row>
                    <Col lg={3}><label>Item Name</label></Col>
                    <Col lg={2}><label>UOM</label></Col>

                    <Col lg={2}><label>Available Quantity</label></Col>

                    <Col lg={2}><label>Quantity</label></Col>

                    <Col lg={2}><label>Comments</label></Col>
                    <Col lg={1}><label >Action</label></Col>


                </Row>

                <div>
                    {
                        inputFields.map((data, index) => {
                            const { itemName, qunatity, comments, availableQunatity, UOM, error, errorlabel } = data;
                            return (
                                <Row key={index}>
                                    <Col lg={3}>
                                        <div className="inputdiv">

                                            <div className="row_div_search">
                                                <div className="input_div" >
                                                    <input type="type" style={{ margin: 0, background: 'transparent' }}
                                                        name="itemName"
                                                        value={itemName}
                                                        onChange={(evnt) => handleChange(index, evnt)}
                                                        placeholder="Item Name" />
                                                    <AiOutlineSearch size={24} style={{ margin: 10, cursor: 'pointer' }} color="lightgray" />
                                                </div>

                                            </div>
                                            {index == assetIndex &&
                                                <>
                                                    {renderItemSuggestions()}
                                                </>
                                            }
                                            {/* // {assetErr != '' && <p className="alert-message">{assetErr}</p>} */}


                                            {error == false && <p className="alert-message">{errorlabel}</p>}

                                            {/* <select
                                                name="itemName"
                                                value={itemName}
                                                placeholder=" Item Name"
                                                onChange={(evnt) => handleChange(index, evnt)}>
                                                <option>Select</option>

                                                {assetItems.length != 0 &&
                                                    <>
                                                        {assetItems.map((obj, index) => {
                                                            return (
                                                                <option value={obj.id}>{obj.AssetName}</option>
                                                            )
                                                        })}
                                                    </>
                                                }
                                            </select>
 */}
                                        </div>
                                    </Col>
                                    <Col lg={2}>
                                        <div className="inputdiv">
                                            <input type="text"
                                                name="UOM"
                                                value={UOM}
                                                placeholder="  "
                                                disabled="true"
                                                onChange={(evnt) => handleChange(index, evnt)} />
                                        </div>
                                    </Col>
                                    <Col lg={2}>
                                        <div className="inputdiv">
                                            <input type="text" min="0"
                                                name="availableQunatity"
                                                value={availableQunatity}
                                                placeholder="  "
                                                disabled="true"
                                                onChange={(evnt) => handleChange(index, evnt)} />
                                        </div>
                                    </Col>

                                    <Col lg={2}>
                                        <div className="inputdiv">
                                            <input type="text" min="0"
                                                name="qunatity"
                                                value={qunatity}
                                                placeholder=" "
                                                onChange={(evnt) => handleChange(index, evnt)} />
                                        </div>
                                    </Col>

                                    <Col lg={2}>
                                        <div className="inputdiv">

                                            <input type="text"
                                                name="comments"
                                                value={comments}
                                                placeholder="Type Something"
                                                onChange={(evnt) => handleChange(index, evnt)} />
                                        </div>
                                    </Col>
                                    <Col lg={1}>
                                        <img src={Delete} onClick={() => handleDeleteItem(data, index)} style={{ width: 20, height: 20, marginTop: 40, marginLeft: 15, cursor: 'pointer' }} />
                                    </Col>


                                </Row>
                            )
                        })
                    }
                    {itemErr != '' && <p className="alert-message">{itemErr}</p>}

                </div>
                <Row>
                    <Col lg={4}>

                        <div className="row_table_div " onClick={() => addInputField()} style={{ width: 115, margin: 15, fontWeight: 'bold', float: 'left' }}>
                            <AiOutlinePlus size={20} style={{ margin: 10 }} />
                            <p>ADD ITEMS</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={3}>

                        <button className="add_button" disabled={disableSubmit} onClick={() => handleSendApproval()}>
                            <p className="pcat_title"> SEND FOR APPROVAL</p></button>
                    </Col>
                </Row>
            </Container>
            <ToastContainer />
        </Wrapper>
    )
}
export default FixedRequestAdd