import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Wrapper } from "./Designaiton.styles.js"
import { Row, Container, Col } from "react-bootstrap"
import Table from "react-bootstrap/Table";
import Edit from "../../images/edit.png";
import { LoadingSpinner } from "../LoadingSpinner/index";
import { AiOutlineSearch } from "react-icons/ai";
import { Title } from "../../GlobalStyle";
import API_Auth from "../../API_Auth";

import ReactPaginate from 'react-paginate';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Modal from 'react-bootstrap/Modal';
import {toast } from "react-toastify";
import Delete from "../../images/delete.png"


const Designation=()=>{
    const {
        isExpanded,
    } = useContext(UserContext);
    const [isLoading,setIsLoading]=useState(false);
    const [formTitle, setFormTitle] = useState('ADD DESIGNATION');
    const [searchData, setSearchData] = useState([]);
    const [offset, setOffSet] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [orgtableData, setOrgTableData] = useState([]);
    const [perPage, setPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [searchKey,setSearchKey]=useState('n');
     const[searchKeyTable,setSearchKeyTable]=useState('');
     const[designationData,setDesignationData]=useState('');
     const [formButtonValue, setFormButtonValue] = useState('ADD');
     const[designationName,setDesignationName]=useState('');
     const[designationId,setDesignationId]=useState('');
     const [disableSubmit, setDisableSubmit] = useState(false);


     const[show,setShow]=useState(false);
     const[designationNameErr,setDesignationNameErr]=useState('');

   
     const handleEditUOMDesignation=(obj)=>{
        setShow(true)
        setFormTitle('Edit Designation');
        setFormButtonValue('UPDATE');
        setDesignationName(obj.Designation);
        setDesignationId(obj.id);
    }
     
     const handleAddDesignation=()=>{

        setShow(true);
        setDesignationName('')

     }

     const handleUOMName = (e) => {
         setDesignationName(e.currentTarget.value)
    }

     const handleDesignationAdd = async () => {
        setDisableSubmit(true);
        let error = false;
        if (designationName == '') {
            setDesignationNameErr('this field is required.');
            error = true;
        }
        else{
            setDesignationNameErr(''); 
        }
        if (!error) {
            setIsLoading(true);

            if (formButtonValue == 'ADD') {
                let result = await API_Auth.insertDesignation(designationName);
                let res = result[0];
                if (res.Flag == 'Success') {
                    setShow(false);
                    setIsLoading(false);

                    toast.success('Designation added successfully');
                    setInterval(() => {
                        window.location.reload();
                    }, 2000);

                } else {
                    setIsLoading(false);
                    setDisableSubmit(false);
                    toast.error('Something went wrong please try again');
                }
            } else if (formButtonValue == 'UPDATE') {

                let result = await API_Auth.updateDesignation(designationName, designationId);
                let res = result[0];
                if (res.Flag == 'Success') {
                    setIsLoading(false);

                    setShow(false);
                    toast.success('Designation updated successfully');
                    setInterval(() => {
                        window.location.reload();
                    }, 2000);

                } else {
                    setIsLoading(false);
                    setDisableSubmit(false);
                    toast.error('Something went wrong please try again');
                }
            }
        } else {
            setDisableSubmit(false);
        }
    }

     useEffect(() => {
        setIsLoading(true);
        const fetchDesignations = async () => {
            let result = await API_Auth.getDesignations();
           // result.sort((a, b) => a.v_categoryName.localeCompare(b.v_categoryName))
            setDesignationData(result);
            setIsLoading(false);
            var slice = result.slice(offset, offset + perPage);
            setPageCount(Math.ceil(result.length / perPage));
            setOrgTableData(result);
            setTableData(slice);
            setSearchData(result);
        };
        fetchDesignations()
            // make sure to catch any error
            .catch(console.error);
    }, []);

    
    const handlerowsperPage = (e) => {
        let showperpage = e.currentTarget.value
        setPerPage(parseInt(showperpage));
        setCurrentPage(0);
        setOffSet(0);
        if(searchKey=="y"){
            const slice = searchKeyTable.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(searchKeyTable.length / e.currentTarget.value))
            setTableData(slice);   
            setSearchData(searchKeyTable); 
        }
        else{
        const slice = orgtableData.slice(0, 0 + e.currentTarget.value);
        setPageCount(Math.ceil(orgtableData.length / e.currentTarget.value))
        setTableData(slice);
        setSearchData(orgtableData);
        }
    }
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offsetdata = selectedPage * perPage;
        setCurrentPage(selectedPage);
        setOffSet(offsetdata);
        loadMoreData(offsetdata);
    }

    const loadMoreData = (offsetdata) => {
        
        const data = orgtableData;
        setOffSet(offsetdata);
        
        if(searchKey=="y"){
            
            const slice = searchKeyTable.slice(offsetdata, offsetdata + perPage);
            
            setPageCount(Math.ceil(searchKeyTable.length / perPage))
            setTableData(slice);
            setSearchData(searchKeyTable);
        }else{  
        const slice = data.slice(offsetdata, offsetdata + perPage);
        setPageCount(Math.ceil(data.length / perPage));
        setTableData(slice);
        setSearchData(data);
        }

    }

    const handleClose = () => {
        setShow(false)
    }

    const handleSearch = (e) => {
        let filterValue = e.target.value;
        
        setCurrentPage(0);     
        if (filterValue == '') {
            let offsetsearch=0;
            
            var slice = orgtableData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(orgtableData.length / perPage));
            setOrgTableData(orgtableData);
            setCurrentPage(0);
            setOffSet(offsetsearch);
            setTableData(slice);
            setSearchData(orgtableData);  
            setSearchKey('n');
        }
        else {
            let offsetsearch=0;
            
            const filteredData = orgtableData.filter(item => {
                return item ?.Designation ?.toLowerCase().includes(filterValue.toLowerCase())
            });
            
            
            const slice = filteredData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(filteredData.length / perPage))
            setSearchKey('y');
            
            setSearchKeyTable(filteredData);
            setTableData(slice);
            setOffSet(offsetsearch);
            setSearchData(filteredData);
        }
    }

    const handleDeleteDesignation = (obj) => {
        
        if (window.confirm('Are you sure you want to delete the Designation ?')) {
            // Save it!
            const fetchDeleteVendor = async () => {
                let result = await API_Auth.deleteDesignation(obj.id);
                let res = result[0];
                if (res.Flag == 'Success') {
                    setIsLoading(true);

                    toast.success('Designation Deleted successfully');
                    const fetchDesignation= async () => {
                        let result = await API_Auth.getDesignations();
                        // result.sort((a, b) => a.v_categoryName.localeCompare(b.v_categoryName))
                        setDesignationData(result);
                         setIsLoading(false);
                         var slice = result.slice(offset, offset + perPage);
                         setPageCount(Math.ceil(result.length / perPage));
                         setOrgTableData(result);
                         setTableData(slice);
                         setSearchData(result);
                    };
                    // call the function
                    fetchDesignation()
                        // make sure to catch any error
                        .catch(console.error);

                } else {
                    toast.error('Something went wrong please try again');
                }

            };
            // call the function
            fetchDeleteVendor()
                // make sure to catch any error
                .catch(console.error);
        } else {
            // Do nothing!
        }

    }
    
    return(
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
        {isLoading && <LoadingSpinner />}
        <Container>
            <Row>
                <Col lg={4} style={{paddingLeft:0}}>
                    <Title>Designation</Title>
                </Col>
                <Col lg={5} style={{paddingRight:0}}>
                    <div className="row_div">
                          <div className="input_div" >
                            <input type="type" style={{ margin: 0, background: 'transparent' }}
                                name="filterValue"
                               onChange={(e) => handleSearch(e)}
                                placeholder="Search" />
                            <AiOutlineSearch size={24} style={{ margin: 10, cursor: 'pointer' }} color="lightgray" />
                        </div>

                    </div>
                </Col>
                <Col>
                <button className="add_button" onClick={() => handleAddDesignation()}>
                               <p className="pcat_title"> {formTitle}</p></button>
                 
                  </Col>
            </Row>
            <Row>
            <Col lg={12} sm={6} md={6} className="table_head">
            <Table className="tablelist">
            <thead>
                                <tr>
                                    <th>Designation</th>
                                    <th style={{textAlign:'right'}}>Action</th>
                                </tr>
                            </thead>
                            {tableData.length == 0 && <tbody>
                                <tr>
                                    <td colSpan="12">
                                        <p className="no_Data_table">No Data Found</p>
                                    </td>
                                </tr>
                            </tbody>
                            }
                            
                            {tableData.length != 0 && <tbody>
                                {tableData.map((obj, index) => {
                                    return (
                                        <>
                                            <tr key={index}>
                                                <td>{obj.Designation}</td>
                                                <td style={{textAlign:'right'}}>
                                                <div className="row_table_edit " onClick={() => handleDeleteDesignation(obj)}>
                                                        <img src={Delete} />
                                                        <p>DELETE</p>
                                                    </div>
                                                    <div className="row_table_edit " onClick={() => handleEditUOMDesignation(obj)} style={{marginRight:20}}>
                                                        <img src={Edit} />
                                                        <p>EDIT</p>
                                                    </div>
                                                  
                                                </td>
                                            </tr>
                                        </>
                                    );
                                })}
                            </tbody>
                            }




</Table>

 <div>
                        <div className="pagination_row_div">
                            <p className="pagination_title">Showing items per page:</p>
                            <div className="pagination_input_div" >
                                <select className="pagination_select"
                                 style={{ margin: 0, background: 'transparent' }}
                                    name="pagination_value"
                                    value={perPage}
                                    onChange={handlerowsperPage} >
                                    <option value={4}>4</option>
                                    <option value={8}>8</option>
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                </select>
                                </div>
                        </div>
                        <div className="pagination_row_right_div">
                        <p className="pagination_total">Showing {offset+1} to {searchData.length<offset+perPage&&
                        <span>{searchData.length}</span> }
                        {searchData.length>offset+perPage&&
                        <span>{offset+perPage }</span>} of {searchData.length} items</p>
                         <ReactPaginate
                            previousLabel={<ArrowBackIosIcon  className="pagination_prev_btn" color="#2438a7"/>}
                            nextLabel={<ArrowForwardIosIcon className="pagination_next_btn" color="#2438a7"/>}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"} 
                            forcePage={currentPage} />
                    </div>
                    </div>
            </Col>
                </Row>
                <Modal show={show} onHide={handleClose}
                    aria-labelledby="contained-modal-title-vcenter"
                    dialogclassname="modal-content">
                    <Modal.Header closeButton>
                        <Modal.Title>{formButtonValue} Designation</Modal.Title>
                    </Modal.Header>

                    <Modal.Body >
                        <div>
                        <label >Designation Name</label>
                            <br />
                            <div className="input_div" >
                            <input placeholder='Enter Designation Name'
                                value={designationName}
                                onChange={handleUOMName}
                                style={{ width: "85%" }}
                            />
                            </div>
                            {designationNameErr != '' && <p className="alert-message">{designationNameErr}</p>}
                        </div>
                        
                    </Modal.Body>
                    <Modal.Footer className="modal-footer">
                        <div className="row_div">
                            <button className="add_button" onClick={() => handleDesignationAdd()} style={{
                                    padding: 12,
                                    height: 45,
                                    margin: 10 
                            }}>
{/*                                 <img src={plus} style={{ margin: 5 }} /> */}
                                <p className="pcat_title">{formButtonValue}</p></button>
                        </div>
                    </Modal.Footer>
                </Modal>


            </Container>
                </Wrapper>

    )

}

export default Designation