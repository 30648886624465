import styled from "styled-components";

export const Wrapper = styled.div`


.tablelist {
  margin-top:15px;
  margin-right:5px;;
}
 
thead{
  color:black;
  background:#c0d3ef;
  border:1px solid #c0d3ef;
  border-radius:10px;
}
td{
  color:black;
  font-weight:400;
  font-size:17px;
  height:60px;
  padding:15px;
  font-family:Roboto;
  text-transform: capitalize;
}
th{
  font-size:16px;
  height:50px;
  padding:14px;
  font-weight:500;
  font-family:Roboto;

}
.table-head-content {
  padding: 13px 13px 0 13px;
  background: white;
  border-bottom: 1px solid #E2E2E2;   
}  

.row_div {
  display:flex;
  flex-direction:row;
  padding: 8px 0px;
}
.input_div {
  border: 2px solid #DFDFDF;
  width: 80%;
  color:var(--black);
  font-family: 'ARIAL';
  height:45px;
  border-radius:6px;
  margin:5px 0px;
  background:white;
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  input,select{
     border:none;
     :focus {
       outline: none !important;
       border-color:none;  
     }
    }
   
}
.title{
  font-family:Roboto;
  font-size:16px;
  font-weight:600;
  margin-left:10px;
  margin-bottom:0 !important;
  margin-top:15px;

}

.adddiv {
  background:white;
  width: 80%;
  margin-top:15px;
  margin-left:20px;
  border-radius:5px;
  padding: 10px 0px;
  
  
  
}
.rowdiv {
  margin:10px;

}
.add_button {
  display:flex;
  flex-direction:row;
  background:#6EDC8F;
  color:black;
  border:#4EACFB;
  padding:10px;
  height:45px;
  margin:15px 10px 5px;
  text-align:center;
  justify-content:center;
  align-item:center;
  box-shadow: 0px 2px 2px #00000030;
border-radius: 6px;
opacity: 1;  
}
.add_cat_button {
  display:flex;
  flex-direction:row;
  background:#000044;
  color:white;
  border:#4EACFB;
  width:85%;
  text-align:center;
  justify-content:center;
  align-item:center;
  box-shadow: 0px 2px 2px #00000030;
  border-radius: 6px;
  opacity: 1;    border:#4EACFB;
  padding:10px;
  height:40px;
  width:85%;
  margin: 50px auto;
  font-weight:bold;
  margin-bottom: 24px;

}

.alert-message{
  color: red;
  margin-left: 15px;
}

`