import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Row, Container, Col, OverlayTrigger, Tooltip, Button } from "react-bootstrap"
import { Wrapper } from "./styles.js"

import API_Auth from "../../API_Auth";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Title } from "../../GlobalStyle.js"
import Table from "react-bootstrap/Table";
import moment from "moment"
import InfoIcon from '@mui/icons-material/Info';
import { AiOutlineSearch } from "react-icons/ai";
import Modal from 'react-bootstrap/Modal';

import { LoadingSpinner } from "../LoadingSpinner/index";

const Consumption = () => {
    const navigate = useNavigate();
    const {
        isExpanded,
    } = useContext(UserContext);
    const [formTitle, setFormTitle] = useState('Item Consumption');
    const [assets, setAssets] = useState([]);
    const [assetText, setAssetText] = useState('');
    const [itemsuggestions, setItemSuggestions] = useState('');
    const [itemText, setItemText] = useState('a');
    const [assetErr, setAssetErr] = useState('')
    const [disabledAddRows, setAddRowsDisabled] = useState(false)
    const [asset, setAsset] = useState('');
    const [qunatity, setQunaity] = useState('');
    const [qunatityErr, setQunatityErr] = useState('')
    let { StorID, assetID } = useParams();
    const [consume_date, setDate] = useState('');
    const [dateErr, setDateErr] = useState('');
    const [consume_qty, setConsumed] = useState('');
    const [consumedErr, setConsumedErr] = useState('')
    const [batchNo, setBatchNo] = useState('');
    const [batchErr, setBatchErr] = useState('')
    const [no_patients, setno_patients] = useState('')
    const [no_patientsErr, setNo_patientsErr] = useState('');
    const [no_investigations, setno_investigations] = useState('')
    const [patientID, setPatientID] = useState('');
    const [patientIDErr, setPatientIDErr] = useState('');
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [typeChecked, setChecked] = useState('');
    const [comments, setComments] = useState('')
    const [commentsErr, setCommentsErr] = useState('');
    const [formButtonValue, setFormButtonValue] = useState('UPDATE');
    const [totalArrayAsset, setTotalArrayAsset] = useState([]);
    const [duplicateAsset, setDuplicateAsset] = useState(false);
    const [showAssetList, setShowAssetList] = useState(false);


    const handleCloseAssetList = () => setShowAssetList(false)

    const handleUpdateItems = () => {
        setShowAssetList(false)
    }
    const handleRemove = (obj, index) => {
        var setValue = totalArrayAsset;
        const dataRemoved = setValue.filter((el) => {
            return el.asset !== obj.asset;
        });
        setTotalArrayAsset(dataRemoved);
    }

    const handleInput = (e) => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;

        if (name === "asset") {
            if (value.length == 0) {
                setItemSuggestions(assets);
                setAssetText('');
            } else {
                const filterValue = value;

                setAssetText(value);
                const suggestdta = assets.filter(item => {
                    return item ?.AssetName ?.toLowerCase().includes(filterValue.toLowerCase())
                    
                    });
                setItemText('f')
                if (suggestdta.length == 0) {
                    setAssetErr('Asset not found');
                } else {
                    setAssetErr('')
                }
                setItemSuggestions(suggestdta);

            }

        };

        if (name === "consume_date") setDate(value);
        if (name === "consume_qty") setConsumed(value);
        if (name === "qunatity") setQunaity(value);
        if (name === "no_patients") setno_patients(value);
        if (name === "no_investigations") setno_investigations(value);
/*         if (name === "batchNo") setBatchNo(value);
 */        if (name === "patientID") setPatientID(value);
        if (name === "comments") setComments(value);
    }

    const handleViewItems = () => {
        setShowAssetList(true)

    }

    const handleAddAsset = async () => {

        setDisableSubmit(true);

        setPatientIDErr('');
        setConsumedErr('');
        setDateErr('');
        setNo_patientsErr('');
        setCommentsErr('');
        setBatchErr('');

        let error = false;
       
        if (!error) {

            if (totalArrayAsset.length == 0) {
               toast.error("Please add rows")
            } else {
                setIsLoading(true);

                let totalErrorArray = await handleMultipleRows();


                if (totalErrorArray.length === 0) {
                    setIsLoading(false)

                    toast.success('Consumption Successful');
                    setTimeout(() => {
                        navigate("/assets-management/" + StorID);

                    }, 2000)

                } else {
                    setDisableSubmit(false);

                    setIsLoading(false)
                    toast.error('Something went Wrong')


                }

            }
        } else {
            setDisableSubmit(false);
        }
    }

    const handleMultipleRows = async () => {
        let totalErrorArray = []

        for (var i = 0; i < totalArrayAsset.length; i++) {
            let app = totalArrayAsset[i];
            let result = await API_Auth.addConsumeAsset(app.asset, app.StorID, app.patientID, app.consume_qty, app.no_patients || app.no_investigations, app.comments, app.batchNo, app.consume_date);
            const res = result[0];
            if (res.Flag == 'Fail') {
                totalErrorArray.push(i);

            } 
        }
        return totalErrorArray;
    }

    useEffect(() => {
        const date = new Date();
        const futureDate = date.getDate();
        date.setDate(futureDate);
        const defaultValue = date.toLocaleDateString('en-CA');
        setDate(defaultValue);

        const fetchAsset = async () => {
            let result = await API_Auth.getAsset();
            const filteredData = result.filter(item => {
                return (item.AssetType.toLowerCase().includes("consumable"))

            });
            setAssets(filteredData);
        };
        // call the function
        fetchAsset()
            // make sure to catch any error
            .catch(console.error);
    }, []);

    const handleTextChange = async (item) => {
        setAddRowsDisabled(false);
        if (totalArrayAsset.length != 0) {
            for (var i = 0; i < totalArrayAsset.length; i++) {
                if (totalArrayAsset[i].asset === item.id) {
                    setAssetErr('Please Select Another Item ..Item is already added');
                    setAssetText(item.AssetName);
                    setDuplicateAsset(true);
                    break;
                } else {
                    setAssetText(item.AssetName);
                    setAsset(item.id);
                    setDuplicateAsset(false);
                }
            }
        } else {
            setAssetText(item.AssetName);
            setAsset(item.id)
        }
        //setAssetText(item.AssetName);
        //setAsset(item.id)

        setAssetText(item.AssetName);
        setAsset(item.id);
        let asset_det = await API_Auth.getConsumeAssetQuantity(StorID, item.id);
        let data = asset_det.length != 0 ? asset_det[0].Quantity : ''
        setQunaity(data);
        if(data==""){
        setAssetErr("Item not available");
        setAddRowsDisabled(true);
        }
        setItemText('a');
        // setItemSuggestions([]);
    }

    const handleChange = (e) => {
        setChecked(e.currentTarget.value);
    };


    const renderSuggestions = () => {
        if (itemsuggestions.length == 0) {
            return null;
        }
        return (
            <ul className={itemsuggestions.length != 0 && itemText == 'f' ? "ul_search" : "ul_search_length"} >
                {itemsuggestions.length != 0 && itemText == 'f' &&
                    <>

                        {itemsuggestions.map((item, index) => {
                            return (
                                <li className="li_search" onClick={() => handleTextChange(item)}>{item.AssetName}</li>
                            )
                        })}
                    </>
                }
            </ul>
        )
    }
    const addInputField = () => {
        setPatientIDErr('');
        setQunatityErr('');
        setConsumedErr('');
        setDateErr('');
        setNo_patientsErr('');
        setCommentsErr('');
        setBatchErr('');

        let error = false;

        /*   if (no_patients != '') {
              if (patientID == '') {
                  setPatientIDErr('This field is required');
                  error = true;
              }
          } */
        if (asset == '' || assetText == "") {
            setAssetErr('This field is required');
            error = true;
        }

        /*  if (qunatity == '') {
             setQunatityErr('This field is required');
             error = true;
         } */
        if (consume_qty == '') {
            setConsumedErr('This field is required');
            error = true;
        }
        if (consume_date == '') {
            setDateErr('This field is required');
            error = true;
        }
        /*   if (batchNo == "") {
              setBatchErr('This field is required');
              error = true;
          }
          if (no_patients == '' && no_investigations == '') {
              setNo_patientsErr('This field is required');
              error = true;
          }
          else {
              setNo_patientsErr('');
              error = false;
          } */
        /*   if (comments == '') {
              setCommentsErr('This field is required');
              error = true;
          } */
        if (duplicateAsset) {
            setAssetErr('Please Select Another Item ..Item is already added');
            error = true
        }
        if (!error) {
            let data = {
                asset: asset,
                StorID: StorID, patientID: patientID,
                consume_qty: consume_qty,
                no_patients: no_patients,
                comments: comments,
                batchNo: 'abc',
                consume_date: consume_date,
                assetText: assetText,
                qunatity: qunatity
            }
            let array = totalArrayAsset
            array.push(data);
            setAsset('');
            setAssetText('');
            setConsumed('');
            setno_patients('');
            setComments('');
            setBatchNo('');
            setPatientID('');
            setQunaity('')
            /*    const date = new Date();
   
               const futureDate = date.getDate();
               date.setDate(futureDate);
               const defaultValue = date.toLocaleDateString('en-CA');
               
               setDate(defaultValue);
               console.log(array); */
            setTotalArrayAsset(array);
        }


    }
    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            <Container>
                <Row className="row_title">
                    <Col lg={4}><p>{formTitle}</p></Col>
                    <Col lg={5}></Col>
                    {/*  <Col lg={3} >
                        <div className="view_button" onClick={() => handleViewItems()}>
                            <h6>VIEW ALL ROWS-({totalArrayAsset.length})</h6>
                        </div>
                    </Col> */}

                </Row>
            </Container>
            {isLoading && <LoadingSpinner />}

            <hr />
            <Row>
                <Col lg={6}>
                    <div className="inputdiv">
                        <label>Date</label>
                        <br />
                        <input type="date"
                            name="consume_date"
                            value={consume_date}
                            placeholder="dd/mm/yyyy"
                            max={moment().format("YYYY-MM-DD")}
                            onChange={handleInput} />
                        {dateErr != '' && <p className="alert-message">{dateErr}</p>}
                    </div>
                </Col>
            </Row>

            <Table className="tablelist">
                <thead>
                    <tr>
                        <th> Item </th>
                        <th>Quantity</th>

                        <th>Consumed Quantity</th>
                        <th>Date</th>
                        <th>Comments</th>
                        <th>Action</th>
                    </tr>
                </thead>
                {totalArrayAsset.length == 0 && <tbody>
                    <tr >
                        <td colSpan="12">
                            <p className="no_Data_table">No Rows Added</p>
                        </td>
                    </tr>
                </tbody>
                }
                {totalArrayAsset.length != 0 && <tbody>
                    {totalArrayAsset.map((obj, index) => {
                        return (
                            <>
                                <tr key={index}>

                                    <td>{obj.assetText}  </td>
                                    <td>{obj.qunatity}</td>
                                    <td>{obj.consume_qty}</td>
                                    <td>{obj.consume_date}</td>
                                    <td>{obj.comments}</td>
                                    <td>
                                        <div onClick={() => handleRemove(obj, index)}>
                                            <p style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}>Remove</p>
                                        </div>
                                    </td>
                                </tr>
                            </>
                        );
                    })}
                </tbody>
                }
            </Table>

            <Row>
                <Col lg={10}></Col>

                <Col lg={2}>
                   {/*  <div className="row_div">
                        <button className="add_buttons" onClick={() => handleAddAsset()}>
                            <p style={{ fontFamily:'Roboto',fontWeight:15,textAlign:'center',fontWeight:'500' ,
                            marginTop:10}}>{formButtonValue}</p></button>

                    </div> */}
                     <button className="row_table_divs " disabled={disableSubmit}  onClick={() => handleAddAsset()} style={{ width: 100, margin: 15, fontWeight: 'bold', float: 'left' }}>
                                    <p style={{ marginLeft: 20 }}>{formButtonValue}</p>
                                </button></Col>
            </Row>

            <Container>

                <Row style={{ background: 'aliceblue', margin: 5, borderRadius: 6, border: "1px solid black" }}>
                    <Col lg={12}>
                        <Row>
                            <Col>
                                <div className="inputdiv">
                                    <label style={{ marginTop: 10 }}>Item</label>
                                    <br />
                                    <div className="row_div_search">
                                        <div className="input_div" >
                                            <input type="type" style={{ margin: 0, background: 'transparent' }}
                                                name="asset"
                                                value={assetText}
                                                onChange={handleInput}
                                                placeholder="Search Item" />
                                            <AiOutlineSearch size={24} style={{ margin: 10, cursor: 'pointer' }} color="lightgray" />
                                        </div>

                                    </div>
                                    {renderSuggestions()}

                                    {assetErr != '' && <p className="alert-message">{assetErr}</p>}


                                </div>
                            </Col>
                            <Col>
                                <div className="inputdiv">
                                    <label>Quantity</label>
                                    <br />
                                    <input type="text" style={{ width: "95%" }}
                                        name="qunatity"
                                        value={qunatity}
                                        placeholder="Quantity"
                                        disabled
                                    //onChange={handleInput}
                                    />
                                    {qunatityErr != '' && <p className="alert-message">{qunatityErr}</p>}
                                </div>

                            </Col>
                        </Row>
                        <Row>

                            <Col >
                                <div className="inputdiv">
                                    <label>Consumed Quantity</label>
                                    <br />
                                    <input type="text" min="0" style={{ width: "95%" }}
                                        name="consume_qty"
                                        value={consume_qty}
                                        placeholder="Enter Quantity"
                                        onChange={handleInput} />
                                    {consumedErr != '' && <p className="alert-message">{consumedErr}</p>}
                                </div>
                            </Col>

                            <Col >
                                <div className="inputdiv">
                                    <label>No of Patients</label>
                                    <br />
                                    <input type="text"
                                        name="no_patients"
                                        value={no_patients}
                                        placeholder="No of Patients"
                                        onChange={handleInput} />
                                    {no_patientsErr != '' && <p className="alert-message">{no_patientsErr}</p>}
                                </div>
                            </Col>
                            <Col >
                                <div className="inputdiv">
                                    <label>Patient IDs</label>
                                    <br />
                                    <div className="row_div_id">
                                        <div className="input_div">
                                            <input type="text"
                                                name="patientID"
                                                style={{ margin: 0, background: 'transparent', width: "95%" }}
                                                value={patientID}
                                                placeholder="Patient ID"
                                                onChange={handleInput} />
                                            <OverlayTrigger overlay={
                                                <Tooltip id={`tooltip-top`}>Ex: ID1,ID2,ID3,ID4</Tooltip>}>
                                                <InfoIcon size={24} style={{ margin: 10, cursor: 'pointer' }} color="lightgray" />
                                            </OverlayTrigger>
                                            {patientIDErr != '' && <p className="alert-message">{patientIDErr}</p>}
                                        </div>
                                    </div>
                                </div>
                            </Col>


                            {/*   <Col>
                            <div className="inputdiv">
                                    <label>Batch No</label>
                                    <br />
                                    <input type="text" style={{width:"95%"}}
                                        name="batchNo"
                                        value={batchNo}
                                        placeholder="Batch No"
                                        onChange={handleInput} />
                                    {batchErr != '' && <p className="alert-message">{batchErr}</p>}
                                </div>
                         
                            </Col> */}
                        </Row>
                        <Row>

                            {/*  <Col lg={5}>
                                <input type="radio" id="no_of_patients" name="radio" value="no_of_patients" style={{ width: 25, height: 15 }}
                                    onChange={handleChange}
                                />
                                <label for="no_of_patients" className="radiolabel">No of Patients</label>
                            </Col>
                            <Col lg={5}>
                                <input type="radio" id="no_of_patients" name="radio" value="no_of_investigations" style={{ width: 25, height: 15 }}
                                    onChange={handleChange}
                                />
                                <label for="no_of_patients" className="radiolabel">No of Investigations</label>
                            </Col> */}
                            {/*   {typeChecked == 'no_of_patients' && */}
                            <>
                            </>

                        </Row>
                        <Row>
                            {/*  {typeChecked == 'no_of_investigations' &&
                                <Col lg={5}>
                                    <div className="inputdiv">
                                        <label>No of Investigations</label>
                                        <br />
                                        <input type="text" min="0"
                                            name="no_investigations"
                                            value={no_investigations}
                                            placeholder="No of Investigations"
                                            onChange={handleInput} />
                                        {no_patientsErr != '' && <p className="alert-message">{no_patientsErr}</p>}
                                    </div>
                                </Col>
                            } */}
                           {/* <Col lg={5}>
                                <div className="inputdiv">
                                    <label>Comments</label>
                                    <br />
                                    <textarea
                                        value={comments}
                                        onChange={handleInput}
                                        name="comments" placeholder="Type Something"
                                        rows={5}
                                        style={{ height: 100, }}
                                        cols={5}
                                    />
                                    {commentsErr != '' && <p className="alert-message">{commentsErr}</p>}
                                </div></Col>  */}
                        </Row>
                        <Row>
                              <Col lg={5}>
                                <div className="inputdiv">
                                    <label>Comments</label>
                                    <br />
                                    <textarea
                                        value={comments}
                                        onChange={handleInput}
                                        name="comments" placeholder="Type Something"
                                        rows={5}
                                        style={{ height: 100, }}
                                        cols={5}
                                    />
                                    {commentsErr != '' && <p className="alert-message">{commentsErr}</p>}
                                </div></Col>

                                <Col lg={3}></Col>

                            <Col lg={2}>
                                <button className="row_table_div" disabled={disabledAddRows} onClick={() => addInputField()} style={{ width: 120,border:"none", margin: "60% 15px", fontWeight: 'bold', float: 'left' }}>
                                    <p style={{marginLeft:25}}>ADD  ROWS</p>
                                </button></Col>
                        </Row>


                    </Col>
                </Row>

                <Modal show={showAssetList} onHide={handleCloseAssetList}
                    aria-labelledby="contained-modal-title-vcenter"
                    dialogclassname="modal-content">
                    <Modal.Header closeButton>
                        <Modal.Title>Items</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>

                        <Table className="tablelist">
                            <thead>
                                <tr>
                                    <th> Item </th>
                                    <th>Qunatity</th>

                                    <th>Consumed Quantity</th>
                                    <th>Batch No</th>
                                    <th>Date</th>
                                    <th>Action</th>



                                </tr>
                            </thead>
                            {totalArrayAsset.length == 0 && <tbody>
                                <tr >
                                    <td colSpan="12">
                                        <p className="no_Data_table">No Rows Added</p>
                                    </td>
                                </tr>
                            </tbody>
                            }
                            {totalArrayAsset.length != 0 && <tbody>
                                {totalArrayAsset.map((obj, index) => {
                                    return (
                                        <>
                                            <tr key={index}>

                                                <td>{obj.assetText}  </td>
                                                <td>{obj.quantity}</td>
                                                <td>{obj.consume_qty}</td>
                                                <td>{obj.batchNo}</td>
                                                <td>{obj.consume_date}</td>
                                                <td>
                                                    <div onClick={() => handleRemove(obj, index)}>
                                                        <p style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}>Remove</p>
                                                    </div>
                                                </td>



                                            </tr>
                                        </>
                                    );
                                })}
                            </tbody>
                            }
                        </Table>

                    </Modal.Body>
                    <Modal.Footer className="modal-footer">
                        <div className="row_div">
                            <button className="add_button" onClick={() => handleUpdateItems()}>
                                <p className="pcat_title">Update Items</p></button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </Container>
            <ToastContainer />

        </Wrapper>
    )
}
export default Consumption