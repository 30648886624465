import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Wrapper } from "./Assets.styles"
import { Row, Container, Col, OverlayTrigger, Tooltip, } from "react-bootstrap"
import { Title } from "../../GlobalStyle"
import plus from "../../images/white_plus.png"
import { AiOutlineSearch, AiOutlinePlusSquare, AiOutlinePlus } from "react-icons/ai";
import Table from "react-bootstrap/Table";
import API_Auth from "../../API_Auth";
import { useNavigate, Link, useParams, } from "react-router-dom";
import Consumed from "../../images/consumed.svg";
import Issue from "../../images/issue.svg";
import Adjust from "../../images/adjust.svg";
import Request from "../../images/request.svg";
import View from "../../images/view.svg";
import AddSharpIcon from '@mui/icons-material/AddSharp';
import Edit from "../../images/user-edit.png";
import Delete from "../../images/delete.png"
import { LoadingSpinner } from "../LoadingSpinner/index";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ReactPaginate from 'react-paginate';

import { ToastContainer, toast } from "react-toastify";
const Assets = () => {
    let id;

    const {
        isExpanded, mainStoreId, title
    } = useContext(UserContext);
    const [assetType, setAssetType] = useState('Consumable');
    const [isLoading, setIsLoading] = useState(false);
    const [assets_types, setAssetTypes] = useState([]);
    const [storeDet, setStoreDet] = useState([]);
    const navigate = useNavigate();
    const [filterValue, setFilterValue] = useState("");
    const [offset, setOffSet] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [orgtableData, setOrgTableData] = useState([]);
    const [perPage, setPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [searchKey, setSearchKey] = useState('n');
    const [searchKeyTable, setSearchKeyTable] = useState('');
    const [assetsData, setAssetsData] = useState([]);
    const [searchData, setSearchData] = useState([]);
    const [shownDrawerIndex, setshownDrawerIndex] = useState(null);
    const [itemSelect, setItemSelect] = useState('');


    const [adminData, setAdminData] = useState([]);
    const [adminStoreId, setAdminStoreId] = useState('');


    let { StorID } = useParams();


    const handleGoTOItem = async (obj, item_value) => {
        if (item_value == 'consumble') {
            navigate("/asset-consumption", { state: { asset_data: obj, StoreID: StorID } });
        }
        if (item_value == 'addItems') {
            navigate('/central-consumable-add-item/central-consumable-asset/' + StorID + "/" + obj.AssetID + '/add');
        }
        if (item_value == 'adjust') {
            navigate("/asset-adjustment", { state: { asset_data: obj, StoreID: StorID } });
        }
        if (item_value == 'viewDetails') {
            navigate("/central-consumable-view-asset" + "/Consumable", { state: { asset_data: obj, StoreID: StorID } });
        }
        if (item_value == 'requestItem') {
            // navigate("/central-consumable-view-asset"+StorID+"/"+obj.AssetID);
            let store_det = await API_Auth.getSubstoreById(StorID);
            // 
            let name = store_det.substore[0].SubStoreName;

            // navigate("/fixed-add-request/fixed-request/" + StorID + "/" + name + "/" + "fixed")
            navigate("/add-request/" + StorID)

        }
        if (item_value == 'issue') {
            navigate("/central-consumable-asset-issue", { state: { asset_data: obj, StoreID: StorID } });
        }
    }




    const handleAssetType = (type, StorID) => {
        setAssetType(type)
        setCurrentPage(0);
        setOffSet(0);
        if (type == 'Consumable') {
            setIsLoading(true);

            const fetchAsset = async () => {
                let store_det = await API_Auth.getSubstoreById(StorID);
                setStoreDet(store_det.substore[0]);
                // console.log(store_det.substore[0])

                setIsLoading(false);
                let result = await API_Auth.getConsumedAssetList(StorID);
                for (var i = 0; i < result.length; i++) {

                    if (result[i].RedAlertlvl == "" || result[i].RedAlertlvl == null) {
                        result[i]['color'] = ""
                    }

                    else if (parseInt(result[i].RedAlertlvl) < parseInt(result[i].Quantity)) {

                        if (result[i].Recordlvl < result[i].Quantity) {
                            result[i]['color'] = " "
                        } else {
                            result[i]['color'] = "#d9d9b1"
                        }
                    } else {
                        result[i]['color'] = "#eb8585"

                    }

                }

                setAssetsData(result);
                //console.log("useoffset", result, offset + perPage)

                var slice = result.slice(0, 0 + perPage);
                setPageCount(Math.ceil(result.length / perPage));
                setOrgTableData(result);
                setTableData(slice);
                setSearchData(result);
            };
            // call the function
            fetchAsset()
                // make sure to catch any error
                .catch(console.error);
        } else {
            setIsLoading(true);
            const fetchFiedAssets = async () => {
                let result = await API_Auth.getCentralStoreAssets(StorID);
                for (var i = 0; i < result.length; i++) {

                    if (result[i].RedAlertlvl == "" || result[i].RedAlertlvl == null) {
                        result[i]['color'] = ""
                    }

                    else if (parseInt(result[i].RedAlertlvl) < parseInt(result[i].Quantity)) {

                        if (result[i].Recordlvl < result[i].Quantity) {
                            result[i]['color'] = " "
                        } else {
                            result[i]['color'] = "#d9d9b1"
                        }
                    } else {
                        result[i]['color'] = "#eb8585"

                    }

                }

                setAssetsData(result);
                var slice = result.slice(0, 0 + perPage);
                setPageCount(Math.ceil(result.length / perPage));
                setOrgTableData(result);
                setTableData(slice);
                setSearchData(result);

                let store_det = await API_Auth.getSubstoreById(StorID);
                setStoreDet(store_det.substore[0]);
                setIsLoading(false);
            };
            // call the function
            fetchFiedAssets()
                // make sure to catch any error
                .catch(console.error);

        }
    }
    const handleSearch = (e) => {
        if (assetType == 'Consumable') {
            let filterValue = e.target.value;
            setCurrentPage(0);
            if (filterValue == '') {
                let offsetsearch = 0;
                var slice = orgtableData.slice(offsetsearch, offsetsearch + perPage);
                setPageCount(Math.ceil(orgtableData.length / perPage));
                setOrgTableData(orgtableData);
                setTableData(slice);
                setSearchData(orgtableData);
                setSearchKey('n');
                setOffSet(offsetsearch);
                setCurrentPage(0);
            }
            else {
                let value1 = filterValue.toLowerCase();
                let offsetsearch = 0;
                const filteredData = orgtableData.filter(item => {
                    return (item ?.AssetName ?.toLowerCase()?.includes(value1) ||
                        item ?.Quantity ?.toString()?.includes(value1) ||
                            item ?.Recordlvl ?.toLowerCase()?.includes(value1) ||
                                item ?.RedAlertlvl ?.toLowerCase()?.includes(value1))

                });
                const slice = filteredData.slice(offsetsearch, offsetsearch + perPage);
                setPageCount(Math.ceil(filteredData.length / perPage))
                setSearchKey('y');
                setOffSet(offsetsearch);
                setSearchKeyTable(filteredData);
                setTableData(slice);
            }
        } else {
            let filterValue = e.target.value
            setCurrentPage(0);
            if (filterValue == '') {
                let offsetsearch = 0;
                var slice = orgtableData.slice(offsetsearch, offsetsearch + perPage);
                setPageCount(Math.ceil(orgtableData.length / perPage));
                setOrgTableData(orgtableData);
                setTableData(slice);
                setSearchData(orgtableData);
                setSearchKey('n');
                setCurrentPage(0);
                setOffSet(offsetsearch);
            }
            else {
                let value1 = filterValue.toLowerCase();
                let offsetsearch = 0;
                const filteredData = orgtableData.filter(item => {
                    return (item ?.AssetName?.toLowerCase()?.includes(value1) ||
                        item ?.AssetType?.toLowerCase()?.includes(value1) ||
                            item ?.CategoryName?.toLowerCase()?.includes(value1) ||
                                item ?.AssetSpacs?.includes(value1))

                });
                const slice = filteredData.slice(offsetsearch, offsetsearch + perPage);
                setPageCount(Math.ceil(filteredData.length / perPage))
                setSearchKey('y');
                setOffSet(offsetsearch);
                setSearchKeyTable(filteredData);
                setTableData(slice);
                setSearchData(filteredData);
            }
        }

    }


    const handlerowsperPage = (e) => {
        let showperpage = e.currentTarget.value
        setPerPage(parseInt(showperpage));
        setCurrentPage(0);
        if (searchKey == "y") {
            const slice = searchKeyTable.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(searchKeyTable.length / e.currentTarget.value))
            setTableData(slice);
            setOffSet(0);
            setSearchData(searchKeyTable);
        }
        else {
            const slice = orgtableData.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(orgtableData.length / e.currentTarget.value))
            setTableData(slice);
            setOffSet(0);
            setSearchData(orgtableData);
        }
    }

    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offsetdata = selectedPage * perPage;
        setCurrentPage(selectedPage);
        setOffSet(offsetdata);
        loadMoreData(offsetdata);
    }

    const loadMoreData = (offsetdata) => {
        const data = orgtableData;
        setOffSet(offsetdata);
        if (searchKey == "y") {
            const slice = searchKeyTable.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(searchKeyTable.length / perPage))
            setTableData(slice);
            setSearchData(searchKeyTable);
        } else {
            const slice = data.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(data.length / perPage))
            setTableData(slice);
            setSearchData(data);
        }

    }

    const showDrawyer = async (e, index, assetID) => {
        if (shownDrawerIndex == index) {
            let all_assets = assetsData;
            all_assets[index].items = [];
            setAssetsData(all_assets);
            setshownDrawerIndex(null);
        } else {
            e.preventDefault();
            let all_assets = assetsData;
            let result = await API_Auth.getStorAsset(StorID, assetID);
            let items = result.data.assets;
            all_assets[index].items = items;
            setAssetsData(all_assets);
            setshownDrawerIndex(index);

        }
    }
    const handleAddItem = (obj) => {
        navigate('/fixed-add-item/fixed-asset-list/' + StorID + "/" + obj.AssetID + '/add');
    }
    const handleEditItem = (item) => {
        navigate('/fixed-add-item/fixed-asset-list/' + StorID + "/" + item.assetID + '/' + item.id);

    }
    const handleDeleteItem = async (item) => {
        let res = window.confirm('Are you sure to delete item!');
        if (res) {
            setIsLoading(true);
            let res = await API_Auth.deleteItemToAsset(item.assetID, StorID, item.id);
            if (res.Flag == 'Success') {

                toast.success('Item Deleted successfully');
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
                setIsLoading(false);

            } else {
                setIsLoading(false);
                if (res.Reason != undefined) {
                    toast.error(res.Reason);
                } else {
                    toast.error('Something went wrong please try again');
                }

            }
        }
    }

    const handleStoreChange = (e) => {

        let data = adminData.filter((item, index) => {
            return item.i_storeId == e.currentTarget.value
        })
        setAdminStoreId(data[0].i_storeId);
        handleAssetType(assetType, data[0].i_storeId);
    }

    useEffect(() => {

        /*    const logged_user_ls = localStorage.getItem("user");
           let data = JSON.parse(logged_user_ls);
           console.log(data.Admin[0].i_storeId)
   
           setAdminData(data.Admin)
           setAdminStoreId(data.Admin[0].i_storeId)
           var id = data.Admin[0].i_storeId */



        if (assetType == 'Consumable') {
            setIsLoading(true);

            const fetchAsset = async () => {
                let store_det = await API_Auth.getSubstoreById(StorID);
                setStoreDet(store_det.substore[0]);

                setIsLoading(false);
                let result = await API_Auth.getConsumedAssetList(StorID);
                setAssetsData(result);
                for (var i = 0; i < result.length; i++) {

                    if (result[i].RedAlertlvl == "" || result[i].RedAlertlvl == null) {
                        result[i]['color'] = ""
                    }

                    else if (parseInt(result[i].RedAlertlvl) < parseInt(result[i].Quantity)) {

                        if (result[i].Recordlvl < result[i].Quantity) {
                            result[i]['color'] = " "
                        } else {
                            result[i]['color'] = "#d9d9b1"
                        }
                    } else {
                        result[i]['color'] = "#eb8585"

                    }

                }
                var slice = result.slice(offset, offset + perPage);
                setPageCount(Math.ceil(result.length / perPage));
                setOrgTableData(result);
                setTableData(slice);
                setSearchData(result);
            };
            // call the function
            fetchAsset()
                // make sure to catch any error
                .catch(console.error);
        } else {
            setIsLoading(true);
            const fetchFiedAssets = async () => {
                let result = await API_Auth.getCentralStoreAssets(StorID);
                setAssetsData(result);

                var slice = result.slice(offset, offset + perPage);
                setPageCount(Math.ceil(result.length / perPage));
                setOrgTableData(result);
                setTableData(slice);
                setSearchData(result);

                let store_det = await API_Auth.getSubstoreById(StorID);
                setStoreDet(store_det.substore[0]);
                setIsLoading(false);
            };
            // call the function
            fetchFiedAssets()
                // make sure to catch any error
                .catch(console.error);

        }
        /* return navigate.addListener('focus',() => { 
             
            console.log(`You changed the page to:` ) 
            })   */
    }, [StorID]);
    const handleAddAsset = () => {
        if (assetType == 'Consumable') {
            navigate('/central-consumable-add-item/central-consumable-asset/' + StorID + '/add/add');

        } else {
            navigate('/fixed-add-item/fixed-asset-list/' + StorID + '/add/add');

        }

    }
    const handleViewDetails = (obj) => {


        navigate("/central-consumable-view-asset" + "/Fixed", { state: { asset_data: obj, StoreID: StorID } });

    }
    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            {isLoading && <LoadingSpinner />}

            <Container>
                <Row>
                    <Col lg={4} style={{ paddingLeft: 0 }}>
                        <Title>Item List</Title>
                    </Col>
                    <Col lg={5} style={{ flexDirection: 'row', display: 'flex', marginTop: 24 }}>
                        <p>Curently View : </p>

                        <p className="store_div">{title}</p>

                    </Col>
                    <Col lg={3} style={{ paddingRight: 0 }}>
                        {storeDet.SubStoreType == 'CENTRAL' &&
                            <div>
                                <button className="add_button" onClick={() => handleAddAsset()}  >
                                    {/*                                     <img src={plus} style={{ margin: 5 }} />
 */}                                    <p className="pcat_title"> ADD ITEM</p></button>
                            </div>
                        }
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col lg={12} sm={12} md={12} className="table_head">
                        <Row>
                            <Col lg={5}>
                                <Row className="row_type">
                                    <Col className={assetType == "Consumable" ?
                                        "button_asset_type" : "button_asset_type_fixed"} onClick={() => handleAssetType('Consumable', StorID)}>Consumable </Col>
                                    <Col className={assetType == "Fixed" ?
                                        "button_asset_type" : "button_asset_type_fixed"} onClick={() => handleAssetType('Fixed', StorID)}>Fixed</Col>
                                </Row>
                            </Col>
                            <Col lg={4}>
                                {/*    <select onChange={(e) => handleStoreChange(e)}
                                    value={StorID} style={{ width: "89%" }}>
                                    {adminData.map((item, index) => {
                                        return (
                                            <option value={item.i_storeId}>{item.SubstoreName}</option>
                                        )
                                    })}

                                </select> */}
                            </Col>
                            <Col lg={3}>
                                <div className="row_div">
                                    <div className="input_div" >
                                        <input type="type" style={{ margin: 0, background: 'transparent' }}
                                            name="filterValue"
                                            onChange={(e) => handleSearch(e)}
                                            placeholder="Search" />
                                        <AiOutlineSearch size={24} style={{ margin: 10, cursor: 'pointer' }} color="lightgray" />
                                    </div>

                                </div></Col>
                        </Row>

                        {assetType == 'Consumable' && <Table className="tablelist">
                            <thead>
                                <tr>
                                    <th style={{ textAlign: 'center' }}> Name</th>
                                    <th style={{ textAlign: 'center' }}> UOM</th>

                                    <th style={{ textAlign: 'center' }}>Quantity</th>
                                    <th style={{ textAlign: 'center' }}>Re Order Level</th>
                                    <th style={{ textAlign: 'center' }}>Red Alert Level</th>
                                    <th style={{ textAlign: 'right' }}>Action</th>
                                </tr>
                            </thead>
                            {tableData.length == 0 && <tbody>
                                <tr >
                                    <td colSpan="12">
                                        <p className="no_Data_table">No Data Found</p>
                                    </td>
                                </tr>
                            </tbody>
                            }
                            {tableData.length != 0 && <>
                                <tbody>
                                    {tableData.map((obj, index) => {
                                        return (
                                            <>
                                                <tr key={index} style={{
                                                    background: obj.color
                                                }}>
                                                    <td style={{ textAlign: 'center' }}>{obj.AssetName}</td>
                                                    <td style={{ textAlign: 'center' }}>{obj.UOM}</td>

                                                    <td style={{ textAlign: 'center' }}>{obj.Quantity}</td>
                                                    <td style={{ textAlign: 'center' }}>{obj.Recordlvl}</td>
                                                    <td style={{ textAlign: 'center', }}>{obj.RedAlertlvl}</td>
                                                    {storeDet.SubStoreType == 'CENTRAL' && <td>
                                                        <div style={{ float: 'right' }}>
                                                            <>
                                                                <OverlayTrigger overlay={
                                                                    <Tooltip id={`tooltip-top`}>ADD ITEMS</Tooltip>}>

                                                                    <AddSharpIcon size={24} style={{ marginLeft: 15, cursor: 'pointer', color: "#000044" }} onClick={() => handleGoTOItem(obj, 'addItems')} />

                                                                </OverlayTrigger>
                                                            </>
                                                            <>
                                                                <OverlayTrigger overlay={
                                                                    <Tooltip id={`tooltip-top`}>VIEW DETAILS</Tooltip>}>
                                                                    <img src={View} onClick={() => handleGoTOItem(obj, 'viewDetails')} style={{ width: 24, color: '#7483f0', height: 24, margin: 5, cursor: 'pointer' }} />
                                                                </OverlayTrigger>
                                                            </>
                                                            <>
                                                                <OverlayTrigger overlay={
                                                                    <Tooltip id={`tooltip-top`}>ISSUE</Tooltip>}>
                                                                    <img src={Issue} onClick={() => handleGoTOItem(obj, 'issue')} style={{ width: 24, color: '#7483f0', height: 24, margin: 5, cursor: 'pointer' }} />
                                                                </OverlayTrigger>
                                                            </>
                                                            <>
                                                                <OverlayTrigger overlay={
                                                                    <Tooltip id={`tooltip-top`}>ADJUST</Tooltip>}>
                                                                    <img src={Adjust} onClick={() => handleGoTOItem(obj, 'adjust')} style={{ width: 24, color: '#7483f0', height: 24, margin: 5, cursor: 'pointer' }} />
                                                                </OverlayTrigger>
                                                            </>
                                                            <>
                                                                {/*   <OverlayTrigger overlay={
                                                                    <Tooltip id={`tooltip-top`}>CONSUMED</Tooltip>}>
                                                                    <img src={Consumed} onClick={() => handleGoTOItem(obj, 'consumble')} style={{ width: 24, color: '#7483f0', height: 24, margin: 5, cursor: 'pointer' }} />
                                                                </OverlayTrigger> */}
                                                            </>
                                                        </div>
                                                    </td>
                                                    }
                                                    {storeDet.SubStoreType != 'CENTRAL' && <td>
                                                        <div style={{ float: 'right' }}>
                                                            <>
                                                                <OverlayTrigger overlay={
                                                                    <Tooltip id={`tooltip-top`}>ADJUST</Tooltip>}>
                                                                    <img src={Adjust} onClick={() => handleGoTOItem(obj, 'adjust')} style={{ width: 24, color: '#7483f0', height: 24, margin: 5, cursor: 'pointer' }} />
                                                                </OverlayTrigger>
                                                            </>
                                                            <>                                                                             <OverlayTrigger overlay={
                                                                <Tooltip id={`tooltip-top`}>VIEW DETAILS</Tooltip>}>
                                                                <img src={View} onClick={() => handleGoTOItem(obj, 'viewDetails')} style={{ width: 24, color: '#7483f0', height: 24, margin: 5, cursor: 'pointer' }} />
                                                            </OverlayTrigger>
                                                            </>
                                                            <>
                                                                <OverlayTrigger overlay={
                                                                    <Tooltip id={`tooltip-top`}>REQUEST ITEM</Tooltip>}>
                                                                    <img src={Request} onClick={() => handleGoTOItem(obj, 'requestItem')} style={{ width: 24, color: '#7483f0', height: 24, margin: 5, cursor: 'pointer' }} />
                                                                </OverlayTrigger>
                                                            </>
                                                            <>
                                                                <OverlayTrigger overlay={
                                                                    <Tooltip id={`tooltip-top`}>ISSUE</Tooltip>}>
                                                                    <img src={Issue} onClick={() => handleGoTOItem(obj, 'issue')} style={{ width: 24, color: '#7483f0', height: 24, margin: 5, cursor: 'pointer' }} />
                                                                </OverlayTrigger>
                                                            </>
                                                            <>
                                                                {/*    <OverlayTrigger overlay={
                                                                    <Tooltip id={`tooltip-top`}>CONSUMED</Tooltip>}>
                                                                    <img src={Consumed} onClick={() => handleGoTOItem(obj, 'consumble')} style={{ width: 24, color: '#7483f0', height: 24, margin: 5, cursor: 'pointer' }} />
                                                                </OverlayTrigger> */}
                                                            </>
                                                        </div>
                                                    </td>}
                                                </tr>
                                            </>
                                        );
                                    })}
                                </tbody>
                            </>
                            }
                        </Table>
                        }

                        {assetType == 'Fixed' && <Table className="tablelist">

                            <thead>
                                <tr>
                                    <th style={{ textAlign: 'center' }}> Name</th>
                                    <th style={{ textAlign: 'center' }}> UOM</th>

                                    <th style={{ textAlign: 'center' }}>Quantity</th>
                                    <th style={{ textAlign: 'center' }}>Re Order Level</th>
                                    <th style={{ textAlign: 'center' }}>Red Alert Level</th>
                                    <th style={{ textAlign: 'right' }}>Action</th>
                                </tr>
                            </thead>
                            {tableData.length == 0 && <tbody>
                                <tr >
                                    <td colSpan="12">
                                        <p className="no_Data_table">No Data Found</p>
                                    </td>
                                </tr>
                            </tbody>
                            }
                            {tableData.length != 0 && <>
                                <tbody>
                                    {tableData.map((obj, index) => {
                                        return (
                                            <>
                                                <tr key={index} style={{
                                                    background: obj.color
                                                }}>                                                    
                                                <td style={{ textAlign: 'center' }}>{obj.AssetName}</td>
                                                    <td style={{ textAlign: 'center' }}>{obj.UOM}</td>

                                                    <td style={{ textAlign: 'center' }}>{obj.Quantity}</td>
                                                    <td style={{ textAlign: 'center' }}>{obj.Recordlvl}</td>
                                                    <td style={{ textAlign: 'center'}}>{obj.RedAlertlvl}</td>

                                                    <td>
                                                        <div style={{ float: 'right' }}>
                                                            <>
                                                                <OverlayTrigger overlay={
                                                                    <Tooltip id={`tooltip-top`}>ADD ITEMS</Tooltip>}>

                                                                    <AddSharpIcon size={24} style={{ marginLeft: 15, cursor: 'pointer', color: "#000044" }} onClick={() => handleAddItem(obj)} />

                                                                </OverlayTrigger>
                                                            </>
                                                            <>
                                                                <OverlayTrigger overlay={
                                                                    <Tooltip id={`tooltip-top`}>VIEW DETAILS</Tooltip>}>
                                                                    <img src={View} onClick={() => handleViewDetails(obj)} style={{ width: 24, color: '#7483f0', height: 24, margin: 5, cursor: 'pointer' }} />
                                                                </OverlayTrigger>
                                                            </>
                                                            {/*     <>
                                                                <OverlayTrigger overlay={
                                                                    <Tooltip id={`tooltip-top`}>ISSUE</Tooltip>}>
                                                                    <img src={Issue} onClick={() => handleGoTOItem(obj, 'issue')} style={{ width: 24, color: '#7483f0', height: 24, margin: 5, cursor: 'pointer' }} />
                                                                </OverlayTrigger>
                                                            </>
                                                            <>
                                                                <OverlayTrigger overlay={
                                                                    <Tooltip id={`tooltip-top`}>ADJUST</Tooltip>}>
                                                                    <img src={Adjust} onClick={() => handleGoTOItem(obj, 'adjust')} style={{ width: 24, color: '#7483f0', height: 24, margin: 5, cursor: 'pointer' }} />
                                                                </OverlayTrigger>
                                                            </> */}
                                                            <>
                                                                {/*   <OverlayTrigger overlay={
                                                                    <Tooltip id={`tooltip-top`}>CONSUMED</Tooltip>}>
                                                                    <img src={Consumed} onClick={() => handleGoTOItem(obj, 'consumble')} style={{ width: 24, color: '#7483f0', height: 24, margin: 5, cursor: 'pointer' }} />
                                                                </OverlayTrigger> */}
                                                            </>
                                                        </div>
                                                    </td>

                                                    {storeDet.SubStoreType != 'CENTRAL' && <td>
                                                        <div style={{ float: 'right' }}>
                                                            {/*         <>
                                                                <OverlayTrigger overlay={
                                                                    <Tooltip id={`tooltip-top`}>ADJUST</Tooltip>}>
                                                                    <img src={Adjust} onClick={() => handleGoTOItem(obj, 'adjust')} style={{ width: 24, color: '#7483f0', height: 24, margin: 5, cursor: 'pointer' }} />
                                                                </OverlayTrigger>
                                                            </>
                                                            <>                                                                             <OverlayTrigger overlay={
                                                                <Tooltip id={`tooltip-top`}>VIEW DETAILS</Tooltip>}>
                                                                <img src={View} onClick={() => handleGoTOItem(obj, 'viewDetails')} style={{ width: 24, color: '#7483f0', height: 24, margin: 5, cursor: 'pointer' }} />
                                                            </OverlayTrigger>
                                                            </>
                                                            <>
                                                                <OverlayTrigger overlay={
                                                                    <Tooltip id={`tooltip-top`}>REQUEST ITEM</Tooltip>}>
                                                                    <img src={Request} onClick={() => handleGoTOItem(obj, 'requestItem')} style={{ width: 24, color: '#7483f0', height: 24, margin: 5, cursor: 'pointer' }} />
                                                                </OverlayTrigger>
                                                            </>
                                                            <>
                                                                <OverlayTrigger overlay={
                                                                    <Tooltip id={`tooltip-top`}>ISSUE</Tooltip>}>
                                                                    <img src={Issue} onClick={() => handleGoTOItem(obj, 'issue')} style={{ width: 24, color: '#7483f0', height: 24, margin: 5, cursor: 'pointer' }} />
                                                                </OverlayTrigger>
                                                            </>
                                                                */  }      <>
                                                                {/*    <OverlayTrigger overlay={
                                                                    <Tooltip id={`tooltip-top`}>CONSUMED</Tooltip>}>
                                                                    <img src={Consumed} onClick={() => handleGoTOItem(obj, 'consumble')} style={{ width: 24, color: '#7483f0', height: 24, margin: 5, cursor: 'pointer' }} />
                                                                </OverlayTrigger> */}
                                                            </>
                                                        </div>
                                                    </td>}
                                                </tr>
                                            </>
                                        );
                                    })}
                                </tbody>
                            </>
                            }
                        </Table>
                        }
                        { /* {assetType == 'Fixed' && <Table className="tablelist">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th> Name</th>
                                    <th> UOM</th>

                                    
                                    <th>Type</th>
                                    <th>Category</th>
                                    <th>Specs</th>
                                    {storeDet.SubStoreType == 'CENTRAL' && <th style={{textAlign:'right'}}>Action</th>}
                                </tr>
                            </thead>
                            {tableData.length == 0 && <tbody>
                                <tr >
                                    <td colSpan="12">
                                        <p className="no_Data_table">No Data Found</p>
                                    </td>
                                </tr>
                            </tbody>
                            }
                            {tableData.map((obj, index) => {
                                return (
                                    <>
                                        <tbody>
                                            <tr key={index}>
                                                <td class="pointer" onClick={(e) => showDrawyer(e, index, obj.i_assetID)} >
                                                    <AiOutlinePlusSquare size="24" style={{ marginLeft: 10 }} />
                                                </td>
                                                <td>{obj.AssetName}  </td>
                                                <td>{obj.UOM}  </td>

                                                <td>{obj.AssetType}</td>
                                                <td>{obj.CategoryName}</td>
                                                <td>{obj.AssetSpacs}</td>
                                                {storeDet.SubStoreType == 'CENTRAL' && <td>
                                                    <div className="row_table_div " onClick={() => handleAddItem(obj)} style={{ width: 115 }}>
                                                        <AiOutlinePlus style={{ margin: 10 }} />
                                                        <p>ADD ITEMS</p>
                                                    </div>

                                                </td>}
                                            </tr>
                                        </tbody>
                                        <tr>
                                            <td colspan="12" className="hiddenRow">

                                            
                                                {
                                                    (obj.items && obj.items.length != 0) && <>

                                                        <Table style={{ display: shownDrawerIndex == index ? '' : 'none' }}>
                                                            <thead>
                                                                <th>Item Id</th>
          <th style={{textAlign:'center'}}>Batch No</th>
                                                                <th style={{ textAlign: 'center' }}>Supplier</th>
                                                                <th>Price</th>
                                                                <th>Other Notes</th>
                                                                {storeDet.SubStoreType == 'CENTRAL' && <th>Action</th>}
                                                            </thead>
                                                            <tbody>
                                                                {obj.items.map((item, index) => {
                                                                    return (
                                                                        <tr key={index} >
                                                                            <td>{item.id}</td>

                                                                            <td style={{textAlign:'center'}}>{item.batchNo}</td>
                                                                         

                                                                            {item.supplier != undefined && <td style={{ textAlign: 'center' }}>{item.supplier}</td>}
                                                                            {item.supplier == undefined && <td style={{ textAlign: 'center' }}></td>}

                                                                            <td>Rs {item.price}</td>
                                                                            <td>{item.description}</td>
                                                                            {storeDet.SubStoreType == 'CENTRAL' && <td>
                                                                                <div  >
                                                                                    <img src={Edit} onClick={() => handleEditItem(item)} style={{ width: 20, height: 20, margin: 5, cursor: 'pointer' }} />
                                                                                    <img src={Delete} onClick={() => handleDeleteItem(item)} class="pointer" style={{ width: 20, height: 20, margin: 5, cursor: 'pointer' }} />
                                                                                </div>
                                                                            </td>}

                                                                        </tr>
                                                                    )
                                                                })}

                                                            </tbody>

                                                        </Table>
                                                    </>
                                                }

                                            </td>

                                        </tr>


                                    </>
                                );
                            })}

                        </Table>
                        }

                         */ }
                      {tableData.length != 0 &&
                          <div>
                            <div className="pagination_row_div">
                                <p className="pagination_title">Showing items per page:</p>
                                <div className="pagination_input_div" >
                                    <select className="pagination_select"
                                        style={{ margin: 0, background: 'transparent' }}
                                        name="pagination_value"
                                        value={perPage}
                                        onChange={handlerowsperPage} >
                                        <option value={4}>4</option>
                                        <option value={8}>8</option>
                                        <option value={10}>10</option>
                                        <option value={20}>20</option>
                                    </select>
                                </div>
                            </div>
                            <div className="pagination_row_right_div">
                        <p className="pagination_total">Showing {offset+1} to {searchData.length<offset+perPage&&
                        <span>{searchData.length}</span> }
                        {searchData.length>offset+perPage&&
                        <span>{offset+perPage }</span>} of {searchData.length} items</p>
                            <ReactPaginate
                                previousLabel={<ArrowBackIosIcon className="pagination_prev_btn" color="#2438a7" />}
                                nextLabel={<ArrowForwardIosIcon className="pagination_next_btn" color="#2438a7" />}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}
                                forcePage={currentPage} />
                        </div>
                        </div>
                        }
                    </Col>
                </Row>
            </Container>
        </Wrapper>
    )
}
export default Assets