import dashboard  from "./images/dashboard.png";
import money from  "./images/money-sack.png";
import transactions_log from "./images/transactions_log.png"
const _navmngmnt = [
  {
    title: "Dashboard",
    to: "/dashboard",
    icon:dashboard,
    subitems:[]
  },
  {
    title: "Stock Register",
    to: "/stock-register",
    icon:money,
    subitems:[]
  },
  {
    title: "Transaction Log",
    to: "/tansaction-log",
    icon:transactions_log,
    subitems:[]
  }
  ];
  
  export default _navmngmnt;
  