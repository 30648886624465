import styled from "styled-components";

export const Wrapper = styled.div`
.tablelist {
  margin-top:15px;
  margin-right:5px;;
}
 
thead{
  color:black;
  background:#c0d3ef;
  border:1px solid #c0d3ef;
  border-radius:10px;
}
td{
  color:black;
  font-weight:400;
  font-size:14px;
  height:60px;
  padding:15px;
  font-family:Roboto;
  text-transform: capitalize;
}
th{
  font-size:16px;
  height:50px;
  padding:14px;
  font-weight:500;
  font-family:Roboto;

}
.table-head-content {
  padding: 13px 13px 0 13px;
  background: white;
  border-bottom: 1px solid #E2E2E2;   
}  

.row_div {
  display:flex;
  flex-direction:row;
  padding: 8px;
}
.input_div {
  border: 2px solid #DFDFDF;;
  width: 70%;
  color:var(--black);
  font-family: 'ARIAL';
  height:45px;
  border-radius:6px;
  margin:5px;
  background:white;
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  input,select{
     border:none;
     :focus {
       outline: none !important;
       border-color:none;  
     }
    }
   
}
.title{
  font-family:Roboto;
  font-size:16px;
  font-weight:600;
  margin-left:10px;
  margin-bottom:0 !important;
  margin-top:15px;

}
.adddiv {
  background:white;
  height:95%;
  width:80%;
  margin-top:15px;
  margin-left:20px;
  border-radius:5px;
  
  input,select{
    width:85%;
    height: 40px;
    border: 1px solid #00001F24;
    border-radius: 6px;
    margin: 15px ;
    padding: 10px;     
    :focus {
      outline: none !important;
      border-color:none;  
    }
   }
  
}
.table_active_btn{
  color: #2438a7;
  font-weight: 600;
  cursor: pointer;
}
.row_type{
  border:1px solid #6EDC8F;;
  margin:15px 1px;
  height:40px;
  border-radius:6px;
  opacity:1
}
.button_asset_type {
  background:#6EDC8F;
  color:black;
  border-radius:6px;
  margin:-1px;
  padding:10px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Roboto', sans-serif;
  font-weight:400;
  font-size:14px;
  cursor:pointer;
  }
  .button_asset_type_fixed {
    border-radius:6px;
    margin:-1px;
    padding:10px;
    text-align: center;
    text-transform: uppercase;
    font-family: 'Roboto', sans-serif;
    font-weight:500;
    font-size:14px;
    cursor:pointer;
    }
.add_cat_button {
  background:#000044;
  color:white;
  border:#4EACFB;
  padding:10px;
  height:40px;
  width:85%;
  margin:100px 25px 0px;
  font-weight:bold;
  border-radius:6px;
}
.table_text_overflow {
  display: inline-block;
  width: 180px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  }
  
.row_table_div {
  background:#ECECEC;
  color:#000044;
  display:flex;
  flex-direction:row;
  width:75%;
  border-radius:4px;
  @media screen and (max-width: 1365px) {
    width:100%;

  }
  @media screen and (max-width: 1199px) {
    width:100%;

  }
  @media screen and (max-width: 991px) {
    width:100%;

  }
  @media screen and (max-width: 768px) {
    width:100%;
  }
  img{
    margin:10px;
    width:15px;
    height:15px;
  }
  p{
    margin:10px 0px;
    font-size:12px;
    font-weight:bold;

  }
}
`
export const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

export const Switch = styled.div`
   position: relative;
  width: 60px;
  height: 28px;
  background: #b3b3b3;
  border-radius: 32px;
  padding: 4px;
  transition: 300ms all;

  &:before {
    transition: 300ms all;
    content: "";
    position: absolute;
    width: 28px;
    height: 28px;
    border-radius: 35px;
    top: 50%;
    left: 4px;
    background: lightgray;
    transform: translate(0, -50%);
  }

`;

export const Input = styled.input`
  opacity: 0;
  position: absolute;
  margin-left:20px;

  &:checked + ${Switch} {
    background: green;

    &:before {
      transform: translate(32px, -50%);
    }
  }
`;
