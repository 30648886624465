import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Wrapper } from "./styles"
import { useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Title } from "../../GlobalStyle"
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import API_Auth from "../../API_Auth";

const Dashboard = () => {
  let { StorID, UserType} = useParams();
    const {isExpanded,} = useContext(UserContext);
    const navigate = useNavigate();
    const [dashboard_data, setDashboardData] = useState([]);
    const [user_role, setUserRole] = useState();
    

    useEffect(() => {
        const fetchRequests = async () => {
            const user_id = localStorage.getItem("UserID");
            const userRole = localStorage.getItem("loggedRole");
             setUserRole(userRole);
            if(userRole == 'Admin'||userRole == 'MGMT'){
                let result = await API_Auth.getAdminDashboard();
                setDashboardData(result);
/*                 const array_result = Object.values(result);
                console.log(array_result);
 */               /*  let admin_array = [];
                admin_array.push(result[0])
                console.log(result[0],admin_array); */
            }
            if(userRole == 'Substore Admin'){
              if(UserType=="subAdmin"){
               // let result = await API_Auth.getStoreAdminDashboard(StorID);

               let result = await API_Auth.getStoreAdminAll(user_id)
                setDashboardData(result);

              }
              if(UserType=="approver"){
                let result = await API_Auth.getApproverDashboard(user_id);
                setDashboardData(result);
              }
            }
        };
        // call the function
        fetchRequests()
            // make sure to catch any error
            .catch(console.error);
    }, [UserType])
    

    return (

        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            <Container>
            <Col lg={4}>
                        <Title>Dashboard</Title>
                    </Col>

                <Row>
                {user_role == 'Admin'&&
                <>
                  <Col className="conotent_text" lg={4}>
                    <Card  style={{backgroundColor: '#ff8080'}} >
        <CardContent>
          <h6 gutterBottom variant="h5" component="div">
         Active Users
          </h6>
          <p>
          {dashboard_data[0]?.userCount}
          </p>
        </CardContent>
    </Card>                   
                    </Col>
                <Col className="conotent_text" lg={4}>
                    <Card  style={{backgroundColor: '#c2bdbd',borderRadius: 14}} >
        <CardContent>
          <h6 gutterBottom variant="h5" component="div">
          Active Vendors
          </h6>
          <p>
          {dashboard_data[0]?.ActiveVendorCount}
          </p>
        </CardContent>
    </Card>                   
                    </Col>
                 <Col className="conotent_text" lg={4}>
                    <Card  style={{backgroundColor: '#8080ff'}} >
        <CardContent>
          <h6 gutterBottom variant="h5" component="div">
          Item Categories
          </h6>
          <p>
          {dashboard_data[0]?.categoryCount}
          </p>
        </CardContent>
    </Card>                   
                    </Col>
                    <Col className="conotent_text" lg={4}>
                    <Card  style={{backgroundColor: '#80ffaa'}} >
        <CardContent>
          <h6 gutterBottom variant="h5" component="div">
          Central Stores
          </h6>
          <p>
          {dashboard_data[0]?.centralStoreCount}
          </p>
        </CardContent>
    </Card>                   
                    </Col>
                    <Col className="conotent_text" lg={4}>
                    <Card   style={{backgroundColor: '#e699cc'}}>
        <CardContent>
          <h6 gutterBottom variant="h5" component="div">
          Non-Central Stores
          </h6>
          <p>
          {dashboard_data[0]?.normalStoreCount}
          </p>
        </CardContent>
    </Card>                   
                    </Col>
                  <Col className="conotent_text" lg={4}>
                    <Card   style={{backgroundColor: '#ff80bf'}}>
        <CardContent>
          <h6 gutterBottom variant="h5" component="div">
 Consumable Items
          </h6>
          <p>
          {dashboard_data[0]?.CounsumableAssetCount}
          </p>
        </CardContent>
    </Card>                   
                    </Col>
                    <Col className="conotent_text" lg={4}>
                    <Card   style={{backgroundColor: '#ff9f80'}}>
        <CardContent>
          <h6 gutterBottom variant="h5" component="div">
          Fixed Items
          </h6>
          <p>
          {dashboard_data[0]?.fixedAssetCount}
          </p>
        </CardContent>
    </Card>                   
                    </Col>
                    <Col className="conotent_text" lg={4}>
                    <Card   style={{backgroundColor: '#ffd557'}}>
        <CardContent>
          <h6 gutterBottom variant="h5" component="div">
          Total Transactions
          </h6>
          <p>
          {dashboard_data[0]?.TotalTx}
          </p>
        </CardContent>
    </Card>                   
                    </Col> 
                    </>
                  }
                   {user_role == 'MGMT'&&
                <>
                  <Col className="conotent_text" lg={4}>
                    <Card  style={{backgroundColor: '#ff8080'}} >
        <CardContent>
          <h6 gutterBottom variant="h5" component="div">
         Active Users
          </h6>
          <p>
          {dashboard_data[0]?.userCount}
          </p>
        </CardContent>
    </Card>                   
                    </Col>
                <Col className="conotent_text" lg={4}>
                    <Card  style={{backgroundColor: '#c2bdbd',borderRadius: 14}} >
        <CardContent>
          <h6 gutterBottom variant="h5" component="div">
          Active Vendors
          </h6>
          <p>
          {dashboard_data[0]?.ActiveVendorCount}
          </p>
        </CardContent>
    </Card>                   
                    </Col>
                 <Col className="conotent_text" lg={4}>
                    <Card  style={{backgroundColor: '#8080ff'}} >
        <CardContent>
          <h6 gutterBottom variant="h5" component="div">
          Item Categories
          </h6>
          <p>
          {dashboard_data[0]?.categoryCount}
          </p>
        </CardContent>
    </Card>                   
                    </Col>
                    <Col className="conotent_text" lg={4}>
                    <Card  style={{backgroundColor: '#80ffaa'}} >
        <CardContent>
          <h6 gutterBottom variant="h5" component="div">
          Central Stores
          </h6>
          <p>
          {dashboard_data[0]?.centralStoreCount}
          </p>
        </CardContent>
    </Card>                   
                    </Col>
                    <Col className="conotent_text" lg={4}>
                    <Card   style={{backgroundColor: '#e699cc'}}>
        <CardContent>
          <h6 gutterBottom variant="h5" component="div">
          Non-Central Stores
          </h6>
          <p>
          {dashboard_data[0]?.normalStoreCount}
          </p>
        </CardContent>
    </Card>                   
                    </Col>
                  <Col className="conotent_text" lg={4}>
                    <Card   style={{backgroundColor: '#ff80bf'}}>
        <CardContent>
          <h6 gutterBottom variant="h5" component="div">
 Consumable Items
          </h6>
          <p>
          {dashboard_data[0]?.CounsumableAssetCount}
          </p>
        </CardContent>
    </Card>                   
                    </Col>
                    <Col className="conotent_text" lg={4}>
                    <Card   style={{backgroundColor: '#ff9f80'}}>
        <CardContent>
          <h6 gutterBottom variant="h5" component="div">
          Fixed Items
          </h6>
          <p>
          {dashboard_data[0]?.fixedAssetCount}
          </p>
        </CardContent>
    </Card>                   
                    </Col>
                    
                   <Col className="conotent_text" lg={4}>
                    <Card   style={{backgroundColor: '#ffd557'}}>
        <CardContent>
          <h6 gutterBottom variant="h5" component="div">
          Total Transactions
          </h6>
          <p>
          {dashboard_data[0]?.TotalTx}
          </p>
        </CardContent>
    </Card>                   
                    </Col> 
                    </>
                  }
               {UserType=='approver'&&
                                       <>
                    <Col className="conotent_text" lg={4}>
                    <Card  style={{backgroundColor: '#ff8080'}} >
        <CardContent>
          <h6 gutterBottom variant="h5" component="div">
          Recent Requests
          </h6>
          <p>
          {dashboard_data[0]?.RecReqCount||0}
          </p>
        </CardContent>
    </Card>                   
                    </Col>
                    <Col className="conotent_text" lg={4}>
                    <Card  style={{backgroundColor: '#c2bdbd'}} >
        <CardContent>
          <h6 gutterBottom variant="h5" component="div">
          Request Approve Count
          </h6>
          <p>
          {dashboard_data[0]?.ReqApproveCount||0}
          </p>
        </CardContent>
    </Card>                   
                    </Col>

                    <Col className="conotent_text" lg={4}>
                    <Card  style={{backgroundColor: '#e699cc'}} >
        <CardContent>
          <h6 gutterBottom variant="h5" component="div">
          Reject Requests
          </h6>
          <p>
          {dashboard_data[0]?.rejectReqcount||0}
          </p>
        </CardContent>
    </Card>                   
                    </Col>

                </>
}
        {UserType=='subAdmin'&&
                                       <>
                    <Col className="conotent_text" lg={4}>
                    <Card  style={{backgroundColor: '#ff8080'}} >
        <CardContent>
          <h6 gutterBottom variant="h5" component="div">
          Consumable Items
          </h6>
          <p>
          {dashboard_data[0]?.CounsumableAssetCount||0}
          </p>
        </CardContent>
    </Card>                   
                    </Col>
                    <Col className="conotent_text" lg={4}>
                    <Card  style={{backgroundColor: '#ff9f80'}} >
        <CardContent>
          <h6 gutterBottom variant="h5" component="div">
           Requests
          </h6>
          <p>
          {dashboard_data[0]?.RecReqCount||0}
          </p>
        </CardContent>
    </Card>                   
                    </Col>

                    <Col className="conotent_text" lg={4}>
                    <Card  style={{backgroundColor: '#80ffaa'}} >
        <CardContent>
          <h6 gutterBottom variant="h5" component="div">
          Fixed Items
          </h6>
          <p>
          {dashboard_data[0]?.fixedAssetCount||0}
          </p>
        </CardContent>
    </Card>                   
                    </Col>
                    <Col className="conotent_text" lg={4}>
                    <Card  style={{backgroundColor: '#e699cc'}} >
        <CardContent>
          <h6 style={{fontSize:23}} gutterBottom variant="h5" component="div">
          Pending Consumable Requests
          </h6>
          <p>
          {dashboard_data[0]?.pendingConsumableReqCount||0}
          </p>
        </CardContent>
    </Card>                   
                    </Col>
                    <Col className="conotent_text" lg={4}>
                    <Card  style={{backgroundColor: '#8080ff'}} >
        <CardContent>
          <h6 gutterBottom variant="h5" component="div">
          Pending Fixed Requests
          </h6>
          <p>
          {dashboard_data[0]?.pendingFixedReqCount||0}
          </p>
        </CardContent>
    </Card>                   
                    </Col>
                 {/*    <Col className="conotent_text" lg={4}>
                    <Card  style={{backgroundColor: '#c2bdbd'}} >
        <CardContent>
          <h6 gutterBottom variant="h5" component="div">
          Send Requests 
          </h6>
          <p>
          {dashboard_data[0]?.sendReqCount||0}
          </p>
        </CardContent>
    </Card>                   
                    </Col> */}

                </>
}
                </Row>
                
            </Container>
        </Wrapper>
    )
}
export default Dashboard