import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Wrapper } from "./styles"
import { Row, Container, Col, OverlayTrigger, Tooltip } from "react-bootstrap"
import Table from "react-bootstrap/Table";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import API_Auth from "../../API_Auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Title } from "../../GlobalStyle.js"
import { AiOutlineSearch } from "react-icons/ai";
import moment from "moment"
import { LoadingSpinner } from "../LoadingSpinner/index";
import ReactPaginate from 'react-paginate';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { IoIosArrowBack } from "react-icons/io";
import Modal from 'react-bootstrap/Modal';


const CentralStoreConsumableViewAsset = () => {
    const {
        isExpanded,
    } = useContext(UserContext);
    const location = useLocation();

    let { assetType } = useParams();

    const [asset_data, setasset_data] = useState(location.state ?.asset_data);
    const [StorID, setStorID] = useState(location.state ?.StoreID);
    const [asset_quantity, setAssetQuantity] = useState([{
        Quantity: 2,
        RedAlertlvl: "",
        ReorderLevel: "1"
    }]);

    const navigate = useNavigate();
    const [viewAssetsData, setviewAssetsData] = useState([]);
    const [status, setStatus] = useState('');
    const [searchData, setSearchData] = useState([])
    const [formTitle, setFormTitle] = useState('View Item');
    const [re_order_level, setReOrderLevel] = useState(asset_quantity[0].ReorderLevel);
    const [red_alert_level, setRedAlertLevel] = useState(asset_quantity[0].RedAlertlvl);
    const [red_alert_levelErr, setRedAlertLevelErr] = useState('');
    const [re_order_levelErr, setReOrderLevelErr] = useState('');
    const [style, setStyle] = useState({ display: 'none' });
    const [filterValue, setFilterValue] = useState("")
    const [isLoading, setIsLoading] = useState(false);

    const [offset, setOffSet] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [orgtableData, setOrgTableData] = useState([]);
    const [perPage, setPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [searchKey, setSearchKey] = useState('n');
    const [searchKeyTable, setSearchKeyTable] = useState('');
    const [storeInfo, setStoreInfo] = useState('');
    const [storeName, setStoreName] = useState('');
    const [show, setShow] = useState(false);
    const [billInfoData, setBillInfoData] = useState([]);
    const [billAssetData, setBillAssetData] = useState([]);
    const [totalBillAmount, setTotalBillAmount] = useState(0)

    const handleInput = (e) => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;
        if (name == "filterValue") setFilterValue(value);
        if (name == "re_order_level") setReOrderLevel(value);
        if (name == "red_alert_level") setRedAlertLevel(value);
    }
    const handleSearch = (e) => {
        let filterValue = e.target.value;


        setCurrentPage(0);
        if (filterValue == '') {
            let offsetsearch = 0;
            var slice = orgtableData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(orgtableData.length / perPage));
            setOrgTableData(orgtableData);
            setTableData(slice);
            setSearchData(orgtableData);
            setSearchKey('n');
            setOffSet(orgtableData);
            setCurrentPage(0);
        }
        else {
            let value1 = filterValue.toLowerCase();
            let offsetsearch = 0;
            const filteredData = orgtableData.filter(item => {
                return (moment(item.MFD).format('DD-MM-YYYY').includes(value1) ||
                    item.FromStoreName.toLowerCase().includes(value1) ||
                    item.ToStoreName.toLowerCase().includes(value1) ||
                    moment(item.EXPD).format('DD-MM-YYYY').includes(value1))

            });
            filteredData.sort((a, b) => b.TDate.localeCompare(a.TDate))
            const slice = filteredData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(filteredData.length / perPage))
            setSearchKey('y');
            setOffSet(filteredData);
            setSearchData(filteredData);
            setSearchKeyTable(filteredData);
            setTableData(slice);
        }
    }
    const handleUpdateLevel = async () => {
        let error = false;
        if (red_alert_level == '') {
            setRedAlertLevelErr('This field is required');
            error = true;
        } else {
            setRedAlertLevelErr('');
        }
        if (re_order_level == '') {
            setReOrderLevelErr('This field is required');
            error = true;
        }
        if (!error) {
            let result = await API_Auth.updateRedAndReOrder(asset_data.AssetID, StorID, re_order_level, red_alert_level);
            let res = result[0];
            setIsLoading(true);
            if (res.Flag == 'Success') {
                toast.success('Update successful');
                setIsLoading(false);
            } else {
                setIsLoading(false);
                toast.error('Something went wrong please try again');
            }
        }
    }
    useEffect(() => {
        setIsLoading(true);
        const fetchAsset = async () => {
            let result = await API_Auth.getConsumeAssetView(StorID, asset_data.AssetID, assetType);
            setviewAssetsData(result);
            var slice = result.slice(offset, offset + perPage);
            setPageCount(Math.ceil(result.length / perPage));
            setOrgTableData(result);
            setTableData(slice);
            setSearchData(result);
            setIsLoading(false);
            let asset_det = await API_Auth.getConsumeAssetQuantity(StorID, asset_data.AssetID);
            setAssetQuantity(asset_det);
            setReOrderLevel(asset_det[0].ReorderLevel)
            setRedAlertLevel(asset_det[0].RedAlertlvl)
            let store_det = await API_Auth.getSubstoreById(StorID);
            setStoreInfo(store_det.substore[0]);
            setStoreName(store_det.substore[0].SubStoreName)

        };
        // call the function
        fetchAsset()
            // make sure to catch any error
            .catch(console.error);
    }, []);
    const handlerowsperPage = (e) => {
        let showperpage = e.currentTarget.value
        setPerPage(parseInt(showperpage));
        setOffSet(0);
        setCurrentPage(0);
        if (searchKey == "y") {
            const slice = searchKeyTable.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(searchKeyTable.length / e.currentTarget.value))
            setTableData(slice);
            setSearchData(searchKeyTable);
        }
        else {
            const slice = orgtableData.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(orgtableData.length / e.currentTarget.value))
            setTableData(slice);
            setSearchData(orgtableData);
        }
    }

    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offsetdata = selectedPage * perPage;
        setCurrentPage(selectedPage);
        setOffSet(offsetdata);
        loadMoreData(offsetdata);
    }

    const loadMoreData = (offsetdata) => {
        const data = orgtableData;
        setOffSet(offsetdata);
        if (searchKey == "y") {
            const slice = searchKeyTable.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(searchKeyTable.length / perPage))
            setTableData(slice);
            setSearchData(searchKeyTable);
        } else {
            const slice = data.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(data.length / perPage))
            setTableData(slice);
            setSearchData(data);
        }
    }
    const handleBackClick = () => {
        navigate("/assets-management/" + StorID);

    }
    const handleBillInfo = async (billno) => {
        const data = await API_Auth.getBillInfo(billno);
        console.log("Data", data);
        setBillAssetData(data.Assetdata);
        // setTotalBillAmount;
        var x = 0
        for (var i = 0; i < data.Assetdata.length; i++) {
            x = x + parseFloat(data.Assetdata[i].Price)
        }
        console.log("price", x)
        setTotalBillAmount(x)
        setBillInfoData(data.Billdata);
        setShow(true)
    }
    const handleClose = () => setShow(false);

    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            {isLoading && <LoadingSpinner />}
            <Row className="row_title">
                <Col lg={4}></Col>
                <Col lg={6}></Col>
                <Col lg={2}>
                    <div className="back_button" onClick={() => handleBackClick()}>
                        <IoIosArrowBack size={18} />
                        <h6>Back</h6>
                    </div>
                </Col>

            </Row>
            <Row className="row_title">
                <Col lg={4}><p>{formTitle}</p></Col>
                <Col lg={4}></Col>
                <Col lg={4} style={{ margin: 0, padding: 0 }}>
                    <div className="input_div">
                        <input type="type" style={{ margin: 0, background: 'transparent', border: "none" }}
                            name="filterValue"
                            onChange={(e) => handleSearch(e)}
                            placeholder="Search" />
                        <AiOutlineSearch size={24} style={{ margin: 10, cursor: 'pointer' }} color="lightgray" />
                    </div>
                </Col>
            </Row>

            {/*   <Row>
                <Col lg={2}>
                    <Title>{formTitle}</Title>
                </Col>
                <Col lg={6}>
                </Col>
                <Col lg={4}>
                    <div className="rowdiv">
                        <p className="title">Filter By:</p>
                        <div className="input_div" >
                            <input type="type"
                                className="input"
                                name="filterValue"
                                onChange={handleSearch}
                                placeholder="Search" />
                            <AiOutlineSearch size={24} style={{ margin: 10 }} color="lightgray" />
                        </div>

                    </div>
                </Col>
            </Row>
         */}    <hr />
            <Container>
                <Row>
                    <Col lg={2}>
                        <div className="inputdiv">
                            <label style={{ marginLeft: 30 }}>Item</label>
                            <br />
                            <br />
                            <label style={{ marginLeft: 30 }}> {asset_data ?.AssetName}</label>
                        </div>
                    </Col>
                    <Col lg={2}>
                        <div className="inputdiv">
                            <label>Total Quantity</label>
                            <br />
                            <br />
                            <label >{asset_quantity[0].Quantity}</label>
                        </div>
                    </Col>
                    <Col lg={2}>
                        <div className="inputdiv">
                            <label>UOM</label>
                            <br />
                            <br />
                            <label >{asset_quantity[0].UOM}</label>
                        </div>
                    </Col>
                    <Col lg={2}>
                        <div className="inputdiv">
                            <label>Re-Order Level</label>
                            <br />
                            <input type="text"
                                name="re_order_level"
                                value={re_order_level}
                                placeholder="Re order level"
                                onChange={handleInput}
                            />
                            {re_order_levelErr != '' && <p className="alert-message">{re_order_levelErr}</p>}
                        </div>
                    </Col>
                    <Col lg={2}>
                        <div className="inputdiv">
                            <label>Red-Alert Level</label>
                            <br />
                            <input type="text"
                                name="red_alert_level"
                                value={red_alert_level}
                                placeholder="Red alert level"
                                onChange={handleInput}
                            />
                            {red_alert_levelErr != '' && <p className="alert-message">{red_alert_levelErr}</p>}
                        </div>
                    </Col>
                    <Col lg={1}>

                    </Col>

                </Row>
                <Row>
                    <Col lg={8}></Col>
                    <Col lg={3} className={'submit_butn'}>
                        {/*   <div className="rowdiv">
                            <button className="add_button" onClick={() => handleUpdateLevel()}>
                                <p className="pcat_title">SUBMIT</p></button>
                        </div></Col> */}
                        <div className="row_table_divs " onClick={() => handleUpdateLevel()} style={{ width: 100, margin: 15, fontWeight: 'bold', float: 'left' }}>
                            <p style={{ marginLeft: 30 }}>SUBMIT</p>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col lg={12} sm={12} md={12} className="table_head">
                        <Table className="tablelist">
                            <thead>
                                <tr>
                                    <th>From</th>
                                    <th>To</th>
                                    <th>Qty</th>
                                    <th>Date</th>
                                    <th>Request Id	</th>
                                    <th>Bill No</th>
                                    <th>Bill Date</th>
                                    <th>Batch No</th>
                                    <th>Vendor</th>
                                    <th>MFD</th>
                                    <th>Expiry</th>
                                    <th>Price</th>
                                    <th>Comments</th>
                                </tr>
                            </thead>
                            {tableData.length != 0 && <>
                                <tbody>
                                    {tableData.map((item, index) => {
                                        return (
                                            <tr key={index} >
                                                {storeName === (item ?.FromStoreName || item ?.FromStoreID) &&
                                                    <td style={{ color: 'red', wordWrap: 'break-word' }}>{item ?.FromStoreName }</td>}
                                                {storeName !== (item ?.FromStoreName || item ?.FromStoreID) &&
                                                    <td style={{ color: 'black' }}>{item ?.FromStoreName }</td>}

                                                {storeName === (item.ToStoreName || item ?.ToStoreID) &&
                                                    <td style={{ color: 'green' }}>{item.ToStoreName} </td>}

                                                {storeName !== (item.ToStoreName || item ?.ToStoreID) &&
                                                    <td style={{ color: 'black' }}>{item.ToStoreName}</td>}
                                                <td>{item.Quantity}</td>
                                                <td>{item.TDate}</td>

                                                {item ?.requestID != '' &&
                                                    <td>REQ{item ?.requestID }</td>
                                                }
                                                {item ?.requestID == '' &&
                                                    <td>{item ?.requestID }</td>
                                                 }
                                                <td>
                                                    <div onClick={() => handleBillInfo(item ?.billNo)}
                                                        style={{
                                                            cursor: 'pointer',
                                                            textDecoration: 'underline',
                                                            color: 'blue'
                                                        }}>
                                                        {item ?.billNo}
                                                    </div>
                                                </td>

                                                {item.billDate != '' && <td>{moment(item ?.billDate).format("DD MMM YYYY  h:mm A")}</td>}
                                                {item.billDate == '' && <td></td>}

                                                <td>{item.BatchNo}</td>
                                                <td>{item.VendorName}</td>
                                                {item.MFD != '' && <td> {moment(item.MFD).format("DD MMM YYYY  h:mm A")}</td>}
                                                {item.MFD == '' && <td> </td>}

                                                {item.EXPD != '' && <td>{moment(item.EXPD).format("DD MMM YYYY  h:mm A")}</td>}
                                                {item.EXPD == '' && <td></td>}

                                                <td>{item.Price}</td>
                                                <td>{item.Tcpmment}</td>

                                                {/*  {item.Tcpmment != '' &&
                                                    <OverlayTrigger
                                                        key={item.Tcpmment}
                                                        overlay={
                                                            <Tooltip id={`tooltip-top`}>
                                                                {item.Tcpmment}.
        </Tooltip>
                                                        }
                                                    >
                                                        <td className="table_text_overflow">{item.Tcpmment}</td>
                                                    </OverlayTrigger>
                                                }  */}
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </>
                            }
                            {tableData.length == 0 &&

                                <tbody>
                                    <tr >
                                        <td colSpan="12">
                                            <p className="no_Data_table">No Data Found</p>
                                        </td>
                                    </tr>
                                </tbody>
                            }
                        </Table>
                        {tableData.length != 0 &&
                            <div>
                                <div className="pagination_row_div">
                                    <p className="pagination_title">Showing items per page:</p>
                                    <div className="pagination_input_div" >
                                        <select className="pagination_select"
                                            style={{ margin: 0, background: 'transparent' }}
                                            name="pagination_value"
                                            value={perPage}
                                            onChange={handlerowsperPage} >
                                            <option value={4}>4</option>
                                            <option value={8}>8</option>
                                            <option value={10}>10</option>
                                            <option value={20}>20</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="pagination_row_right_div">
                                    <p className="pagination_total">Showing {offset + 1} to {searchData.length < offset + perPage &&
                                        <span>{searchData.length}</span>}
                                        {searchData.length > offset + perPage &&
                                            <span>{offset + perPage}</span>} of {searchData.length} items</p>
                                    <ReactPaginate
                                        previousLabel={<ArrowBackIosIcon className="pagination_prev_btn" color="#2438a7" />}
                                        nextLabel={<ArrowForwardIosIcon className="pagination_next_btn" color="#2438a7" />}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                        forcePage={currentPage} />
                                </div>
                            </div>
                        }
                    </Col>
                </Row>
            </Container>

            <Modal show={show} onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                dialogclassname="modal-dialogcontent">
                <Modal.Header closeButton>
                    <Modal.Title>Bill Information</Modal.Title>
                </Modal.Header>

                <Modal.Body >
                    <Row>
                        <Col >
                            <div className="inputdiv">
                                <label>Bill No</label>
                                <br />
                                <input type="text"
                                    name="billNo"
                                    value={billInfoData.length != 0 ? billInfoData[0].BillNo : ''}
                                    disabled
                                    placeholder="" />


                            </div>
                        </Col>
                        <Col>
                            <div className="inputdiv">
                                <label>Bill Date</label>
                                <br />
                                <input type="date"
                                    name="billDate"
                                    value={billInfoData.length != 0 ? billInfoData[0].BillDate : ''}
                                    placeholder="dd/mm/yyyy"
                                    disabled />
                            </div>

                        </Col>
                        <Col>
                            <div className="inputdiv">
                                <label>Vendor Name</label>
                                <br />
                                <input type="text"
                                    name="billDate"
                                    value={billInfoData.length != 0 ? billInfoData[0].VandorName : ''}
                                    disabled />
                            </div>

                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4}>
                        <div className="inputdiv">
                                <label>Total Amount</label>
                                <br />
                                <input type="text"
                                    value={totalBillAmount}
                                    disabled />
                            </div>
                        </Col>
                    </Row>

                    <Table className="tablelist">
                        <thead>
                            <tr>
                                <th> Item </th>
                                <th>Quantity</th>
                                <th>Tax %</th>
                                <th>Total Price</th>
                                <th>Batch No</th>

                                <th>Expiry Date</th>



                            </tr>
                        </thead>
                        {billAssetData.length == 0 && <tbody>
                            <tr >
                                <td colSpan="12">
                                    <p className="no_Data_table">No Rows Added</p>
                                </td>
                            </tr>
                        </tbody>
                        }
                        {billAssetData.length != 0 && <tbody >
                            {billAssetData.map((obj, index) => {
                                return (
                                    <>
                                        <tr key={index}>

                                            <td>{obj.AssetName}  </td>
                                            <td>{obj.Quantity}</td>
                                            <td>{obj.Tax}</td>
                                            <td>{obj.Price}</td>
                                            <td>{obj.BatchNumber}</td>
                                            <td>{obj.ExpDate}</td>





                                        </tr>
                                    </>
                                );
                            })}
                        </tbody>
                        }
                    </Table>


                </Modal.Body>

            </Modal>


            <ToastContainer />
        </Wrapper>
    )
}
export default CentralStoreConsumableViewAsset
