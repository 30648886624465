import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Wrapper } from "./FixedRequestApproval.styles"
import { Row, Container, Col } from "react-bootstrap"
import Table from "react-bootstrap/Table";
import { useNavigate, Link, useParams } from "react-router-dom";
import { AiOutlineSearch } from "react-icons/ai";
import API_Auth from "../../API_Auth";
import moment from "moment";
import { LoadingSpinner } from "../LoadingSpinner/index";
import ReactPaginate from 'react-paginate';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


const FixedRequestApproval = () => {
    const {
        isExpanded,
    } = useContext(UserContext);
    const navigate = useNavigate();
    const [approval, setApproval] = useState([]);
    const [searchData, setSearchData] = useState()
    const [status, setStatus] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [offset, setOffSet] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [orgtableData, setOrgTableData] = useState([]);
    const [perPage, setPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [searchKey, setSearchKey] = useState('n');
    const [searchKeyTable, setSearchKeyTable] = useState('');



    let { StorID, ApproverId, assetType } = useParams();


    const [filterValue, setFilterValue] = useState("");


    useEffect(() => {
        //window.location.reload(false);


        setIsLoading(true);
        //alert("true");
        // window.location.reload();

        const fetchApproval = async () => {
            let data = await API_Auth.getApproversByStore(ApproverId, assetType, StorID);
            setApproval(data);
            var slice = data.slice(offset, offset + perPage);
            setPageCount(Math.ceil(data.length / perPage));
            setOrgTableData(data);
            setTableData(slice);
            setSearchData(data);
            setIsLoading(false);

        };
        // call the function
        fetchApproval()
            // make sure to catch any error
            .catch(console.error);
    }, [])
    const handleViewDetails = (obj) => {
        const user_id = localStorage.getItem("UserID")
        // navigate("/fixed-request-view/" + StorID + "/" + user_id +"/" + obj.RquestID + "/" + assetType + "/" + "Approve")
        navigate("/fixed-request-view/" + StorID + "/" + user_id + "/" + obj.RquestID + "/" + assetType + "/" + "Approve", { state: { status: obj.Status } });
    }
    const handlerowsperPage = (e) => {
        
        let showperpage = e.currentTarget.value
        setPerPage(parseInt(showperpage));
        setCurrentPage(0);
        setOffSet(0);
        if(searchKey=="y"){
            const slice = searchKeyTable.slice(0, 0 + e.currentTarget.value);
            
            setPageCount(Math.ceil(searchKeyTable.length / e.currentTarget.value))
            setTableData(slice);  
            setSearchData(searchKeyTable);
        }
        else{
        const slice = orgtableData.slice(0, 0 + e.currentTarget.value);
        
        setPageCount(Math.ceil(orgtableData.length / e.currentTarget.value))
        setTableData(slice);
        setSearchData(orgtableData);
        }
    }
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offsetdata = selectedPage * perPage;
        setCurrentPage(selectedPage);
        setOffSet(offsetdata);
        loadMoreData(offsetdata);
    }
    const loadMoreData = (offsetdata) => {
        
        const data = orgtableData;
        setOffSet(offsetdata);
        
        if (searchKey == "y") {
            
            const slice = searchKeyTable.slice(offsetdata, offsetdata + perPage);
            
            setPageCount(Math.ceil(searchKeyTable.length / perPage))
            setTableData(slice);
            setSearchData(searchKeyTable);
        } else {
            
            const slice = data.slice(offsetdata, offsetdata + perPage);
            
            setPageCount(Math.ceil(data.length / perPage))
            setTableData(slice);
            setSearchData(data);
        }

    }
    const handleSearch = (e) => {
        let filterValue = e.target.value
        
        setCurrentPage(0);
        if (filterValue == '') {
            let offsetsearch = 0;
            var slice = orgtableData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(orgtableData.length / perPage));
            setOrgTableData(orgtableData);
            setTableData(slice);
            setOffSet(offsetsearch);
            setSearchData(orgtableData);
            setSearchKey('n');
            setCurrentPage(0);
        }
        else {
            let offsetsearch = 0;
            const filteredData = orgtableData.filter(item => {
                return (item ?.Status ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                    item ?.ToStoreName ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                        item ?.FromStoreName ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                            item ?.CName ?.toLowerCase().includes(filterValue.toLowerCase()))

            });
            const slice = filteredData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(filteredData.length / perPage))
            setSearchKey('y');
            
            setOffSet(offsetsearch);
            setSearchKeyTable(filteredData);
            setTableData(slice);
            setSearchData(filteredData);

        }
    }
    const handleInput = (e) => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;
        if (name == "filterValue") setFilterValue(value);
        if (name == "status") {

            setStatus(value);
            setCurrentPage(0);
            if (value == "all") {
                let offsetsearch = 0;
                var slice = orgtableData.slice(offsetsearch, offsetsearch + perPage);
                setPageCount(Math.ceil(orgtableData.length / perPage));
                setOrgTableData(orgtableData);
                setTableData(slice);
                setSearchData(orgtableData);
                setSearchKey('n');
                setCurrentPage(0);
            } else {
                let value1 = value.toLowerCase();
                let offsetsearch = 0;
                const filteredData = approval.filter(item => {
                    return (item.Status.toLowerCase().includes(value1))
                });
                const slice = filteredData.slice(offsetsearch, offsetsearch + perPage);
                setPageCount(Math.ceil(filteredData.length / perPage))
                setSearchKey('y');
                
                setSearchKeyTable(filteredData);
                setTableData(slice);
                setSearchData(filteredData);
                // setFilterValue("")
            }
        }
    }
    return (

        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            {isLoading && <LoadingSpinner />}

            <Container>
                <Row>
                    <Col>
                        {/*                         <p className="title">Dr Amith</p>
 */}                        <p className="subtitle"> {assetType} Approval</p>
                    </Col>
                </Row>
                <Row style={{ marginTop: 30 }}>
                    <Col lg={4}>
                        <div className="rowdiv">
{/*                             <p className="title">Filter By:</p>
 */}                            <div className="input_div" >
                                <input type="type" style={{ margin: 0, background: 'transparent' }}
                                    name="filterValue"
                                    onChange={handleSearch}
                                    placeholder="Search" />
                                <AiOutlineSearch size={24} style={{ margin: 10 }} color="lightgray" />
                            </div>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className="rowdiv">
                            <select
                                value={status}
                                name="status"
                                style={{ margin: 0, background: 'white' }}
                                onChange={handleInput}>
                                <option value=''>Select</option>
                                <option value="all">All </option>
                                <option value="open">Open</option>
                                <option value="approved" >Approved</option>
                                <option value="assigned" >Assigned</option>
                                <option value="reject" >Reject</option>

                            </select>
                        </div></Col>
                    {/*  <Col lg={4}>
                        <div className="rowdiv">
                            <p className="title">Search :</p>
                            <div className="input_div">
                                <input type="type"
                                    style={{ margin: 0, background: 'transparent' }}
                                    name="filterValue"
                                    value={filterValue}
                                    onChange={handleInput}
                                    placeholder="Search" />
                                <AiOutlineSearch size={24} style={{ margin: 10 }} color="lightgray" />
                            </div>
                        </div></Col> */}
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col lg={12} sm={12} md={12} className="table_head">
                        <Table className="tablelist">
                            <thead>
                                <tr>
                                    <th style={{ textAlign: 'center' }}>  Request No</th>

                                    <th>From</th>
                                    <th>To </th>
                                    <th>Status</th>

                                    <th>Date</th>
                                    <th>Requested By</th>
                                    <th>Assets</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            {tableData.length == 0 && <tbody>
                                <tr >
                                    <td colSpan="12">
                                        <p className="no_Data_table">No Data Found</p>
                                    </td>
                                </tr>
                            </tbody>
                            }
                            {tableData.length != 0 && <tbody>

                                {tableData.map((obj, index) => {
                                    return (
                                        <>
                                            <tr key={index}>
                                                                                                 <td style={{ textAlign: 'center' }}>{obj.RquestID}  </td>
 

                                                <td>{obj.FromStoreName}</td>
                                                <td>{obj.ToStoreName}</td>
                                                <td>
                                                    {obj.Status == "open" &&

                                                        <div className="status_div">
                                                            <p>{obj.Status}</p>
                                                        </div>
                                                    }
                                                    {obj.Status == "assignedToMs" &&

                                                        <div className="status_div1">
                                                            <p>{obj.Status}</p>
                                                        </div>
                                                    }
                                                    {obj.Status == "assigned" &&

                                                        <div className="status_div1">
                                                            <p>{obj.Status}</p>
                                                        </div>
                                                    }
                                                    {obj.Status == "assignedToprincipal" &&

                                                        <div className="status_div1">
                                                            <p>{obj.Status}</p>
                                                        </div>
                                                    }
                                                    {obj.Status == "approved" &&

                                                        <div className="status_div2">
                                                            <p>{obj.Status}</p>
                                                        </div>
                                                    }
                                                    {obj.Status == "reject" &&

                                                        <div className="status_div2">
                                                            <p>{obj.Status}</p>
                                                        </div>
                                                    }
                                                    {obj.Status == "issue" &&

                                                        <div className="status_div1">
                                                            <p>{obj.Status}</p>
                                                        </div>
                                                    }
                                                </td>
                                                {/*                                                 <td>{moment(obj.date).format('DD-MM-YYYY')}</td>

 */}
                                               <td> { moment.utc(obj.DATE).format("DD MMM YYYY  h:mm A")}</td>
                                               <td>{obj.CName}</td>
                                                <td>{obj.Asset}</td>{}


                                                <td>
                                                    <div className="row_table_div" onClick={() => handleViewDetails(obj)} style={{ width: 100 }}>
                                                        <p style={{ marginLeft: 10 }}>VIEW DETAILS</p>
                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    );
                                })}
                            </tbody>
                            }
                        </Table>
                        {tableData.length != 0 &&
                        <div>
                        <div className="pagination_row_div">
                            <p className="pagination_title">Showing items per page:</p>
                            <div className="pagination_input_div" >
                                <select className="pagination_select"
                                 style={{ margin: 0, background: 'transparent' }}
                                    name="pagination_value"
                                    value={perPage}
                                    onChange={handlerowsperPage} >
                                    <option value={4}>4</option>
                                    <option value={8}>8</option>
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                </select>
                                </div>
                        </div>
                        <div className="pagination_row_right_div">
                        <p className="pagination_total">Showing {offset+1} to {searchData.length<offset+perPage&&
                        <span>{searchData.length}</span> }
                        {searchData.length>offset+perPage&&
                        <span>{offset+perPage }</span>} of {searchData.length} items</p>
                         <ReactPaginate
                            previousLabel={<ArrowBackIosIcon  className="pagination_prev_btn" color="#2438a7"/>}
                            nextLabel={<ArrowForwardIosIcon className="pagination_next_btn" color="#2438a7"/>}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"} 
                            forcePage={currentPage} />
                    </div>
                    </div>
                    }    
                    </Col>
                </Row>
            </Container>
        </Wrapper>
    )
}
export default FixedRequestApproval