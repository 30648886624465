import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Wrapper} from "./AdminVendor.styles"
import { Row, Container, Col ,OverlayTrigger, Tooltip,} from "react-bootstrap"
import Table from "react-bootstrap/Table";
import Edit from "../../images/user-edit.png";
import plus from "../../images/white_plus.png"
import { useNavigate } from "react-router-dom";
import Delete from "../../images/delete.png"

import API_Auth from "../../API_Auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Title } from "../../GlobalStyle";
import {LoadingSpinner} from "../LoadingSpinner/index";
import { AiOutlineSearch } from "react-icons/ai";
import ReactPaginate from 'react-paginate';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const AdminVendor = () => {
    const {
        isExpanded,
    } = useContext(UserContext);
    const [vendors, setVendors] = useState([]);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [typeChecked, setChecked] = useState(false);
    const [assetType, setAssetType] = useState('Active');
    const [filterValue, setFilterValue] = useState('');
    const [offset, setOffSet] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [orgtableData, setOrgTableData] = useState([]);
    const [active_data, setaAtiveData] = useState([]);
    const [Inactive_data, setInActive_data] = useState([]);
    const [perPage, setPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [searchKey,setSearchKey]=useState('n');
    const [searchData, setSearchData] = useState([]);
     const[searchKeyTable,setSearchKeyTable]=useState('');

    const handleSearch=(e)=>{
        let filterValue=e.target.value;
        setCurrentPage(0);   
        setFilterValue(e.target.value);
       if (filterValue == '') {
        let offsetsearch=0;
        var slice = orgtableData.slice(offsetsearch, offsetsearch + perPage);
        setPageCount(Math.ceil(orgtableData.length / perPage));
        setOrgTableData(orgtableData);
        setaAtiveData()
        setOffSet(offsetsearch);
        setSearchData(orgtableData);
        setTableData(slice);
        setSearchKey('n');
        setCurrentPage(0);

       }
       else {
        let offsetsearch=0;
           const filteredData = orgtableData.filter(item => {
                return (item?.VendorName?.toLowerCase().includes(filterValue.toLowerCase())||
                item?.VendorCno?.toLowerCase().includes(filterValue.toLowerCase())||
                item?.VendorEmail?.toLowerCase().includes(filterValue.toLowerCase())||
                item?.VendorAdd?.toLowerCase().includes(filterValue.toLowerCase())||
                item?.VendorAdd?.toLowerCase().includes(filterValue.toLowerCase()))
          });
          const slice = filteredData.slice(offsetsearch, offsetsearch + perPage);
          setPageCount(Math.ceil(filteredData.length / perPage))
          setSearchKey('y');
          setOffSet(offsetsearch);
          setSearchData(filteredData);
          setSearchKeyTable(filteredData);
          setTableData(slice);
     }
   }
    useEffect(() => {
        setIsLoading(true)
        setAssetType("Active");
        const fetchAsset = async () => {
            let result = await API_Auth.getVendors();
            setVendors(result);
            setIsLoading(false);
            let activeData = [];
            let InactiveData = [];
            for (var i = 0; i < result.length; i++) {
                if (result[i].ActiveFlag == "InActive") {
                    InactiveData.push(result[i]);
                 }
                else{
                    activeData.push(result[i]);   
                    }
                }
            var slice = activeData.slice(offset, offset + perPage);
            setPageCount(Math.ceil(activeData.length / perPage));
            setOrgTableData(activeData);
            setaAtiveData(activeData);
            setInActive_data(InactiveData);
            setTableData(slice);
            setSearchData(activeData);
        };
        // call the function
        fetchAsset()
            // make sure to catch any error
            .catch(console.error);
    }, []);

    const handleEditVendor = (obj) => {
        navigate("/add-admin-vendor/" +obj.VendorID);
    }
    const handleAddVendor = () => {
        navigate("/add-admin-vendor/add");
    }
    const handlerowsperPage = (e) => {
        let showperpage = e.currentTarget.value
        setPerPage(parseInt(showperpage));
        setCurrentPage(0);
        if(searchKey=="y"){
            const slice = searchKeyTable.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(searchKeyTable.length / e.currentTarget.value))
            setTableData(slice);   
            setOffSet(0);
            setSearchData(searchKeyTable);
        }
        else{
        const slice = orgtableData.slice(0, 0 + e.currentTarget.value);
        setPageCount(Math.ceil(orgtableData.length / e.currentTarget.value))
        setTableData(slice);
        setOffSet(0);
        setSearchData(orgtableData);
        }
    }

    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offsetdata = selectedPage * perPage;
        setCurrentPage(selectedPage);
        setOffSet(offsetdata);
        loadMoreData(offsetdata);
    }

    const loadMoreData = (offsetdata) => {
        const data = orgtableData;
        if(searchKey=="y"){
            const slice = searchKeyTable.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(searchKeyTable.length / perPage));
            setTableData(slice);
            setOffSet(offsetdata);
            setSearchData(searchKeyTable);
        }else{
        const slice = data.slice(offsetdata, offsetdata + perPage);
        setPageCount(Math.ceil(data.length / perPage));
        setTableData(slice);
        setOffSet(offsetdata);
        setSearchData(data);
        }

    }   
    const handleChangeStatus = async(obj,status) => {
      //  setChecked(e.target.checked);
      setIsLoading(true);
        let result = await API_Auth.updateVendorStatus(obj.VendorID, status);
        let res = result[0];
        if (res.Flag == 'Success') {
            setIsLoading(false);
            toast.success('Vendor updated successfully');
            setTimeout(() => {
                navigate("/admin-vendors");
                navigate(0);
            }, 2000);
        } else {
            setIsLoading(false);
            toast.error('Something went wrong please try again');
        }
    };
    const handleAssetType = (status) => {
        setAssetType(status);
        setFilterValue('');
        setCurrentPage(0);  
        setSearchKey('n');
        setSearchKeyTable([]);
 
        if(status=="Active"){
          var slice = active_data.slice(offset, offset + perPage);
            setPageCount(Math.ceil(active_data.length / perPage));
            setOrgTableData(active_data);
            setTableData(slice);
            setOffSet(offset);
            setSearchData(active_data);

        }
        if(status=="InActive"){
            var slice = Inactive_data.slice(0, 0 + perPage);
            setPageCount(Math.ceil(Inactive_data.length / perPage));
            setOrgTableData(Inactive_data);
            setSearchData(Inactive_data);
            setTableData(slice);
            setOffSet(0);
            }
    };
    
     const handleDeleteVendor = (obj) => {
        if (window.confirm('Are you sure you want to delete the vendor ?')) {
            // Save it!
            const fetchDeleteVendor = async () => {
                let result = await API_Auth.deleteVendor(obj.VendorID);
                let res = result[0];
                if (res.Flag == 'Success') {
                    setIsLoading(true);

                    toast.success('Vendor Deleted successfully');
                    const fetchAsset = async () => {
                        let result = await API_Auth.getVendors();
                        setVendors(result);
                        setIsLoading(false);
                    };
                    // call the function
                    fetchAsset()
                        // make sure to catch any error
                        .catch(console.error);

                } else {
                    toast.error('Something went wrong please try again');
                }

            };
            // call the function
            fetchDeleteVendor()
                // make sure to catch any error
                .catch(console.error);
        } else {
            // Do nothing!
        }

    }
    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
                                   {isLoading &&  <LoadingSpinner />}

            <Container>
                <Row>
                    <Col lg={4} style={{paddingLeft:0}}>
                        <Title>Vendors List</Title>
                    </Col>
                    <Col lg={5}>
                    <div className="row_div">
{/*                             <p className="title">Filter By:</p> */}
                            <div className="input_div" >
                                <input type="type" style={{margin:0,background:'transparent'}}
                                name="filterValue"
                                value={filterValue}
                                onChange={(e)=>handleSearch(e)}
                                placeholder="Search"/>
                                <AiOutlineSearch size={24} style={{ margin: 10,cursor:'pointer' }} color="lightgray"/>
                            </div>

                        </div>
                    </Col>          
                    <Col lg={3} style={{paddingRight:0}}>
                        <div>
                            <button className="add_button" onClick={() => handleAddVendor()}>
{/*                                 <img src={plus} style={{ margin: 5 }} /> */}
                                <p className="pcat_title"> ADD A NEW VENDOR</p></button>
                        </div></Col>
                </Row>
            </Container>
            <Container>
                <Row>
                 <Col lg={12} sm={12} md={12} className="table_head">
                     <Row>
                 <Col lg={5}>
                                <Row className="row_type">
                                    <Col className={assetType == "Active" ?
                                        "button_asset_type" : "button_asset_type_fixed"} onClick={() => handleAssetType('Active')}>Active Vendors </Col>
                                    <Col className={assetType == "InActive" ?
                                        "button_asset_type" : "button_asset_type_fixed"} onClick={() => handleAssetType('InActive')}>InActive Vendors</Col>
                                </Row>
                            </Col>
                            </Row>
                        <Table className="tablelist">
                            <thead>
                                <tr>
                                    <th>Vendor Name</th>
                                    <th>Contact No</th>
                                    <th>Email</th>
                                    <th>Address</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            {tableData.length == 0 && <tbody>
                                <tr >
                                    <td colSpan="12">
                                        <p className="no_Data_table">No Data Found</p>
                                    </td>
                                </tr>
                            </tbody>
                            }
                            {tableData.length != 0 && <tbody>
                                {tableData.map((obj, index) => {
                                    return (
                                        <>
                                            <tr key={index}>

                                                {obj.VendorName == '' &&
                                                    <td>{obj.VendorName}</td>
                                                }
                                                {obj.VendorName != '' &&
                                                    <td>
                                                    <OverlayTrigger
                                                        key={obj.VendorName}
                                                        overlay={<Tooltip id={`tooltip-top`}>
                                                                {obj.VendorName}</Tooltip>}>
                                                 <span className="table_text_overflow">{obj.VendorName}</span>
                                                    </OverlayTrigger>
                                                    </td>
                                                }
                                                <td>{obj.VendorCno}</td>
                                                <td>{obj.VendorEmail}</td>
                                                {obj.VendorAdd == '' &&
                                                    <td>{obj.VendorAdd}</td>
                                                }
                                                {obj.VendorAdd != '' &&
                                                <td>
                                                    <OverlayTrigger
                                                        key={obj.VendorAdd}
                                                        overlay={
                                                            <Tooltip id={`tooltip-top`}>
                                                                {obj.VendorAdd}.
                                                                </Tooltip>
                                                        }
                                                    >
                                                        <span className="table_text_overflow">{obj.VendorAdd}</span>
                                                    </OverlayTrigger>
                                                    </td>
                                                }
                                                {obj.ActiveFlag == "InActive"?(
                                                  <td>
                                                   <span className="table_active_btn" onClick={()=>handleChangeStatus(obj,"Active")}>Activate</span> 
                                                  </td>
                                                ):(
                                                    <td>
                                                    <span className="table_active_btn" onClick={()=>handleChangeStatus(obj,"InActive")}>InActivate</span> 
                                                  </td>
                                                )}
                                                <td>
                                                    <div  >
                                                        <img src={Edit} onClick={() => handleEditVendor(obj)} style={{ width: 20, height: 20, margin: 5,cursor: 'pointer'}} />
                                                        <img src={Delete} onClick={() => handleDeleteVendor(obj)} style={{ width: 20, height: 20, margin: 5,cursor: 'pointer'}} />
                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    );
                                })}
                            </tbody>
                            }
                        </Table>
                        {tableData.length != 0 &&
                        <div>
                        <div className="pagination_row_div">
                            <p className="pagination_title">Showing items per page:</p>
                            <div className="pagination_input_div" >
                                <select className="pagination_select"
                                 style={{ margin: 0, background: 'transparent' }}
                                    name="pagination_value"
                                    value={perPage}
                                    onChange={handlerowsperPage} >
                                    <option value={4}>4</option>
                                    <option value={8}>8</option>
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                </select>
                                </div>
                        </div>
                        <div className="pagination_row_right_div">
                        <p className="pagination_total">Showing {offset+1} to {searchData.length<offset+perPage&&
                        <span>{searchData.length}</span> }
                        {searchData.length>offset+perPage&&
                        <span>{offset+perPage }</span>} of {searchData.length} items</p>
                         <ReactPaginate
                            previousLabel={<ArrowBackIosIcon  className="pagination_prev_btn" color="#2438a7"/>}
                            nextLabel={<ArrowForwardIosIcon className="pagination_next_btn" color="#2438a7"/>}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"} 
                            forcePage={currentPage} />
                                                </div>
                    </div>
                    }    
                    </Col>
                </Row>
            </Container>
            <ToastContainer/>
        </Wrapper>
    )
}
export default AdminVendor