import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Container, Row, Col } from "react-bootstrap";
import { Wrapper, RowInput, Label, Input, Switch } from "./FixedRequestApprovalView.styles"
import plus from "../../images/white_plus.png"
import { Title } from "../../GlobalStyle"
import { FaCheckCircle, FaSearch } from "react-icons/fa"
import { AiOutlinePlus } from "react-icons/ai";
import RequestListData from "../json/FixedRequestApproval.json"

const FixedRequestApprovalView = () => {
    const {
        isExpanded,
    } = useContext(UserContext);
    const [itemName, setitemName] = useState('');
    const [store_type, setstore_type] = useState('');
    const [quantity, setquantity] = useState('');
    const [comments, setcomments] = useState('');

    const [inputFields, setInputFields] = useState(RequestListData.requestViewList);
    const [approveComments, setApproveComments] = useState(' Lorem Ipsum is simply dummy text of the printing');


    const handleChange = (index, evnt) => {

        const { name, value } = evnt.target;
        const list = [...inputFields];
        list[index][name] = value;
        setInputFields(list);
    }
    const handleInput = (e) => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;
        if (name === "itemName") setitemName(value);
        if (name === "store_type") setstore_type(value);
        if (name === "comments") setcomments(value);
        if (name === "quantity") setquantity(value);
        if (name === "approveComments") setApproveComments(value)
    };
    const [checked, setChecked] = useState(true);

    const handleSend=()=>{

    }

    const handleReject=()=>{
        
    }


    return (
        <>

            <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>

                <Row>
                    <Col lg={4}>
                        <p className="title">Dr Amith</p>
                        <p className="subtitle">Pending Request No:123</p>

                    </Col>
                    <Col lg={4}></Col>
                    <Col style={{ marginTop: 10 }}>
                        <p className="title"> Made  By:Sunil Singh</p>
                    </Col>
                </Row>
                <hr />

                <Container>
                    <Row>
                        <Col lg={5}>
                            <div>
                                <label>From: X-Ray Ward</label>
                                <br />
                                <label>To: Radiology Substore</label>
                            </div>


                        </Col>
                    </Row>
                    <Row style={{ marginTop: 20 }}>
                        <Col lg={3}><label>Item Name</label></Col>
                        <Col lg={2}><label>Quantity</label></Col>
                        <Col lg={3}><label>Comments</label></Col>
                        <Col lg={4}><label>Approved Quantity</label></Col>
                    </Row>

                    <div >
                        {
                            inputFields.map((data, index) => {
                                const { itemName, qunatity, comments, approvedqunatity } = data;
                                return (
                                    <Row key={index} >
                                        <Col lg={3}>
                                            <div className="inputdiv">
                                                <input type="text"
                                                    name="itemName"
                                                    value={itemName}
                                                    placeholder=" Item Name"
                                                    onChange={(evnt) => handleChange(index, evnt)} />

                                            </div>
                                        </Col>
                                        <Col lg={2}>
                                            <div className="inputdiv">
                                                <input type="text"
                                                    name="qunatity"
                                                    value={qunatity}
                                                    placeholder=" Quantity "
                                                    onChange={(evnt) => handleChange(index, evnt)} />
                                            </div>
                                        </Col>
                                        <Col lg={3}>
                                            <div className="inputdiv">

                                                <input type="text"
                                                    name="comments"
                                                    value={comments}
                                                    placeholder=" Type Something"
                                                    onChange={(evnt) => handleChange(index, evnt)} />
                                            </div>
                                        </Col>
                                        <Col lg={3}>
                                            <div className="inputdiv">
                                                <input type="text"
                                                    name="approvedqunatity"
                                                    value={approvedqunatity}
                                                    placeholder="Approved Quantity "
                                                    onChange={(evnt) => handleChange(index, evnt)} />
                                            </div>
                                        </Col>

                                    </Row>
                                )
                            })
                        }
                    </div>
                    <Row>
                        <Col lg={5}>
                            <label>Approver Comments</label>
                            <input type="text"
                                value={approveComments}
                                name="approveComments"
                                placeholder=" Enter Comments"
                                onChange={handleInput}
                            >
                            </input>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={8} style={{ margin: 20 }}>
                            <Row>
                                <Col lg={2}>
                                    <label style={{ marginTop: 15 }}>Send To:</label>
                                </Col>
                                <Col lg={2}>
                                    <input type="radio" id="age1" name="radio" value="MS" style={{ width: 25, height: 15 }}
                                        onChange={handleInput}
                                    />
                                    <label for="age1" className="radiolabel"> MS</label>
                                </Col>
                                <Col lg={3}>
                                    <input type="radio" id="age2" name="radio" value="Radiology" style={{ width: 25, height: 15 }}
                                        onChange={handleInput}
                                    />
                                    <label for="age2" className="radiolabel">Radiology</label>
                                </Col>
                                <Col lg={3}>
                                    <input type="radio" id="age3" name="radio" value="Principal" style={{ width: 25, height: 15 }}
                                        onChange={handleInput}
                                    />
                                    <label for="age3" className="radiolabel">  Principal</label>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={1}></Col>
                        <Col lg={3} className="send_button" onClick={()=>handleSend()}>
                            <p>SEND</p>
                        </Col>
                        <Col lg={3} className="reject_button" onClick={()=>handleReject()}>
                            <p>REJECT</p>
                        </Col>

                    </Row>

                </Container>
            </Wrapper>
        </>
    )
}
export default FixedRequestApprovalView