import styled from "styled-components";
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
overflow: hidden;
padding: 0px 10px;
.menu_toogler{
    margin:12px;
    margin-bottom: 2px;
}
.adminStore{
    border:1px solid #00001F24;
    border-radius:6px;
    opacity:1;
    display:flex;
    flex-direction:row;
    margin:37px 18px 23px;
    height:45px;
    justify-content: space-evenly;

    p{
        margin:7px 10px;
        font-weight: normal;
        font-family: 'Roboto', sans-serif;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
    }
    .ulsearch {
        background: white;
        margin: 50px 18px 23px;
        border: 1px solid #00001F24;
        border-radius: 6px;
        opacity: 1;
        list-style-type: none;
        position: fixed;
        width: 16%;
        z-index: 100;
        
        li {
            margin:5px;
            padding:3px;
            cursor:pointer;
            font-weight: normal;
            font-family: 'Roboto', sans-serif;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
    
        }
    
    }
    
}

.iconSize {
    margin:10px;

}
.itemRound{
    border-radius: 12px;
    border: 1px solid #E4E4E7;
    background: #EFF2FB;
    text-align: center;
    position: relative;
    left: 67%;
    top: 55.9%;
    font-size: 15px;
    width:25px;
    height:25px;
}
.css-pwcg7p-MuiCollapse-root{
    min-height: unset !important;
}
.css-10hburv-MuiTypography-root {
    font-weight: 600;
    font-size: 16px;

}

appMenu: {
    width: '100%';
  }
  navList: {
    width: drawerWidth;
  }
  menuItem: {
    width: drawerWidth;
  }
  menuItemIcon: {
    color: '#97c05c';
  }
.menu-open{
    background: #c0d3ef;
    }
        .css-bshv44-MuiButtonBase-root-MuiListItem-root:hover{
            background-color:#c0d3ef;
        }
hr {
    margin: 0;
    color: #e1e1e1;
}
sidebar::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}
  .sidebar {
    position: fixed;
    background: white;
    top: 70px;
    left: 0;
    bottom: 0;
    display:flex;
    flex-direction:column;
    width: 18%;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    overflow-x: hidden;
    overflow-y: auto;
}
.sub-item{
    padding:0 10px;
    position: relative;
    float: right;
}
.sidebar-logo{
    font-size:16px; 
    width:100px; 
    margin: 32px 27px 0 10px;
}
.sidebar-icon{
    height: 32px;
    width: 32px;
    margin-right:16px;
    cursor:pointer;
    user-select:none;
}
.toggle-icon{
    color: #1E3D73;
    position: relative;
    top: 32px;
    left: 50px;
}
.sidebar-header{
    display:flex;
    align-items:center,
    padding:16px;
    height: 70px;
    background: white;
    padding: 0px 5px;
}
.sidebar-items{
    cursor:pointer;
    display:flex;
    flex-direction:column;
    padding: 0 8px; 
    margin: 45px 0px 30px 0;
    p{
        color:var(--darkwhite);
        margin:15px;
    }
      
}
.sidebar-items .item{
    display:block;
    font-family: Arial;
    margin: 1px 15px;
    font-family: Arial;
    padding: 11px;
    border-radius: 6px;
    .parent-bar{
        display:flex;
    }
    .child-bar{
        .item{
            margin: 1px;
        }
    }
}
.sidebar-items .active{
    background:#EFF2FB
}

.sidebar.collapsed{
    width:52px;    
}
.sidebar.collapsed .sidebar-icon{
    margin-right: 0;
    left:10px;
}
.sidebar.collapsed .sidebar-text,
.sidebar.collapsed .sidebar-logo{
    display:none;
}
.sidebar.collapsed .sidebar-icon{
    position: absolute;
}

`;


export const LCLogoImg = styled.img`
  width: 60px;
  @media screen and (max-width: 500px) {
    width: 40px;
  }
`;
