
import React from "react";
// Routing
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from './components/Login';
//Styles
import "bootstrap/dist/css/bootstrap.min.css";
import { GlobalStyle } from "./GlobalStyle";
import Header from "./components/Header"
import Layout from "./components/Layout";
import UserProvider from "./context";

import Aboutus from "./components/Aboutus";
import Dashboard from "./components/Dashboard";


import AdminCategory from "./components/AdminCategory";
import AdminSubCategory from "./components/AdminSubCategory";
import AdminAsset from "./components/AdminAsset";
import AddAddminAsset from "./components/AddAdminAsset";
import AdminUser from "./components/AdminUser";
import AddAdminUser from "./components/AddAdminUser";
import SubStore from "./components/SubStore";
import AddSubStore from "./components/AddSubStore";
import AdminVendor from "./components/AdminVendor";
import UserProfile from "./components/UserProfile"
import TransactionLog from "./components/TransactionLog"
import AddAdminVendor from "./components/AddAdminVendor";
import FixedAssetList from "./components/FixedAssetList";
import FixedAddItem from "./components/FixedAddItem";
import FixedRequestApproval from "./components/FixedRequestApproval";
import FixedRequest from "./components/FixedRequest";
import FixedRequestAdd from "./components/FixedRequestAdd";
import FixedRequestView from "./components/FixedRequestView";
import FixedRequestApprovalView from "./components/FixedRequestApprovalView";
import Store from "./components/Store";
import CentralStoreConsumableAsset from "./components/CentralStoreConsumableAsset";
import CentralConsumableAddItem from "./components/CentralConsumableAddItem";
import CentralConsumableAssetIssue from "./components/CentralConsumableAssetIssue";
import CentralStoreConsumableViewAsset from "./components/CentralStoreConsumableViewAsset";
import SubStoreConsumbleAssetList from "./components/SubStoreConsumbleAssetList";
import AssetConsumption from "./components/AssetConsumption";
import AssetAdjustment from "./components/AssetAdjustment";
import Assets from "./components/Assets";
import SentRequest from "./components/SentRequest";
import IssueRequest from "./components/IssueRequest";
import PendingRequest from "./components/PendingRequest";
import Adjustment from "./components/Adjustment";
import AdjustmentLog from "./components/AdjustmentLog";
import ConsumptionLog from "./components/ConsumptionLog";

import ReceivedRequest from "./components/ReceivedRequest";
import ApprovalManagement from "./components/ApprovalManagement";
import Consumption from "./components/Consumption";
import CompletedReceivedRequest from "./components/CompletedReceivedRequest";

import PendingReceivedRequest from "./components/PendingReceivedRequest";
import StockRegister from "./components/StockRegister";
import UOM from "./components/UOM";
import Designation from './components/Designation'
import PendingApprovalManagement from "./components/PendingApprovalManagement";
import CompleteApprovalManagement from "./components/CompleteApprovalManagement";






const App = () => {
  return (
    <Router>
      <UserProvider>

        <Routes>
          <Route path="" exact={true} element={<Login />} />
          <Route path="/login" exact={true} element={<Login />} />
          <Route element={<Layout />}>
            <Route path="/admin-category" element={<AdminCategory />} />
            <Route path="/admin-subcategory" element={<AdminSubCategory />} />
            <Route path="/admin-asset" element={<AdminAsset />} />
            <Route path="/add-admin-asset/:assetID/:from" element={<AddAddminAsset />} />
            <Route path="/admin-users" element={<AdminUser />} />
            <Route path="/add-admin-user/:userID" element={<AddAdminUser />} />
            <Route path="/substore-list" element={<SubStore />} />
            <Route path="/add-substore/:subStoreID" element={<AddSubStore />} />
            <Route path="/admin-vendors" element={<AdminVendor />} />
            <Route path="/add-admin-vendor/:vendorID" element={<AddAdminVendor />} />
            <Route path="/stock-register" element={<StockRegister/>}/>
            <Route path="/uom" element={<UOM/>}/>
            <Route path="/designation" element={<Designation/>}/>
            <Route path="/tansaction-log" element={<TransactionLog/>}/>
            <Route path="/assets-management/:StorID" element={<Assets />} />
            <Route path="/sent-request/:StorID" element={<SentRequest />} />
            <Route path="/received-request/:StorID" element={<ReceivedRequest />} />
            <Route path="/approval-management/:StorID/:ApproverD" element={<ApprovalManagement />} />

            <Route path="/pending-approved-request/:StorID/:ApproverD" element={<PendingApprovalManagement />} />
            <Route path="/approved-request/:StorID/:ApproverD" element={<CompleteApprovalManagement />} />



            <Route path="/fixed-asset-list/:StorID" element={<FixedAssetList />} />
            <Route path="/fixed-add-item/fixed-asset-list/:storeID/:assetID/:itemID" element={<FixedAddItem />} />
            <Route path="/fixed-request/:StorID/:SubstoreName/:assetType/:RequestType" element={<FixedRequest />} />
            {/*             <Route path="/fixed-add-request/fixed-request/:StorID/:SubstoreName/:assetType" element={<FixedRequestAdd/>}/>
 */}

            <Route path="/add-request/:StorID" element={<FixedRequestAdd />} />
            <Route path="/fixed-request-view/:StorID/:ApproverId/:RquestID/:assetType/:RequestType" element={<FixedRequestView />} />
            <Route path="/fixed-request-approval/:StorID/:ApproverId/:assetType" element={<FixedRequestApproval />} />
            <Route path="/request-received/:StorID/:SubstoreName/:assetType/:RequestType" element={<Store />} />


            <Route path="/request-received/:StorID/:SubstoreName/:assetType" element={<Store />} />
            <Route path="/central-consumable-asset/:StorID" element={<CentralStoreConsumableAsset />} />

{/*             <Route path="/central-consumable-view-asset/:StorID/:assetID" element={<CentralStoreConsumableViewAsset />} />
 */}            <Route path="/central-consumable-add-item/central-consumable-asset/:StorID/:assetID/:itemID" element={<CentralConsumableAddItem />} />
            <Route path="/central-consumable-view-asset/:assetType" element={<CentralStoreConsumableViewAsset />} />
            <Route path="/central-consumable-asset-issue" element={<CentralConsumableAssetIssue />} />
            <Route path="/substore-consumable-asset" element={<SubStoreConsumbleAssetList />} />
            <Route path="/asset-consumption" element={<AssetConsumption />} />
            <Route path="/asset-adjustment" element={<AssetAdjustment />} />
            <Route path="/issue-request/:StorID" element={<IssueRequest />} />
            <Route path="/pending-request/:StorID" element={<PendingRequest />} />

            <Route path="/issue-outgoing-request/:StorID" element={<CompletedReceivedRequest />} />

            <Route path="/pending-outing-request/:StorID" element={<PendingReceivedRequest />} />


            <Route path="/aboutus" element={<Aboutus/>} />
            <Route path="/dashboard" element={<Dashboard/>} />
            <Route path="/dashboard/:StorID/:UserType" element={<Dashboard/>} />
            <Route path ="/consumption/:StorID" element={<Consumption/>}/>
            <Route path="/user-profile/:userID" element={<UserProfile/>}/>
            <Route path ="/adjustment/:StorID" element={<Adjustment/>}/>

            <Route path ="/consumption_log/:StorID" element={<ConsumptionLog/>}/>

            <Route path ="/adjustment_log/:StorID" element={<AdjustmentLog/>}/>

          </Route>

        </Routes>
        <GlobalStyle />
      </UserProvider>

    </Router>
  );
};

export default App;
