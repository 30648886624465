import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Row, Container, Col, OverlayTrigger, Tooltip, Table } from "react-bootstrap"
import { Wrapper, RowInput, Label, Input, Switch } from "./FixedRequestView.styles"
import plus from "../../images/white_plus.png"
import { Title } from "../../GlobalStyle"
import { FaCheckCircle, FaSearch } from "react-icons/fa"
import { AiOutlinePlus, AiOutlinePlusSquare } from "react-icons/ai";
import RequestListData from "../json/FixedRequestView.json"
import { useNavigate, useParams, useLocation } from "react-router-dom";
import API_Auth from "../../API_Auth";
import { ToastContainer, toast } from "react-toastify";
import { LoadingSpinner } from "../LoadingSpinner/index";
import eye from "../../images/eye.png";
import InfoIcon from '@mui/icons-material/Info';

import "react-toastify/dist/ReactToastify.css";
import Modal from 'react-bootstrap/Modal';
import { AiOutlineSearch } from "react-icons/ai";

import signature from "../../images/dsignature.png"

import { base_url } from "../../config"


const FixedRequestView = () => {
    const {
        isExpanded,
    } = useContext(UserContext);
    let { RquestID, StorID, RequestType, assetType, ApproverId } = useParams();
    const [itemName, setitemName] = useState('');
    const [store_type, setstore_type] = useState('');
    const [quantity, setquantity] = useState('');
    const [comments, setcomments] = useState('');
    const [shownDrawerIndex, setshownDrawerIndex] = useState(null);
    const [approveComments, setaApproveComments] = useState('');
    const [radioValue, setRadioValue] = useState("");
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [disbleIssueButton, setDisbleIssueButton] = useState(false);

    const [processType, setProcessType] = useState('a');
    const [finalComments, setFinalComments] = useState('')


    const [allAprrovers, setAllAprrovers] = useState([]);
    const [allStoreAprrovers, setAllStoreAprrovers] = useState([]);

    const [modalAssetDataItems, setModalAssetDataItems] = useState([]);
    const [modalAssetDataIndex, setModalAssetDataIndex] = useState('');
    const [modalConsumableDataIndex, setModalConsumableDataIndex] = useState('');
    const [modalShowConsumable, setModalShowConsumable] = useState(false);
    const [modalConsumabletDataItems, setModalConsumableDataItems] = useState([]);
    const [designationErr, setDesignationError] = useState('');


    const [modalShow, setModalShow] = useState(false);
    const [itemErr, setItemErr] = useState('');
    const location = useLocation();
    const [approvalStatus, setApprovalStatus] = useState(location.state ?.status);
    const [approverD, setApproverD] = useState('');
    const [approvedValue, setApprovedValue] = useState(true);
    const [designationData, setDesignationData] = useState([]);
    const [availableItems, setAvailableItems] = useState([]);
    const [nonAvailableItems, setNonAvailableItems] = useState([]);

    const [availableStore, setAvailableStore] = useState(false)



    const [inputFields, setInputFields] = useState([{
        itemName: '',
        qunatity: '',
        comments: '',

        ApprovedQuantity: '',
        IssueQuantity: '',
        AvailableQuantity: '',
        assetsDataItems: [],
        batchItems: [],
        issueqtyErr: '',
        batchNo: '',
        UOM: '',
        issueqtyerror: '',
        batchTextConsumable: [],
        issuecolor: '',
        show: '',
        items: [/* {
            asset_name: "",
            asset_comments: ""
        } */
        ]

    }]);
    const [approverData, setApproverData] = useState([]);
    const [senderSignaturePicShow, setsenderSignaturePicShow] = useState('');
    const [issueSignaturePicShow, setIssueSignaturePicShow] = useState('');

    const [signaturePicErr, setSignaturePicErr] = useState('');
    const [requestData, setRequestData] = useState('');
    const [assetsData, setAssetsData] = useState([]);


    const [storeDet, setStoreDet] = useState([]);
    const [reqApproverData, setReqApproverData] = useState([]);
    const [receivedName, setRecievedName] = useState('');
    const [radioid, setRadioId] = useState('');
    const [issueqty, setIssueqty] = useState(false);
    //const [issueqtyErr, setIssueqtyErr] = useState('');
    const [disableSubmit, setDisableSubmit] = useState(false);



    useEffect(() => {
        var ApproverD = localStorage.getItem("ApproverD");
        setApproverD(ApproverD)
        if (RequestType == "Request" || RequestType == "Received") {
            const fetchRequestView = async () => {

                let fetch_request_view_data = await API_Auth.getRequestByRequestId(RquestID)

                setRequestData(fetch_request_view_data.reqData[0]);
                setApproverData(fetch_request_view_data.Approver);
                setsenderSignaturePicShow(fetch_request_view_data.reqData[0].Sign);
                setFinalComments(fetch_request_view_data.issuarComment.length == 0 ? '' : fetch_request_view_data.issuarComment[0].issuarComment)

                setIssueSignaturePicShow(fetch_request_view_data.issuarComment.length == 0 ? '' : fetch_request_view_data.issuarComment[0].issuerSign)
                let asset = fetch_request_view_data.Asset;
                asset.forEach(v => {
                    v.assetsDataItems = [];
                    v.batchItems = [];
                    v.show = false;
                });

                /*     asset.map(async (app, index) => {
                        console.log(app, index)
                        let asset_det = await API_Auth.getConsumeAssetQuantity(StorID, app.AssetID);
                        console.log("asset_det", asset_det);
                        app['AvailableQuantity'] = asset_det.length != 0 ? asset_det[0].Quantity : "0"
    
    
                    }) */
                for (var i = 0; i < asset.length; i++) {
                    let app = asset[i]
                    let asset_det = await API_Auth.getConsumeAssetQuantityIssue(StorID, app.AssetID);
                    app['AvailableQuantity'] = asset_det.length != 0 ? asset_det[0].Quantity : 0
                }
                if (fetch_request_view_data.reqData[0].Status == "open") {
                    for (var i = 0; i < asset.length; i++) {
                        asset[i].IssueQuantity = asset[i].Quantity;
                    }
                }
                if (fetch_request_view_data.reqData[0].Status == "approved") {
                    for (var i = 0; i < asset.length; i++) {
                        asset[i].IssueQuantity = asset[i].AvailableQuantity == "0" ? asset[i].AvailableQuantity : asset[i].ApprovedQuantity;
                        if (asset[i].AvailableQuantity == "0") {
                            asset[i]['issueqtyerror'] = false;
                            asset[i]['issueqtyErr'] = "Item not available"
                            asset[i]["issuecolor"] = "r"
                            // setIssueqtyErr("Item not available");

                        } else if (asset[i].AvailableQuantity < asset[i].ApprovedQuantity) {
                            asset[i]['issueqtyerror'] = false;
                            asset[i]['issueqtyErr'] = "Quantity Exceeds"
                            asset[i]["issuecolor"] = "y"


                            //  setIssueqtyErr("Quantity Exceeds");
                        } else {
                            asset[i]['issueqtyerror'] = true;
                            asset[i]['issueqtyErr'] = "";


                        }
                        if (asset[i].ApprovedQuantity == "0") {
                            asset[i]['issueqtyerror'] = false;
                            asset[i]['issueqtyErr'] = "Not Approved"
                            asset[i]["issuecolor"] = "b"

                        } else {
                            /* asset[i]['issueqtyerror'] = true ;
                            asset[i]['issueqtyErr'] = "" ; */

                        }


                        //asset[i]['issueqtyerror'] = asset[i].AvailableQuantity == "0" ? false : true;

                    }
                }
                setInputFields(asset);
                if (StorID != 'all') {
                    let store_det = await API_Auth.getSubstoreById(StorID);
                    setStoreDet(store_det.substore[0]);
                }

                let store_det_data = await API_Auth.getSubstoreById(fetch_request_view_data.reqData[0].fromStoreID);
                setRecievedName(store_det_data.substore[0].SubStoreName);



            };
            // call the function
            fetchRequestView()
                // make sure to catch any error
                .catch(console.error);
        }
        if (RequestType == "Approve") {
            const fetchApproveView = async () => {
                if (approvalStatus == "open") {

                    let fetch_approve_view_data = await API_Auth.getApproverByRequestId(RquestID, ApproverId, assetType)
                    setRequestData(fetch_approve_view_data.reqData[0]);
                    setApproverData(fetch_approve_view_data.Approver);
                    setsenderSignaturePicShow(fetch_approve_view_data.reqData[0].Sign);
                    setFinalComments(fetch_approve_view_data.issuarComment.length == 0 ? '' : fetch_approve_view_data.issuarComment[0].issuarComment)
                    setIssueSignaturePicShow(fetch_approve_view_data.issuarComment.length == 0 ? '' : fetch_approve_view_data.issuarComment[0].issuerSign)

                    let approvers = fetch_approve_view_data.Approver;
                    let fetch_request_approve_view_data = await API_Auth.getRequestByRequestId(RquestID);
                    setReqApproverData(fetch_request_approve_view_data.Approver);

                    let approversArray = [];
                    Object.keys(approvers[0]).map(function (item, value) {
                        if (approvers[0][item] != null && approvers[0][item] != undefined) {
                            let temp_str = approvers[0][item].split(',');
                            let temp_obj = {
                                id: temp_str[1],
                                name: temp_str[0],
                                type: 'a'
                            }
                            approversArray.push(temp_obj);
                        }

                    })

                    //fetching store detils 
                    if (StorID != 'all') {
                        let store_det = await API_Auth.getSubstoreById(StorID);
                        setStoreDet(store_det.substore[0]);
                    }
                    else {
                        if (fetch_approve_view_data.reqData[0].fromStoreID != undefined) {
                            let store_det = await API_Auth.getSubstoreById(fetch_approve_view_data.reqData[0].fromStoreID);
                            setStoreDet(store_det.substore[0]);
                        } else {
                            let store_det = await API_Auth.getSubstoreById(fetch_approve_view_data.reqData[0].FromStoreID);
                            setStoreDet(store_det.substore[0]);
                        }
                    }
                    let approverstoreArray = [];
                    approverstoreArray.push({
                        id: fetch_approve_view_data.reqData[0].ToSubstoreID,
                        name: fetch_approve_view_data.reqData[0].ToSubstoreName,
                        type: 's'
                    })
                    //setting request process
                    setAllAprrovers(approversArray)
                    setAllStoreAprrovers(approverstoreArray)

                    let asset = fetch_approve_view_data.Asset
                    asset.forEach(v => {
                        v.batchItems = [];

                        v.assetsDataItems = [];
                    });
                    if (fetch_approve_view_data.reqData[0].Status == "open") {
                        for (var i = 0; i < asset.length; i++) {
                            asset[i].ApprovedQuantity = asset[i].Quantity;
                        }
                    }
                    setInputFields(asset);


                }
                else {
                    let fetch_request_view_data = await API_Auth.getRequestByRequestId(RquestID);
                    setRequestData(fetch_request_view_data.reqData[0]);
                    setApproverData(fetch_request_view_data.Approver);
                    setsenderSignaturePicShow(fetch_request_view_data.reqData[0].Sign);
                    setFinalComments(fetch_request_view_data.issuarComment.length == 0 ? '' : fetch_request_view_data.issuarComment[0].issuarComment)

                    setIssueSignaturePicShow(fetch_request_view_data.issuarComment.length == 0 ? '' : fetch_request_view_data.issuarComment[0].issuerSign)
                    let asset = fetch_request_view_data.Asset
                    asset.forEach(v => {
                        v.assetsDataItems = [];
                        v.batchItems = [];
                        v.show = false;
                    });

                    setReqApproverData(fetch_request_view_data.Approver);



                    if ((fetch_request_view_data.reqData[0].Status == "assignedToMs" || fetch_request_view_data.reqData[0].Status == "assignedToprincipal" ||
                        fetch_request_view_data.reqData[0].Status == "assignedToNs") && (ApproverD == "4" || ApproverD == "5" || ApproverD == "3")) {
                        let fetch_approve_view_data = await API_Auth.getApproverByRequestId(RquestID, ApproverId, assetType)
                        setApproverData(fetch_approve_view_data.Approver);
                        let approvers = fetch_approve_view_data.Approver;
                        let approversArray = [];
                        Object.keys(approvers[0]).map(function (item, value) {
                            if (approvers[0][item] != null && approvers[0][item] != undefined) {
                                let temp_str = approvers[0][item].split(',');
                                let temp_obj = {
                                    id: temp_str[1],
                                    name: temp_str[0],
                                    type: 'a'
                                }
                                approversArray.push(temp_obj);
                            }

                        })

                        //fetching store detils 
                        if (StorID != 'all') {
                            let store_det = await API_Auth.getSubstoreById(StorID);
                            setStoreDet(store_det.substore[0]);
                        }
                        let approverstoreArray = [];
                        approverstoreArray.push({
                            id: fetch_approve_view_data.reqData[0].ToSubstoreID,
                            name: fetch_approve_view_data.reqData[0].ToSubstoreName,
                            type: 's'
                        })
                        //setting request process
                        setAllAprrovers(approversArray)
                        setAllStoreAprrovers(approverstoreArray)

                    }







                    if (StorID != 'all') {
                        let store_det = await API_Auth.getSubstoreById(StorID);
                        setStoreDet(store_det.substore[0]);
                    } else {
                        let store_det_data = await API_Auth.getSubstoreById(fetch_request_view_data.reqData[0].fromStoreID);
                        setStoreDet(store_det_data.substore[0]);

                    }

                    let store_det_data = await API_Auth.getSubstoreById(fetch_request_view_data.reqData[0].fromStoreID);
                    setRecievedName(store_det_data.substore[0].SubStoreName)
                    setInputFields(asset);



                }




            };
            // call the function
            fetchApproveView()
                // make sure to catch any error
                .catch(console.error);

        }


    }, []);


    const handleChange = (index, evnt, itemindex) => {

        const { name, value } = evnt.target;
        const list = [...inputFields];
        //list[index][name] = value;
        var keyvalue = true;
        var sum = 0;

        for (var i = 0; i < list.length; i++) {
            sum = sum + parseInt(list[i].ApprovedQuantity);

        }
        if (sum == 0) {
            setApprovedValue(false)
        } else {
            setApprovedValue(true)

        }
        if (name == "IssueQuantity") {
            if (value == 0) {
                // setIssueqtyErr("Item not available");
                list[index]["issueqtyErr"] = "Item not available"
                list[index]["issuecolor"] = "r"

                setIssueqty(true);
                list[index]['issueqtyerror'] = false;

            } else {
                if (parseInt(list[index].AvailableQuantity) >= parseInt(value)) {
                    // setIssueqtyErr('');
                    setIssueqty(false);
                    list[index]["issueqtyErr"] = ""

                    list[index]['issueqtyerror'] = true;


                } else {
                    //setIssueqtyErr("Item not available");
                    setIssueqty(true);
                    list[index]['issueqtyerror'] = false;
                    list[index]["issueqtyErr"] = "Item not available"
                    list[index]["issuecolor"] = "r"





                }
            }


            list[index][name] = value;


        }

        if (name == "ApprovedQuantity") {
            list[index][name] = value;

        }
        setInputFields(list);
    }
    const handlCloseModal = () => {
        setModalShow(false);

    }
    const handlCloseModalConsume = () => {
        setModalShowConsumable(false)
    }
    const handleAvailableStore = () => {
        setAvailableStore(false)
    }
    const handleme = async (obj, itemindex, e) => {
        let checked = e.currentTarget.checked;
        const index = modalAssetDataIndex;
        const list = [...inputFields];
        list[index]['asset_name'] = obj.id;

        if (checked) {
            list[index]["assetsDataItems"].map(function (item, key) {
                if (item.id === obj.id)
                    list[index]["assetsDataItems"][key]['selected'] = true;
            });
        } else {
            list[index]["assetsDataItems"].map(function (item, key) {
                if (item.id === obj.id)
                    list[index]["assetsDataItems"][key]['selected'] = false;
            });
        }

        let qunatity = list[index]["assetsDataItems"].filter(function (item, key) {
            return item['selected'] == true;
        });
        list[index]['IssueQuantity'] = qunatity.length;
        setInputFields(list);
    }

    const handlConsume = async (obj, itemindex, e) => {
        let checked = e.currentTarget.checked;
        const index = modalConsumableDataIndex;
        const list = [...inputFields];

        if (checked) {
            list[index]["batchItems"].map(function (item, key) {
                if (item.BatchNo === obj.BatchNo) {
                    list[index]["batchItems"][key]['selected'] = true;
                }
            });
        } else {
            list[index]["batchItems"].map(function (item, key) {
                if (item.BatchNo === obj.BatchNo) {
                    list[index]["batchItems"][key]['selected'] = false;
                }
            });
        }
        let batch = list[index]["batchItems"].filter(function (item, key) {
            return item['selected'] == true;
        });
        let batch_Consumable_aray = [];
        for (var i = 0; i < batch.length; i++) {
            if (batch[i]['selected'] == true) {
                batch_Consumable_aray.push(batch[i].BatchNo)
            }
        }
        list[index]['batchNo'] = batch_Consumable_aray.toString();
        list[index]['batchTextConsumable'] = batch_Consumable_aray
        setInputFields(list);
    }


    const handleInput = async (e) => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;
        if (name === "itemName") setitemName(value);
        if (name === "store_type") setstore_type(value);
        if (name === "comments") setcomments(value);
        if (name === "quantity") setquantity(value);
        if (name === "radio") {
            setProcessType(e.currentTarget.id);
            setRadioValue(value)
            if (value == "NS") {
                let data = await API_Auth.getApproverNS();
                setDesignationData(data)
            }
            else if (value == "MS") {
                let data = await API_Auth.getApproverMS();
                setDesignationData(data)
            }
            else if (value == "Principal") {
                let data = await API_Auth.getApproverPrincipal();
                setDesignationData(data)
            }
            else {
                var filterData = allStoreAprrovers.filter(function (item, key) {
                    return item.type == 's';
                });
                setRadioId(filterData[0].id)
            }

        }
        if (name === "finalComments") setFinalComments(value);
        if (name === "approverName") {
            setRadioId(value);
        }
    };

    const showDrawyerConsumable = async (index, data) => {
        let result = await API_Auth.getConsumeBatch();
        setModalConsumableDataIndex(index);
        setModalShowConsumable(true);
        for (var i = 0; i < inputFields.length; i++) {
            if (i == index) {
                if (inputFields[i]['batchItems'].length == 0) {
                    inputFields[i]['batchItems'] = result;
                }
                setModalConsumableDataItems(inputFields[i]['batchItems']);
            }
        }
        setInputFields(inputFields)

    }
    const showDrawyer = async (index, data) => {
        setModalAssetDataIndex(index);
        setModalShow(true);

        let result = await API_Auth.getStorAsset(StorID, data.AssetID);
        let items = result.data.assets;
        // setAssetsData(items)

        setshownDrawerIndex(index);
        inputFields[index].show = true;
        for (var i = 0; i < inputFields.length; i++) {
            if (i == index) {
                if (inputFields[i]['assetsDataItems'].length == 0) {
                    inputFields[i]['assetsDataItems'] = items;
                }
                setModalAssetDataItems(inputFields[i]['assetsDataItems']);
            }
        }
        setInputFields(inputFields)
    }


    const handleIssue = async (type) => {
        let fromStorID = requestData.ToSubstoreID;
        let toStoreID = requestData.fromStoreID;
        let store_det = await API_Auth.getSubstoreById(StorID);




        //ToSubstoreID
        let collegeId = 11;
        var quantityarray = [];
        var inventoryarray = [];
        var assetArray = [];
        let batcharray = [];
        let itemarray = [];
        let batchConsumableArray = [];
        let ApprovedQuantityArror = [];
        // in quantity field - assetID-Quantity, inventory = assetID-toStoreID, dataNewRequest=assetID, bachno = assetID-batch
        //itemid_cid_tostoreid_assetid_collegeid_requestID
        for (var i = 0; i < inputFields.length; i++) {
            let var_issue_quantity = inputFields[i]["AssetID"] + "-" + inputFields[i]["IssueQuantity"];
            let var_approved_quantity = inputFields[i]["AssetID"] + "-" + inputFields[i]["ApprovedQuantity"];
            quantityarray.push(var_issue_quantity);
            ApprovedQuantityArror.push(var_approved_quantity);
            let var_inventory = inputFields[i]["AssetID"] + "-" + toStoreID + "-" + "'" + inputFields[i]["AssetID"] + "_" + toStoreID + "'";
            inventoryarray.push(var_inventory);
            let var_asset = inputFields[i]["AssetID"];
            let var_conumable_batch = inputFields[i]["AssetID"] + "-" + "'" + "abc" + "'"
            batchConsumableArray.push(var_conumable_batch)



            assetArray.push(var_asset);
            let var_batch = inputFields[i]["AssetID"] + "-" + "'" + "abc" + "'";
            batcharray.push(var_batch);
            /*    for (var j = 0; j < inputFields[i].assetsDataItems.length; j++) {
                   //let var_batch = inputFields[i]["AssetID"] + "-" + "'" + inputFields[i].assetsDataItems[j].batchNo + "'"
                   let var_batch = inputFields[i]["AssetID"] + "-" + "'" + "abc" + "'"
                 
                   batcharray.push(var_batch);
                   if (inputFields[i].assetsDataItems[j].selected == true) {
                       let item_array = inputFields[i].assetsDataItems[j].id + "_" + inputFields[i].assetsDataItems[j].cid + "_" + toStoreID + "_" + inputFields[i]["AssetID"] + "_" + collegeId + "_" + RquestID
                       itemarray.push(item_array)
                   }
   
               } */
        }
        var error = false;
        for (var j = 0; j < inputFields.length; j++) {
            let assetsData = inputFields[j]["assetsDataItems"].filter(function (item, key) {
                return item['selected'] == true;
            });
            if (assetsData.length == 0) {
                error = true;
            }
            else {
                error = false;
            }
        }
        let quantityarraytext = quantityarray.toString();
        let apprvedQtyyText = ApprovedQuantityArror.toString();
        let inventorytext = inventoryarray.toString()
        let assetText = assetArray.toString();
        let batchText = batcharray.toString();
        let batchConsumableText = batchConsumableArray.toString();
        let itemText = itemarray.toString();
        let Quantity = RequestType == "Approve" ? apprvedQtyyText : quantityarraytext;
        let requestId = RquestID;
        let status = type;
        let inventory = inventorytext;
        let dataNewRequest = assetText;
        let transectionHash = 1;
        let batchNo = assetType == "Consumable" ? batchConsumableText : batchText;
        let itemData = itemText;
        let apcomments = finalComments;
        if (RequestType == "Approve") {
            let error = false

            if (allAprrovers.length != 0) {
                var filterData = allAprrovers.filter(function (item, key) {
                    return item.name == radioValue;
                });
            }

            if (type == "send") {
                if (radioValue == '') {
                    toast.error("Please select an approver");
                    error = true;

                } else if (radioValue == "MS" || radioValue == "Principal") {
                    var finalStatus = radioValue == "MS" ? "assignedToMs" : "assignedToprincipal"

                } else if (radioValue == "NS") {
                    var finalStatus = "assignedToNs"

                }
            }

            if (type == "approved") {
                if (radioValue == '') {
                    toast.error("Please select an approver");
                    error = true;

                } else {
                    error = false;

                }
                var finalStatus = "approved"

            }
            if (type == "reject") {
                if (approverD == "2") {
                    // ‘rejectByApp’,‘rejectByNs’,‘rejectByMs’,‘rejectByPr’
                    // HOD-2,NS-3,MS-4,Principal -5
                    var finalStatus = "rejectByApp"
                } else if (approverD == "3") {
                    var finalStatus = "rejectByNs"

                }
                else if (approverD == "4") {
                    var finalStatus = "rejectByMs"

                }
                else if (approverD == "5") {
                    var finalStatus = "rejectByPr"

                }

                //HOD-2,NS-3,MS-4,Principal -5
                //‘rejectByApp’,‘rejectByNs’,‘rejectByMs’,‘rejectByPr’
                if (approverD == "2") {
                    var finalStatus = "rejectByApp"

                } else if (approverD == "3") {
                    var finalStatus = "rejectByNs"

                } else if (approverD == "4") {
                    var finalStatus = "rejectByMs"

                }
                else if (approverD == "5") {
                    var finalStatus = "rejectByPr"

                }

            }
            if (radioValue == "MS" || radioValue == "NS" || radioValue == "Principal") {
                if (radioid == "") {
                    setDesignationError("This field is required");
                    error = true
                } else {
                    error = false;
                }
            }
            //   let data;
            if (!error) {
                setDisableSubmit(true)

                setIsLoading(true);
                let data = await API_Auth.getRequestIssueByAssigned_Approved("UPDATEREQUESTBYAPPROVER", requestId, ApproverId, radioid, dataNewRequest, Quantity, apcomments, finalStatus);
                if (data[0].Flag == "Success") {
                    setIsLoading(false);
                    var approver_id = localStorage.getItem("ApproverD")
                    toast.success(finalStatus + " " + "added successfully");
                    setTimeout(() => {
                        //navigate("/fixed-request-approval/" + StorID + "/" + ApproverId + "/" + assetType)
                        navigate("/approval-management/" + StorID + "/" + approver_id)

                    }, 2000)
                } else {
                    setIsLoading(false);

                    setDisableSubmit(false)
                    toast.error('Something went wrong');
                }
            }
        }


        else {

            /*  if (assetType == "Fixed") {
                 console.log("ids", fromStorID, toStoreID);
                 if (issueqty) {
                     error = true;
                     toast.error('Something went wrong');
                 }
 
                 console.log("inputFields", inputFields);
 
                 if (error == false) {
                     setIsLoading(true);
 
                     let data = await API_Auth.getRequestIssueByFixed("INSERTTRANFORREQWITHREQID", fromStorID, toStoreID, Quantity, requestId, assetType, status, inventory, dataNewRequest,
                         transectionHash, batchNo, itemData, apcomments);
 
                     if (data.Flag == "Success") {
                         setIsLoading(false);
 
                         toast.success(type + "added successfully");
                         setTimeout(() => {
                             //navigate("/request-received/" + StorID + "/" + storeDet.SubStoreName + "/" + assetType + "/" + RequestType)
                             navigate("/received-request/" + StorID)
 
                         }, 2000)
                     } else {
                         toast.error('Something went wrong');
 
                     }
                 } else {
 
                     toast.success("Item not found in Asset");
                     setTimeout(() => {
                         navigate("/received-request/" + StorID)
 
                         // navigate("/request-received/" + StorID + "/" + storeDet.SubStoreName + "/" + assetType + "/" + RequestType)
 
                     }, 2000)
                 }
 
             }
             else {
             */
            let error = "a";
            var availableItems = [];
            var nonavailableItems = [];

            if (inputFields.length != 0) {

                for (var i = 0; i < inputFields.length; i++) {

                    if (inputFields[i].issueqtyerror) {
                        availableItems.push(inputFields[i].Asset);
                    } else {
                        nonavailableItems.push(inputFields[i].Asset);

                    }


                    if (inputFields[i].IssueQuantity === 0) {
                        //  setIssueqtyErr("Item not available");
                        inputFields[i]["issueqtyErr"] = "Item not available";
                        inputFields[i]["issuecolor"] = "r"


                        setIssueqty(true);
                        inputFields[i].issueqtyerror = issueqty;
                        error = "b";
                    } else {
                        error = "a";
                        // setIssueqtyErr("");
                        inputFields[i][""] = "Item not available"

                        setIssueqty(false);
                    }
                }
            }
            setInputFields(inputFields);
            setAvailableItems(availableItems);
            setNonAvailableItems(nonavailableItems);
            if (type == "issue") {
                if (nonavailableItems.length != 0 && availableItems.length != 0) {
                    error = 'b';
                    setAvailableStore(true)

                }

                if (nonavailableItems.length != 0 && availableItems.length == 0) {
                    error = 'b';
                    toast.error("Items are not available");

                }
            }
            else {
                error = 'a';

            }
            if (type == "reject") {
                if (error == "a") {
                    setDisableSubmit(true);

                    setIsLoading(true);
                    let data = await API_Auth.getRequestIssueByConsumption("INSERTTRANFORREQWITHREQID", fromStorID, toStoreID, Quantity,
                        requestId, assetType, status, inventory, dataNewRequest, transectionHash, batchNo, apcomments);
                    setItemErr('');
                    if (data[0].Flag == "Success") {
                        setIsLoading(false);
                        setAvailableStore(false)

                        toast.success(type + " " + "added successfully");
                        setTimeout(() => {
                            navigate("/received-request/" + StorID)

                            // navigate("/request-received/" + StorID + "/" + storeDet.SubStoreName + "/" + assetType + "/" + RequestType)
                        }, 2000)
                    } else {
                        setAvailableStore(false)
                        setIsLoading(false);
                        setDisableSubmit(false);

                        toast.error('Something went wrong');
                    }
                }

            } else {
                if (error == "a") {
                    if (availableItems.length != 0 && nonavailableItems.length == 0) {
                        setIsLoading(true);
                        setDisableSubmit(true);

                        let data = await API_Auth.getRequestIssueByConsumption("INSERTTRANFORREQWITHREQID", fromStorID, toStoreID, Quantity,
                            requestId, assetType, status, inventory, dataNewRequest, transectionHash, batchNo, apcomments);
                        setItemErr('');
                        if (data[0].Flag == "Success") {
                            setIsLoading(false);
                            setAvailableStore(false)

                            toast.success(type + " " + "added successfully");
                            setTimeout(() => {
                                navigate("/received-request/" + StorID)

                                // navigate("/request-received/" + StorID + "/" + storeDet.SubStoreName + "/" + assetType + "/" + RequestType)
                            }, 2000)
                        } else {
                            setAvailableStore(false)
                            setIsLoading(false);
                            setDisableSubmit(false);


                            toast.error('Something went wrong');
                        }
                    }
                }
            }
        }



        setDisbleIssueButton(false);
    }
    const handleAvailableStoreYes = async () => {
        let fromStorID = requestData.ToSubstoreID;
        let toStoreID = requestData.fromStoreID;
        var quantityarray = [];
        var inventoryarray = [];
        var assetArray = [];
        let batcharray = [];
        let itemarray = [];
        let batchConsumableArray = [];
        let ApprovedQuantityArror = [];

        let dataresult = inputFields.filter((item) => {
            return item.issueqtyerror == true
        })
        for (var i = 0; i < inputFields.length; i++) {
            let var_issue_quantity = inputFields[i]["AssetID"] + "-" + inputFields[i]["IssueQuantity"];
            let var_approved_quantity = inputFields[i]["AssetID"] + "-" + inputFields[i]["ApprovedQuantity"];
            quantityarray.push(var_issue_quantity);
            ApprovedQuantityArror.push(var_approved_quantity);
            let var_inventory = inputFields[i]["AssetID"] + "-" + toStoreID + "-" + "'" + inputFields[i]["AssetID"] + "_" + toStoreID + "'";
            inventoryarray.push(var_inventory);
            let var_asset = inputFields[i]["AssetID"];
            let var_conumable_batch = inputFields[i]["AssetID"] + "-" + "'" + "abc" + "'"
            batchConsumableArray.push(var_conumable_batch)

            let collegeId = 11;


            assetArray.push(var_asset);
            let var_batch = inputFields[i]["AssetID"] + "-" + "'" + "abc" + "'";
            batcharray.push(var_batch);
            /*     for (var j = 0; j < inputFields[i].assetsDataItems.length; j++) {
                   // let var_batch = inputFields[i]["AssetID"] + "-" + "'" + inputFields[i].assetsDataItems[j].batchNo + "'"
                   let var_batch = inputFields[i]["AssetID"] + "-" + "'" + "abc"+ "'"
                   
                    batcharray.push(var_batch);
                    if (inputFields[i].assetsDataItems[j].selected == true) {
                        let item_array = inputFields[i].assetsDataItems[j].id + "_" + inputFields[i].assetsDataItems[j].cid + "_" + toStoreID + "_" + inputFields[i]["AssetID"] + "_" + collegeId + "_" + RquestID
                        itemarray.push(item_array)
                    }
    
                } */
        }
        var error = false;
        for (var j = 0; j < inputFields.length; j++) {
            let assetsData = inputFields[j]["assetsDataItems"].filter(function (item, key) {
                return item['selected'] == true;
            });
            if (assetsData.length == 0) {
                error = true;
            }
            else {
                error = false;
            }
        }
        let quantityarraytext = quantityarray.toString();
        let apprvedQtyyText = ApprovedQuantityArror.toString();
        let inventorytext = inventoryarray.toString()
        let assetText = assetArray.toString();
        let batchText = batcharray.toString();
        let batchConsumableText = batchConsumableArray.toString();
        let itemText = itemarray.toString();
        let Quantity = RequestType == "Approve" ? apprvedQtyyText : quantityarraytext;
        let requestId = RquestID;
        let status = "issue";
        let inventory = inventorytext;
        let dataNewRequest = assetText;
        let transectionHash = 1;
        let batchNo = assetType == "Consumable" ? batchConsumableText : batchText;
        let itemData = itemText;
        let apcomments = finalComments;



        setDisableSubmit(true);

        let data = await API_Auth.getRequestIssueByConsumption("INSERTTRANFORREQWITHREQID", fromStorID, toStoreID, Quantity,
            requestId, assetType, status, inventory, dataNewRequest, transectionHash, batchNo, apcomments);
        if (data[0].Flag == "Success") {
            setIsLoading(false);

            toast.success("issue" + " " + "added successfully");
            setTimeout(() => {
                navigate("/received-request/" + StorID)

                // navigate("/request-received/" + StorID + "/" + storeDet.SubStoreName + "/" + assetType + "/" + RequestType)
            }, 2000)
        } else {
            setDisableSubmit(false);

            toast.error('Something went wrong');
        }

    }

    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            {isLoading && <LoadingSpinner />}

            <Row>
                <Col lg={4}>
                    <div style={{ flexDirection: 'row', display: 'flex' }}>
                        <p className="title"> From : </p>
                        <p className="subtitle1"> {storeDet.SubStoreName}</p>
                    </div>
                    <div style={{ flexDirection: 'row', display: 'flex', marginTop: -15 }}>
                        <p className="title"> Request No: </p>
                        <p className="subtitle1" style={{ color: "#4169E1" }}> REQ{requestData.RquestID}</p>
                    </div>
                    <div style={{ flexDirection: 'row', display: 'flex', marginTop: -15 }}>
                        <p className="title"> Status: </p>
                        <p className="subtitle1"> {requestData.Status}</p>
                    </div>

                </Col>
                <Col lg={4}></Col>
                <Col>

                    <div style={{ flexDirection: 'row', display: 'flex', }}>
                        <p className="title"> Requested By: </p>
                        <p className="subtitle1"> {requestData.CName}</p>
                    </div>

                </Col>
            </Row>
            <hr />

            <Container>
                <Row>
                    <Col lg={5}>
                        <div>
                            {RequestType == 'Received' && <label>To: {receivedName} </label>}
                            {RequestType != 'Received' && <label>To:  {requestData.ToSubstoreName} </label>}

                        </div>


                    </Col>
                </Row>
                <Table className="tablelist">
                    <thead className="tableHead">
                        <tr>
                            <th> Item Name</th>
                            <th>UOM</th>
                            <th>Requested Qty</th>
                            <th>Comments</th>
                            {RequestType == "Received" && <th>Available Quantity</th>}
                            <th>Approved Qty</th>


                            {approvalStatus == "issue" || (RequestType == "Received" || RequestType == "Request") ? (<th>Issued Quantity</th>) : (<></>)}
                            {/*                             {RequestType == "Received" && assetType == "Consumable" && <th>Batch No</th>}
 */}
                            {/*   {(RequestType == 'Received' && assetType == "Fixed") && <th>Action</th>}
 */}
                        </tr>
                    </thead>

                    {inputFields.length != 0 &&

                        <>

                            {
                                inputFields.map((data, index) => {
                                    const { AssetID, Quantity, show, v_comments, Asset, ApprovedQuantity, batchNo, issueqtyErr, issuecolor, AvailableQuantity, issueqtyerror, IssueQuantity, Comments, items, asset_name, asset_comments,
                                        assetsDataItems, UOM
                                    } = data;
                                    return (
                                        <>
                                            <tbody>
                                                <tr key={index}>
                                                    <td>
                                                        <div> <p style={{ marginTop: 18, marginLeft: 10 }}>{Asset}</p></div>
                                                    </td>
                                                    <td>
                                                        <div >
                                                            {/*   <input type="text"
                                                                name="UOM"
                                                                disabled='true'
                                                                value={UOM}
                                                                /> */}
                                                            <div> <p style={{ marginTop: 18, marginLeft: 10 }}>{UOM}</p></div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div >
                                                            {/*   <input type="text" min="0"
                                                                name="qunatity"
                                                                disabled='true'
                                                                value={Quantity}
                                                                /> */}
                                                            <p style={{ marginTop: 18, marginLeft: 10 }}>{Quantity}</p></div>


                                                    </td>
                                                    <td>
                                                        <div>
                                                            {/* <input type="text"
                                                                name="comments"
                                                                disabled='true'
                                                                value={v_comments != undefined ? v_comments : Comments}
                                                            /> */}
                                                            {v_comments != undefined && <p style={{ marginTop: 18, marginLeft: 10 }}>{v_comments}</p>

                                                            }
                                                            {v_comments == undefined && <p style={{ marginTop: 18, marginLeft: 10 }}>{Comments}</p>
                                                            }

                                                        </div>


                                                    </td>
                                                    {RequestType == "Received" && <td>
                                                        <div>
                                                            <input type="text" min="0"
                                                                name="AvailableQuantity"
                                                                disabled
                                                                value={AvailableQuantity}
                                                                onChange={(evnt) => handleChange(index, evnt)} readonly />
                                                        </div>
                                                    </td>
                                                    }
                                                    <td>
                                                        <div>
                                                            <input type="text" min="0"
                                                                name="ApprovedQuantity"
                                                                value={ApprovedQuantity}
                                                                disabled={RequestType == "Approve" && (requestData.Status == "open" || requestData.Status == "assignedToMs" || requestData.Status == "assignedToprincipal" || requestData.Status == "assignedToNs") ? false : true}
                                                                onChange={(evnt) => handleChange(index, evnt)} readonly />
                                                        </div>
                                                    </td>

                                                    {approvalStatus == "issue" || RequestType == "Received" || RequestType == "Request" ? (<td>
                                                        <div >
                                                            <input type="text" min="0"
                                                                name="IssueQuantity"

                                                                value={IssueQuantity}
                                                                onChange={(evnt) => handleChange(index, evnt)} />
                                                        </div>
                                                        {issueqtyerror == false && issuecolor == "r" && <p className="alert-message">{issueqtyErr}</p>}
                                                        {issueqtyerror == false && issuecolor == "y" && <p style={{
                                                            color: '#DF8D0F', textAlign: 'center', fontWeight: '700'
                                                        }}>{issueqtyErr}</p>}
                                                        {issueqtyerror == false && issuecolor == "b" && <p
                                                            style={{
                                                                color: 'blue', textAlign: 'center', fontWeight: '700'
                                                            }}>{issueqtyErr}</p>}

                                                    </td>
                                                    ) : (<></>)}

                                                    {/*   {RequestType == "Received" && assetType == "Consumable" && <td>
                                                        <div  className="inputdiv" >
                                                            <input type="text"
                                                                name="batchNo"
                                                                style={{border:"none"}}
                                                                value={batchNo}
                                                                disabled={RequestType == "Recieved" ? true : false}
                                                                onChange={(evnt) => handleChange(index, evnt)} />
                                                                  <OverlayTrigger overlay={
                                                                    <Tooltip id={`tooltip-top`}>Ex:1,2,3</Tooltip>}>
                                                                    <img src={blueplus}  style={{ width: 24, color: '#7483f0', height: 24, margin: 5, cursor: 'pointer' }} />
                                                                </OverlayTrigger>
                                                        </div>
                                                    </td>
                                                    } */}
                                                    {/*    {RequestType == "Received" && assetType == "Consumable" && <td>
                                                        <div className="rowdiv" style={{ marginTop: -1,width:180 }}>
                                                            <div className="input_div" >
                                                                <input type="type" style={{ margin: 0, background: 'transparent', border: "none" }}
                                                                    name="batchNo"
                                                                    value={batchNo}
                                                                    onChange={(evnt) => handleChange(index, evnt)}
                                                                />
                                                                <OverlayTrigger overlay={
                                                                    <Tooltip id={`tooltip-top`}>{batchNo}</Tooltip>}>

                                                                    <InfoIcon size={24} style={{ margin: 10, cursor: 'pointer' }} color="lightgray" />

                                                                </OverlayTrigger>
                                                            </div>

                                                        </div>
                                                    </td>
                                                    }


 */}
                                                    {/*  {(storeDet.SubStoreType == 'CENTRAL' && RequestType != 'Approve'  && assetType == 'Fixed') && <td onClick={() => showDrawyer(index, data)} >
                                                        <AiOutlinePlusSquare size="34" style={{ margin: 20, cursor: "pointer" }} color="gray" />
                                                    </td>}      */}
                                                    {/*    {(RequestType == 'Received' && assetType != 'Consumable') &&
                                                        <td onClick={() => showDrawyer(index, data)} >
                                                            <AiOutlinePlusSquare size="34" style={{ margin: 20, cursor: "pointer" }} color="gray" />
                                                        </td>} */}

                                                    {/*   {(RequestType == 'Received' && assetType == 'Consumable') &&
                                                        <td onClick={() => showDrawyerConsumable(index, data)} >
                                                            <AiOutlinePlusSquare size="34" style={{ marginTop: 15, cursor: "pointer" }} color="gray" />
                                                        </td>} */}
                                                </tr>
                                            </tbody>
                                            {itemErr != '' && <p className="alert-message">{itemErr}</p>}


                                        </>
                                    )
                                })
                            }
                        </>}

                </Table>

                <Modal show={availableStore}
                    aria-labelledby="contained-modal-title-vcenter"
                    dialogclassname="modal-content">
                    <Modal.Header onClick={() => handleAvailableStore()} closeButton>
                        <Modal.Title>Items Information</Modal.Title>
                    </Modal.Header>

                    <Modal.Body >
                        <>
                            <div>
                                <p style={{ fontWeight: 'bold', fontFamily: 'Roboto', fontSize: 18 }}>Available Items</p>
                                {availableItems.length != 0 && <>

                                    {availableItems.map((item, index) => {
                                        return (
                                            <div style={{ flexDirection: 'row', display: 'flex' }}>
                                                <p>{item}</p>
                                            </div>

                                        )
                                    })}
                                </>
                                }
                            </div>

                            <div>
                                <p style={{ fontWeight: 'bold', fontFamily: 'Roboto', fontSize: 18 }}>Non Available Items</p>
                                {nonAvailableItems.length != 0 && <>
                                    {nonAvailableItems.map((item, index) => {
                                        return (
                                            <div style={{ flexDirection: 'row', display: 'flex' }}>
                                                <p>{item},</p>
                                            </div>

                                        )
                                    })}
                                </>}
                            </div>
                            <div>
                                <p style={{ fontFamily: 'Roboto', fontWeight: 500 }}>
                                    Note: Only {availableItems.length} items are available. Would you like to issue available items ?</p>
                            </div>


                        </>
                    </Modal.Body>
                    <Modal.Footer className="modal-footer">
                        <div style={{ flexDirection: 'row', display: 'flex' }}>
                            <div className="row_div">
                                <div style={{
                                    background: "#6EDC8F",
                                    color: "black",
                                    border: "#6EDC8F",

                                    borderRadius: 6,
                                    cursor: "pointer"
                                }} onClick={() => handleAvailableStoreYes()}>
                                    <p style={{ marginLeft: 30, marginRight: 30, marginTop: 20, marginBottom: 20 }} >Yes</p></div>

                            </div>
                            <div className="row_div">
                                <div style={{
                                    background: "#6EDC8F",
                                    color: "black",
                                    border: "#6EDC8F",

                                    borderRadius: 6,
                                    cursor: "pointer",
                                    marginLeft: 30
                                }} onClick={() => handleAvailableStore()}>
                                    <p style={{ marginLeft: 30, marginRight: 30, marginTop: 20, marginBottom: 20 }}  >No</p></div>

                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>



                <Modal show={modalShowConsumable}
                    aria-labelledby="contained-modal-title-vcenter"
                    dialogclassname="modal-content">
                    <Modal.Header onClick={() => handlCloseModalConsume()} closeButton>
                        <Modal.Title>Items</Modal.Title>
                    </Modal.Header>

                    <Modal.Body >
                        <Row>
                            <Col className="head_action" style={{ textAlign: 'center' }}>Action</Col>

                            <Col className="head_action">Batch No</Col>
                        </Row>

                        {modalConsumabletDataItems.length != 0 && <>
                            {modalConsumabletDataItems.map((item, i) => {
                                return (
                                    <Row>

                                        <Col className="body_action">
                                            <input type='checkbox' style={{ margin: 'unset', height: '19px' }}
                                                checked={item.selected == true}
                                                onClick={(e) => handlConsume(item, i, e)}></input>
                                        </Col>

                                        <Col className="body_action">{item.BatchNo}</Col>
                                    </Row>
                                )
                            })}
                        </>}

                        {modalConsumabletDataItems.length == 0 && <>
                            <Row>
                                <Col>
                                    <p>No Data Found </p>
                                </Col>
                            </Row>
                        </>
                        }


                    </Modal.Body>
                    <Modal.Footer className="modal-footer">
                        <div className="row_div">
                            <button className="add_buttons" onClick={() => handlCloseModalConsume()}>
                                <p >Update Batch</p></button>
                        </div>
                    </Modal.Footer>
                </Modal>


                <Modal show={modalShow}
                    aria-labelledby="contained-modal-title-vcenter"
                    dialogclassname="modal-content">
                    <Modal.Header onClick={() => handlCloseModal()} closeButton>
                        <Modal.Title>Items</Modal.Title>
                    </Modal.Header>

                    <Modal.Body >
                        <Row>
                            <Col lg={2} className="head_action">Action</Col>
                            <Col lg={3} className="head_action">Item Id</Col>
                            <Col lg={3} className="head_action">Item Name</Col>
                            <Col lg={2} className="head_action">Batch No</Col>
                            <Col lg={2} className="head_action">Comments</Col>
                        </Row>

                        {modalAssetDataItems.length != 0 && <>
                            {modalAssetDataItems.map((item, i) => {
                                return (
                                    <Row>

                                        <Col lg={2} className="body_action">
                                            <input type='checkbox' style={{ margin: 'unset', height: '19px' }}
                                                checked={item.selected == true}
                                                onClick={(e) => handleme(item, i, e)}></input>
                                        </Col>
                                        <Col lg={3} className="body_action">{item.id}</Col>
                                        <Col lg={3} className="body_action">{item.itemName}</Col>
                                        <Col lg={2} className="body_action">{item.batchNo}</Col>
                                        <Col lg={2} className="body_action">{item.comments}</Col>
                                    </Row>
                                )
                            })}
                        </>}

                        {modalAssetDataItems.length == 0 && <>
                            <Row>
                                <Col>
                                    <p>No Data Found </p>
                                </Col>
                            </Row>
                        </>
                        }


                    </Modal.Body>
                    <Modal.Footer className="modal-footer">
                        <div className="row_div">
                            <button className="add_buttons" onClick={() => handlCloseModal()}>
                                <p >Update Items</p></button>
                        </div>
                    </Modal.Footer>
                </Modal>


                {approverData.length != 0 && RequestType != 'Approve' && <Row>

                    <table style={{ margin: 20, width: "90%" }}>
                        <thead>
                            <th style={{ border: "1px solid #dddddd" }}>Approver</th>
                            <th style={{ border: "1px solid #dddddd" }}>Comments</th>
                            <th style={{ border: "1px solid #dddddd" }}>Is Approved ?</th>
                            <th style={{ border: "1px solid #dddddd" }}>Signature</th>
                        </thead>
                        <tbody>

                            {approverData.map((item, index) => {
                                return (
                                    <tr>
                                        <td style={{ border: "1px solid #dddddd" }}>
                                            {item.AppName}({item.AppDName})
                                        </td>
                                        <td style={{ border: "1px solid #dddddd" }}>
                                            {item.Comments}
                                        </td>
                                        <td style={{ border: "1px solid #dddddd" }}>
                                            {item.isApproved}
                                        </td>
                                        <td style={{ border: "1px solid #dddddd" }}>
                                            {item.isApproved != "" && item.Sign != "" && <img src={base_url + "/images?filename=" + item.Sign} alt="sign"
                                                style={{ marginLeft: 40, marginTop: 10, width: 80, height: 80 }}
                                            />
                                            }
                                            {item.isApproved != "" && item.Sign == "" && <img src={signature} alt="sign"
                                                style={{ marginLeft: 40, marginTop: 10, width: 80, height: 80 }}
                                            />
                                            }
                                        </td>
                                    </tr>

                                )
                            })
                            }

                        </tbody>
                    </table>

                </Row>
                }
                {reqApproverData.length != 0 && RequestType == 'Approve' && <Row>

                    <table style={{ margin: 20, width: "90%" }}>
                        <thead className="tableHead">
                            <th style={{ border: "1px solid #dddddd" }}>Approver</th>
                            <th style={{ border: "1px solid #dddddd" }}>Comments</th>
                            <th style={{ border: "1px solid #dddddd" }}>Is Approved ?</th>
                            <th style={{ border: "1px solid #dddddd" }}>Signature</th>
                        </thead>
                        <tbody>

                            {reqApproverData.map((item, index) => {
                                return (
                                    <tr>
                                        <td style={{ border: "1px solid #dddddd" }}>
                                            {item.AppName}({item.AppDName})
                                        </td>
                                        <td style={{ border: "1px solid #dddddd" }}>
                                            {item.Comments}
                                        </td>
                                        <td style={{ border: "1px solid #dddddd" }}>
                                            {item.isApproved}
                                        </td>
                                        <td style={{ border: "1px solid #dddddd" }}>
                                            {item.isApproved != "" && item.Sign != "" && <img src={base_url + "/images?filename=" + item.Sign} alt="sign"
                                                style={{ marginLeft: 40, marginTop: 10, width: 80, height: 80 }}
                                            />
                                            }
                                            {item.isApproved != "" && item.Sign == "" && <img src={signature} alt="sign"
                                                style={{ marginLeft: 40, marginTop: 10, width: 80, height: 80 }}
                                            />
                                            }
                                        </td>
                                    </tr>
                                )
                            })
                            }

                        </tbody>
                    </table>
                </Row>
                }
                <Row>
                    <Col lg={4}>
                        <label style={{ marginLeft: 25 }}>Comments</label>
                        <br />
                        <textarea rows="5" cols="60"
                            placeholder="Type Someting"
                            value={finalComments}
                            name="finalComments"
                            onChange={handleInput}
                            style={{ margin: 20, height: 100 }}
                        ></textarea>
                    </Col>

                    <Col lg={3}></Col>
                    <Col lg={5}>


                        {RequestType != 'Approve' && <Row>
                            <Col>

                                {senderSignaturePicShow != '' &&
                                    <>
                                        <br></br>
                                        <img src={base_url + "/images?filename=" + senderSignaturePicShow} className="sigImg" alt="Sign" style={{ marginLeft: 70, width: 80, height: 80 }} />
                                    </>
                                }
                                <>

                                </>
                                {senderSignaturePicShow != "" && <label style={{ textAlign: 'center', marginTop: 10, marginLeft: 50 }}> Requestor Signature</label>

                                }</Col>
                            <Col>

                                {issueSignaturePicShow != '' &&
                                    <>
                                        <br></br>
                                        <img src={base_url + "/images?filename=" + issueSignaturePicShow} className="sigImg" style={{ marginLeft: 10, width: 80, height: 80 }} />
                                    </>
                                }

                                <br />
                                {issueSignaturePicShow != '' && <label style={{ textAlign: 'center', marginTop: 10, marginLeft: 10 }}> Issuer Signature</label>}
                            </Col>
                        </Row>
                        }

                        {RequestType == 'Approve' && requestData.Status != "open" && <Row>
                            <Col>

                                {senderSignaturePicShow != '' &&
                                    <>
                                        <br></br>
                                        <img src={"http://165.232.180.142:3011/ukh/images?filename=" + senderSignaturePicShow} className="sigImg" alt="Sign" style={{ marginLeft: 70, width: 80, height: 80 }} />
                                    </>
                                }
                                <>

                                </>
                                {senderSignaturePicShow != "" && <label style={{ textAlign: 'center', marginTop: 10, marginLeft: 50 }}> Requestor Signature</label>
                                }
                            </Col>
                            <Col>

                                {issueSignaturePicShow != '' &&
                                    <>
                                        <br></br>
                                        <img src={"http://165.232.180.142:3011/ukh/images?filename=" + issueSignaturePicShow} className="sigImg" style={{ marginLeft: 10, width: 80, height: 80 }} />
                                    </>
                                }

                                <br />
                                {issueSignaturePicShow != "" && <label style={{ textAlign: 'center', marginTop: 10, marginLeft: 10 }}> Issuer Signature</label>}
                            </Col>
                        </Row>
                        }

                    </Col>
                </Row>

                {
                    (RequestType == 'Approve' && requestData.Status == "assignedToprincipal" && approverD == "5") && <Row>
                        <Row>
                            <Col>
                                <label style={{ marginTop: 15 }}>Send To:</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={8} style={{ margin: 20 }}>

                                <input type="radio" id="store" name="radio" value={requestData.ToSubstoreName} style={{ width: 25, height: 15 }}
                                    onChange={handleInput}

                                />
                                <label for="store" className="radiolabel"> {requestData.ToSubstoreName}  </label>


                            </Col>
                        </Row>
                    </Row>

                }



                {
                    (RequestType == 'Approve' && requestData.Status == "assignedToprincipal" && approverD == "5") && <Row>
                        <Col lg={1}>  </Col>

                        {/*  {approvedValue == true && <Col lg={3} className="add_buttons" onClick={() => handleIssue("approved")}>

                            <p>Approve</p>
                        </Col>
                        } */}

                        {approvedValue == true && <Col lg={3} >
                            <button className="add_buttons" disabled={disableSubmit} onClick={() => handleIssue("approved")}

                                style={{ width: "80%" }}>
                                <p>Approve</p></button>
                        </Col>
                        }



                        {/*   <Col lg={3} className="add_buttons" onClick={() => handleIssue("reject")}>
                            <p>REJECT</p>
                        </Col> */}
                        <Col lg={3}>
                            <button className="add_buttons" disabled={disableSubmit} onClick={() => handleIssue("reject")}
                                style={{ width: "80%" }}>

                                <p >REJECT</p></button>
                        </Col>

                    </Row>
                }

                {/* {
                    (RequestType == 'Approve' && (requestData.Status == "assignedToMs" || requestData.Status == "assignedToNs") && (approverD == "4" || approverD == "3")) && <Row>
                        <Row>
                            <Col>
                                <label style={{ marginTop: 15 }}>Send To:</label>
                            </Col>
                        </Row>
                        <Col lg={8} style={{ margin: 20 }}>
                            <Row>

                                {allAprrovers.map((item, index) => {
                                    return (
                                        <>
                                            <Col lg={4}>
                                                <input type="radio" id={item.type} name="radio" value={item.name} style={{ width: 25, height: 15 }}
                                                    onChange={handleInput} checked={radioValue == item.name}

                                                />
                                                <label for={item.type} className="radiolabel"> {item.name}</label>
                                            </Col>
                                        </>
                                    )
                                })}

                            </Row>
                        </Col>
                        <Row>
                            {
                                (radioValue == "MS" || radioValue == "Principal" || radioValue == "NS") &&
                                <div>
                                    <label>Select {radioValue}</label>
                                    <select value={radioid} onChange={handleInput}
                                        name="approverName" className="input_div" style={{ width: "50%" }}>
                                        <option value="">Select {radioValue}</option>
                                        {designationData.length != 0 && <>
                                            {designationData.map((item, index) => {
                                                return (
                                                    <option value={item.UserID}>{item.Name}({item.ApproverDesignation})</option>
                                                )
                                            })}
                                        </>}

                                    </select>
                                    {designationErr != '' && <p className="alert-message">{designationErr}</p>}

                                </div>
                            }
                        </Row>
                    </Row>
                }

                {(RequestType == 'Approve') && (requestData.Status == "assignedToMs" || requestData.Status == "assignedToNs") && (approverD == "4" || approverD == "3") && <Row>
                    <Col lg={1}></Col>
                    {processType == "a" && approvedValue == true && <Col lg={3} className="add_buttons" onClick={() => handleIssue()}>
                        <p>SEND</p>
                    </Col>
                    }

                    {(processType == "s" && approvedValue == true) && <Col lg={3} className="add_buttons" onClick={() => handleIssue("approved")}
                    >
                        <p>Approve</p>
                    </Col>
                    }


                    <Col lg={3} className="add_buttons" onClick={() => handleIssue("reject")}>
                        <p>REJECT</p>
                    </Col>

                </Row>
                } */
                }
                {(RequestType == 'Approve' && (requestData.Status == "assignedToMs" || requestData.Status == "assignedToNs") && (approverD == "4" || approverD == "3")) && <Row>



                    <hr />

                    <Col lg={12} style={{ margin: 20, marginTop: 0 }}>
                        <Row>
                            <Col lg={6}>
                                <label style={{ marginTop: 15 }}>Send For Approval</label>

                                {allAprrovers.map((item, index) => {
                                    return (
                                        <>
                                            <Col style={{ marginTop: 12 }}>
                                                <input type="radio" id={item.type} name="radio" value={item.name} style={{ width: 25, height: 15 }}
                                                    onChange={handleInput} checked={radioValue == item.name}

                                                />
                                                <label for={item.type} className="radiolabel"> {item.name}</label>
                                            </Col>
                                        </>
                                    )
                                })}
                                {(radioValue == "MS" || radioValue == "Principal" || radioValue == "NS") && <div>
                                    <label>Select {radioValue} </label>
                                    <select value={radioid} onChange={handleInput}
                                        name="approverName" className="input_div" style={{ width: "50%" }}>
                                        <option value="">Select </option>
                                        {designationData.length != 0 && <>
                                            {designationData.map((item, index) => {
                                                return (
                                                    <option value={item.UserID}>{item.Name}({item.ApproverDesignation})</option>
                                                )
                                            })}
                                        </>}

                                    </select>
                                    {designationErr != '' && <p className="alert-message">{designationErr}</p>}

                                </div>
                                }
                                <Row>
                                    {/*  <Col lg={3} className="add_buttons" onClick={() => handleIssue("send")}>
                                        <p>SEND</p>
                                    </Col> */}

                                    <Col lg={3}>
                                        <button className="add_buttons" disabled={disableSubmit} onClick={() => handleIssue("send")}
                                                                                    style={{width:"80%"}}>
                                                                                    
                                            <p >SEND</p></button>
                                    </Col>

                                    {/*  <Col lg={3} className="add_buttons" onClick={() => handleIssue("reject")}>
                                        <p>REJECT</p>
                                    </Col> */}
                                    <Col lg={3}>
                                        <button className="add_buttons" disabled={disableSubmit} onClick={() => handleIssue("reject")}
                                    style={{width:"80%"}}>

                                        
                                            <p >REJECT</p></button>
                                    </Col>

                                </Row>
                            </Col>

                            <Col lg={6}>
                                <label style={{ marginTop: 15 }}>Approval directly</label>

                                {allStoreAprrovers.map((item, index) => {
                                    return (
                                        <>
                                            <Col style={{ marginTop: 12 }}>
                                                <input type="radio" id={item.type} name="radio" value={item.name} style={{ width: 25, height: 15 }}
                                                    onChange={handleInput} checked={radioValue == item.name} />
                                                <label for={item.type} className="radiolabel"> {item.name}</label>
                                            </Col>
                                        </>
                                    )
                                })}
                                <Row>
                                    {/*  <Col lg={3} className="add_buttons" onClick={() => handleIssue("approved")}>
                                        <p>Approve</p>
                                    </Col> */}
                                    <Col lg={3}>
                                        <button className="add_buttons" disabled={disableSubmit} onClick={() => handleIssue("approved")}
                                            style={{ width: "80%" }}>

                                            <p>Approve</p></button>
                                    </Col>

                                    <Col lg={3}>
                                        <button className="add_buttons" disabled={disableSubmit} onClick={() => handleIssue("reject")}
                                            style={{ width: "80%" }}>

                                            <p >REJECT</p></button>
                                    </Col>



                                    {/*  <Col lg={3} className="add_buttons" onClick={() => handleIssue("reject")}>
                                        <p>REJECT</p>
                                    </Col> */}
                                </Row>
                            </Col>
                        </Row>
                    </Col>

                </Row>
                }
                {RequestType == 'Approve' && (requestData.Status == "open") && <Row>

                    <hr />

                    <Col lg={12} style={{ margin: 20, marginTop: 0 }}>
                        <Row>
                            <Col lg={6}>
                                <label style={{ marginTop: 15 }}>Send For Approval</label>

                                {allAprrovers.map((item, index) => {
                                    return (
                                        <>
                                            <Col style={{ marginTop: 12 }}>
                                                <input type="radio" id={item.type} name="radio" value={item.name} style={{ width: 25, height: 15 }}
                                                    onChange={handleInput} checked={radioValue == item.name}

                                                />
                                                <label for={item.type} className="radiolabel"> {item.name}</label>
                                            </Col>
                                        </>
                                    )
                                })}
                                {(radioValue == "MS" || radioValue == "Principal" || radioValue == "NS") && <div>
                                    <label>Select {radioValue} </label>
                                    <select value={radioid} onChange={handleInput}
                                        name="approverName" className="input_div" style={{ width: "50%" }}>
                                        <option value="">Select </option>
                                        {designationData.length != 0 && <>
                                            {designationData.map((item, index) => {
                                                return (
                                                    <option value={item.UserID}>{item.Name}({item.ApproverDesignation})</option>
                                                )
                                            })}
                                        </>}

                                    </select>
                                    {designationErr != '' && <p className="alert-message">{designationErr}</p>}

                                </div>
                                }
                                <Row>
                                   {/*  <Col lg={3} className="add_buttons" onClick={() => handleIssue("send")}>
                                        <p>SEND</p>
                                    </Col> */}


                                    <Col lg={3}>
                                        <button className="add_buttons" disabled={disableSubmit} onClick={() => handleIssue("send")}
                                            style={{width:"80%"}}>
                                            <p >SEND</p></button>
                                    </Col>

                                   {/*  <Col lg={3} className="add_buttons" onClick={() => handleIssue("reject")}>
                                        <p>REJECT</p>
                                    </Col> */}

                                    <Col lg={3}>
                                        <button className="add_buttons" disabled={disableSubmit} onClick={() => handleIssue("reject")}
                                        style={{width:"80%"}}>
                                            <p >REJECT</p></button>
                                    </Col>
                                </Row>
                            </Col>

                            <Col lg={6}>
                                <label style={{ marginTop: 15 }}>Approval directly</label>

                                {allStoreAprrovers.map((item, index) => {
                                    return (
                                        <>
                                            <Col style={{ marginTop: 12 }}>
                                                <input type="radio" id={item.type} name="radio" value={item.name} style={{ width: 25, height: 15 }}
                                                    onChange={handleInput} checked={radioValue == item.name} />
                                                <label for={item.type} className="radiolabel"> {item.name}</label>
                                            </Col>
                                        </>
                                    )
                                })}
                                <Row>
                                    {/*  <Col lg={3} className="add_buttons" onClick={() => handleIssue("approved")}>
                                        <p>Approve</p>
                                    </Col> */}
                                    <Col lg={3}>
                                        <button className="add_buttons" disabled={disableSubmit} onClick={() => handleIssue("approved")}
                                            style={{ width: "80%" }}>

                                            <p >Approve</p></button>
                                    </Col>

                                    {/*  <Col lg={3} className="add_buttons" onClick={() => handleIssue("reject")}>
                                        <p>REJECT</p>
                                    </Col> */}

                                    <Col lg={3}>
                                        <button className="add_buttons" disabled={disableSubmit} onClick={() => handleIssue("reject")}
                                            style={{ width: "80%" }}>

                                            <p >REJECT</p></button>
                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    {/* <Row>
                        {
                            (radioValue == "MS" || radioValue == "Principal" || radioValue == "NS") &&
                            <div>
                                <label>Select {radioValue} </label>
                                <select value={radioid} onChange={handleInput}
                                    name="approverName" className="input_div" style={{ width: "50%" }}>
                                    <option value="">Select </option>
                                    {designationData.length != 0 && <>
                                        {designationData.map((item, index) => {
                                            return (
                                                <option value={item.UserID}>{item.Name}({item.ApproverDesignation})</option>
                                            )
                                        })}
                                    </>}

                                </select>
                                {designationErr != '' && <p className="alert-message">{designationErr}</p>}

                            </div>
                        }
                    </Row> */}

                </Row>

                }


                {/* {(RequestType == 'Approve') && (requestData.Status == "open") && <Row>
                    <Col lg={1}></Col>
                    {processType == "a" && approvedValue == true && <Col lg={3} className="add_buttons" onClick={() => handleIssue()}>
                        <p>SEND</p>
                    </Col>
                    }

                    {(processType == "s" && approvedValue == true) && <Col lg={3} className="add_buttons" onClick={() => handleIssue("approved")}
                    >
                        <p>Approve</p>
                    </Col>
                    }


                    <Col lg={3} className="add_buttons" onClick={() => handleIssue("reject")}>
                        <p>REJECT</p>
                    </Col>

                </Row>
                } */}

                {/*                 {( RequestType != "Approve" && storeDet.SubStoreType == 'CENTRAL' && requestData.Status == "open") && <Row>
 */}
                {(RequestType == "Received" && (requestData.Status == "open" || requestData.Status == "approved")) && <Row>
                    <Col lg={1}></Col>
                    {/*   <Col lg={3} className="add_buttons" onClick={() => handleSend()}>
                        <p>SEND</p>
                    </Col>
                     */}
                    {/*   <Col lg={3} className="add_buttons" disable={disbleIssueButton} onClick={() => handleIssue("issue")}>
                        <p>ISSUE</p>
                    </Col> */}

                    <Col lg={3}>
                        <button className="add_buttons" disabled={disableSubmit} onClick={() => handleIssue("issue")}
                                                            style={{width:"80%"}}>
                                                            
                            <p >SEND</p></button>
                    </Col>

                    {/*   <Col lg={3} className="add_buttons" disable={disbleIssueButton} onClick={() => handleIssue("reject")}>
                        <p>REJECT</p>
                    </Col> */}

                    <Col lg={3}>
                        <button className="add_buttons" disabled={disableSubmit} onClick={() => handleIssue("reject")}
                                                            style={{width:"80%"}}>

                            <p >REJECT</p></button>
                    </Col>

                </Row>
                }
            </Container>
            <ToastContainer />

        </Wrapper>
    )
}
export default FixedRequestView
