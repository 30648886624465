import { createGlobalStyle } from "styled-components";
import styled from "styled-components";

export const GlobalStyle = createGlobalStyle`
  :root {
    --maxWidth: 1280px;
    --white: #929cf1;
    --lightGrey: #3d4370;
    --medGrey: #2b2e4a;
    --darkGrey: #1a1a27;
    --darkwhite:#FFFFFF;
    --lightblue :#000044;
    --blue: #071F4A;
    --brColr:#DCDFE8;
    --black:#000000;
    --fontSuperBig: 2.5rem;
    --fontBig: 1.5rem;
    --fontMed: 1.2rem;
    --fontSmall: 1rem;
  }

  

  body {
    background:#f5f3f3;
    
  
    /* kavya changes */

    .back_button {
      border: 1px solid #192771;
      border-radius: 6px;
      opacity: 1;
      display:flex;
      flex-direction:row;
      justify-content:space-evenly;
      margin: 10px;
      padding: 10px 10px 0px;
      cursor:pointer;
    
      h6{
        color:#192771;
        font-size:14px;
        font-weight:600;
        font-family:'Roboto'
            }
    }
    
    .no_Data_table {
      text-align:center;
      font-family:Roboto;
      font-size:15px;
      font-weight:500;
      color:black;
    }
    .textareaclass{
      border: 2px solid #DFDFDF;
      padding-left:15px;
      margin-top:10px;
      border-radius: 6px;
      font-size: 16px;
      resize: none;
      :focus {
        outline: none !important;
        border-color:none;  
      }
      }
    .pagination {
      display: flex;
      float: right;
      margin: 24px 0px;
      list-style: none;
      outline: none;
        }
    .pagination > .active > a{
      background-color: #000044 ;
      border-color:#00001F24 ;
      color: #fff;
    }
    .pagination > li > a{
      border: 1px solid #00001F24 ;
      padding: 0px 6px;
      outline: none;
      margin: 0px 4px;
      border-radius: 3px;
      cursor: pointer;
    }
    .pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
      background-color: #000044 ;
      border-color: #00001F24;
      outline: none ;
      color: #ffff;

    }
    .pagination > li > a, .pagination > li > span{
      color: #2438a7;
      text-decoration:none;
    }
    .pagination_prev_btn{
      margin-bottom: 4px;
      width: 18px;
      height: 18px;
      margin-right: -8px;
    }
    .pagination_next_btn  {
    margin-bottom: 4px;
    width: 18px;
    height: 18px;
    /* margin: -4px; */
    margin-right: -4px;
    margin-left: -4px;
    }
    .pagination_row_div{
    display: flex;
    flex-direction: row;
    margin: 24px 0px;
    float: left;
    }
    .pagination_row_right_div{
      display: flex;
      flex-direction: row;
      margin: 0px 0px;
      float: right;
      }
    .pagination_input_div{
    color: var(--black);
    font-family: 'ARIAL';
    margin-left: 4px;
    background: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    }
    .pagination_select{
      border: 1px solid #707070;
      border-radius: 4px;
      opacity: 1;  
      margin: 15px;
    width: 100%;
    padding: 0px 10px;
    height: 28px;
    }
    .pagination_title{
      font-family: Roboto;
      font-size: 12px;
      margin: 0;
      align-self: center;
      letter-spacing: 0px;
      color: #A09E9E;
      opacity: 1;
      }
  .pagination_total{
    font-family: Roboto;
    font-size: 15px;
    margin: 0;
    margin-right:4;
    align-self: center;
    letter-spacing: 0px;
    color: #000;
    opacity: 1;
  }
  /*   .pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
      border-radius: unset
    } */
  
    input,textarea,select{
      height: 45px;
      border: 2px solid #DFDFDF;
      border-radius: 6px;
      margin: 15px ;
      padding: 10px; 
      width:100%;
      ::placeholder,
   ::-webkit-input-placeholder {
    color: #A09E9E;
    font: normal normal normal 14px/19px Roboto!important;
    letter-spacing: 0px;
    margin:15px;
  }
  :-ms-input-placeholder {
    color: #A09E9E;
    font: normal normal normal 14px/19px Roboto!important;
    letter-spacing: 0px;
     margin-left:15px;
  }
  ::-webkit-select-placeholder {
    color: #A09E9E;
    font: normal normal normal 14px/19px Roboto!important;
    letter-spacing: 0px;
    margin:15px;
  }
  :-ms-select-placeholder {
    color: #A09E9E;
    font: normal normal normal 14px/19px Roboto!important;
    letter-spacing: 0px;
     margin-left:15px;
  }
      :focus {
        outline: none !important;
        //border-color: 2px solid #6EDC8F;;;
        border-color: green!important;

        outline: 0;
        -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, 0.6);
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, 0.6);
      
      }
     }
    th {
      font-size:16px;
      height:50px;
      padding:15px;
      font-weight:500
    }
    .content-box{
        margin-left: 20%;
        margin-top: 6.6%;
        margin-right:1.84%;
        margin-bottom:1.5%;
      }
      .collapse-content-box{
        margin-left: 7%;
        margin-top: 5.8%;      
      }
      .table-head-content{
        max-width: 100%;
        background: #EBEBEE;
        padding: 13px;
        border-radius: 10px;
        border-radius: 6px 6px 0px 0px;
      }
      .table-content{
        padding: 20px;
      }
      .table>:not(:first-child) {
        border-top: unset;
      }
      .form-check-input:checked {
        background-color: #1E3D73;
        border-color: #1E3D73;
    }  
    
    .pcat_title{
      margin-left:10px;
      font-family:Roboto;
      font-weight:500;
      font-size:16px;
    }
    .add_title{
      color:black;
      font-weight:bold;
      font-size:20px;
      padding:10px 1px 0px 13px;
      font-family:Roboto
      
    }
    label {
      color:black;
      margin-left:15px;
      font-weight:bold;
      font-size:14px;
      font-family:Roboto
    }
  }
  /* edit button style by Prashanth */
  .row_table_edit {
    width: 80px;
    align-items: center;
    justify-content: center;
    padding: 4px 6px;
    background:#ECECEC;
    color:#000044;
    display:flex;
    flex-direction:row;
    border-radius:4px;
    cursor:pointer; 
    float:right;
   
    @media screen and (max-width: 1365px) {
      width:70%;
  
    }
    @media screen and (max-width: 1199px) {
      width: 75%;
  
    }
    @media screen and (max-width: 991px) {
      width: 85%;
  
    }
    @media screen and (max-width: 768px) {
      width: 90%;
    }
    img{
      width: 15px;
      height: 14px;
      margin: 0px 4px;
    }
    p{
      margin: 0px 4px;
      font-size: 13px;
      margin-top: 3px;
      font-weight: bold;      }
  }
  .table_head{
    background: white;
    box-shadow: 0px 3px 6px #00000029;
border-radius: 6px;
opacity: 1;
  }
  
  .row_table_div {
    background:#ECECEC;
    color:#000044;
    display:flex;
    flex-direction:row;
    border-radius:4px;
    cursor:pointer; 
    float:right;
   
    @media screen and (max-width: 1365px) {
      width:70%;
  
    }
    @media screen and (max-width: 1199px) {
      width: 75%;
  
    }
    @media screen and (max-width: 991px) {
      width: 85%;
  
    }
    @media screen and (max-width: 768px) {
      width: 90%;
    }
    img{
      margin:10px;
      width:15px;
      height:15px;
    }
    p{
      margin:10px 0px;
      font-size:12px;
      font-weight:bold;
  
    }
  }
  .rowdiv {
    margin:10px 0px;
  }
  .store_div{
   text-transform:capitalize;
   font-family:'Roboto';
   font-weight:600;
   margin-top:2px;
   color:#000044
  }
  .add_button {
    display:flex;
    flex-direction:row;
    background:#6EDC8F;
  
    color:black;
    border:#4EACFB;
    padding:15px;
    height:50px;
    margin: 10px 0px;
    width:100%;
    text-align:center;
    justify-content:center;
    align-item:center;
    box-shadow: 0px 2px 2px #00000030;
  border-radius: 6px;
  opacity: 1;
  }  
  

  .modal-content {
    width:160%;
    .tablelist {
      margin-top:15px;
      margin-right:5px;;
    }
     
    thead{
      color:black;
      background:#c0d3ef;
      border:1px solid #c0d3ef;
      border-radius:10px;
    }
    td{
      color:black;
      font-weight:400;
      font-size:14px;
      height:60px;
      padding:15px;
      font-family:Roboto;
      text-transform: capitalize;
    }
    th{
      font-size:16px;
      height:50px;
      padding:14px;
      font-weight:500;
      font-family:Roboto;
    
    }
    .table-head-content {
      padding: 13px 13px 0 13px;
      background: white;
      border-bottom: 1px solid #E2E2E2;   
    }  
    
    
  
    .head_action {
      font-size:16px;
      padding:15px;
      font-weight:700;
      font-family:Roboto;
    }
    .body_action {
      font-weight:500;
      font-family:Roboto;
      font-size:12px;
      margin:10px 0;

      
    }
input,select {
  padding-left: 10px;
  border: 2px solid #DFDFDF;
  width: 85%;
  color:var(--black);
  font-family: 'ARIAL';
  height:45px;
  border-radius:6px;
  margin:15px;
  ::placeholder,
 ::-webkit-input-placeholder {
  color: #9AA2AC;
  font-family:'Arial-Regular';
  margin:15px;
}
:-ms-input-placeholder {
   color: #9AA2AC;
   font-family:'Arial-Regular';
   margin-left:15px;
}
:focus {
    outline: none !important;
    border-color:none;  
  }
  }
.modal-footer{
   justify-content: flex-start; 
   border-top: none; 
}
.alert-message{
  color: red;
  margin-left: 15px;
}

}
  `

  export const Title = styled.p`
  color:black;
  font-weight:500;
  font-size:24px;
  margin:10px 0px;
  font-family:Roboto;
  padding:8px 0px;
`;




