import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Row, Container, Col, OverlayTrigger, Tooltip, Button } from "react-bootstrap"
import { Wrapper } from "./styles.js"
import API_Auth from "../../API_Auth";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Title } from "../../GlobalStyle.js"
import Table from "react-bootstrap/Table";
import moment from "moment"
import { IoIosArrowDown, IoIosArrowUp, IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const CentralConsumableAssetIssue = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {
        isExpanded,
    } = useContext(UserContext);
    const [asset_data, setasset_data] = useState(location.state.asset_data);
    const [StorID, setStoreID] = useState(location.state.StoreID);
    const [formTitle, setFormTitle] = useState('Issue Item');
    const [asset, setAsset] = useState(asset_data.AssetName);
    const [issue, setVendor] = useState('');
    const [qunatity, setQunaity] = useState(asset_data.Quantity);
    const [qunatity_issue, setQunaityIssue] = useState('');
    const [comments, setComments] = useState('')
    const [formButtonValue, setFormButtonValue] = useState('ISSUE');
    const [issErr, setIssueErr] = useState('')
    const [qunatityIssueErr, setQunatityIssudErr] = useState('')
    const [commentsErr, setCommentsErr] = useState('');
    const [batchNo, setBatchNo] = useState('');
    const [batchErr, setBatchErr] = useState('')
    const [subStores, setSubStores] = useState([]);
    const [viewAssetsData, setviewAssetsData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);

    const handleInput = (e) => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;
        if (name === "asset") setAsset(value);
        if (name === "issue") setVendor(value);
        if (name === "qunatity") setQunaity(value);
        if (name === "qunatity_issue") setQunaityIssue(value);
        if (name === "comments") setComments(value);
        if (name === "batchNo") setBatchNo(value);
    };

    const handleAddAsset = async () => {
        setDisableSubmit(true);
        let error = false;
        if (issue == '') {
            setIssueErr('This field is required');
            error = true;
            setDisableSubmit(false);
        }
        else {
            setIssueErr('');
        }
        if (qunatity_issue == '') {
            setDisableSubmit(false);
            setQunatityIssudErr('This field is required');
            error = true;
        }
        else {
            setQunatityIssudErr('');
        }
        if (batchNo == "") {
            setDisableSubmit(false);
            setBatchErr('This field is required');
            error = true;
        }
        else {
            setBatchErr('');
        }
        if (comments == '') {
            setDisableSubmit(false);
            setCommentsErr('This field is required');
            error = true;
        }
        else {
            setCommentsErr('');
        }
        if (!error) {

            let result = await API_Auth.addIssuetoSubstore(asset_data.AssetID, StorID, issue, qunatity_issue, batchNo, comments);
            let res = result[0];
            if (res.Flag == 'Success') {
                toast.success('Item issued');
                setTimeout(() => {
                 navigate("/assets-management/" + StorID);
                }, 2000);
            }
            else {
                setDisableSubmit(false);
                toast.error(asset_data.AssetName + " Quantity is insufficient");
            }
        }
    }
    useEffect(() => {
        const fetchSubStores = async () => {
            let result = await API_Auth.getSubstores();
            result.sort((a, b) => a.StoreName.localeCompare(b.StoreName))
            setSubStores(result);
            let resultdata = await API_Auth.getConsumeAssetView(StorID, asset_data.AssetID,"Consumable");
            resultdata.sort((a, b) => b.TDate.localeCompare(a.TDate))
            setviewAssetsData(resultdata);
            setIsLoading(false);
        };

        // call the function
        fetchSubStores()
            // make sure to catch any error
            .catch(console.error);
    }, []);
    const handleBackClick = () => {
        navigate(-1);
    }

    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            <Container>
                <Row className="row_title">
                    <Col lg={4}><p>{formTitle}</p></Col>
                    <Col lg={6}></Col>
                    <Col lg={2}>
                        <div className="back_button" onClick={() => handleBackClick()}>
                            <IoIosArrowBack size={18} />
                            <h6>Back</h6>
                        </div>
                    </Col>
                </Row>
            </Container>            <hr />
            <Container>
                <Row>
                    <Col lg={10}>
                        <Row>
                            <Col lg={6}>
                                <div className="inputdiv">
                                    <label>Item</label>
                                    <br />
                                    <input
                                        value={asset}
                                        name="asset"
                                        placeholder="Asset"
                                    //  onChange={handleInput}
                                    >
                                    </input>
                                </div></Col>
                            <Col lg={4}>
                                <div className="inputdiv">
                                    <label>Quantity</label>
                                    <br />
                                    <input type="text" min="0"
                                        name="qunatity"
                                        value={qunatity}
                                        placeholder="Quantity"
                                    // onChange={handleInput}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <Row>
                                    <Col>
                                        <div className="inputdiv">
                                            <label>Quantity Issue</label>
                                            <br />
                                            <input type="text" min="0"
                                                name="qunatity_issue"
                                                value={qunatity_issue}
                                                placeholder="Quantity Issue"
                                                onChange={handleInput} />
                                            {qunatityIssueErr != '' && <p className="alert-message">{qunatityIssueErr}</p>}

                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="inputdiv">
                                            <label>Issue to</label>
                                            <br />
                                            <select
                                                value={issue}
                                                name="issue"
                                                onChange={handleInput}>
                                                <option >Select</option>
                                                {subStores.map((item, index) => {
                                                    return (
                                                        <option value={item.StoreID}>{item.StoreName}</option>
                                                    )
                                                })}
                                            </select>
                                            {issErr != '' && <p className="alert-message">{issErr}</p>}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={4}>
                                <div className="inputdiv">
                                    <label>Batch No</label>
                                    <br />
                                    <input type="text"
                                        name="batchNo"
                                        value={batchNo}
                                        placeholder="Batch No"
                                        onChange={handleInput} />
                                    {batchErr != '' && <p className="alert-message">{batchErr}</p>}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <Row>

                                    <Col>
                                        <div className="inputdiv">
                                            <label>Comments</label>
                                            <br />
                                            {/*   <input type="text"
                                        name="comments"
                                        value={comments}
                                        placeholder="Type Something"
                                        cols="40"
                                        rows="5"
                                        style={{ width: "100%", height: 90 }}
                                        onChange={handleInput} /> */}

                                            <textarea rows="5" cols="60" name="comments" placeholder="Type Something" onChange={handleInput} style={{
                                                margin: 20, height: 100,
                                                border: "2px solid #DFDFDF;"
                                            }}
                                                value={comments} className="textareaclass" ></textarea>
                                            {commentsErr != '' && <p className="alert-message">{commentsErr}</p>}
                                        </div></Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={5}>
                        <div className="row_div">
                            <button className="add_button" disabled={disableSubmit}onClick={() => handleAddAsset()}>
                                <p className="pcat_title">{formButtonValue}</p></button>
                        </div>
                    </Col>
                </Row>

            </Container>
            <Container>
                <Row>
                    <Row className="row_title">
                        <h5>Transaction Log</h5>

                    </Row>
                    <Col lg={12} sm={12} md={12} className="table_head">
                        <Table>
                            <thead>
                                <th>From</th>
                                <th>To</th>
                                <th>Qty</th>
                                <th>Date</th>
                                <th>Request Id	</th>
                                <th>Bill No</th>
                                <th>Batch No</th>
                                <th>Vendor</th>
                                <th>MFD</th>
                                <th>Expiry</th>
                                <th>Price</th>
                                <th>Comments</th>
                            </thead>
                            {viewAssetsData.length != 0 && <>

                                <tbody>
                                    {viewAssetsData.map((item, index) => {
                                        return (
                                            <tr key={index} >
                                                <td>{item ?.FromStoreName || item ?.FromStoreID}</td>
                                                <td>{item.ToStoreName || item ?.ToStoreID}</td>
                                                <td>{item.Quantity}</td>
                                                <td>{item.TDate}</td>
                                                {item ?.requestID != '' && <td>REQ{item ?.requestID }</td>
                                                }
                                                {item ?.requestID == '' && <td>{item ?.requestID }</td>
                                                }                                               
                                                 <td>{item ?.billNo}</td>
                                                <td>{item.BatchNo}</td>
                                                <td>{item.VendorName}</td>
                                                {item.MFD != '' && <td>{moment(item.MFD).format("DD MMM YYYY  h:mm A")}</td>}
                                                {item.MFD == '' && <td>{moment(item.MFD).format("DD MMM YYYY  h:mm A")}</td>}

                                                {item.EXPD != '' && <td>{moment(item.EXPD).format("DD MMM YYYY  h:mm A")}</td>}
                                                {item.EXPD == '' && <td>{moment(item.EXPD).format("DD MMM YYYY  h:mm A")}</td>}

                                                <td>{item.Price}</td>
                                                {item.Tcpmment == '' &&
                                                    <td>{item.Tcpmment}</td>
                                                }
                                                {item.Tcpmment != '' &&
                                                    <OverlayTrigger
                                                        key={item.Tcpmment}
                                                        overlay={
                                                            <Tooltip id={`tooltip-top`}>
                                                                {item.Tcpmment}.
        </Tooltip>
                                                        }
                                                    >
                                                        <td className="table_text_overflow">{item.Tcpmment}</td>
                                                    </OverlayTrigger>
                                                }
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </>
                            }
                            {viewAssetsData.length == 0 &&
                                <tbody>
                                    <tr>
                                        <td colSpan="12" >
                                            <p style={{ textAlign: 'center', fontFamily: 'Robot', fontWeight: 'bold' }}>No Data Found </p>
                                        </td>
                                    </tr>
                                </tbody>
                            }
                        </Table>
                    </Col>
                </Row>
            </Container>


            <ToastContainer />

        </Wrapper>
    )
}
export default CentralConsumableAssetIssue