import styled from "styled-components";

export const Wrapper = styled.div`
.conotent_text{
  p{
    color:black;
    font-size:16px;
    font-family:Roboto;
    letter-spacing: 0.4px;
  }
}
.add_cat_button {
  background:#000044;
  color:white;
  border:#4EACFB;
  padding:10px;
  height:40px;
  width:85%;
  margin:100px 25px 0px;
  font-weight:bold;
  border-radius:6px;
}
`