

import styled from "styled-components";

export const Wrapper = styled.div`
background:white;
margin:20px;
border-radius:6px;
.row_title{
  margin:10px;
  padding:20px 10px 0px;
  p{
    color:black;
    font-weight:500;
    font-size:24px;
    margin:10px 0px;
    font-family:Roboto;
  }
}
.alert-message{
  color: red;
  margin-left: 15px;
}:focus {
      outline: none !important;
      border-color:none;  
    }
.input_show input{
  display: flex !important;
}
.multiSelectContainer {
  position: relative;
  text-align: left;
  width: 80%;
  margin: 13px 0px;
  padding: 0px 0px 1px 0px;
}
.multiselect-container{
  .searchWrapper{    
    border:unset;
    padding: 0px; 
    input{
      display: none;
      border: 2px solid #DFDFDF;
    },   
  }
  .multiselect-container{
    .searchWrapper{    
      border:unset;
      padding: 0px; 
       input{
        display: flex;
        border: 2px solid #DFDFDF;
      },   
    }
  .optionListContainer{
    width: 100%;
    left:10px;
    .optionContainer{
      max-height: 150px;
      overflow-y: auto;
    }
  }
}
.row_div {
    margin:10px;
  
  }
  .custom-file-upload {
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    width:120px;
    height:120px;
    border:2px solid  #6EDC8F;  
    background:#ACACAC;
    border-radius:5%;
    margin:15px;
}   
.sigImg{
  width: 28%;
    margin: 10px;
}
.custom-file-uploaded {
  text-align: center;
  background: transparent;
  img{
    width: 100px;
    height: 100px;
    border:2px solid  #6EDC8F;  
    border-radius: 5%;
  }
  a{
  cursor: pointer;
  }

}
.custom-file-signature{
    border:2px solid  #6EDC8F;  
    border-radius:6px;
    width:60%;
    height:60px;
    p{
        text-align:center;
        margin-top:20px;
        font-weight:400
    }
}
.custom_div {
    //margin-left:-50px;
    //margin-top:40px;
}
.edit_photo_div{
    color:white;
    padding:10px;
    p{
        text-align:center;
        font-weight:400;
        margin-top:20px;
    }
    img{
        margin-left:28px;
    }
  
}
  .add_button {
    display:flex;
    flex-direction:row;
    background:#6EDC8F;
    color:black;
    border:#4EACFB;  
    padding:15px;
    height:50px;
    margin:15px;
    width:60%;
    text-align:center;
    justify-content:center;
    align-item:center;
    box-shadow: 0px 2px 2px #00000030;
  border-radius: 6px;
  opacity: 1;
  
  
    
  }
.title{
    color:black;
  font-weight:500;
  font-size:24px;
  margin: 20px;
  padding: 20px 0px 0px 0px;
}
.inputdiv {
    margin:20px 0px;
}
label {
    color:black;
    font-size:14px;
    margin-left:15px;
    margin-top:10px;
    font-weight:700
}
.css-b62m3t-container{
  width:80%;
  margin-left:10px;
}  
   
`