import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context"; import { Container, Row, Col } from "react-bootstrap";
import { Wrapper } from "./AddAdminAssets.styles"
import API_Auth from "../../API_Auth";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { LoadingSpinner } from "../LoadingSpinner/index";
import { IoIosArrowBack } from "react-icons/io";

const AddAdminAsset = () => {
    let { assetID, from } = useParams();
    const navigate = useNavigate();
    const {
        isExpanded,
    } = useContext(UserContext);
    const [itemName, setItemName] = useState('');
    const [spacs, setSpacs] = useState('');
    const [UOM, setUOM] = useState('');
    const [notes, setNotes] = useState('');
    const [categoryName, setCategoryName] = useState('')
    const [categoryType, setCategoryType] = useState('')
    const [subcategoryName, setSubCategoryName] = useState('');

    const [formTitle, setFormTitle] = useState('Add Item');
    const [formButtonValue, setFormButtonValue] = useState('ADD ASSET');

    const [itemNameErr, setItemNameErr] = useState('');
    const [notesErr, setNotesErr] = useState('');
    const [categoryNameErr, setCategoryNameErr] = useState('')
    const [categoryTypeErr, setCategoryTypeErr] = useState('')
    const [subcategoryNameErr, setSubCategoryNameErr] = useState('')
    const [isLoading, setIsLoading] = useState(false);


    const [subCategories, setSubCategories] = useState([]);
    const [Uomlist, setUomlist] = useState([]);
    const [Categories, setCategories] = useState([]);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const handleInput = async (e) => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;
        if (name === "itemName") setItemName(value);
        if (name === "spacs") setSpacs(value);
        if (name === "UOM") setUOM(value);
        if (name === "notes") setNotes(value);
        if (name === "categoryName") {
            setCategoryName(value);
            setSubCategoryName('')
            let subcats = await API_Auth.getSubCategoriesByCatID(value);
            subcats.sort((a, b) => a.SubCategoryName.localeCompare(b.SubCategoryName))
            setSubCategories(subcats);
        }
        if (name === "categoryType") setCategoryType(value);
        if (name === "subcategoryName") setSubCategoryName(value);
    };
    const handleAddAsset = async () => {
        setDisableSubmit(true);
        let error = false;
        if (itemName == '') {
            setItemNameErr('This field is required');
            error = true;
        }
        else {
            setItemNameErr('');
        }
        /*  if (notes == '') {
             setNotesErr('This field is required');
             error = true;
         }
         else {
             setNotesErr('');
         } */
        if (categoryName == '') {
            setCategoryNameErr('This field is required');
            error = true;
        }
        else {
            setCategoryNameErr('');
        }
        if (categoryType == '') {
            setCategoryTypeErr('This field is required');
            error = true;
        } else {
            setCategoryTypeErr('');
        }
        if (subcategoryName == '') {
            setSubCategoryNameErr('This field is required');
            error = true;
        }
        else {
            setSubCategoryNameErr('');
        }
        if (!error) {
            setIsLoading(true);

            if (assetID == 'add') {
                let result = await API_Auth.addAsset(itemName, categoryType, categoryName, spacs, notes, subcategoryName, UOM);
                let res = result[0];
                if (res.Flag == 'Success') {
                    toast.success('Items added successfully');
                    setIsLoading(false);

                    setTimeout(() => {
                        if (from == 'admin') {
                            navigate("/admin-asset");
                        } else {
                            let from_data = from.split("_");
                            navigate("/fixed-asset-list/" + from_data[1]);
                        }
                    }, 2000);

                } else {
                    setIsLoading(false);

                    setDisableSubmit(false);
                    toast.error('Something went wrong please try again');
                }
            } else {

                let result = await API_Auth.updateAsset(itemName, categoryType, categoryName, spacs, notes, subcategoryName, assetID, UOM);
                let res = result[0];
                if (res.Flag == 'Success') {
                    setIsLoading(false);

                    toast.success('Items updated successfully');
                    setTimeout(() => {
                        if (from == 'admin') {
                            navigate("/admin-asset");
                        } else {
                            let from_data = from.split("_");
                            navigate("/fixed-asset-list/" + from_data[1]);
                        }
                    }, 2000);

                } else {
                    setIsLoading(false);

                    setDisableSubmit(false);
                    toast.error('Something went wrong please try again');
                }

            }

        } else {
            setDisableSubmit(false);
        }

    }

    useEffect(() => {
        const fetchAsset = async () => {

            if (assetID != 'add') {
                let res = await API_Auth.getAssetById(assetID);
                let asset_det = res[0];
                setItemName(asset_det.AssetName);
                setSpacs(asset_det.AssetSpacs);
                setCategoryName(asset_det.CategoryID);
                setSubCategoryName(asset_det.SubCategoryID);
                setNotes(asset_det.Notes);
                setCategoryType(asset_det.AssetType);
                setUOM(asset_det.UOM);
                setFormTitle('Edit Item');
                setFormButtonValue('UPDATE ASSET');
                let subcats = await API_Auth.getSubCategoriesByCatID(asset_det.CategoryID)
                subcats.sort((a, b) => a.SubCategoryName.localeCompare(b.SubCategoryName))
                setSubCategories(subcats);
            }
            let uom_list = await API_Auth.getUOM();
         //   uom_list.sort((a, b) => a.v_categoryName.localeCompare(b.v_categoryName))
            setUomlist(uom_list);
            let categories = await API_Auth.getCategories();
            categories.sort((a, b) => a.v_categoryName.localeCompare(b.v_categoryName))
            setCategories(categories);
        };
        // call the function
        fetchAsset()
            // make sure to catch any error
            .catch(console.error);
    }, []);
    const handleBackClick = () => {
        navigate("/admin-asset");
        navigate(0);

    }
    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            <Container>
                <Row className="row_title">
                    <Col lg={4}><p>{formTitle}</p></Col>
                    <Col lg={6}></Col>
                    <Col lg={2}>
                        <div className="back_button" onClick={() => handleBackClick()}>
                            <IoIosArrowBack size={18} />
                            <h6>Back</h6>
                        </div>
                    </Col>
                </Row>
            </Container>
            {isLoading && <LoadingSpinner />}

            <hr />
            <Container>
                <Row>
                    <Col lg={6} md={6} sm={6}>
                        <div className="inputdiv">
                            <label>Item Name</label>
                            <br />
                            <input type="text"
                                name="itemName"
                                value={itemName}
                                placeholder="Add Item Name"
                                onChange={handleInput}
                                style={{ width: "75%" }} />
                            {itemNameErr != '' && <p className="alert-message">{itemNameErr}</p>}
                        </div>
                    </Col>
                    <Col lg={6} md={6} sm={6}>
                        <div className="inputdiv">
                            <label>Asset Type</label>
                            <br />
                            <select style={{ width: "75%" }}
                                value={categoryType}
                                name="categoryType"
                                onChange={handleInput} >
                                <option value="">Select</option>
                                <option value="Consumable">Consumable</option>
                                <option value="Fixed"> Fixed</option>

                            </select>
                            {categoryTypeErr != '' && <p className="alert-message">{categoryTypeErr}</p>}
                        </div>
                    </Col>
                    <Col lg={6} md={6} sm={6}>

                        <div className="inputdiv">
                            <label>Category</label>
                            <br />
                            <select style={{ width: "75%" }}
                                value={categoryName}
                                name="categoryName"
                                onChange={handleInput}>
                                <option >Select</option>

                                {Categories.map((item, index) => {
                                    return (
                                        <option value={item.id}>{item.v_categoryName}</option>
                                    )
                                })}
                            </select>
                            {categoryNameErr != '' && <p className="alert-message">{categoryNameErr}</p>}
                        </div>
                    </Col>
                    <Col lg={6} sm={6} md={6}>
                        <div className="inputdiv">
                            <label>Sub Category</label>
                            <br />
                            <select style={{ width: "75%" }}
                                value={subcategoryName}
                                name="subcategoryName"
                                onChange={handleInput}>
                                <option >Select</option>

                                {subCategories.map((item, index) => {
                                    return (
                                        <option value={item.id}>{item.SubCategoryName}</option>
                                    )
                                })}
                            </select>
                            {subcategoryNameErr != '' && <p className="alert-message">{subcategoryNameErr}</p>}
                        </div>
                    </Col>
                    <Col lg={6} md={6} sm={6}>
                        <div className="inputdiv">
                            <label>Unit of measurement(UOM)</label>
                            <br />
                            <select style={{ width: "75%" }}
                                value={UOM}
                                name="UOM"
                                onChange={handleInput}>
                                <option >Select</option>

                                {Uomlist.map((item, index) => {
                                    return (
                                        <option value={item.UOM}>{item.UOM}</option>
                                    )
                                })}
                            </select>
                          {/*   <input type="text"
                                name="UOM"
                                value={UOM}
                                placeholder="Unit of measurement"
                                onChange={handleInput}
                                style={{ width: "75%" }} /> */}
                            {itemNameErr != '' && <p className="alert-message">{itemNameErr}</p>}
                        </div>
                    </Col>
{/*                     <Col lg={6} md={6} sm={6}>
                        <div className="inputdiv">
                            <label>Asset Specifications</label>
                            <br />
                            <textarea
                                name="spacs"
                                value={spacs}
                                rows={5}
                                style={{ width: 400, height: 100, }}
                                cols={5}
                                placeholder="Asset Specifications"
                                onChange={handleInput} />
                        </div>
                    </Col> */}
                    <Col lg={6} md={6} sm={6}>
                  

                  <div className="inputdiv">
                      <label>Notes</label>
                      <br />
                      <textarea
                          value={notes}
                          onChange={handleInput}
                          name="notes" placeholder="Type Something"
                          rows={5}
                          style={{ width: 400, height: 100, }}
                          cols={5}
                      />

                      {notesErr != '' && <p className="alert-message">{notesErr}</p>}
                  </div>

              </Col>
             
                </Row>
            </Container>

            <Row>

                <Col lg={3} style={{ marginLeft: 40, marginBottom: 20, marginTop: 20 }}>
                    <div className="row_div">
                        <button className="add_button" disabled={disableSubmit} onClick={() => handleAddAsset()}>
                            {/*                                 <img src={plus} style={{ margin: 5 }} /> */}
                            <p className="pcat_title">{formButtonValue}</p></button>
                    </div>
                </Col>
            </Row>
            <ToastContainer />
        </Wrapper>
    )
}
export default AddAdminAsset