import React, { Component } from 'react';
import { Outlet } from "react-router-dom";
import Sidebar from './Sidebar';
import Header from './Header';


function Layout({props}){
    return(
        <>
        <Header showImage={true}/>
         <Sidebar/>
         
 <Outlet/>
        </>
    )
}

export default Layout;