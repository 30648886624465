import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Wrapper } from "../AdminCategory/styles"
import { Row, Container, Col } from "react-bootstrap"
import Table from "react-bootstrap/Table";
import Edit from "../../images/edit.png";
import API_Auth from "../../API_Auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Title } from "../../GlobalStyle"
import { LoadingSpinner } from "../LoadingSpinner/index";
import { AiOutlineSearch } from "react-icons/ai";
import ReactPaginate from 'react-paginate';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Modal from 'react-bootstrap/Modal';

const AdminSubCategory = () => {
    const {
        isExpanded,
    } = useContext(UserContext);
    const [subCategories, setSubCategories] = useState([]);
    const [Categories, setCategories] = useState([]);

    const [addCat, setAddCat] = useState(false);
    const [subCategoryName, setSubCategoryName] = useState('');
    const [subCategoryNameErr, setSubCategoryNameErr] = useState('');

    const [subCatID, setSubCatID] = useState('');
    const [isLoading, setIsLoading] = useState(false);


    const [selectedCategoryName, setSelectedCategoryName] = useState('');
    const [selectedCategoryNameErr, setSelectedCategoryNameErr] = useState('');
    const [formTitle, setFormTitle] = useState('ADD A NEW SUB CATEGORY');
    const [formButtonValue, setFormButtonValue] = useState('ADD');
    const [searchData, setSearchData] = useState([]);
    const [offset, setOffSet] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [orgtableData, setOrgTableData] = useState([]);
    const [perPage, setPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [searchKey,setSearchKey]=useState('n');
     const[searchKeyTable,setSearchKeyTable]=useState('');


    const handleSearch = (e) => {
        let filterValue = e.target.value;
        setCurrentPage(0);     
        if (filterValue == '') {
            let offsetsearch=0;
            var slice = orgtableData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(orgtableData.length / perPage));
            setOrgTableData(orgtableData);
            setTableData(slice);
            setOffSet(offsetsearch);
            setSearchData(orgtableData);  
            setSearchKey('n');
            setCurrentPage(0);

        }
        else {
            let offsetsearch=0;
            const filteredData = orgtableData.filter(item => {
                return item ?.CategoryName ?.toLowerCase().includes(filterValue.toLowerCase()) ||
                    item ?.SubCategoryName ?.toLowerCase().includes(filterValue.toLowerCase())
                });
            const slice = filteredData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(filteredData.length / perPage));
            setSearchKey('y');
            setOffSet(offsetsearch);
            setSearchKeyTable(filteredData);
            setTableData(slice);
            setSearchData(filteredData);  

        }
    }
    const handleClose = () => {
        setAddCat(false)
    }

    useEffect(() => {
        setIsLoading(true);

        const fetchSubCategories = async () => {

            let subcats = await API_Auth.getSubCategories();
            setSubCategories(subcats);

            var slice = subcats.slice(offset, offset + perPage);
            setPageCount(Math.ceil(subcats.length / perPage));
            setOrgTableData(subcats);
            setTableData(slice);
            setSearchData(subcats);


            let categories = await API_Auth.getCategories();
           
           categories.sort((a, b) => a.v_categoryName.localeCompare(b.v_categoryName))
            setCategories(categories);
            setIsLoading(false);

            if (categories.length > 0) {
                setSelectedCategoryName(categories[0].id);
            }
        };
        // call the function
        fetchSubCategories()
            // make sure to catch any error
            .catch(console.error);
    }, []);
    const handlerowsperPage = (e) => {
        let showperpage = e.currentTarget.value
        setPerPage(parseInt(showperpage));
        setCurrentPage(0);
        if(searchKey=="y"){
            const slice = searchKeyTable.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(searchKeyTable.length / e.currentTarget.value))
            setTableData(slice);    
            setOffSet(0);
            setSearchData(searchKeyTable);
        }
        else{
        const slice = orgtableData.slice(0, 0 + e.currentTarget.value);
        setPageCount(Math.ceil(orgtableData.length / e.currentTarget.value))
        setTableData(slice);
        setOffSet(0);
        setSearchData(orgtableData);
        }
    }
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offsetdata = selectedPage * perPage;
        setCurrentPage(selectedPage);
        setOffSet(offsetdata);
        loadMoreData(offsetdata);
    }

    const loadMoreData = (offsetdata) => {
        const data = orgtableData;
        if(searchKey=="y"){
            const slice = searchKeyTable.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(searchKeyTable.length / perPage))
            setTableData(slice);
            setOffSet(offsetdata);
            setSearchData(searchKeyTable);
        }else{
        const slice = data.slice(offsetdata, offsetdata + perPage);
        setPageCount(Math.ceil(data.length / perPage))
        setTableData(slice);
        setOffSet(offsetdata);
        setSearchData(data);
        }

    }

    const handleCategory = () => {
        setAddCat(true)
        setSubCategoryName('')
    }
    const handleSelectCategory = (e) => {
        setSelectedCategoryName(e.currentTarget.value);
    }
    const handleEditCategory = (obj) => {
        setAddCat(true)
        setFormTitle('Edit Sub Category');
        setFormButtonValue('UPDATE');
        setSubCategoryName(obj.SubCategoryName);
        setSubCatID(obj.id);
        setSelectedCategoryName(obj.CategoryID);

    }

    const handleSubCategoryName = (e) => {
        setSubCategoryName(e.currentTarget.value)
    }
    const handleAddCategory = async () => {
        let error = false;

        if (selectedCategoryName == '') {
            setSelectedCategoryNameErr('This field is required.');
            error = true;
        }
        else{
            setSelectedCategoryNameErr('');
        }
        if (subCategoryName == '') {
            setSubCategoryNameErr('This field is required.');
            error = true;
        }
        else{
            setSubCategoryNameErr('') 
        }
        if (!error) {
            setIsLoading(true);

            if (formButtonValue == 'ADD') {
                let result = await API_Auth.addSubCategory(subCategoryName, selectedCategoryName);
                let res = result[0];
                if (res.Flag == 'Success') {
                    setIsLoading(false);

                    toast.success('Sub category added successfully.');
                    setAddCat(false);
                    setInterval(() => {
                        window.location.reload();
                    }, 2000);

                } else {
                    setIsLoading(false);

                    toast.error('Something went wrong please try again');
                }
            }

            if (formButtonValue == 'UPDATE') {
                let result = await API_Auth.updateSubCategory(subCategoryName, selectedCategoryName, subCatID);
                let res = result[0];
                if (res.Flag == 'Success') {
                    setIsLoading(false);

                    toast.success('Sub category updated successfully.');
                    setAddCat(false);
                    setInterval(() => {
                        window.location.reload();
                    }, 2000);

                } else {
                    setIsLoading(false);

                    toast.error('Something went wrong please try again');
                }
            }


        }



    }
    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            {isLoading && <LoadingSpinner />}


            <Container>
                <Row>
                    <Col lg={4} style={{paddingLeft:0}}>
                        <Title>Sub Category List</Title>
                    </Col>
                    <Col lg={4} style={{paddingRight:0}}>
                        <div className="row_div">
{/*                             <p className="title">Filter By:</p> */}
                            <div className="input_div" >
                                <input type="type" style={{ margin: 0, background: 'transparent' }}
                                    name="filterValue"
                                    //   value={filterValue}
                                    onChange={(e) => handleSearch(e)}
                                    placeholder="Search" />
                                <AiOutlineSearch size={24} style={{ margin: 10, cursor: 'pointer' }} color="lightgray" />
                            </div>

                        </div>
                    </Col>
                    <Col>
                    <button className="add_button" onClick={() => handleCategory()}>
                               <p className="pcat_title"> {formTitle}</p></button>
                    </Col>
                </Row>
                <Row>
                <Col lg={12} sm={6} md={6} className="table_head">
                        <Table className="tablelist">
                            <thead>
                                <tr>
                                    <th>Category Name</th>
                                    <th>Sub Category </th>
                                    <th style={{textAlign:'right'}}>Action</th>
                                </tr>
                            </thead>
                            {tableData.length == 0 && <tbody>
                                <tr >
                                    <td colSpan="12">
                                        <p className="no_Data_table">No Data Found</p>
                                    </td>
                                </tr>
                            </tbody>
                            }
                            {tableData.length != 0 && <tbody>
                                {tableData.map((obj, index) => {
                                    return (
                                        <>
                                            <tr key={index}>
                                                <td>{obj.CategoryName}</td>
                                                <td>{obj.SubCategoryName}</td>

                                                <td>
                                                    <div className="row_table_edit" onClick={() => handleEditCategory(obj)}>
                                                        <img src={Edit} />
                                                        <p>EDIT</p>
                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    );
                                })}
                            </tbody>
                            }
                        </Table>
                        {tableData.length != 0 &&
                        <div>
                        <div className="pagination_row_div">
                            <p className="pagination_title">Showing items per page:</p>
                            <div className="pagination_input_div" >
                                <select className="pagination_select"
                                 style={{ margin: 0, background: 'transparent' }}
                                    name="pagination_value"
                                    value={perPage}
                                    onChange={handlerowsperPage} >
                                    <option value={4}>4</option>
                                    <option value={8}>8</option>
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                </select>
                                </div>
                        </div>
                        <div className="pagination_row_right_div">
                        <p className="pagination_total">Showing {offset+1} to {searchData.length<offset+perPage&&
                        <span>{searchData.length}</span> }
                        {searchData.length>offset+perPage&&
                        <span>{offset+perPage }</span>} of {searchData.length} items</p>
                         <ReactPaginate
                            previousLabel={<ArrowBackIosIcon  className="pagination_prev_btn" color="#2438a7"/>}
                            nextLabel={<ArrowForwardIosIcon className="pagination_next_btn" color="#2438a7"/>}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"} 
                            forcePage={currentPage} />
                    </div>
                    </div>
                    }
                    </Col>
                    <Modal show={addCat} onHide={handleClose}
                    aria-labelledby="contained-modal-title-vcenter"
                    dialogclassname="modal-content">
                    <Modal.Header closeButton>
                        <Modal.Title>ADD SUB CATEGORY</Modal.Title>
                    </Modal.Header>

                    <Modal.Body >
                            <div>
                                <select value={selectedCategoryName} onChange={(e) => handleSelectCategory(e)} style={{ width: "85%" }}
                                >
                                    {Categories.map((item, index) => {
                                        return (
                                            <option value={item.id}>{item.v_categoryName}</option>
                                        )
                                    })}
                                </select>
                                {selectedCategoryNameErr != '' && <p className="alert-message">{selectedCategoryNameErr}</p>}
                            </div>
                            <div style={{marginTop:18}}>
                                <label >Sub Category Name</label>
                                <br />
                                <input placeholder='Enter Sub Category '
                                    value={subCategoryName}
                                    onChange={handleSubCategoryName}
                                    style={{ width: "85%" }}
                                />
                                {subCategoryNameErr != '' && <p className="alert-message">{subCategoryNameErr}</p>}
                            </div>
                            </Modal.Body>
                    <Modal.Footer className="modal-footer">
                        <div className="row_div">

                        <button className="add_button" onClick={() => handleAddCategory()} style={{
                                    padding: 12,
                                    height: 45,
                                    margin: 10 
                            }}>
                                <p className="pcat_title">{formButtonValue}</p>
                            </button>
                            </div>
                            </Modal.Footer>
                </Modal>
                </Row>

            </Container>
            <ToastContainer />
        </Wrapper>
    )
}
export default AdminSubCategory