import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Wrapper } from "./FixedAssetlist.styles"
import { Row, Container, Col } from "react-bootstrap"
import Table from "react-bootstrap/Table";
import Edit from "../../images/user-edit.png";
import plus from "../../images/white_plus.png"
import { useNavigate, useParams } from "react-router-dom";
import Delete from "../../images/delete.png"

import API_Auth from "../../API_Auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Title } from "../../GlobalStyle";
import { AiOutlinePlusSquare, AiOutlinePlus, AiOutlineSearch } from "react-icons/ai";
import { LoadingSpinner } from "../LoadingSpinner/index";
import ReactPaginate from 'react-paginate';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


const FixedAssetList = () => {
    let { StorID } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [searchData, setSearchData] = useState([]);



    const {
        isExpanded
    } = useContext(UserContext);
    const [assets, setAssets] = useState([]);
    const [storeDet, setStoreDet] = useState([]);
    const [shownDrawerIndex, setshownDrawerIndex] = useState(null)
    const navigate = useNavigate();
    const [offset, setOffSet] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [orgtableData, setOrgTableData] = useState([]);
    const [perPage, setPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [searchKey,setSearchKey]=useState('n');
    const[searchKeyTable,setSearchKeyTable]=useState('');


    const handleAddAsset = () => {
        let from = "store_" + StorID;
        navigate('/fixed-add-item/fixed-asset-list/' + StorID + '/add/add');

    }
    const showDrawyer = async (e, index, assetID) => {
        if (shownDrawerIndex == index) {
            let all_assets = assets;
            all_assets[index].items = [];
            setAssets(all_assets);
            setshownDrawerIndex(null);
        } else {
            e.preventDefault();
            let all_assets = assets;
            let result = await API_Auth.getStorAsset(StorID, assetID);
            let items = result.data.assets;
            all_assets[index].items = items;
            setAssets(all_assets);
            setshownDrawerIndex(index);

        }

    }
    const handleAddItem = (obj) => {
        navigate('/fixed-add-item/fixed-asset-list/' + StorID + "/" + obj.i_assetID + '/add');
    }
    const handleEditItem = (item) => {
        navigate('/fixed-add-item/fixed-asset-list/' + StorID + "/" + item.assetID + '/' + item.id);

    }
    const handleDeleteItem = async (item) => {
        let res = window.confirm('Are you sure to delete item!');
        if (res) {
            setIsLoading(true);
            let res = await API_Auth.deleteItemToAsset(item.assetID, StorID, item.id);
            if (res.Flag == 'Success') {

                toast.success('Item Deleted successfully');
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
                setIsLoading(false);

            } else {
                setIsLoading(false);
                if (res.Reason != undefined) {
                    toast.error(res.Reason);
                } else {
                    toast.error('Something went wrong please try again');
                }

            }
        }
    }
    useEffect(() => {
        setIsLoading(true);
        const fetchFiedAssets = async () => {
            let result = await API_Auth.getCentralStoreAssets(StorID);
            setAssets(result);
            var slice = result.slice(offset, offset + perPage);
            setPageCount(Math.ceil(result.length / perPage));
            setOrgTableData(result);
            setTableData(slice);
            setSearchData(result);

            let store_det = await API_Auth.getSubstoreById(StorID);
            setStoreDet(store_det.substore[0]);
            setIsLoading(false);
        };
        // call the function
        fetchFiedAssets()
            // make sure to catch any error
            .catch(console.error);
    }, [StorID]);
    const handlerowsperPage = (e) => {
        let showperpage = e.currentTarget.value
        setPerPage(parseInt(showperpage));
        setOffSet(0);
        setCurrentPage(0);
        if(searchKey=="y"){
            const slice = searchKeyTable.slice(0, 0 + e.currentTarget.value);
            
            setPageCount(Math.ceil(searchKeyTable.length / e.currentTarget.value))
            setTableData(slice);    
            setSearchData(searchKeyTable);
        }
        else{
        const slice = orgtableData.slice(0, 0 + e.currentTarget.value);
        
        setPageCount(Math.ceil(orgtableData.length / e.currentTarget.value))
        setTableData(slice);
        setSearchData(orgtableData);
        }
    }

    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offsetdata = selectedPage * perPage;
        setCurrentPage(selectedPage);
        setOffSet(offsetdata);
        loadMoreData(offsetdata);
    }

    const loadMoreData = (offsetdata) => {
        
        const data = orgtableData;
        setOffSet(offsetdata);
        
        if(searchKey=="y"){
            
            const slice = searchKeyTable.slice(offsetdata, offsetdata + perPage);
            
            setPageCount(Math.ceil(searchKeyTable.length / perPage))
            setTableData(slice);
            setSearchData(searchKeyTable);
        }else{
        const slice = data.slice(offsetdata, offsetdata + perPage);        
        setPageCount(Math.ceil(data.length / perPage))
        setTableData(slice);
        setSearchData(data);
        }

    }  
    const handleSearch = (e) => {
        let filterValue = e.target.value
        setCurrentPage(0);     
        if (filterValue == '') {
            let offsetsearch=0;
            var slice = orgtableData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(orgtableData.length / perPage));
            setOrgTableData(orgtableData);
            setTableData(slice);
            setSearchData(orgtableData);  
            setOffSet(offsetsearch);
            setSearchKey('n');
            setCurrentPage(0);
            }
        else {
            let value1 = filterValue.toLowerCase();
            let offsetsearch=0;
            const filteredData = orgtableData.filter(item => {
                return (item ?.AssetName ?.toLowerCase().includes(value1) ||
                 item ?.AssetType ?.toLowerCase().includes(value1) ||
                    item ?.CategoryName?.toLowerCase().includes(value1) || 
                    item ?.AssetSpacs.includes(value1))

            });
            const slice = filteredData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(filteredData.length / perPage))
            setSearchKey('y');
            
            setOffSet(offsetsearch);
            setSearchKeyTable(filteredData);
            setTableData(slice);
            setSearchData(filteredData);
        }
    }


    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            {isLoading && <LoadingSpinner />}
            <Container>
                <Row>
                    <Col lg={4}>
                        <Title> Fixed Asset List</Title>
                    </Col>
                    <Col lg={5}></Col>
                    <Col lg={3}>
                        {storeDet.SubStoreType == 'CENTRAL' && <div className="rowdiv">
                            <button className="add_button" onClick={() => handleAddAsset()}>
                                <img src={plus} style={{ margin: 5 }} />
                                <p className="pcat_title"> ADD ASSET</p></button>
                        </div>}
                    </Col>
                </Row>
            </Container>
            <Row>
                <Col lg={4}>
                    <div className="row_div">
{/*                         <p className="title">Filter By:</p>
 */}                        <div className="input_div" >
                            <input type="type" style={{ margin: 0, background: 'transparent' }}
                                name="filterValue"
                                //    value={filterValue}
                                onChange={(e) => handleSearch(e)}
                                placeholder="Search" />
                            <AiOutlineSearch size={24} style={{ margin: 10, cursor: 'pointer' }} color="lightgray" />
                        </div>

                    </div>
                </Col>
            </Row>
            <Container>
                <Row>
                    <Col lg={12} sm={12} md={12} className="table_head">
                        <Table className="tablelist">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th> Name</th>
                                    <th>Type</th>
                                    <th>Category</th>
                                    <th>Specs</th>
                                    {storeDet.SubStoreType == 'CENTRAL' && <th>Action</th>}
                                </tr>
                            </thead>
                            {tableData.length == 0 && <tbody>
                                <tr >
                                    <td colSpan="12">
                                        <p className="no_Data_table">No Data Found</p>
                                    </td>
                                </tr>
                            </tbody>
                            }
                            {tableData.map((obj, index) => {
                                return (
                                    <>
                                        <tbody>
                                            <tr key={index}>
                                                <td class="pointer" onClick={(e) => showDrawyer(e, index, obj.i_assetID)} >
                                                    <AiOutlinePlusSquare size="24" style={{ marginLeft: 10 }} />
                                                </td>
                                                <td>{obj.AssetName}  </td>
                                                <td>{obj.AssetType}</td>
                                                <td>{obj.CategoryName}</td>
                                                <td>{obj.AssetSpacs}</td>
                                                {storeDet.SubStoreType == 'CENTRAL' && <td>
                                                    <div className="row_table_div " onClick={() => handleAddItem(obj)} style={{ width: 115 }}>
                                                        <AiOutlinePlus style={{ margin: 10 }} />
                                                        <p>ADD ITEMS</p>
                                                    </div>
                                                </td>}
                                            </tr>
                                        </tbody>
                                        <tr>
                                            <td colspan="12" className="hiddenRow">

                                                {
                                                    (obj.items && obj.items.length == 0) && <>
                                                        <Table>
                                                            <tr>
                                                                <td colspan="12" className="hiddenRow">
                                                                <p style={{
                                                                    textAlign:"center",
                                                                    fontWeight:"bold",
                                                                    margin:10,
                                                                    color:'black'
                                                                }}>No Data Found</p>

                                                                </td>
                                                                </tr>
                                                        </Table>
                                                        </>
                                                                }
                                            {
                                                                    (obj.items && obj.items.length != 0) && <>

                                                                        <Table style={{ display: shownDrawerIndex == index ? '' : 'none' }}>
                                                                            <thead>
                                                                                <th>Item Id</th>
                                                                                <th>Voucher No</th>
                                                                                <th>MFD</th>
                                                                                <th>Batch No</th>
                                                                                <th>Expiry</th>
                                                                                <th>Supplier</th>
                                                                                <th>Price</th>
                                                                                <th>Other Notes</th>
                                                                                {storeDet.SubStoreType == 'CENTRAL' && <th>Action</th>}
                                                                            </thead>
                                                                            <tbody>
                                                                                {obj.items.map((item, index) => {
                                                                                    return (
                                                                                        <tr key={index} >
                                                                                            <td>{item.id}</td>
                                                                                            <td>{item.voucher_no}</td>
                                                                                            {item.mfDate !='' && <td>{item.mfDate}</td>}
                                                                                            {item.mfDate =='' && <td></td>}

                                                                                            <td>{item.batchNo}</td>
                                                                                            {item.expDate !='' &&  <td>{item.expDate}</td>}
                                                                                            {item.expDate =='' &&  <td></td>}

                                                                                         { item.supplier !=undefined && <td style={{ textAlign: 'center' }}>{item.supplier}</td>}
                                                                                         { item.supplier ==undefined && <td style={{ textAlign: 'center' }}></td>}

                                                                                            <td>Rs {item.price}</td>
                                                                                            <td>{item.description}</td>
                                                                                            {storeDet.SubStoreType == 'CENTRAL' && <td>
                                                                                                <div  >
                                                                                                    <img src={Edit} onClick={() => handleEditItem(item)} style={{ width: 20, height: 20, margin: 5, cursor: 'pointer' }} />
                                                                                                    <img src={Delete} onClick={() => handleDeleteItem(item)} class="pointer" style={{ width: 20, height: 20, margin: 5, cursor: 'pointer' }} />
                                                                                                </div>
                                                                                            </td>}

                                                                                        </tr>
                                                                                    )
                                                                                })}

                                                                            </tbody>

                                                                        </Table>
                                                                    </>
                                                                }

                                            </td>

                                        </tr>


                                                    </>
                                );
                                            })}
                
                        </Table>
                        <div>
                        <div className="pagination_row_div">
                            <p className="pagination_title">Showing items per page:</p>
                            <div className="pagination_input_div" >
                                <select className="pagination_select"
                                 style={{ margin: 0, background: 'transparent' }}
                                    name="pagination_value"
                                    value={perPage}
                                    onChange={handlerowsperPage} >
                                    <option value={4}>4</option>
                                    <option value={8}>8</option>
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                </select>
                                </div>
                        </div>
                        <div className="pagination_row_right_div">
                        <p className="pagination_total">Showing {offset+1} to {searchData.length<offset+perPage&&
                        <span>{searchData.length}</span> }
                        {searchData.length>offset+perPage&&
                        <span>{offset+perPage }</span>} of {searchData.length} items</p>
                         <ReactPaginate
                            previousLabel={<ArrowBackIosIcon  className="pagination_prev_btn" color="#2438a7"/>}
                            nextLabel={<ArrowForwardIosIcon className="pagination_next_btn" color="#2438a7"/>}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"} 
                            forcePage={currentPage} />
                    </div>
                    </div>                    
                    </Col>
                                    </Row>
            </Container>
            <ToastContainer />
        </Wrapper>

                        )
                    }
export default FixedAssetList