import styled from "styled-components";

export const Wrapper = styled.div`
background:white;
margin:20px;
border-radius:6px;
.row_title{
  margin:10px;
  padding:20px 10px 0px;
  p{
    color:black;
    font-weight:500;
    font-size:24px;
    margin:10px 0px;
    font-family:Roboto;
  }
}
.row_table_divs {

  background:#6EDC8F;
  color:black;
  border:#6EDC8F;
  display:flex;
  flex-direction:row;
  border-radius:4px;
  cursor:pointer; 
  float:right;
  p{
    margin:10px 0px;
    font-size:12px;
    font-weight:bold;

  }
}

.table_head {
  background:white;
  margin-top:15px;
  padding-left: 22px;
  padding-right: 22px;

}
.tablelist {
  margin-top:15px;
  margin-right:5px;;
}
 
thead{
  color:black;
  background:#c0d3ef;
  border:1px solid #c0d3ef;;
  border-radius:10px;
}
td{
  color:black;
  font-weight:400;
  font-size:15px;
  height:60px;
  padding:10px;
  font-family:Roboto;
  text-transform: capitalize;
}
th{
  font-size:16px;
  height:50px;
  padding:14px;
  font-weight:500;
  font-family:Roboto;

}
.table-head-content {
  padding: 13px 13px 0 13px;
  background: white;
  border-bottom: 1px solid #E2E2E2;   
}  
.table_text_overflow {
display: inline-block;
width: 150px;
white-space: nowrap;
overflow: hidden !important;
text-overflow: ellipsis;
}
.adddiv {
  background:white;
  height:95%;
  width:80%;
  margin-top:15px;
  margin-left:20px;
  border-radius:5px;
  
  input,select{
     width:85%;
    height: 40px;
    border: 1px solid #00001F24;
    border-radius: 6px;
    margin: 15px ;
    padding: 10px;  
    :focus {
      outline: none !important;
      border-color:none;  
    }
   }
  
}
.rowdiv {
  margin:5px;

}
.add_button {
  display:flex;
  flex-direction:row;
  background: #6EDC8F;
  color:black;
  border:#4EACFB;
  padding:15px;
  height:50px;
  margin:15px;
  width:85%;
  text-align:center;
  justify-content:center;
  align-item:center;
  box-shadow: 0px 2px 2px #00000030;
border-radius: 6px;
opacity: 1; 
}
.add_cat_button {
  background:#000044;
  color:white;
  border:#4EACFB;
  padding:10px;
  height:40px;
  width:85%;
  margin:100px 25px 0px;
  font-weight:bold;
  border-radius:6px;
}

.rowtablediv {
    background:#ECECEC;
    color:#000044;
    display:flex;
    flex-direction:row;
    border-radius:4px;
    cursor:pointer; 
    margin:10px;
    
    p{
    margin:8px;
    font-family:Roboto;
    font-weight:bold;
    }

}
.status_div {
    background:#FD7E1433;
    text-align:center;
    border-radius:4px;
    font-family:Roboto;
    height:25px;
    padding:2px;
    font-size:13px;
  }
  
  .status_div1 {
    background:#19875433;
    text-align:center;
    border-radius:4px;
    font-family:Roboto;
    height:25px;
    padding:2px;
    font-size:13px;
  
  }
  .status_div2 {
    background:#F1154133;
    text-align:center;
    border-radius:4px;
    font-family:Roboto;
    height:25px;
    font-size:13px;
  
    padding:2px;
  }
  
.title{
    font-family:Roboto;
    font-size:16px;
    font-weight:600;
    margin-left:10px;
    margin-bottom:0 !important;
    margin-top:15px;

}
.subtitle{
    font-family:Roboto;
    font-size:24px;
    font-weight:600;
    margin-left:10px;
    margin-top:0 !important
}

.rowdiv {
    display:flex;
    flex-direction:row;
}
.one-edge-shadow {
            box-shadow: 0 4px 0px 0px black;
}
.input_div {
    border: 2px solid #DFDFDF;
    width: 70%;
    color:var(--black);
    font-family: 'ARIAL';
    height:45px;
    border-radius:6px;
    margin:5px;
    background:white;
    display:flex;
    flex-direction:row;
    justify-content:space-between
}
.input{
    border:none;
    margin-top:5px;
    margin-left:8px;
  }
  :focus {
      outline: none !important;
      border-color:none;  
    }
   
}
}
.title{
    font-family:Roboto;
    font-size:16px;
    font-weight:600;
    margin-left:10px;
    margin-bottom:0 !important;
    margin-top:15px;

}
.span_sec{
    color: black;
    font-family:'Arial-Regular';
    font-weight:bold;
    text-align:center;
    width:50%
}
.submit_butn{
align-self: center;
}
.rowdiv {
    display:flex;
    flex-direction:row;
}

.title{
    font-family:Roboto;
    font-size:16px;
    font-weight:600;
    margin-left:10px;
    margin-bottom:0 !important;
    margin-top:15px;

}
.alert-message{
    color: red;
    margin-left: 15px;
  }
.table_col_btn{
    color: rgb(36, 56, 167);
    font-weight: bold;
    cursor: pointer;
 }
.inputdiv {
    margin:20px 0px;
input,select {
    padding-left: 10px;
    border: 2px solid #DFDFDF;
    width: 100%;
    color:var(--black);
    font-family: 'ARIAL';
    height:45px;
    border-radius:6px;
    margin:15px;
  }
  :focus {
      outline: none !important;
      border-color:none;  
 }   }

`