import styled from "styled-components";

export const Wrapper = styled.div`
.add_button {
    display:flex;
    flex-direction:row;
    background:#000044;
    color:white;
    border:#4EACFB;
    padding:15px;
    height:50px;
    margin:15px;
    width:85%;
    text-align:center;
    justify-content:center;
    align-item:center;
    box-shadow: 0px 2px 2px #00000030;
    border-radius: 6px;
    opacity: 1;
  }
  .row_div {
    display:flex;
    flex-direction:row;
    padding: 8px;
  }
  .input_div {
    border: 1px solid #00001F24;
    width: 70%;
    color:var(--black);
    font-family: 'ARIAL';
    height:45px;
    border-radius:6px;
    margin:5px;
    background:white;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    input,select{
       border:none;
       :focus {
         outline: none !important;
         border-color:none;  
       }
      }
     
  }
  .title{
    font-family:Roboto;
    font-size:16px;
    font-weight:600;
    margin-left:20px;
    margin-bottom:0 !important;
    margin-top:15px;
  
  }
  
  .rowdiv {
    margin:10px;
  
  }
  .table_head {
    background:white;
    margin-top:15px;
  
  }
  .tablelist {
    margin-top:15px;
    margin-right:5px;;
  }
   
  thead{
    color:black;
    background:#EBEBEE;
    border:1px solid #EBEBEE;
    border-radius:10px;
  }
  td{
    color:black;
    font-weight:400;
    font-size:14px;
    padding:15px;
    font-family:Roboto;
    text-transform: capitalize;
  }
  th{
    font-size:16px;
    height:50px;
    padding:14px;
    font-weight:500;
    font-family:Roboto;
  
  }
  .table-head-content {
    padding: 13px 13px 0 13px;
    background: white;
    border-bottom: 1px solid #E2E2E2;   
  }  
  .hiddenRow {
    padding: 0 !important;
}
.pointer {
  cursor:pointer;
}
  
`