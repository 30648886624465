

import styled from "styled-components";

export const Wrapper = styled.div`
background:white;
margin:20px;
border-radius:6px;

.row_title{
    margin:10px;
    padding:20px 10px 0px;
    p{
      color:black;
      font-weight:500;
      font-size:24px;
      margin:10px 0px;
      font-family:Roboto;
    }
}
.row_div {
    margin:10px;
  
  }
  .alert-message{
    color: red;
    margin-left: 15px;
  }
  .custom-file-upload {
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    width:120px;
    height:120px;
    background:#ACACAC;
    border-radius:50%;
    margin:15px;
}   
.custom-file-signature{
    border:1px solid  #00000029;
    border-radius:6px;
    width:60%;
    height:60px;
    p{
        text-align:center;
        margin-top:20px;
        font-weight:400
    }
}
.custom_div {
    margin-left:-50px;
    margin-top:40px;
}
.edit_photo_div{
    color:white;
    padding:10px;
    p{
        text-align:center;
        font-weight:400;
        margin-top:20px;
    }
    img{
        margin-left:28px;
    }
  
}
  .add_button {
    display:flex;
    flex-direction:row;
    background:#6EDC8F;
    color:black;
    border:#4EACFB;  
    padding:15px;
    height:50px;
    margin:15px;
    width:60%;
    text-align:center;
    justify-content:center;
    align-item:center;
    box-shadow: 0px 2px 2px #00000030;
  border-radius: 6px;
  opacity: 1;
  
  
    
  }
.title{
    color:black;
  font-weight:500;
  font-size:24px;
  margin: 20px;
  padding: 20px 0px 0px 0px;
}
.inputdiv {
    margin:20px 0px;
}

 
   
`