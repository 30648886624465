import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Wrapper } from "./SubStore.styles"
import { Row, Container, Col } from "react-bootstrap"
import Table from "react-bootstrap/Table";
import SubstoreData from "../json/Substore.json"
import Edit from "../../images/edit.png";
import plus from "../../images/white_plus.png"
import { useNavigate, Link } from "react-router-dom";
import {Title} from "../../GlobalStyle"

import API_Auth from "../../API_Auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {LoadingSpinner} from "../LoadingSpinner/index";
import { AiOutlineSearch } from "react-icons/ai";
import ReactPaginate from 'react-paginate';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const SubStore = () => {
    const {
        isExpanded,
    } = useContext(UserContext);
    const [subStores, setSubStores] = useState([]);
        const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const [searchData,setSearchData] = useState([]);
    const [offset, setOffSet] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [orgtableData, setOrgTableData] = useState([]);
    const [perPage, setPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [searchKey,setSearchKey]=useState('n');
    const[searchKeyTable,setSearchKeyTable]=useState('');

    const handleSearch=(e)=>{
        let filterValue=e.target.value;
        setCurrentPage(0);     
       if (filterValue == '') {
        let offsetsearch=0;
        
        var slice = orgtableData.slice(offsetsearch, offsetsearch + perPage);
        setPageCount(Math.ceil(orgtableData.length / perPage));
        setOrgTableData(orgtableData);
        setTableData(slice);
        setOffSet(offsetsearch)
        setSearchData(orgtableData);  
        setSearchKey('n');  
        setCurrentPage(0);     
    }
       else {
        let offsetsearch=0;
           const filteredData = subStores.filter(item => {
                return (item?.StoreName?.toLowerCase().includes(filterValue.toLowerCase())||
                item?.StoreType?.toLowerCase().includes(filterValue.toLowerCase())||
                item?.Approvers?.toLowerCase().includes(filterValue.toLowerCase())||
                item?.Admins?.toLowerCase().includes(filterValue.toLowerCase()))
          });
          const slice = filteredData.slice(offsetsearch, offsetsearch + perPage);
          setPageCount(Math.ceil(filteredData.length / perPage))
          setSearchKey('y');
          
          setOffSet(offsetsearch)
          setSearchData(filteredData);  
          setSearchKeyTable(filteredData);
          setTableData(slice);
     }
   }

    const handleAddSubStore=()=>{
        navigate("/add-substore/add");
    }
    const handleEditSubStore=(obj)=>{       
        navigate("/add-substore/"+obj.StoreID);
    }
    const handlerowsperPage = (e) => {
        
        let showperpage = e.currentTarget.value
        setPerPage(parseInt(showperpage));
        setCurrentPage(0);
        if(searchKey=="y"){
            const slice = searchKeyTable.slice(0, 0 + e.currentTarget.value);
            
            setPageCount(Math.ceil(searchKeyTable.length / e.currentTarget.value))
            setTableData(slice);
            setOffSet(0)   
            setSearchData(searchKeyTable);
        }
        else{
        const slice = orgtableData.slice(0, 0 + e.currentTarget.value);
        
        setPageCount(Math.ceil(orgtableData.length / e.currentTarget.value))
        setTableData(slice);
        setOffSet(0)
        setSearchData(orgtableData);
        }
    }

    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offsetdata = selectedPage * perPage;
        setCurrentPage(selectedPage);
        setOffSet(offsetdata);
        loadMoreData(offsetdata);
    }

    const loadMoreData = (offsetdata) => {
        
        setOffSet(offsetdata)
        const data = orgtableData;
        
        if(searchKey=="y"){
            
            const slice = searchKeyTable.slice(offsetdata, offsetdata + perPage);
            
            setPageCount(Math.ceil(searchKeyTable.length / perPage))
            setTableData(slice);
            setSearchData(searchKeyTable);
        }else{
        const slice = data.slice(offsetdata, offsetdata + perPage);
        setPageCount(Math.ceil(data.length / perPage))
        setTableData(slice);
        setSearchData(data);
        }

    }


    useEffect(() => {  
        setIsLoading(true);
        const fetchSubStores = async () => {           
            let result = await API_Auth.getSubstores();                    
            setSubStores(result);
            setIsLoading(false);

            var slice = result.slice(offset, offset + perPage);
            setPageCount(Math.ceil(result.length / perPage));
            setOrgTableData(result);
            setTableData(slice);
            setSearchData(result);
          };                  
           // call the function
           fetchSubStores()
                    // make sure to catch any error
                    .catch(console.error);    
    },[]);
    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            {isLoading &&  <LoadingSpinner />}

            <Container>
                <Row>
                    <Col lg={4}style={{paddingLeft:0}}>
                        <Title>Substore</Title>
                    </Col>
                    <Col lg={5}>
                    <div className="row_div">
{/*                             <p className="title">Filter By:</p> */}
                            <div className="input_div" >
                                <input type="type" style={{margin:0,background:'transparent'}}
                                name="filterValue"
                            //    value={filterValue}
                                onChange={(e)=>handleSearch(e)}
                                placeholder="Search"/>
                                <AiOutlineSearch size={24} style={{ margin: 10,cursor:'pointer' }} color="lightgray"/>
                            </div>

                        </div>
                    </Col>  
                    <Col lg={3}style={{paddingRight:0}}>
                        <div>
                            <button className="add_button" onClick={() => handleAddSubStore()}>
{/*                                 <img src={plus} style={{ margin: 5 }} /> */}
                                <p className="pcat_title"> ADD A NEW SUBSTORE</p></button>
                        </div></Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col lg={12} sm={12} md={12} className="table_head">
                        <Table className="tablelist">
                            <thead>
                                <tr>
                                    <th> Name</th>
                                    <th>Type</th>
                                    <th>Approver(HOD)</th>
                                    <th>Admin</th>
                                    <th></th>
                                   
                                </tr>
                            </thead>
                            {tableData.length == 0 && <tbody>
                                <tr >
                                    <td colSpan="12">
                                        <p className="no_Data_table">No Data Found</p>
                                    </td>
                                </tr>
                            </tbody>
                            }
                             {tableData.length != 0 && <tbody>
                                {tableData.map((obj, index) => {
                                    return (
                                        <>
                                            <tr key={index}>
                                                <td>{obj.StoreName}</td>
                                                <td>{obj.StoreType}</td>
                                                <td>{obj.Approvers}</td>
                                                <td>{obj.Admins}</td>  
                                                <td>
                                                    <div  >
                                                        <img src={Edit}  onClick={()=>handleEditSubStore(obj)} style={{width:20,height:20,margin:5}}/>                                                       
                                                    </div>
                                                </td>                                            
                                            </tr>
                                        </>
                                    );
                                })}
                            </tbody>
                             }
                        </Table>
                        {tableData.length != 0 &&
                        <div>
                        <div className="pagination_row_div">
                            <p className="pagination_title">Showing items per page:</p>
                            <div className="pagination_input_div" >
                                <select className="pagination_select"
                                 style={{ margin: 0, background: 'transparent' }}
                                    name="pagination_value"
                                    value={perPage}
                                    onChange={handlerowsperPage} >
                                    <option value={4}>4</option>
                                    <option value={8}>8</option>
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                </select>
                                </div>
                        </div>
                        <div className="pagination_row_right_div">
                        <p className="pagination_total">Showing {offset+1} to {searchData.length<offset+perPage&&
                        <span>{searchData.length}</span> }
                        {searchData.length>offset+perPage&&
                        <span>{offset+perPage }</span>} of {searchData.length} items</p>
                         <ReactPaginate
                            previousLabel={<ArrowBackIosIcon  className="pagination_prev_btn" color="#2438a7"/>}
                            nextLabel={<ArrowForwardIosIcon className="pagination_next_btn" color="#2438a7"/>}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"} 
                            forcePage={currentPage} />
                    </div>
                    </div>
                    }    
                  </Col>
                </Row>
            </Container>
        </Wrapper>
    )
}
export default SubStore