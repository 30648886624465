import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Container, Row, Col } from "react-bootstrap";
import { Wrapper } from "./Adjustment.styles"
import API_Auth from "../../API_Auth";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Table from "react-bootstrap/Table";
import {IoIosArrowBack } from "react-icons/io";
import {AiOutlineSearch } from "react-icons/ai";
import Modal from 'react-bootstrap/Modal';

const Adjustment = () => {
    const navigate = useNavigate();
    // let {StorID,assetID } = useParams();
    const location = useLocation();

    const {
        isExpanded,
    } = useContext(UserContext);
    const [disableSubmit, setDisableSubmit] = useState(false);

    const [assets, setAssets] = useState([]);
    const [assetText, setAssetText] = useState('');
    const [itemsuggestions, setItemSuggestions] = useState('');
    const [itemText, setItemText] = useState('a');
    const [assetErr, setAssetErr] = useState('')
    const [asset, setAsset] = useState('');
    let { StorID, assetID } = useParams();

    const [totalArrayAsset, setTotalArrayAsset] = useState([]);
    const [duplicateAsset, setDuplicateAsset] = useState(false);
    const [showAssetList, setShowAssetList] = useState(false);



    const [formTitle, setFormTitle] = useState('Item Adjustment');
    const [qunatity, setQunaity] = useState(0);
    const [adjust_date, setDate] = useState(new Date());
    const [dateErr, setDateErr] = useState('');
    const [adjust_qty, setAdjust] = useState('');
    const [comments, setComments] = useState('')
    const [formButtonValue, setFormButtonValue] = useState('UPDATE');
    const [qunatityErr, setQunatityErr] = useState('')
    const [adjustErr, setAdjustErr] = useState('')
    const [commentsErr, setCommentsErr] = useState('');
    const [batchNo, setBatchNo] = useState('');
    const [batchErr, setBatchErr] = useState('')
    const [radioValue, setRadioValue] = useState('');
    const [radioErr, setRadioErr] = useState('');
    const [disabledAddRows, setAddRowsDisabled] = useState(false)
    const handleCloseAssetList = () => setShowAssetList(false)
    const [assetType, setAssetType] = useState('Consumable')

    const handleUpdateItems = () => {
        setShowAssetList(false)
    }
    const handleRemove = (obj, index) => {

        var setValue = totalArrayAsset;
        const dataRemoved = setValue.filter((el) => {
            return el.asset !== obj.asset;
        });

        setTotalArrayAsset(dataRemoved)

    }

    const handleInput = (e) => {
        setItemSuggestions('');
        setAssetErr('');
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;
        if (name === "adjust_date") setDate(value);
        if (name === "qunatity") setQunaity(value);
        if (name === "adjust_qty") setAdjust(value);
        if (name === "comments") setComments(value);
        if (name === "batchNo") setBatchNo(value);
        if (name === "radio"){ setRadioValue(value);
            if(qunatity==0 && value=="Sub"){
                setRadioErr('');


                setAssetErr("Item not available");
                setAddRowsDisabled(true);
                
        }else{
            setRadioErr('');

            setAssetErr("");
            setAddRowsDisabled(false);
        }
    }
        if (name === "asset") {
            const assetItemsData = assets.filter(item => {
                return (item.AssetType.toLowerCase().includes(assetType.toLowerCase()))
            });
            console.log('assets',assets);
            console.log('assetItemsData',assetItemsData);
            if (value.length == 0) {
                setItemSuggestions(assetItemsData);
                setAssetText('');
            } else {
                const filterValue = value;
                setAssetText(value);
                const suggestdta = assetItemsData.filter(item => {
                    return item ?.AssetName ?.toLowerCase().includes(filterValue.toLowerCase())
                    });
                setItemText('f')
                if (suggestdta.length == 0) {
                    setAssetErr('Asset not found');
                } else {
                    setAssetErr('')
                }
                setItemSuggestions(suggestdta);
            }

        };
        const handleViewItems = () => {
            setShowAssetList(true)

        }


    };

    useEffect(() => {

        const date = new Date();
        const futureDate = date.getDate();
        date.setDate(futureDate);
        const defaultValue = date.toLocaleDateString('en-CA');
        setDate(defaultValue)
        const fetchAsset = async () => {
            let data = await API_Auth.getAsset();
           /*  const filteredData = data.filter(item => {
                return (item.AssetType.toLowerCase().includes("consumable"))
            }); */
            setAssets(data);

        };
        // call the function
        fetchAsset()
            // make sure to catch any error
            .catch(console.error);
    }, []);

    const handleTextChange = async (item) => {
        setAddRowsDisabled(false);
        if (totalArrayAsset.length != 0) {
            for (var i = 0; i < totalArrayAsset.length; i++) {
                if (totalArrayAsset[i].asset === item.id) {
                    setAssetErr('Please Select Another Item ..Item is already added');
                    setAssetText(item.AssetName);
                    setDuplicateAsset(true);
                    break;
                } else {
                    setAssetText(item.AssetName);
                    setAsset(item.id);
                    setDuplicateAsset(false);


                }
            }
        } else {
            setAssetText(item.AssetName);
            setAsset(item.id)
        }
        //setAssetText(item.AssetName);
        //setAsset(item.id)

        setAssetText(item.AssetName);
        setAsset(item.id);
        let asset_det = await API_Auth.getConsumeAssetQuantity(StorID, item.id);
        let data = asset_det.length != 0 ? asset_det[0].Quantity : 0
        setQunaity(data)
        setItemText('a')
      
    
        // setItemSuggestions([]);
    }


    const renderSuggestions = () => {
        if (itemsuggestions.length == 0) {
            return null;
        }
        return (
            <ul className={itemsuggestions.length != 0 && itemText == 'f' ? "ul_search" : "ul_search_length"} >
                {itemsuggestions.length != 0 && itemText == 'f' &&
                    <>

                        {itemsuggestions.map((item, index) => {
                            return (
                                <li className="li_search" onClick={() => handleTextChange(item)}>{item.AssetName}</li>
                            )
                        })}
                    </>
                }
            </ul>
        )
    }


    const handleAddAsset = async () => {
        setDisableSubmit(true);

        setQunatityErr('');
        setAdjustErr('');
        setCommentsErr('');
        setDateErr('');
        setBatchErr('');
        setRadioErr('');
        let error = false;
        if (!error) {
            if(totalArrayAsset.length ==0){
                toast.error("Please add Rows")
            }else{
            let totalErrorArray = await handleMultipleRows();
            if (totalErrorArray.length === 0) {
                toast.success('Adjustment successful');
                setTimeout(() => {
                    navigate("/assets-management/" + StorID);
                }, 2000)
            } else {
                setDisableSubmit(false);
                toast.error('Something went Wrong')
            }
        }
    }
    else{
        setDisableSubmit(false);
    }
    }
    const onValueChange = (event) => {
        /*  this.setState({
           selectedOption: event.target.value
         }); */
         setItemSuggestions('');
         setAssetErr('');
        setAssetType(event.target.value)
    }
    const handleMultipleRows = async () => {
        let totalErrorArray = []
        for (var i = 0; i < totalArrayAsset.length; i++) {
            let app = totalArrayAsset[i];
            if (app.comments == "") {
                var datacom = "Adjustment"
            } else {
                var datacom = app.comments
            }
            let result = await API_Auth.adjustConsumeAsset(app.asset, app.StorID, datacom, app.batchNo,app.assetType, app.adjust_qty, app.adjust_date, app.radioValue);
            const res = result[0];
            if (res.Flag == 'Fail') {
                totalErrorArray.push(i);
            }
        }
        return totalErrorArray;
    }
    const handleBackClick = () => {
        navigate(-1);
    }
    const handleViewItems = () => {
        setShowAssetList(true)

    }

    const addInputField = () => {
        setQunatityErr('');
        setAdjustErr('');
        setCommentsErr('');
        setDateErr('');
        setBatchErr('');
        setRadioErr('');
        setAssetErr('');
        if (totalArrayAsset.length == 0) {
            setDuplicateAsset(false)
        }
        let error = false;
        if (asset == '' || assetText == "") {
            setAssetErr('This field is required');
            error = true;
        }
        if (duplicateAsset) {
            error = true;

        }

        /*   if (qunatity == '') {
              setQunatityErr('This field is required');
              error = true;
          } */
        if (adjust_qty == '') {
            setAdjustErr('This field is required');
            error = true;
        }
        if (adjust_date == '') {
            setDateErr('This field is required');
            error = true;
        }
        if (radioValue == "") {
            setRadioErr('This field is required');
            error = true;
        }
        if (!error) {

            var data = {
                asset: asset,
                StorID: StorID,
                comments: comments,
                batchNo: batchNo,
                adjust_qty: adjust_qty,
                assetType:assetType,
                adjust_date: adjust_date,
                radioValue: radioValue,
                assetText: assetText,
                Quantity: qunatity
            }
            let array = totalArrayAsset
            array.push(data);
            //  setDate('');
            setQunaity('');
            setAdjust('');
            setComments('');
            setBatchNo('');
            setRadioValue('');
            setAsset('');
            setAssetText('');
            setTotalArrayAsset(array);
        }
    }
    return (


        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            <Container>
                <Row className="row_title">
                    <Col lg={4}><p>{formTitle}</p></Col>
                    <Col lg={3}></Col>
                    <Col lg={3}>
                        {/*  <div className="view_button" onClick={() => handleViewItems()}>
                            <h6>VIEW ALL ROWS-({totalArrayAsset.length})</h6>
                        </div> */}
                    </Col>
                    <Col lg={2}>
                        <div className="back_button" onClick={() => handleBackClick()}>
                            <IoIosArrowBack size={18} />
                            <h6>Back</h6>
                        </div>
                    </Col>
                </Row>


            </Container>            <hr />

            <Row>
                <Col lg={6} >
                    <div className="inputdiv">
                        <label>Date</label>
                        <br />
                        <input type="date" style={{ width: "95%" }}
                            name="adjust_date"
                            value={adjust_date}
/*                                         placeholder={new Date()}
 */                                        onChange={handleInput} />
                        {dateErr != '' && <p className="alert-message">{dateErr}</p>}
                    </div>
                </Col>
            </Row>
            <Container>
                <Row>
                    <Table className="tablelist">
                        <thead>
                            <tr>
                                <th> Item </th>
                                <th>Qunatity</th>
                                <th>Adjust Quantity</th>
                                <th>Asset Type</th>
                                <th>Date</th>
                                <th>radioValue</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        {totalArrayAsset.length == 0 && <tbody>
                            <tr >
                                <td colSpan="12">
                                    <p className="no_Data_table">No Rows Found</p>
                                </td>
                            </tr>
                        </tbody>
                        }
                        {totalArrayAsset.length != 0 && <tbody>
                            {totalArrayAsset.map((obj, index) => {
                                return (
                                    <>
                                        <tr key={index}>

                                            <td>{obj.assetText}  </td>
                                            <td>{obj.Quantity}</td>
                                            <td>{obj.adjust_qty}</td>
                                            <th>{obj.assetType}</th>
                                            <td>{obj.adjust_date}</td>
                                            <td>{obj.radioValue}</td>
                                            <td>
                                                <div onClick={() => handleRemove(obj, index)}>
                                                    <p style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}>Remove</p>
                                                </div>
                                            </td>



                                        </tr>
                                    </>
                                );
                            })}
                        </tbody>
                        }
                    </Table>

                </Row>
                <Row>
                    <Col lg={10}></Col>
                    <Col lg={2}>
                        {/*    <div className="row_div">
                            <button className="add_button" onClick={() => handleAddAsset()}>
                                <p className="pcat_title">{formButtonValue}</p></button>
                        </div> */}
                        <button className="row_table_divs " disabled={disableSubmit}  onClick={() => handleAddAsset()} style={{ width: 100, margin: 15, fontWeight: 'bold', float: 'left' }}>
                            <p style={{ marginLeft: 20 }}>{formButtonValue}</p>
                        </button></Col>

                </Row>
                <Row style={{ marginTop: 20, marginBottom: 20, marginLeft: 2 }}>
                    <label style={{ marginBottom: 20 }}>Type</label>
                    <Col lg={3}>
                        <input type="radio" id="Consumable" name="Consumable" value="Consumable"
                            style={{ width: 25, height: 15, margin: 5 }}
                            checked={assetType === "Consumable"}

                            onChange={onValueChange}
                        />
                        <label for="Consumable" className="radiolabel">Consumable</label>
                    </Col>
                    <Col lg={3}>
                        <input type="radio" id="Consumable" name="Fixed" value="Fixed" style={{ width: 25, height: 15, margin: 5 }}
                            onChange={onValueChange}
                            checked={assetType === "Fixed"}

                        />
                        <label for="Consumable" className="radiolabel">Fixed</label>
                    </Col>
                </Row>

                <Row style={{ background: 'aliceblue', margin: 5, borderRadius: 6, border: "1px solid black" }}>
                    <Col lg={12}>
                        <Row>
                            <Col  >
                                <div className="inputdiv">
                                    <label style={{ marginTop: 10 }}>Item</label>
                                    <br />
                                    <div className="row_div_search">
                                        <div className="input_div"  >
                                            <input type="type" style={{ margin: 0, background: 'transparent', }}
                                                name="asset"
                                                value={assetText}
                                                onChange={handleInput}
                                                placeholder="Search Item" />
                                            <AiOutlineSearch size={24} style={{ margin: 10, cursor: 'pointer' }} color="lightgray" />
                                        </div>

                                    </div>
                                    {renderSuggestions()}

                                    {assetErr != '' && <p className="alert-message">{assetErr}</p>}


                                </div>
                            </Col>
                            <Col >
                                <div className="inputdiv">
                                    <label>Current Qunatity</label>
                                    <br />
                                    <input type="text" style={{ width: "95%" }}
                                        name="qunatity"
                                        value={qunatity}
                                        disabled
                                        placeholder="Quantity"
                                    // onChange={handleInput}
                                    />
                                    {qunatityErr != '' && <p className="alert-message">{qunatityErr}</p>}
                                </div>
                            </Col>
                            <Col >
                                <div style={{ marginLeft: 40 }}>
                                    <label style={{ marginTop: 34, marginLeft: 80 }}>Add/Substract</label>
                                    <br />
                                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 2 }}>
                                        <input type="radio" id="add" name="radio" value="Add" style={{ width: 25, height: 15, margin: 20 }}
                                            onChange={handleInput} checked={radioValue == "Add"}
                                        />
                                        <label for="add" className="radiolabel">ADD</label>
                                        <input type="radio" id="sub" name="radio" value="Sub" style={{ width: 25, height: 15, margin: 20 }}
                                            onChange={handleInput} checked={radioValue == "Sub"}

                                        />
                                        <label for="sub" className="radiolabel">SUBSTRACT</label>

                                    </div>
                                    {radioErr != '' && <p className="alert-message">{radioErr}</p>}

                                </div>
                            </Col>

                        </Row>
                        <Row>



                            <Col lg={5}>
                                <div className="inputdiv">
                                    <label>Adjustment Quantity</label>
                                    <br />
                                    <input type="text" min="0" style={{ width: "95%" }}
                                        name="adjust_qty"
                                        value={adjust_qty}
                                        placeholder="Adjust"
                                        onChange={handleInput} />
                                    {adjustErr != '' && <p className="alert-message">{adjustErr}</p>}
                                </div>
                            </Col>
                            <Col lg={5}>
                                <div className="inputdiv">
                                    <label>Comments</label>
                                    <br />
                                    <textarea
                                        value={comments}
                                        onChange={handleInput}
                                        name="comments" placeholder="Type Something"
                                        rows={5}
                                        style={{ height: 100, }}
                                        cols={5}
                                    />
                                    {commentsErr != '' && <p className="alert-message">{commentsErr}</p>}
                                </div></Col>

                        </Row>
                        <Row>

                            {/*    <Col lg={5}>
                                <div className="inputdiv">
                                    <label>Batch No</label>
                                    <br />
                                    <input type="text"
                                        name="batchNo"
                                        value={batchNo}
                                        placeholder="Batch No"
                                        onChange={handleInput} />
                                    {batchErr != '' && <p className="alert-message">{batchErr}</p>}
                                </div>
                            </Col> */}

                        </Row>
                        <Row>

                            {/*  <Col lg={5}>
                                <div className="inputdiv">
                                    <label>Comments</label>
                                    <br />
                                    <textarea
                                        value={comments}
                                        onChange={handleInput}
                                        name="comments" placeholder="Type Something"
                                        rows={5}
                                        style={{ height: 100, }}
                                        cols={5}
                                    />
                                    {commentsErr != '' && <p className="alert-message">{commentsErr}</p>}
                                </div></Col> */}
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col lg={10}></Col>
                    <Col lg={2}>
                        <button className="row_table_div " disabled={disabledAddRows} onClick={() => addInputField()} style={{ width: 100,border:"none", margin: 15, fontWeight: 'bold', float: 'left' }}>

                            <p style={{ marginLeft: 15 }}>ADD  ROWS</p>
                        </button></Col>
                </Row>

                <Modal show={showAssetList} onHide={handleCloseAssetList}
                    aria-labelledby="contained-modal-title-vcenter"
                    dialogclassname="modal-content">
                    <Modal.Header closeButton>
                        <Modal.Title>Items</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>

                        <Table className="tablelist">
                            <thead>
                                <tr>
                                    <th> Item </th>
                                    <th>Qunatity</th>
                                    <th>Adjust Quantity</th>
                                    <th>Asset Type</th>
                                    <th>Batch No</th>
                                    <th>Date</th>
                                    <th>radioValue</th>
                                    <th>Action</th>



                                </tr>
                            </thead>
                            {totalArrayAsset.length == 0 && <tbody>
                                <tr >
                                    <td colSpan="12">
                                        <p className="no_Data_table">No Rows Found</p>
                                    </td>
                                </tr>
                            </tbody>
                            }
                            {totalArrayAsset.length != 0 && <tbody>
                                {totalArrayAsset.map((obj, index) => {
                                    return (
                                        <>
                                            <tr key={index}>

                                                <td>{obj.assetText}  </td>
                                                <td>{obj.Quantity}</td>
                                                <td>{obj.adjust_qty}</td>
                                                <td>{obj.assetType}</td>
                                                <td>{obj.batchNo}</td>
                                                <td>{obj.adjust_date}</td>
                                                <td>{obj.radioValue}</td>
                                                <td>
                                                    <div onClick={() => handleRemove(obj, index)}>
                                                        <p style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}>Remove</p>
                                                    </div>
                                                </td>



                                            </tr>
                                        </>
                                    );
                                })}
                            </tbody>
                            }
                        </Table>

                    </Modal.Body>
                    <Modal.Footer className="modal-footer">
                        <div className="row_div">
                            <button className="add_button" onClick={() => handleUpdateItems()}>
                                <p className="pcat_title">Update Items</p></button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </Container>
            <ToastContainer />
        </Wrapper >
    )
}
export default Adjustment