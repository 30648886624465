import styled from "styled-components";

export const Wrapper = styled.div`
.tablelist {
    margin-right:5px;;
  }
  thead{
    color:black;
    background:#c0d3ef;
    border:1px solid #c0d3ef;
    border-radius:10px;
  }
  td{
    color:black;
    font-weight:400;
    font-size:17px;
    padding:15px;
    font-family:Roboto;
    text-transform: capitalize;
  }
  th{
    font-size:16px;
    height:50px;
    padding:14px;
    font-weight:500;
    font-family:Roboto;
  
  }
  .table-head-content {
    padding: 13px 13px 0 13px;
    background: white;
    border-bottom: 1px solid #E2E2E2;   
  }  
  .row_type{
      border:1px solid #6EDC8F;
      margin:15px 1px;
      height:40px;
      border-radius:6px;
      opacity:1
  }
  .button_asset_type {
      background:#6EDC8F;
      color:black;
      border-radius:6px;
      margin:-1px;
      padding:10px;
      text-align: center;
      text-transform: uppercase;
      font-family: 'Roboto', sans-serif;
      font-weight:400;
      font-size:14px;
      cursor:pointer;

      }
      .button_asset_type_fixed {
      
        border-radius:6px;
        margin:-1px;
        padding:10px;
        text-align: center;
        text-transform: uppercase;
        font-family: 'Roboto', sans-serif;
        font-weight:500;
        font-size:14px;
        cursor:pointer;

        }
        .row_div {
            display:flex;
            flex-direction:row;
            padding: 8px;
            margin:8px 1px;

          }
          .input_div {
            border: 2px solid #DFDFDF;
            color:var(--black);
            font-family: 'ARIAL';
            height:40px;
            border-radius:6px;
            background:white;
            display:flex;
            flex-direction:row;
            justify-content:space-between;
            input,select{
               border:none;
               padding-left:5px;
               :focus {
                 outline: none !important;
                 border-color:none;  
               }
              }
             
          }
  
          .row_table_edit {
            width: 80px;
            align-items: center;
            justify-content: center;
            padding: 4px 6px;
            background:#ECECEC;
            color:#000044;
            display:flex;
            flex-direction:row;
            border-radius:4px;
            cursor:pointer; 
           
            @media screen and (max-width: 1365px) {
              width:70%;
          
            }
            @media screen and (max-width: 1199px) {
              width: 75%;
          
            }
            @media screen and (max-width: 991px) {
              width: 85%;
          
            }
            @media screen and (max-width: 768px) {
              width: 90%;
            }
            img{
              width: 15px;
              height: 14px;
              margin: 0px 4px;
            }
            p{
              margin: 0px 4px;
              font-size: 13px;
              margin-top: 3px;
              font-weight: bold;   
               }
          }
          .status_div {
            background:#FD7E1433;
            text-align:center;
            border-radius:4px;
            font-family:Roboto;
            height:25px;
            padding:2px;
            font-size:13px;
          }
          
          .status_div1 {
            background:#19875433;
            text-align:center;
            border-radius:4px;
            font-family:Roboto;
            height:25px;
            padding:2px;
            font-size:13px;
          
          }
          .status_div2 {
            background:#F1154133;
            text-align:center;
            border-radius:4px;
            font-family:Roboto;
            height:25px;
            font-size:13px;
          
            padding:2px;
          }
          
  `