import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Wrapper, Label, Input, Switch } from "./AddSubStore.styles"
import API_Auth from "../../API_Auth";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { LoadingSpinner } from "../LoadingSpinner/index";
import Multiselect from 'multiselect-react-dropdown';
import {IoIosArrowBack } from "react-icons/io";


const AddSubStore = () => {
    let { subStoreID } = useParams();
    const navigate = useNavigate();
    const {
        isExpanded,
    } = useContext(UserContext);
    const [substoreName, setSubStoreName] = useState('');
    const [substoreNameErr, setSubStoreNameErr] = useState('');

    const [typeChecked, setChecked] = useState(false);
    const [typeCheckedErr, setCheckedErr] = useState(false);

    const [approver, setApprover] = useState('');
    const [admin, setAdmin] = useState('');
    const [field, setField] = useState([]);

    const [allAdmins, setAllAdmins] = useState([]);
    const [selectedAdmins, setSelectedAdmins] = useState([]);
    const [selectedAdminsErr, setSelectedAdminsErr] = useState([]);


    const [selectedAdminsEdit, setSelectedAdminsEdit] = useState([]);
    const [selectedApproversEdit, setSelectedApproversEdit] = useState([]);

    const [allAprrovers, setAllAprrovers] = useState([]);
    const [selectedAprrovers, setSelectedAprrovers] = useState([]);
    const [selectedAprroversErr, setSelectedAprroversErr] = useState([]);

    const [formTitle, setFormTitle] = useState('Add Substore');
    const [formButtonValue, setFormButtonValue] = useState('ADD SUBSTORE');
    const [isLoading, setIsLoading] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);

    const handleInput = (e) => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;
        if (name === "substoreName") setSubStoreName(value);
        if (name === "approver") setApprover(value);
        if (name === "admin") setAdmin(value);
    };
    const handleChange = (e) => {
        setChecked(e.target.checked);
    };
    useEffect(() => {
        const fetchAsset = async () => {

            if (subStoreID != 'add') {

                let substore_det = await API_Auth.getSubstoreById(subStoreID);
                setSubStoreName(substore_det.substore[0].SubStoreName);
                let type = false;
                if (substore_det.substore[0].SubStoreType != 'NON-CENTRAL')
                    type = true;

                setChecked(type);
                setFormTitle('Edit Substore');
                setFormButtonValue('UPDATE SUBSTORE');
                setSelectedAdmins(substore_det.Admins);
                setSelectedAdminsEdit(substore_det.Admins);

                if(substore_det.Approvers.length > 0){
                    setSelectedAprrovers(substore_det.Approvers);  
                    setSelectedApproversEdit(substore_det.Approvers);
                }
            }
            let approvers = await API_Auth.getAprrovers();
            let all_aprrovers = [];
            approvers.map(function (value, key) {
                    all_aprrovers.push({ 'appID': value.UserID, 'ApproverDesign': value.Name + '(' + value.ApproverDesignation + ')' });
            });
            all_aprrovers.sort((a, b) => a.ApproverDesign.localeCompare(b.ApproverDesign))
            setAllAprrovers(all_aprrovers);
            let admins = await API_Auth.getUsers();
            let all_admins = [];
            admins.map(function (value, key) {
                all_admins.push({ 'AdminID': value.id, 'Name': value.FirstName + ' ' + value.LastName });
            });
            all_admins.sort((a, b) => a.Name.localeCompare(b.Name))
            setAllAdmins(all_admins);

        };
        // call the function
        fetchAsset()
            // make sure to catch any error
            .catch(console.error);
    }, []);



    const handleSelectAdmin = (selectedList, selectedItem) => {
        setSelectedAdmins(selectedList);  
    };
    const handleSelectApprover = (selectedList, selectedItem) => {
        setSelectedAprrovers(selectedList);
    };
    const handleAddSubstore = async () => {
        setDisableSubmit(true);
        let error = false;
        if (substoreName == '') {
            setSubStoreNameErr('This field is required');
            error = true;
        } else {
            setSubStoreNameErr('');
        }
           /*  if(selectedAdmins.length == 0){
                setSelectedAdminsErr('This field is required');
                error = true;
            }else{
                setSelectedAdminsErr('');
            } */
           /*  if(selectedAprrovers.length == 0){
                setSelectedAprroversErr('This field is required');
                error = true;
            }else{
                setSelectedAprroversErr('');
            } */

        if (!error) {

            let type = 'NON-CENTRAL';
            if (typeChecked) {
                type = 'CENTRAL';
            }
            setIsLoading(true)

            if (subStoreID == 'add') {
                let approverOpt = [];
                if(selectedAprrovers.length > 0){
                    selectedAprrovers.map(item => {
                        approverOpt.push(item.appID);
                    });                    
                }
                let adminOpt = [];
                if(selectedAdmins.length > 0){
                    selectedAdmins.map(item => {
                        adminOpt.push(item.AdminID);
                    });                    
                }
                let approveOptions = approverOpt.join(',');     
                let admintOptions = adminOpt.join(',');     
                let result = await API_Auth.addSubstore(substoreName, type, approveOptions, admintOptions);
                let res = result[0];
                if (res?.Flag == 'Success') {
                    setIsLoading(false);
                    toast.success('Substore added successfully');
                    setTimeout(() => {
                        navigate("/substore-list");
                    }, 2000);
                } 
                else if(result.errno==1062){
                    setIsLoading(false);

                    setDisableSubmit(false);
                    toast.error('Store already exists');
              
                }
                else {
                    setIsLoading(false);

                    setDisableSubmit(false);
                    toast.error('Something went wrong please try again');
                }
            } else {

                let result = await API_Auth.updateSubstore(substoreName, type, subStoreID);
                let res = result[0];

                selectedAdminsEdit.map(async function(app,key){                     
                    let radmin_res = await API_Auth.addRemoveAdminToSubstore(app.AdminID, subStoreID, 'remove');
                });
                selectedApproversEdit.map(async function(app,key){                  
                let rappr_res = await API_Auth.addRemoveApproverToSubstore(app.appID, subStoreID, 'remove');
                  });
                 selectedAprrovers.map(async function(app,key){ 
                let appr_res = await API_Auth.addRemoveApproverToSubstore(app.appID, subStoreID, 'add');
                   });
                selectedAdmins.map(async function(app,key){ 
                    let adm_res = await API_Auth.addRemoveAdminToSubstore(app.AdminID, subStoreID, 'add');
                });


                if (res.Flag == 'Success') {
                    setIsLoading(false);

                    toast.success('Substore updated successfully');
                    setTimeout(() => {
                        navigate("/substore-list");
                    }, 2000);

                } else {
                    setIsLoading(false);

                    setDisableSubmit(false);
                    toast.error('Something went wrong please try again');
                }
            }
        } else {
            setDisableSubmit(false);
        }

    }

    const handleBackClick = () => {
        navigate("/substore-list");
        navigate(0);
    }
    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            <Container>
                <Row className="row_title">
                    <Col lg={4}><p>{formTitle}</p></Col>
                    <Col lg={6}></Col>
                    <Col lg={2}>
                        <div className="back_button" onClick={() => handleBackClick()}>
                            <IoIosArrowBack size={18} />
                            <h6>Back</h6>
                        </div>
                    </Col>
                </Row>
            </Container>     
                 {isLoading && <LoadingSpinner />}

            <hr />
            <Container>
                <Row>
                    <Col >
                        <div className="inputdiv">
                            <label>Substore Name</label>
                            <br />
                            <input type="text"
                                name="substoreName"
                                value={substoreName}
                                placeholder="Enter SubStore Name"
                                onChange={handleInput} />
                            {substoreNameErr != '' && <p className="alert-message">{substoreNameErr}</p>}
                        </div>
                        <div className="inputdiv">
                            <label>Type</label>
                            <br />
                            <Label>
                                <span> {typeChecked ? "Central" : "Non Central"}</span>
                                <Input checked={typeChecked} type="checkbox" onChange={handleChange} />
                                <Switch />
                            </Label>
                            {typeCheckedErr != '' && <p className="alert-message">{typeCheckedErr}</p>}
                        </div>
                      {/*   <Row>
                            <Col>
                                <div className="inputdiv">
                                    <label>Admin</label>
                                    <br />
                                     <Multiselect 
                                            displayValue="Name"
                                            options={allAdmins} // Options to display in the dropdown
                                            selectedValues={selectedAdmins} // Preselected value to persist in dropdown
                                            onSelect={handleSelectAdmin} // Function will trigger on select event
                                            onRemove={handleSelectAdmin} // Function will trigger on remove event                                            
                                    /> 
                                    {selectedAdminsErr != '' && <p className="alert-message">{selectedAdminsErr}</p>}
                                      
                                </div>
                            </Col>
                            <Col>
                                <div className="inputdiv">
                                    <label>Approver (HOD)</label>
                                    <br />
                                    <div className="select_div">
                                    <Multiselect 
                                            displayValue="ApproverDesign"
                                            options={allAprrovers} // Options to display in the dropdown
                                            selectedValues={selectedAprrovers} // Preselected value to persist in dropdown
                                            onSelect={handleSelectApprover} // Function will trigger on select event
                                            onRemove={handleSelectApprover} // Function will trigger on remove event                                            
                                    />
                                    
                                    </div>
                                </div>
                            </Col>
                        </Row>
                       */}  <Row>
                            <Col lg={6}>
                                <div className="row_div">
                                    <button className="add_button" disabled={disableSubmit} onClick={() => handleAddSubstore()}>
{/*                                         <img src={plus} style={{ margin: 5 }} /> */}
                                        <p className="pcat_title">{formButtonValue}</p></button>
                                </div>

                            </Col>
                        </Row>

                    </Col>
                </Row>
            </Container>
            <ToastContainer />
        </Wrapper>
    )
}
export default AddSubStore