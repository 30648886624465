

import styled from "styled-components";

export const Wrapper = styled.div`
background:white;
margin:20px;
border-radius:6px;
width:70%;
.alert-message{
  color: red;
  margin-left: 15px;
}
.input_div {
  border: 2px solid #DFDFDF;
  color:var(--black);
  font-family: 'ARIAL';
  height:45px;
  width:100%;
  margin-top:8px;
  border-radius:6px;
  background:white;
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  input,select{
     border:none;
     :focus {
       outline: none !important;
       border-color:none;  
     }
    }
   
}
.ul_search{
  
    list-style-type:none;
    text-align:left;
    margin-left:10px;
    padding:0;
    overflow: scroll;
    height: 150px;
    border :1px solid lightgrey;
    &:before {
      content:"";
    }
  }
  .ul_search_length{
    list-style-type:none;
    text-align:left;
    margin:0;
    padding:0;
   
    border :none;
    &:before {
      content:"";
    }
  }

 .li_search {
    padding :10px 15px;
    margin-left:5px;
    font-family:'Roboto';
    font-bold:400;
    cursor:pointer;
  } 

.row_div_search{
  display:flex;
  flex-direction:row;
  padding: 8px;
}

.title{
  font-family:Roboto;
  font-size:15px;
  font-weight:600;
  margin-left:20px;
  margin-bottom:0 !important;
  margin-top:15px;

}
.subtitle{
  font-family:Roboto;
  font-size:24px;
  font-weight:600;
  margin-left:20px;
  margin-top:0 !important
}
.row_div {
    margin:10px;
  
  }
  

 


.inputdiv {
    margin:20px 10px 5px;
}

select{
    border: 1px solid #00001F24;
    width: 90%;
    padding-left: 10px;

    color:var(--black);
    font-family: 'ARIAL';
    height:45px;
    border-radius:6px;
    margin:10px;
    :focus {
        outline: none !important;
        border-color:none;  
      }
}




.check_input {
  height:20px;
  margin:10px;
  width: 12%;


}
.check_div{
  flex-direction: row;
   display: flex;
   margin:10px;
   p{
    color:black;
    margin-left:1px;
    margin-top:10px;
    font-weight:bold;
    font-size:14px;
    font-family:Roboto
   }

}
.row_table_div_2 {
  background:#ECECEC;
  color:#000044;
  display:flex;
  flex-direction:row;
  border-radius:4px;
  cursor:pointer; 
}
.add_button {
  display:flex;
  flex-direction:row;
  background:#6EDC8F;
  color:black;
  border:#6EDC8F;
  padding:15px;
  height:50px;
  margin:15px;
  text-align:center;
  justify-content:center;
  align-item:center;
  box-shadow: 0px 2px 2px #00000030;
border-radius: 6px;
opacity: 1;


  
}

input {
  border: 2px solid #DFDFDF;
    padding-left: 10px;

    width: 100%;
    color:var(--black);
    font-family: 'ARIAL';
    height:45px;
    border-radius:6px;
    margin:10px;
  }
  input[type="file"] {
    display: none;
}


  :focus {
      outline: none !important;
      border-color:none;  
    }
  
   
`
export const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;
export const RowInput = styled.div`
  border:2px solid #D1D1D1;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  width: 50%;
  height: 50px;
  margin :20px auto 10px;
  @media screen and (max-width: 1365px) {
    width:55%;

  }
  @media screen and (max-width: 1199px) {
    width: 65%;

  }
  @media screen and (max-width: 991px) {
    width: 85%;

  }
  @media screen and (max-width: 768px) {
    width: 90%;
  }
  input {
    border: none;
    width: 90%;
    color:var(--black);
    font-family: 'ARIAL';
    margin:15px;
  }
  :focus {
      outline: none !important;
      border-color:none;  
    }
  }


`

export const Switch = styled.div`
   position: relative;
  width: 60px;
  height: 28px;
  background: #b3b3b3;
  border-radius: 32px;
  padding: 4px;
  transition: 300ms all;

  &:before {
    transition: 300ms all;
    content: "";
    position: absolute;
    width: 28px;
    height: 28px;
    border-radius: 35px;
    top: 50%;
    left: 4px;
    background: white;
    transform: translate(0, -50%);
  }
`;

export const Input = styled.input`
  opacity: 0;
  position: absolute;
  margin-left:20px;

  &:checked + ${Switch} {
    background: green;

    &:before {
      transform: translate(32px, -50%);
    }
  }
`;
