import nounMedical from "./images/categories.png";
import subCategory from "./images/subcategories.png";
import staff from "./images/vendors.png";
import users from "./images/users.png";
import cart from "./images/substore.png";
import nounInventory  from "./images/assets.png";
import dashboard  from "./images/dashboard.png";
import money from  "./images/money-sack.png";
import measure from "./images/icons8-measure-64.png";
import user from "./images/icons8-user-64.png"
import transactions_log from "./images/transactions_log.png"

const _nav = [
  {
    title: "Dashboard",
    to: "/dashboard",
    icon:dashboard,
    subitems:[]
  },
    {
      title: "Asset Categories",
      to: "/admin-category",
      icon:nounMedical,
      subitems:[]
    },
    {
      title: "Sub Categories",
      to: "/admin-subcategory",
      icon:subCategory,
      subitems:[]
    },
    {
      title: "Asset Master List",
      to: "/admin-asset",
      icon:nounInventory,
      subitems:[]
    },
  
    {
      title: "Users",
      to: "/admin-users",
      icon:users,
      subitems:[]
    },
    {
      title: "Vendors",
      to: "/admin-vendors",
      icon:staff,
      subitems:[]
    },
    {
      title: "Substore",
      to: "/substore-list",
      icon:cart,
      subitems:[]
    },
    {
      title: "Stock Register",
      to: "/stock-register",
      icon:money,
      subitems:[]
    },
    {
      title: "UOM",
      to: "/uom",
      icon:measure,
      subitems:[]
    },
    {
      title: "Designation",
      to: "/designation",
      icon:user,
      subitems:[]
    },
    {
      title: "Transaction Log",
      to: "/tansaction-log",
      icon:transactions_log,
      subitems:[]
    }
  ];
  
  export default _nav;
  