import styled from "styled-components";

export const Wrapper = styled.div`
.table_head {
  background:white;
  margin-top:15px;

}
.tablelist {
  margin-top:15px;
  margin-right:5px;;
}
 
thead{
  color:black;
  background:#EBEBEE;
  border:1px solid #EBEBEE;
  border-radius:10px;
}
td{
  color:black;
  font-weight:400;
  font-size:16px;
  height:60px;
  padding:15px;
  font-family:Roboto;
  text-transform: capitalize;
}
th{
  font-size:18px;
  height:50px;
  padding:14px;
  font-weight:500;
  font-family:Roboto;

}
.table-head-content {
  padding: 13px 13px 0 13px;
  background: white;
  border-bottom: 1px solid #E2E2E2;   
}  


.adddiv {
  background:white;
  height:95%;
  width:80%;
  margin-top:15px;
  margin-left:20px;
  border-radius:5px;
  
  input,select{
     width:85%;
    height: 40px;
    border: 1px solid #00001F24;
    border-radius: 6px;
    margin: 15px ;
    padding: 10px;  
    :focus {
      outline: none !important;
      border-color:none;  
    }
   }
  
}
.rowdiv {
  margin:5px;

}
.add_button {
  display:flex;
  flex-direction:row;
  background:#000044;
  color:white;
  border:#4EACFB;
  padding:15px;
  height:50px;
  margin:15px;
  width:85%;
  text-align:center;
  justify-content:center;
  align-item:center;
  box-shadow: 0px 2px 2px #00000030;
border-radius: 6px;
opacity: 1;


  
}
.add_cat_button {
  background:#000044;
  color:white;
  border:#4EACFB;
  padding:10px;
  height:40px;
  width:85%;
  margin:100px 25px 0px;
  font-weight:bold;
  border-radius:6px;
}

.rowtablediv {
    background:#ECECEC;
    color:#000044;
    display:flex;
    flex-direction:row;
    border-radius:4px;
    cursor:pointer; 
    margin:10px;
    
    p{
    margin:8px;
    font-family:Roboto;
    font-weight:bold;
    }

}

.rowdiv {
    display:flex;
    flex-direction:row;
}
.input_div {
    border: 1px solid #00001F24;
    width: 70%;
    color:var(--black);
    font-family: 'ARIAL';
    height:45px;
    border-radius:6px;
    margin:5px;
    background:white;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    input,select{
       border:none;
       :focus {
         outline: none !important;
         border-color:none;  
       }
      }    
}
.title{
    font-family:Roboto;
    font-size:16px;
    font-weight:600;
    margin-left:10px;
    margin-bottom:0 !important;
    margin-top:15px;

}

.inputdiv {
  margin:0px 0px;
}
option{
  color: black;
  background-color: white;
}
input,select {
  padding-left: 10px;
  border: 3px solid #000044;
  color:#000044;
  font-weight:bold;
  font-family: 'ARIAL';
  height:45px;
  border-radius:6px;
}
:focus {
    outline: none !important;
    border-color:none;  
  }
  .open ul.dropdown-menu {
    display: block;
}
`