import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Wrapper } from "./styles"
import { Row, Container, Col, OverlayTrigger, Tooltip, } from "react-bootstrap"
import Table from "react-bootstrap/Table";
import plus from "../../images/white_plus.png"
import View from "../../images/view.svg";
import Consumed from "../../images/consumed.svg";
import Issue from "../../images/issue.svg";
import Adjust from "../../images/adjust.svg";
import Request from "../../images/request.svg";
import { useNavigate, useParams } from "react-router-dom";
import API_Auth from "../../API_Auth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Title } from "../../GlobalStyle";
import { AiOutlineSearch } from "react-icons/ai";
import { LoadingSpinner } from "../LoadingSpinner/index";
import ReactPaginate from 'react-paginate';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const CentralStoreConsumableAsset = () => {
    const {
        isExpanded,
    } = useContext(UserContext);
    let { StorID } = useParams();
    const [assets, setAssets] = useState([]);
    const [searchData, setSearchData] = useState([])
    const [status, setStatus] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [assets_types, setAssetTypes] = useState([]);
    const [asset_type, setAssetValue] = useState("")
    const [storeDet, setStoreDet] = useState([]);
    const navigate = useNavigate();
    const [filterValue, setFilterValue] = useState("");
    const [offset, setOffSet] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [orgtableData, setOrgTableData] = useState([]);
    const [perPage, setPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [searchKey, setSearchKey] = useState('n');
    const [searchKeyTable, setSearchKeyTable] = useState('');


    const handleAddAsset = () => {
        navigate('/central-consumable-add-item/central-consumable-asset/' + StorID + '/add/add');
    }

    const handleSearch = (e) => {
        let filterValue = e.target.value;
        setCurrentPage(0);
        if (filterValue == '') {
            let offsetsearch = 0;
            var slice = orgtableData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(orgtableData.length / perPage));
            setOrgTableData(orgtableData);
            setTableData(slice);
            setSearchData(orgtableData);
            setSearchKey('n');
            setOffSet(offsetsearch);
            setCurrentPage(0);
        }
        else {
            let value1 = filterValue.toLowerCase();
            let offsetsearch = 0;
            const filteredData = orgtableData.filter(item => {
                return (item ?.AssetName ?.toLowerCase().includes(value1) ||
                    item ?.Quantity ?.toString() ?.includes(value1) ||
                        item ?.Recordlvl ?.toLowerCase().includes(value1) ||
                            item ?.RedAlertlvl ?.toLowerCase().includes(value1))

            });
            const slice = filteredData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(filteredData.length / perPage))
            setSearchKey('y');
            setOffSet(offsetsearch);
            setSearchData(filteredData);
            setSearchKeyTable(filteredData);
            setTableData(slice);
        }
    }
    const handleGoTOItem = async (obj, item_value) => {
        if (item_value == 'consumble') {
            navigate("/asset-consumption", { state: { asset_data: obj, StoreID: StorID } });
        }
        if (item_value == 'addItems') {
            navigate('/central-consumable-add-item/central-consumable-asset/' + StorID + "/" + obj.AssetID + '/add');
        }
        if (item_value == 'adjust') {
            navigate("/asset-adjustment", { state: { asset_data: obj, StoreID: StorID } });
        }
        if (item_value == 'viewDetails') {
            navigate("/central-consumable-view-asset" + "/Consumable", { state: { asset_data: obj, StoreID: StorID } });
        }
        if (item_value == 'requestItem') {
            // navigate("/central-consumable-view-asset"+StorID+"/"+obj.AssetID);
            let store_det = await API_Auth.getSubstoreById(StorID);
            let name = store_det.substore[0].SubStoreName;

           // navigate("/fixed-add-request/fixed-request/" + StorID + "/" + name + "/" + "fixed")
           navigate("/add-request/" + StorID)


        }
        if (item_value == 'issue') {
            navigate("/central-consumable-asset-issue", { state: { asset_data: obj, StoreID: StorID } });
        }
    }

    useEffect(() => {
        setIsLoading(true);
        const fetchAsset = async () => {
            let store_det = await API_Auth.getSubstoreById(StorID);
            setStoreDet(store_det.substore[0]);
            if (store_det.substore[0].SubStoreType == 'CENTRAL') {
                setAssetTypes([{ value: 'issue', asset_name: 'ISSUE' },
                { value: 'addItems', asset_name: 'ADD ITEMS' },
                { value: 'viewDetails', asset_name: 'VIEW DETAILS' }])
            }
            else {
                setAssetTypes([{ value: 'adjust', asset_name: 'ADJUST' },
                { value: 'consumble', asset_name: 'CONSUMED' },
                { value: 'issue', asset_name: 'ISSUE' },
                { value: 'requestItem', asset_name: 'REQUEST ITEM' },
                { value: 'viewDetails', asset_name: 'VIEW DETAILS' }])

            }
            setIsLoading(false);
            let result = await API_Auth.getConsumedAssetList(StorID);
            setAssets(result);
             setSearchData(result);
            var slice = result.slice(offset, offset + perPage);
            setPageCount(Math.ceil(result.length / perPage));
            setOrgTableData(result);
            setTableData(slice);
            setSearchData(slice);
        };
        // call the function
        fetchAsset()
            // make sure to catch any error
            .catch(console.error);
    }, []);
    const handlerowsperPage = (e) => {
        let showperpage = e.currentTarget.value
        setPerPage(parseInt(showperpage));
        setCurrentPage(0);
        setOffSet(0);
        if (searchKey == "y") {
            const slice = searchKeyTable.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(searchKeyTable.length / e.currentTarget.value))
            setTableData(slice);
            setSearchData(searchKeyTable);
        }
        else {
            const slice = orgtableData.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(orgtableData.length / e.currentTarget.value))
            setTableData(slice);
            setSearchData(orgtableData);
        }
    }

    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offsetdata = selectedPage * perPage;
        setCurrentPage(selectedPage);
        setOffSet(offsetdata);
        loadMoreData(offsetdata);
    }

    const loadMoreData = (offsetdata) => {
        const data = orgtableData;
        setOffSet(offsetdata);
        if (searchKey == "y") {
            const slice = searchKeyTable.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(searchKeyTable.length / perPage))
            setTableData(slice);
            setSearchData(searchKeyTable);
        } else {
            const slice = data.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(data.length / perPage))
            setTableData(slice);
            setSearchData(data);
                }

    }

    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            {isLoading && <LoadingSpinner />}
            <Container>
                <Row>
                    <Col lg={5}>
                        {storeDet.SubStoreType == 'CENTRAL' &&
                            <Title>Consumable Asset List</Title>
                        }
                        {storeDet.SubStoreType != 'CENTRAL' &&
                            <Title>Consumable Asset List</Title>
                        }
                    </Col>
                    <Col lg={4}>

                    </Col>
                    <Col lg={3}>
                        {storeDet.SubStoreType == 'CENTRAL' && <div className="rowdiv">
                            <button className="add_button" onClick={() => handleAddAsset()}>
{/*                                 <img src={plus} style={{ margin: 5 }} />
 */}                                <p className="pcat_title"> ADD ASSET</p></button>
                        </div>}
                    </Col>
                </Row>
                <Row>
                    <Col lg={5}>
                        <div className="rowdiv">
{/*                             <p className="title">Filter By:</p>
 */}                            <div className="input_div" >
                                <input type="type" style={{ margin: 0, background: 'transparent' }}
                                    name="filterValue"
                                    onChange={handleSearch}
                                    placeholder="Search" />
                                <AiOutlineSearch size={24} style={{ margin: 10 }} color="lightgray" />
                            </div>

                        </div></Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col lg={12} sm={12} md={12} className="table_head">
                        <Table className="tablelist">
                            <thead>
                                <tr>
                                    {/*                                     <th>Id</th>
 */}                                    <th> Name</th>
                                    <th>Quantity</th>
                                    <th>Re Order Level</th>
                                    <th>Red Alert Level</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            {tableData.length != 0 && <>
                                <tbody>
                                    {tableData.map((obj, index) => {
                                        return (
                                            <>
                                                <tr key={index}>
                                                    <td>{obj.AssetName}</td>
                                                    <td>{obj.Quantity}</td>
                                                    <td>{obj.Recordlvl}</td>
                                                    <td>{obj.RedAlertlvl}</td>
                                                    {storeDet.SubStoreType == 'CENTRAL' && <td>
                                                        <div>
                                                            <>
                                                                <OverlayTrigger overlay={
                                                                    <Tooltip id={`tooltip-top`}>ADD ITEMS</Tooltip>}>

                                                                    <AddSharpIcon size={24} style={{ marginLeft: 15, cursor: 'pointer', color: "#000044" }} onClick={() => handleGoTOItem(obj, 'addItems')} />

                                                                </OverlayTrigger>
                                                            </>
                                                            <>
                                                                <OverlayTrigger overlay={
                                                                    <Tooltip id={`tooltip-top`}>VIEW DETAILS</Tooltip>}>
                                                                    <img src={View} onClick={() => handleGoTOItem(obj, 'viewDetails')} style={{ width: 24, color: '#7483f0', height: 24, margin: 5, cursor: 'pointer' }} />
                                                                </OverlayTrigger>
                                                            </>
                                                            <>
                                                                <OverlayTrigger overlay={
                                                                    <Tooltip id={`tooltip-top`}>ISSUE</Tooltip>}>
                                                                    <img src={Issue} onClick={() => handleGoTOItem(obj, 'issue')} style={{ width: 24, color: '#7483f0', height: 24, margin: 5, cursor: 'pointer' }} />
                                                                </OverlayTrigger>
                                                            </>
                                                            <>
                                                                <OverlayTrigger overlay={
                                                                    <Tooltip id={`tooltip-top`}>ADJUST</Tooltip>}>
                                                                    <img src={Adjust} onClick={() => handleGoTOItem(obj, 'adjust')} style={{ width: 24, color: '#7483f0', height: 24, margin: 5, cursor: 'pointer' }} />
                                                                </OverlayTrigger>
                                                            </>
                                                            <>
                                                                <OverlayTrigger overlay={
                                                                    <Tooltip id={`tooltip-top`}>CONSUMED</Tooltip>}>
                                                                    <img src={Consumed} onClick={() => handleGoTOItem(obj, 'consumble')} style={{ width: 24, color: '#7483f0', height: 24, margin: 5, cursor: 'pointer' }} />
                                                                </OverlayTrigger>
                                                            </>
                                                        </div>
                                                    </td>
                                                    }
                                                    {storeDet.SubStoreType != 'CENTRAL' && <td>
                                                        <div>
                                                            <>
                                                                <OverlayTrigger overlay={
                                                                    <Tooltip id={`tooltip-top`}>ADJUST</Tooltip>}>
                                                                    <img src={Adjust} onClick={() => handleGoTOItem(obj, 'adjust')} style={{ width: 24, color: '#7483f0', height: 24, margin: 5, cursor: 'pointer' }} />
                                                                </OverlayTrigger>
                                                            </>
                                                            <>                                                                             <OverlayTrigger overlay={
                                                                <Tooltip id={`tooltip-top`}>VIEW DETAILS</Tooltip>}>
                                                                <img src={View} onClick={() => handleGoTOItem(obj, 'viewDetails')} style={{ width: 24, color: '#7483f0', height: 24, margin: 5, cursor: 'pointer' }} />
                                                            </OverlayTrigger>
                                                            </>
                                                            <>
                                                                <OverlayTrigger overlay={
                                                                    <Tooltip id={`tooltip-top`}>REQUEST ITEM</Tooltip>}>
                                                                    <img src={Request} onClick={() => handleGoTOItem(obj, 'requestItem')} style={{ width: 24, color: '#7483f0', height: 24, margin: 5, cursor: 'pointer' }} />
                                                                </OverlayTrigger>
                                                            </>
                                                            <>
                                                                <OverlayTrigger overlay={
                                                                    <Tooltip id={`tooltip-top`}>ISSUE</Tooltip>}>
                                                                    <img src={Issue} onClick={() => handleGoTOItem(obj, 'issue')} style={{ width: 24, color: '#7483f0', height: 24, margin: 5, cursor: 'pointer' }} />
                                                                </OverlayTrigger>
                                                            </>
                                                            <>
                                                                <OverlayTrigger overlay={
                                                                    <Tooltip id={`tooltip-top`}>CONSUMED</Tooltip>}>
                                                                    <img src={Consumed} onClick={() => handleGoTOItem(obj, 'consumble')} style={{ width: 24, color: '#7483f0', height: 24, margin: 5, cursor: 'pointer' }} />
                                                                </OverlayTrigger>
                                                            </>
                                                        </div>
                                                    </td>}
                                                </tr>
                                            </>
                                        );
                                    })}
                                </tbody>
                            </>
                            }
                            {tableData.length == 0 &&
                                <tbody>
                                    <tr>
                                        <td colSpan="12" >
                                            <p style={{ textAlign: 'center', fontFamily: 'Robot', fontWeight: 'bold' }}>No Data Found </p>
                                        </td>
                                    </tr>
                                </tbody>
                            }
                        </Table>
                        {tableData.length != 0 &&
                            <div>
                                <div className="pagination_row_div">
                                    <p className="pagination_title">Showing items per page:</p>
                                    <div className="pagination_input_div" >
                                        <select className="pagination_select"
                                            style={{ margin: 0, background: 'transparent' }}
                                            name="pagination_value"
                                            value={perPage}
                                            onChange={handlerowsperPage} >
                                            <option value={4}>4</option>
                                            <option value={8}>8</option>
                                            <option value={10}>10</option>
                                            <option value={20}>20</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="pagination_row_right_div">
                        <p className="pagination_total">Showing {offset+1} to {searchData.length<offset+perPage&&
                        <span>{searchData.length}</span> }
                        {searchData.length>offset+perPage&&
                        <span>{offset+perPage }</span>} of {searchData.length} items</p>
                                <ReactPaginate
                                    previousLabel={<ArrowBackIosIcon className="pagination_prev_btn" color="#2438a7" />}
                                    nextLabel={<ArrowForwardIosIcon className="pagination_next_btn" color="#2438a7" />}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"}
                                    forcePage={currentPage} />
                            </div>
                            </div>
                        }
                    </Col>
                </Row>
            </Container>
            <ToastContainer />
        </Wrapper>
    )
}
export default CentralStoreConsumableAsset