import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context"; import { Container, Row, Col } from "react-bootstrap";
import { Wrapper } from "./styles.js"
import plus from "../../images/white_plus.png"
import API_Auth from "../../API_Auth";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Title } from "../../GlobalStyle.js"
import Modal from 'react-bootstrap/Modal';
import { AiOutlinePlusSquare, AiOutlinePlus, AiOutlineSearch } from "react-icons/ai";
import { IoIosArrowDown, IoIosArrowUp, IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Table from "react-bootstrap/Table";



const FixedAddItem = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {
        isExpanded,
    } = useContext(UserContext);
    let { storeID, assetID, itemID } = useParams();

    const [vendorName, setVendorName] = useState('');
    const [email, setEmail] = useState('');
    const [contact, setContact] = useState('');

    const [vendorNameErr, setVendorNameErr] = useState('');
    const [emailErr, setEmailErr] = useState('');
    const [contactErr, setContactErr] = useState('')

    const [formTitle, setFormTitle] = useState('Add Item');
    const [asset, setAsset] = useState(assetID);
    const [vendor, setVendor] = useState('');
    const [price, setPrice] = useState('');
    const [qunatity, setQunaity] = useState('');
    const [mfd, setMfd] = useState('');
    const [expirydate, setExpiryDate] = useState('')
    const [tax, setTax] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [discountErr, setDiscountErr] = useState('');


    const [comments, setComments] = useState('')
    const [formButtonValue, setFormButtonValue] = useState('ADD ALL ITEMS ');
    const [assetErr, setAssetErr] = useState('')
    const [vendorErr, setVendorErr] = useState('')
    const [priceErr, setPriceErr] = useState('')
    const [taxErr, setTaxErr] = useState('')

    const [qunatityErr, setQunatityErr] = useState('')
    const [mfdErr, setMfdErr] = useState('')
    const [expirydateErr, setExpiryDateErr] = useState('')
    const [commentsErr, setCommentsErr] = useState('');
    const [batchNo, setBatchNo] = useState('');
    const [batchErr, setBatchErr] = useState('')
    const [assets, setAssets] = useState([])
    const [vendors, setVendors] = useState([])
    const [show, setShow] = useState(false);

    const [billNo, setBillNo] = useState('');
    const [billNoErr, setBillNoErr] = useState('');
    const [billDate, setBillDate] = useState('');
    const [billDateErr, setBillDateErr] = useState('');
    const [make, setMake] = useState('');
    const [makeErr, setMakeErr] = useState('');
    const [model, setModel] = useState('');
    const [modelErr, setModelErr] = useState('');
    const [serialNo, setSerialNo] = useState('');
    const [serialNoErr, setSerialNoErr] = useState('');
    const [itemsuggestions, setItemSuggestions] = useState('');
    const [vendorsuggestions, setVendorSuggestions] = useState('');
    const [assetText, setAssetText] = useState('');
    const [vendorText, setVendorText] = useState('');
    const [vendorId, setVendorId] = useState('');
    const [address, setAddress] = useState('');
    const [addressErr, setAddressErr] = useState('');
    const [unitprice, setUnitPrice] = useState('');
    const [unitPriceErr, setUnitPriceErr] = useState('')
    const [itemText, setItemText] = useState('a');
    const [VendorSelectText, setVendorSelectText] = useState('a')
    const [vendorKey, setVendorKey] = useState('n');
    const [totalArrayAsset, setTotalArrayAsset] = useState([]);
    const [duplicateAsset, setDuplicateAsset] = useState(false);
    const [showAssetList, setShowAssetList] = useState(false);
    const [UOM, setUOM] = useState('');
    const [UOMErr, setUOMErr] = useState('');
    const [voucher, setVoucher] = useState('');
    const [voucherErr, setvoucherErr] = useState('');
    const [subtotal, setSubTotal] = useState('');

    const [disableSubmit, setDisableSubmit] = useState(false);



    const handleClose = () => setShow(false);
    const handleCloseAssetList = () => setShowAssetList(false)


    const handleInput = (e) => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;

        if (name === "asset") {
            if (value.length == 0) {
                setItemSuggestions(assets);
                setAssetText('');
            } else {
                const filterValue = value;

                setAssetText(value);
                const suggestdta = assets.filter(item => {
                    return item ?.AssetName ?.toLowerCase().includes(filterValue.toLowerCase())
                    
                    });
                setItemText('f')

                if (suggestdta.length == 0) {
                    setAssetErr('Asset not found');


                } else {
                    setAssetErr('')
                }
                setItemSuggestions(suggestdta);

            }

        };
        if (name === "vendor") {
            //setVendor(value)
            if (value.length == 0) {
                setVendorSuggestions(vendors);
                setVendorText('');
            } else {
                const filterValue = value;

                setVendorText(value);
                const suggestdta = vendors.filter(item => {
                    return item ?.VendorName ?.toLowerCase().includes(filterValue.toLowerCase())
                    
                    });
                setVendorSelectText('f')

                if (suggestdta.length == 0) {
                    setVendorErr('Vendor not found');
                    setVendorKey('y');



                } else {
                    setVendorErr('');
                    setVendorKey('n');

                }
                setVendorSuggestions(suggestdta);
            }
        };
        if (name === "qunatity") {

            setQunaity(value)
            /*  if (unitprice != '') {
                 var d = unitprice * value;
                 setPrice(d);
             }
             if (tax != '') {
                 let data = tax * (unitprice * value / 100);
                 let totalprice = unitprice * value + data;
                 setPrice(totalprice)
             } else {
                 setPrice(d);
             } */

            if (unitprice != '') {
                let d = discount * Math.round(value * unitprice / 100);
                var discountprice =  Math.round((value * unitprice) - d);
                setSubTotal(discountprice)
                let data = tax *  Math.round(discountprice / 100);
                var totalprice =  Math.round((discountprice) + data);
                setPrice(totalprice);
            }

        };
        if (name === "unitprice") {

            setUnitPrice(value)
            /* let d = qunatity * value;

            if (tax != '') {
                let d = tax * (qunatity * value / 100);
                console.log(d);
                let totalprice = qunatity * value + d;
                setPrice(totalprice)
            } else {
                setPrice(d);
            } */

            if (qunatity != '') {
                let d = discount *  Math.round(value * qunatity / 100);
                var discountprice =  Math.round((value * qunatity) - d);
                setSubTotal(discountprice)

                let data = tax * Math.round (discountprice / 100);
                var totalprice = Math.round ((discountprice) + data);
                setPrice(totalprice);
            }


        }
        if (name === "price") {


            let d = discount *  Math.round(unitprice * qunatity / 100);
            var discountprice =  Math.round((unitprice * qunatity) - d);
            setSubTotal(discountprice)

            let data = tax *  Math.round(discountprice / 100);
            var totalprice = Math.round ((discountprice) + data);
            setPrice(totalprice);
            /*  if (tax != '') {
                 let d = tax * (qunatity * unitprice / 100);
                 console.log(d);
                 let totalprice = qunatity * unitprice + d;
                 console.log("totalprice")
                 setPrice(totalprice)
             } else {
                 setPrice(qunatity * unitprice);
             } */

        };

        if (name === "tax") {
            /*    let d = value * (qunatity * unitprice / 100);
               console.log(d);
               let totalprice = (qunatity * unitprice) + d; */

            /*   let d = value * (subtotal / 100);
              console.log(d);
              var totalprice = ((subtotal) + d);
              setTax(value)
              setPrice(totalprice); */

            let d = discount *  Math.round(unitprice * qunatity / 100);
            var discountprice =  Math.round((unitprice * qunatity) - d);
            setSubTotal(discountprice)

            let data = value *  Math.round(discountprice / 100);
            var totalprice =  Math.round((discountprice) + data);
            setPrice(totalprice);
            setTax(value);
        };

        if (name === "discount") {

            /*    console.log(name, value)
               let d = value * (qunatity * unitprice / 100);
               console.log(d);
               var discountprice = ((qunatity * unitprice) - d);
               setSubTotal(discountprice) */
            setDiscount(value);
            let d = value *  Math.round(unitprice * qunatity / 100);
            var discountprice =  Math.round((unitprice * qunatity) - d);
            setSubTotal(discountprice)

            let data = tax *  Math.round(discountprice / 100);
            var totalprice =  Math.round((discountprice) + data);
            setPrice(totalprice);

        };

        if (name === "subtotal") {
            setSubTotal(value);



        }

        if (name === "mfd") setMfd(value);
        if (name === "expirydate") setExpiryDate(value);
        if (name === "comments") setComments(value);
        if (name === "batchNo") setBatchNo(value);
        if (name === "vendorName") setVendorName(value);
        if (name === "email") setEmail(value);
        if (name === "contact") setContact(value);
        if (name === "billNo") setBillNo(value);
        if (name === "billDate") setBillDate(value);
        if (name === "make") setMake(value);
        if (name === "model") setModel(value);
        if (name === "serialNo") setSerialNo(value);
        if (name === "address") setAddress(value);
        if (name === "UOM") setUOM(value);
        if (name === "voucher") setVoucher(value);




    };
    const handleAddVendorPopup = async () => {
        setShow(true);
    }
    const handleTextChange = async (item) => {
      /*  let asset_det = await API_Auth.getConsumeAssetQuantity(StorID, item.id);
        console.log("asset_det", asset_det)
         let x = asset_det.length != 0 ? asset_det[0].UOM : ''
        setUOM(x); */
        setUOM(item.UOM)
        if (totalArrayAsset.length != 0) {
            for (var i = 0; i < totalArrayAsset.length; i++) {
                if (totalArrayAsset[i].asset === item.id) {
                    setAssetErr('Please Select Another Item ..Item is already added');
                    setAssetText(item.AssetName);
                    setDuplicateAsset(true);
                    break;



                } else {
                   // console.log("hello not equal");


                    setAssetText(item.AssetName);
                    setAsset(item.id);
                    setDuplicateAsset(false);



                }
            }
        } else {
            setAssetText(item.AssetName);
            setAsset(item.id)
        }
        //setAssetText(item.AssetName);
        //setAsset(item.id)
        setItemText('a')

        // setItemSuggestions([]);
    }

    const handleVendorTextChange = (item) => {
        setVendorText(item.VendorName);
        //setAsset(item.id);
        setVendorId(item.vendorId)
        // setVendorSuggestions([]);
        setVendorSelectText('a')


    }
    const renderSuggestions = () => {
        if (itemsuggestions.length == 0) {
            return null;
        }
        return (
            <ul className={itemsuggestions.length != 0 && itemText == 'f' ? "ul_search" : "ul_search_length"} >
                {itemsuggestions.length != 0 && itemText == 'f' &&
                    <>

                        {itemsuggestions.map((item, index) => {
                            return (
                                <li className="li_search" onClick={() => handleTextChange(item)}>{item.AssetName}</li>
                            )
                        })}
                    </>
                }
            </ul>
        )
    }
    const vendorSuggestions = () => {
        if (vendorsuggestions.length == 0) {
            return null;
        }
        return (
            <ul className={vendorsuggestions.length != 0 && VendorSelectText == 'f' ? "ul_search" : "ul_search_length"} >
                {vendorsuggestions.length != 0 && VendorSelectText == 'f' &&
                    <>
                        {vendorsuggestions.map((item, index) => {
                            return (
                                <li className="li_search" onClick={() => handleVendorTextChange(item)}>{item.VendorName}</li>
                            )
                        })}
                    </>
                }
            </ul>
        )
    }
    const handleAddVendor = async () => {
        let error = false;
        if (vendorName === '') {
            setVendorNameErr('This field is required');
            error = true;
        }
       /*  if (email === '') {
            setEmailErr('This field is required');
            error = true;
        } */
        if (contact === '') {
            setContactErr('This field is required');
            error = true;
        }
        if (address === '') {
            setAddressErr('This field is required');
            error = true;
        }
        else {
            setAddressErr('');
        }
        if (!error) {
            setVendorNameErr('');
            setEmailErr('');
            setContactErr('');;
            setAddressErr('');

            let result = await API_Auth.addVendor(vendorName, contact, email,address);
            let res = result[0];
            if (res.Flag == 'Success') {
                toast.success('Vendor added successfully');
                setTimeout(() => {
                    setShow(false);
                    setVendorName('')
                    setEmail('')
                    setContact('')
                }, 2000);
                let vendorList = await API_Auth.getVendors();
                setVendors(vendorList);
            } else {
                toast.error('Something went wrong please try again');
            }
        }
    }
    const handleAddAsset = async () => {
        setDisableSubmit(true);


        setAssetErr('')
        setBatchErr('')
        setCommentsErr('')
        setExpiryDateErr('')
        setMfdErr('')
        setPriceErr('')
        setTaxErr('')
        setQunatityErr('')
        setVendorErr('')
        setvoucherErr('')
        let error = false;
        var totalErrorArray = []
        if (!error) {
            if (totalArrayAsset.length == 0) {
              toast.error("Please add rows")
            }

            else {

                totalArrayAsset.map(async function (app, key) {
                    let result = await API_Auth.addAssetInCentralConsume(app.asset, app.storeID, app.mfd, app.expirydate, app.vendorText, app.price, app.tax, app.comments, app.batchNo, app.qunatity, app.billNo, app.billDate, app.make, app.model, app.serialNo, app.voucher,"Fixed");
                    let res = result[0]
                    if (res.Flag == 'Success') {

                    } else {
                        totalErrorArray.push(key);
                    }
                });
                if (totalErrorArray.length === 0) {
                    toast.success('Item added successfully');
                    setTimeout(() => {
                        navigate("/assets-management/" + storeID);

                    }, 2000)

                } else {
                    setDisableSubmit(true);
                    toast.error('Something went Wrong');
                }
            }
        }else{
            setDisableSubmit(false);

        }
    }
    const handleBackClick = () => {
        navigate(-1);
    }
    useEffect(() => {
        setAsset(assetID);
        if (assetID == "add" && itemID == "add") {
            setFormButtonValue("ADD ALL ITEMS ");
            setFormTitle("Add ITEM");
        } else if (assetID != "add" && itemID == "add") {
            setFormButtonValue("ADD ALL ITEMS ");
            setFormTitle("Add Item");


        } else {

        }
        if (assetID == "add" && itemID == "add") {
            setFormButtonValue("ADD ALL ITEMS ");
            setFormTitle("Add Item");
        } else if (assetID != "add" && itemID == "add") {
            setFormButtonValue("ADD ALL ITEMS ");
            setFormTitle("Add Item");


        } else {

        }
        const fetchAsset = async () => {
            let result = await API_Auth.getAsset();
            //  setAssets(result);
            if (assetID != "add" && itemID == "add") {
                const suggestdta = result.filter(item => {
                    return item ?.id == assetID
                
                });
                setItemSuggestions(suggestdta)
            } else if (assetID != "add" && itemID != "add") {
                const suggestdta = result.filter(item => {
                    return item ?.id == assetID
                
                });
                setItemSuggestions(suggestdta)
            } else {

            }

            const filteredData = result.filter(item => {
                return (item.AssetType.toLowerCase().includes("fixed"))

            });
            setAssets(filteredData);
            for (var i = 0; i < result.length; i++) {
                if (result[i].id == assetID) {
                    setAssetText(result[i].AssetName);
                    setUOM(result[i].UOM)
                }
            }
            let vendorList = await API_Auth.getVendors();
            setVendors(vendorList);

            // call the function
        };
        fetchAsset()
            // make sure to catch any error
            .catch(console.error);
    }, []);
    const addInputField = () => {
        setAssetErr('')
        setBatchErr('')
        setCommentsErr('')
        setExpiryDateErr('')
        setMfdErr('')
        setPriceErr('')
        setTaxErr('')
        setQunatityErr('');
        setvoucherErr('');
        setVendorErr('')
        let error = false;
        if (assetText == "") {
            setAssetErr('This field is required');
            error = true;
        }
        else if (itemsuggestions.length == 0) {
            setAssetErr('Asset Not Found');
            error = true;
        }
        else {
            setAssetErr('');
        }
        if (duplicateAsset) {
            setAssetErr('Please Select Another Item ..Item is already added');
            error = true
        }
        /*  if (voucher == "") {
             setvoucherErr('This field is required');
             error = true
         } */
        if (vendorText == '') {
            setVendorErr('This field is required');
            error = true;
        }

        else if (vendorsuggestions.length == 0) {
            setVendorErr('Vendor Not Found');
            error = true;
            setVendorKey('y')


        }

        else {
            setVendorErr('');
        }
        /*   if (tax == '') {
              setTaxErr('This field is required');
              error = true;
          } */
        if (qunatity == '') {
            setQunatityErr('This field is required');
            error = true;
        }

        /*   if (batchNo == "") {
              setBatchErr('This field is required');
              error = true;
          } */
        if (billNo == '') {
            setBillNoErr('This field is required');
            error = true;
        }
        else {
            setBillNoErr('');
        }
        if (billDate == '') {
            setBillDateErr('This field is required');
            error = true;
        }
        else {
            setBillDateErr('');
        }

        /* if (make == '') {
            setMakeErr('This field is required');
            error = true;
        }
        else {
            setMakeErr('');
        }
 */
        if (unitprice == '') {
            setUnitPriceErr('This field is required');
            error = true;
        }
        else {
            setUnitPriceErr('');
        }
        if (!error) {
            let data = {
                asset: asset,
                storeID: storeID,
                mfd: mfd,
                expirydate: expirydate,
                vendorText: vendorText,
                price: price,
                tax: tax,
                comments: comments,
                batchNo: batchNo,
                qunatity: qunatity,
                billNo: billNo,
                billDate: billDate,
                make: make,
                model: model,
                serialNo: serialNo,
                discount: discount,
                assetText: assetText
            }
            let array = totalArrayAsset
            array.push(data);
            setAsset(assetID);
            if (assetID == '') { setAssetText('') };
            setAsset('');
            setMfd('');
            setExpiryDate('');
            setAssetText('')
           // setVendorText('');
            setUnitPrice('');
            setPrice('');
            setTax(0);
            setComments('');
            setBatchNo('');
            setQunaity('');
            setUOM('');
            setDiscount(0);
            //setBillNo('');
            //setBillDate('');
            setMake('');
            setModel('');
            setSerialNo('');
            setTotalArrayAsset(array);
        }


    }
    const handleViewItems = () => {
        setShowAssetList(true)

    }
    const handleRemove = (obj, index) => {
        var setValue = totalArrayAsset;
        const dataRemoved = setValue.filter((el) => {
            return el.asset !== obj.asset;
        });
        setTotalArrayAsset(dataRemoved)
    }

    const handleme = async (obj, itemindex, e) => {
        let checked = e.currentTarget.checked;
        if (checked) {
            totalArrayAsset.map(function (item, index) {
                if (item.asset === obj.asset)
                totalArrayAsset[itemindex]['selected'] = true;
            });
        } else {
            totalArrayAsset.map(function (item, index) {
                if (item.asset === obj.asset);
                totalArrayAsset[itemindex]['selected'] = false;
            });
        }
        setTotalArrayAsset(totalArrayAsset);
    }

    const handleUpdateItems = () => {
        setShowAssetList(false)
    }
    const handleCalculate = () => {
        let d = discount * (qunatity * unitprice / 100);
        var discountprice = ((qunatity * unitprice) - d);
        setSubTotal(discountprice)
        let data = tax * (discountprice / 100);
        var totalprice = ((discountprice) + data);
        setPrice(totalprice);
    }
    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            <Container>
                <Row className="row_title">
                    <Col lg={4}><p>{formTitle}</p></Col>
                    <Col lg={6}></Col>
                    <Col lg={2}>
                        <div className="back_button" onClick={() => handleBackClick()}>
                            <IoIosArrowBack size={18} />
                            <h6>Back</h6>
                        </div>
                    </Col>
                </Row>
            </Container>
            <hr />
            <Container>
                <Row className="row_title">
                    <Col lg={9}>
                        {/*   <div className="row_table_div " onClick={() => addInputField()} style={{ width: 150, fontWeight: 'bold', float: 'left' }}>
                            <p style={{marginLeft:20,fontSize:14}}>ADD MORE ROWS</p>
                        </div> */}
                    </Col>


                    {/*   <Col lg={3} style={{ marginTop: -8 }}>
                        <div className="view_button" onClick={() => handleViewItems()}>
                            <h6>VIEW ALL ROWS-</h6>
                            <p >({totalArrayAsset.length})</p>
                        </div>
                    </Col> */}
                </Row>
                <Row>
                    <Col >
                        <div className="inputdiv">
                            <label>Bill No</label>
                            <br />
                            <input type="text"
                                name="billNo"
                                value={billNo}
                                placeholder=""
                                onChange={handleInput} />
                            {billNoErr != '' && <p className="alert-message">{billNoErr}</p>}

                        </div>
                    </Col>
                    <Col>
                        <div className="inputdiv">
                            <label>Bill Date</label>
                            <br />
                            <input type="date"
                                name="billDate"
                                value={billDate}
                                placeholder="dd/mm/yyyy"

                                onChange={handleInput} />
                            {billDateErr != '' && <p className="alert-message">{billDateErr}</p>}

                        </div>

                    </Col>
                    <Col>
                                <div className="inputdiv">
                                    <div style={{ flexDirection: 'row', display: 'flex' }}>
                                        <label >Vendor Details</label>
                                        <p style={{ textDecoration: 'underline', marginRight: 5, color: '#000044',marginLeft:10,fontWeight:600,fontSize:13 }} onClick={() => handleAddVendorPopup()}>
                                            Add Vendor</p>
                                    </div>
                                    <br />
                                    <div className="row_div_search" style={{marginTop:-39}}>
                                        <div className="input_div" >
                                            <input type="type" style={{ margin: 0, background: 'transparent' }}
                                                name="vendor"
                                                value={vendorText}
                                                onChange={handleInput}
                                                placeholder="Search Vendor" />
                                            <AiOutlineSearch size={24} style={{ margin: 10, cursor: 'pointer' }} color="lightgray" />
                                        </div>

                                    </div>
                                    {vendorSuggestions()}
                                    <br />
                                    {vendorErr != '' && <p className="alert-message">{vendorErr}</p>}

                                    {/*                                     <p className="add_vendor_btn"><button onClick={() => handleAddVendorPopup()} className="add_button">Add Vendor</button></p>
 */}
                                  {/*   {vendorKey == "y" && <p className="add_vendor_btn"><a onClick={() => handleAddVendorPopup()} >
                                        <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'end' }}>
                                            <p style={{ textDecoration: 'underline', marginRight: 5, color: '#000044' }}>Click here to</p>
                                            <p>Add Vendor</p>
                                        </div>



                                    </a></p>
                                    } */}
                                </div>
                            </Col>
                </Row>

                <Table className="tablelist">
                    <thead>
                        <tr>
                            <th> Item </th>
                            <th>Quantity</th>
                            <th>Discount %</th>
                            <th>Tax %</th>
                            <th>Total Price</th>
                            <th>Batch No</th>

                            <th>Action</th>



                        </tr>
                    </thead>
                    {totalArrayAsset.length == 0 && <tbody>
                        <tr >
                            <td colSpan="12">
                                <p className="no_Data_table">No Rows Added</p>
                            </td>
                        </tr>
                    </tbody>
                    }
                    {totalArrayAsset.length != 0 && <tbody>
                        {totalArrayAsset.map((obj, index) => {
                            return (
                                <>
                                    <tr key={index}>

                                        <td>{obj.assetText}  </td>
                                        <td>{obj.qunatity}</td>
                                        <td>{obj.discount}</td>
                                        <td>{obj.tax}</td>
                                        <td>{obj.price}</td>
                                        <td>{obj.batchNo}</td>

                                        <td>
                                            <div onClick={() => handleRemove(obj, index)}>
                                                <p style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}>Remove</p>
                                            </div>
                                        </td>



                                    </tr>
                                </>
                            );
                        })}
                    </tbody>
                    }
                </Table>

                  <Row>
                <Col lg={9}></Col>

                    <Col lg={3}>

                      {/*   <div className="row_div">
                            <button className="add_button" onClick={() => handleAddAsset()}>
                                <p className="pcat_title">{formButtonValue}</p></button>
                        </div> */}

                            <button className="row_table_divs " disabled={disableSubmit} onClick={() => handleAddAsset()} style={{ width: 150, margin: 15, fontWeight: 'bold', float: 'left' }}>
                                    <p style={{ marginLeft: 30 }}>{formButtonValue}</p>
                                </button>
                    </Col>
                </Row>

                <Row style={{ background: 'aliceblue', margin: 5, borderRadius: 6, border: "1px solid black" }}>
                    <Col lg={12}>
                        <Row>
                            <Col>
                                <div className="inputdiv">
                                    <label style={{ marginTop: 10 }}>Item</label>
                                    <br />
                                    <div className="row_div_search">
                                        <div className="input_div" >
                                            <input type="type" style={{ margin: 0, background: 'transparent' }}
                                                name="asset"
                                                value={assetText}
                                                onChange={handleInput}
                                                placeholder="Search Item" />
                                            <AiOutlineSearch size={24} style={{ margin: 10, cursor: 'pointer' }} color="lightgray" />
                                        </div>

                                    </div>
                                    {renderSuggestions()}

                                    {assetErr != '' && <p className="alert-message">{assetErr}</p>}


                                </div></Col>
                            <Col>
                                <div className="inputdiv">
                                    <label style={{marginTop:10}}>UOM</label>
                                    <br />
                                    <input type="text"
                                        name="UOM"
                                        value={UOM}
                                        placeholder="Select UOM"
                                        onChange={handleInput} />
                                    {UOMErr != '' && <p className="alert-message">{UOMErr}</p>}

                                </div>
                            </Col>
                           
                            <Col>
                                <div className="inputdiv">
                                    <label style={{marginTop:10}}>Make</label>
                                    <br />
                                    <input type="text" style={{ width: "90%" }}
                                        name="make"
                                        value={make}
                                        placeholder="Enter make"
                                        onChange={handleInput} />
                                    {makeErr != '' && <p className="alert-message">{makeErr}</p>}

                                </div>
                            </Col>
                        </Row>
                        {/*  Second Row */}
                        <Row>
                            <Col>
                                <div className="inputdiv">
                                    <label>Batch No</label>
                                    <br />
                                    <input type="text"
                                        name="batchNo"
                                        value={batchNo}
                                        placeholder="Enter Batch No"
                                        onChange={handleInput} />
                                    {batchErr != '' && <p className="alert-message">{batchErr}</p>}
                                </div>
                            </Col>
                            <Col>
                                <div className="inputdiv">
                                    <label>MFD Date</label>
                                    <br />
                                    <input type="date"
                                        name="mfd"
                                        value={mfd}
                                        placeholder="MFD DATE"
                                        onChange={handleInput} />
                                    {mfdErr != '' && <p className="alert-message">{mfdErr}</p>}

                                </div>
                            </Col>
                            <Col>
                                <div className="inputdiv">
                                    <label>Expiry Date</label>
                                    <br />
                                    <input type="date" style={{ width: "90%" }}
                                        name="expirydate"
                                        value={expirydate}
                                        placeholder="dd/mm/yyyy"
                                        onChange={handleInput} />
                                    {expirydateErr != '' && <p className="alert-message">{expirydateErr}</p>}

                                </div>
                            </Col>


                        </Row>
                        <Row>
                            <Col>
                                <div className="inputdiv">
                                    <label>Quantity</label>

                                    <input type="text" min="0"
                                        name="qunatity"
                                        value={qunatity}
                                        placeholder="Quantity"

                                        onChange={handleInput} />
                                    {qunatityErr != '' && <p className="alert-message">{qunatityErr}</p>}

                                </div>
                            </Col>
                            <Col >
                                <div className="inputdiv">
                                    <label>Unit Price</label>

                                    <input type="text" min="0"
                                        name="unitprice"
                                        value={unitprice}
                                        placeholder="Unit Price"
                                        onChange={handleInput} />
                                    {unitPriceErr != '' && <p className="alert-message">{unitPriceErr}</p>}

                                </div>
                            </Col>
                            <Col>
                                <div className="inputdiv">
                                    <label>Discount %</label>

                                    <input type="text" min="0"
                                        name="discount"
                                        value={discount}
                                        placeholder="Discount %"
                                        onChange={handleInput} />
                                    {discountErr != '' && <p className="alert-message">{discountErr}</p>}
                                </div>
                            </Col>
                            {/* <Col>
                                <div className="inputdiv">
                                    <input type="text" min="0"
                                        name="subtotal"
                                        value={subtotal}
                                        placeholder="Sub Total"
                                        disabled={subtotal != '' ? true : false}

                                        onChange={handleInput} />
                                </div>
                            </Col> */}
                            <Col>
                                <div className="inputdiv">
                                    <label>Tax %</label>

                                    <input type="text" min="0"
                                        name="tax"
                                        value={tax}
                                        placeholder="Tax %"

                                        onChange={handleInput} />
                                    {taxErr != '' && <p className="alert-message">{taxErr}</p>}
                                </div>
                            </Col>
                            <Col>
                                <div className="inputdiv">
                                    <label>Total Price </label>

                                    <input type="text" min="0" style={{ width: "90%" }}
                                        name="price"
                                        value={price}
                                        placeholder="Total Price"
                                        disabled={price != '' ? true : false}

                                        onChange={handleInput} />
                                    {priceErr != '' && <p className="alert-message">{priceErr}</p>}

                                </div>

                            </Col>
                            {/*    <Col>
                                <div onClick={() => handleCalculate()} >
                                    <FcRefresh size={32} style={{ marginTop: 20, marginLeft: 10 }} />

                                </div>
                            </Col> */}

                        </Row>
                        
                        <Row>
                            <Col lg={10}></Col>
                            <Col lg={2}>
                                <div className="row_table_div " onClick={() => addInputField()} style={{ width: 120, margin: 15, fontWeight: 'bold', float: 'left' }}>
                                    <p style={{ marginLeft: 30 }}>ADD  ROW</p>
                                </div></Col>
                        </Row>




                    </Col>
                </Row>
              
                <Modal show={show} onHide={handleClose}
                    aria-labelledby="contained-modal-title-vcenter"
                    dialogclassname="modal-content">
                    <Modal.Header closeButton>
                        <Modal.Title>Add Vendor</Modal.Title>
                    </Modal.Header>

                    <Modal.Body >
                        <div>
                            <label>Vendor Name</label>
                            <br />
                            <input
                                type="text"
                                name="vendorName"
                                value={vendorName}
                                placeholder="Vendor Name"
                                onChange={handleInput} />
                            {vendorNameErr != '' && <p className="alert-message">{vendorNameErr}</p>}
                        </div>
                        <div>
                            <label>Phone No</label>
                            <br />
                            <input type="text"
                                name="contact"
                                value={contact}
                                placeholder="Enter Contact No"
                                onChange={handleInput} />
                            {contactErr != '' && <p className="alert-message">{contactErr}</p>}
                        </div>
                        <div>
                            <label>Email Id</label>
                            <br />
                            <input type="email"
                                name="email"
                                value={email}
                                placeholder="Enter Email "
                                onChange={handleInput} />
                            {emailErr != '' && <p className="alert-message">{emailErr}</p>}
                        </div>
                        <div>
                            <label>Address</label>
                            <br />
                            <textarea
                                    type="text"
                                        name="address"
                                        value={address}
                                        placeholder="Enter Address"
                                        rows={5}
                                        style={{ height: 100, width:"85%"}}
                                        cols={5}
                                        onChange={handleInput} />
                            {addressErr != '' && <p className="alert-message">{addressErr}</p>}
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="modal-footer">
                        <div className="row_div">
                            <button className="add_button" onClick={() => handleAddVendor()}>
                                {/*                                 <img src={plus} style={{ margin: 5 }} />
 */}                                <p className="pcat_title">ADD VENDOR</p></button>
                        </div>
                    </Modal.Footer>
                </Modal>

                <Modal show={showAssetList} onHide={handleCloseAssetList}
                    aria-labelledby="contained-modal-title-vcenter"
                    dialogclassname="modal-content">
                    <Modal.Header closeButton>
                        <Modal.Title>Items</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>

                        <Table className="tablelist">
                            <thead>
                                <tr>
                                    <th> Item </th>
                                    <th>Qunatity</th>
                                    <th>Tax</th>
                                    <th>Price</th>
                                    <th>Batch No</th>
                                    <th>Bill No</th>
                                    <th>Bill Date</th>
                                    <th>Action</th>



                                </tr>
                            </thead>
                            {totalArrayAsset.length == 0 && <tbody>
                                <tr >
                                    <td colSpan="12">
                                        <p className="no_Data_table">No Data Found</p>
                                    </td>
                                </tr>
                            </tbody>
                            }
                            {totalArrayAsset.length != 0 && <tbody>
                                {totalArrayAsset.map((obj, index) => {
                                    return (
                                        <>
                                            <tr key={index}>

                                                <td>{obj.asset}  </td>
                                                <td>{obj.qunatity}</td>
                                                <td>{obj.tax}</td>
                                                <td>{obj.price}</td>
                                                <td>{obj.batchNo}</td>
                                                <td>{obj.billNo}</td>
                                                <td>{obj.billDate}</td>
                                                <td>
                                                    <div onClick={() => handleRemove(obj, index)}>
                                                        <p style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}>Remove</p>
                                                    </div>
                                                </td>



                                            </tr>
                                        </>
                                    );
                                })}
                            </tbody>
                            }
                        </Table>

                    </Modal.Body>
                    {/*    <Modal.Footer className="modal-footer">
                        <div className="row_div">
                            <button className="add_button" onClick={() => handleUpdateItems()}>
                                <p className="pcat_title">Update Items</p></button>
                        </div>
                    </Modal.Footer> */}
                </Modal>
            </Container>
            <ToastContainer />
        </Wrapper>
    )
}
export default FixedAddItem