import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation, } from "react-router-dom";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Collapse from "@mui/material/Collapse";
import { hasChildren } from "../../utils";
import sendreq from "../../images/sendreq.png";
import request from "../../images/request.png";
import receive from "../../images/receive.png";
import requestmng from "../../images/requestmng.png";
import assetmng from "../../images/assetmng.png";
import dashboard from "../../images/dashboard.png";
import approvalmang from "../../images/approvalmang.png"
import { AiOutlineMenu } from "react-icons/ai";
import Logo from "../../images/uklogo.png";
import pending from "../../images/pending.png";
import completed from "../../images/Completed.png";
import assetconsumption from "../../images/assetconsumption.png"
import received from "../../images/icons8-received-96.png";
import adjust from "../../images/icons8-adjust-96.png";

import transaction from "../../images/icons8-transaction-64.png";
import API_Auth from "../../API_Auth";
import money from  "../../images/money-sack.png";




// Styles
import { Wrapper } from "./styles";

// Context
import adminSidedata from "../../_nav";
import managmentSidedata from "../../_navmngmnt";
import stockData from "../../navstock";

import { UserContext } from "../../context";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";


const Sidebar = () => {
  let logged_role, logged_user;
  const location = useLocation();
  const [sidebar, setSidebar] = useState([]);
  const [totalArray, setTotalArray] = useState('');
  const [userRole, setUserRole] = useState('');
  const [itemSelect, setItemSelect] = useState('');
  const [show, setShown] = useState(false);
  const [id, setId] = useState('n');
  const [sideMenuData, setSideMenuData] = useState([])
  // get token and user from context
  const navigate = useNavigate();
  const [storeDet, setStoreDet] = useState([]);
  const [storeName, setStoreName] = useState('');
  const[keyLabel,setKeyLabel]=useState('');





  const { isExpanded, sessionLoggedUser, sessionLoggedRole, setTitle, setMainStoreId, setIsExpanded } = useContext(UserContext);

  logged_role = sessionLoggedRole;
  logged_user = sessionLoggedUser;
  const logged_role_ls = localStorage.getItem("loggedRole");
  const logged_user_ls = localStorage.getItem("user");
  const approver_id = localStorage.getItem("ApproverD");
  if (!logged_role_ls) {
    logged_role = logged_role_ls;
    logged_user = logged_user_ls
  }
  // useEffect
  useEffect(() => {
    const logged_user_ls = localStorage.getItem("user");
    const user_role = localStorage.getItem("loggedRole");
    setUserRole(user_role)
    const user_id = localStorage.getItem("UserID")

    if (user_role == 'Admin') {
      setSidebar(adminSidedata);
    }
    if (user_role == 'MGMT') {
      setSidebar(managmentSidedata);
    }
    if (user_role == 'STOCK') {
      setSidebar(stockData);
    }
    if (user_role == 'Substore Admin') {
      let user = logged_user_ls != undefined ? JSON.parse(logged_user_ls) : [];
      let admins = user.Admin;
      let approversdata=[];
      let data={
        SubstoreName: "ALL RECORDS",
        SubstoreType: "NON-CENTRAL",
        i_storeId: "all",
        key: "a"   
      }
      approversdata.push(data);
      //console.log("approvers",approversdata)
      let finalapprovers=approversdata.concat(user.Approver);
     // console.log("finalapprovers",finalapprovers)


      let approvers = user.Approver;

      
      let sidebar = [];
      for (var i = 0; i < admins.length; i++) {
        admins[i]['key'] = 's'
      }
      for (var i = 0; i < approvers.length; i++) {
        approvers[i]['key'] = 'a'
        
      }
      if (admins.length != 0 && approvers.length != 0) {
        var finalObj = admins.concat(approvers);

      } else if (admins.length == 0 && approvers.length != 0) {
      //  var finalObj = approvers;
       var finalObj=finalapprovers
      }
      else if (admins.length != 0 && approvers.length == 0) {
        var finalObj = admins;
      }
   //   setItemSelect(finalObj[0].SubstoreName);

     // console.log("finalObj[0].key",finalObj[0].key)
      var keydata=finalObj[0].key

      setKeyLabel(finalObj[0].key)
      setStoreName(finalObj[0].SubstoreType)
      var storeNametype=finalObj[0].SubstoreType
      setTotalArray(finalObj);
      console.log(finalObj);
      if(finalObj.length<2){
      handleInputChange(finalObj[0]);
      }
    }
  }, [])

  const handleMove = (obj) => {
    if (obj.to != '')
      navigate(obj.to);
    //e.preventDefault();

    //  navigate(0);
  };
  const handleToggler = () => {
    if (isExpanded) {
      setIsExpanded(false);
      return;
    }
    setIsExpanded(true);
  };
  const MenuItem = ({ item }) => {
    const Component = hasChildren(item) ? MultiLevel : SingleLevel;
    return <Component item={item} />;
  };
  const SingleLevel = ({ item }) => {
    return (
      <ListItem onClick={() => handleMove(item)} primary={item.title} button className={location.pathname == item.to ? "menu-open" : ""}>
        <ListItemIcon>{item.icon != '' && <img src={item.icon} className="sidebar-icon" />}</ListItemIcon>
        <ListItemText >{item.title} </ListItemText>
      </ListItem>
    );
  };
  const MultiLevel = ({ item }) => {
    const { items: children } = item;
    const [open, setOpen] = useState(true);


    let open_menu = children.filter(function (value, key) {
      if (value.items.length > 0) {
        let found_menu = false;
        let citems = value.items;

        for (let i = 0; i < citems.length; i++) {
          let result = false;
          if (citems[i].to != '') {
            result = location.pathname.includes(citems[i].to);
          }
          if (citems[i].to == location.pathname || result) {
            found_menu = true;
          }
          if (citems[i].items && citems[i].items.length > 0) {
            let ccitems = citems[i].items;
            for (let i = 0; i < ccitems.length; i++) {
              let to = ccitems[i].to.split(' ').join('%20');
              let result = to.includes(location.pathname);
              if (to == location.pathname || result) {
                found_menu = true;
              }
            }
          }
        }
        return found_menu;
      }
      return value.to == location.pathname;
    });

    return (
      <React.Fragment>
        <ListItem button >
          <ListItemIcon>{item.icon != '' && <img src={item.icon} className="icon sidebar-icon" />}</ListItemIcon>
          <ListItemText primary={item.title} >
          </ListItemText>
        </ListItem>
        <Collapse in={open || open_menu.length > 0} timeout="auto" unmountOnExit>
          <hr />
          <List component="div" className="sub-item" disablePadding>
            {children.map((child, key) => (
              <MenuItem key={key} item={child} />
            ))}
          </List>
        </Collapse>
      </React.Fragment>
    );
  };

  const handleStoreChange = (e) => {
   // console.log(totalArray[e.currentTarget.value]);
    setItemSelect(e.currentTarget.value);
    if(e.currentTarget.value != "Select"){
    handleInputChange(totalArray[e.currentTarget.value],e.currentTarget.value);
    }
    else{
      handleInputChange(totalArray,e.currentTarget.value);
    }
  }

  /*  var approver_id =localStorage.getItem("ApproverD", )
   console.log("approver_id",approver_id) */

  const handleInputChange = async(value,select_value) => {
    let items = [];
    var keydata=value.key
    setKeyLabel(value.key)
    setStoreName(value.SubstoreType)
    var storeNametype=value.SubstoreType

  /*   if(value.i_storeId!=''){

      let store_det = await API_Auth.getSubstoreById(value.i_storeId);
      setStoreDet(store_det.substore[0]);
    } */
    if (select_value == 'Select'&&value[0].key=='s') {
    navigate("/dashboard/" + value.i_storeId + "/subAdmin");
    }
    if (select_value == 'Select'&&value[0].key=='a') {
      navigate("/dashboard/" + value.i_storeId + "/approver");
      }
    if (value.key == 's') {
      let consumable_request_sub = [
        {
          title: 'New Request',
          //to: "/fixed-add-request/fixed-request/" + value.i_storeId + "/" + value.SubstoreName + "/Consumable",
          to: "/add-request/" + value.i_storeId,
          icon: request,
          items: []
        },
        {
          title: 'All Sent ',
          to: "/sent-request/" + value.i_storeId,
          icon: sendreq,
          items: []
        },
        {
          title: 'Pending',
          to: "/pending-request/" + value.i_storeId,
          icon: pending,
          items: []
        },
        {
          title: 'Completed ',
          to: "/issue-request/" + value.i_storeId,
          icon: completed,
          items: []
        },
      ]

      let incoming_requests = [
        {
          title: 'All Recieved',
          to: "/received-request/" + value.i_storeId,
          icon: received,
          items: []
        },
        {
          title: 'Pending',
          to: "/pending-outing-request/" + value.i_storeId,
          icon: pending,
          items: []
        },
        {
          title: 'Completed ',
          to: "/issue-outgoing-request/" + value.i_storeId,
          icon: completed,
          items: []
        }
      ]
      items = [
        {
          title: 'Dashboard',
          to: "/dashboard/" + value.i_storeId + "/subAdmin",
          icon: dashboard,
          items: []
        },
        {
          title: 'Item Management',
         to: "assets-management/" + value.i_storeId,
          icon: assetmng,
          items: []
        }, {
          title: 'Outgoing Requests',
          to: "",
          icon: requestmng,
          items: consumable_request_sub
        },
        {
          title: 'Incoming Requests',
          to: "/received-request/" + value.i_storeId,
          icon: receive,
          items: incoming_requests
        },
      
        {
          title: 'Item Consumption',
          to: "/consumption/" + value.i_storeId,
          icon: assetconsumption,
          items: []
        },
        {
          title: 'Consumption Log',
          to: "/consumption_log/" + value.i_storeId,
          icon: transaction,
          items: []
        },
        {
          title: 'Item Adjustment',
          to: "/adjustment/" + value.i_storeId,
          icon: adjust,
          items: []
        },
        {
          title: 'Adjustment Log',
          to: "/adjustment_log/" + value.i_storeId,
          icon: transaction,
          items: []
        },
       
      ]

     var stockitems = [
        {
          title: 'Dashboard',
          to: "/dashboard/" + value.i_storeId + "/subAdmin",
          icon: dashboard,
          items: []
        },
        {
          title: 'Item Management',
         to: "assets-management/" + value.i_storeId,
          icon: assetmng,
          items: []
        }, {
          title: 'Outgoing Requests',
          to: "",
          icon: requestmng,
          items: consumable_request_sub

        },
        {
          title: 'Incoming Requests',
          to: "/received-request/" + value.i_storeId,
          icon: receive,
          items: incoming_requests
        },
      
        {
          title: 'Item Consumption',
          to: "/consumption/" + value.i_storeId,
          icon: assetconsumption,
          items: []
        },
        {
          title: 'Consumption Log',
          to: "/consumption_log/" + value.i_storeId,
          icon: transaction,
          items: []
        },
        {
          title: 'Item Adjustment',
          to: "/adjustment/" + value.i_storeId,
          icon: adjust,
          items: []
        },
        {
          title: 'Adjustment Log',
          to: "/adjustment_log/" + value.i_storeId,
          icon: transaction,
          items: []
        },
        {
          title: 'Stock Register',
          to: "/stock-register",
          icon:money,
          items:[]
        },
      ]

      navigate("/dashboard/" + value.i_storeId + "/subAdmin");
      // navigate(0);
    }
    if (value.key == 'a') {

      let approved_requests = [
        {
          title: 'All Requests',
          to: "approval-management/" + value.i_storeId+"/" +approver_id,
          icon: received,
          items: []
        },
        {
          title: 'Pending',
          to: "/pending-approved-request/" + value.i_storeId+"/"+approver_id,
          icon: pending,
          items: []
        },
        {
          title: 'Completed ',
          to: "/approved-request/" + value.i_storeId+"/"+approver_id,
          icon: completed,
          items: []
        }
      ]

      items = [
        {
          title: 'Dashboard',
          to: "/dashboard/" + value.i_storeId + "/approver",
          icon: dashboard,
          items: []
        },
        {
          title: 'Approval Management',
          to: "approval-management/" + value.i_storeId +"/" +approver_id,
          icon: approvalmang,
          items: approved_requests
        }]
      // navigate("approval-management/" + value.i_storeId);
     // navigate("/dashboard/" + value.i_storeId + "/approver");

     navigate("/approval-management/" + value.i_storeId +"/" +approver_id)

    }
    // setItemSelect(value.SubstoreName)
//console.log("items",storeNametype,keydata)
    setTitle(value.SubstoreName);
    if(storeNametype=='CENTRAL' && keydata=="s"){
      setSidebar(stockitems);
    }else{
      setSidebar(items);
    }
    if(keyLabel=="a"){
      setSidebar(items);
    }
  }

  const handleShow = () => {
    setShown(true);
  }
  return (
    <Wrapper>


      <div className={isExpanded ? "sidebar" : "sidebar collapsed"}>
        <div onClick={handleToggler} className="menu_toogler" >
          <AiOutlineMenu size={24} />
        </div>

        {isExpanded && <img src={Logo} alt="img" style={{ width: 200, height: 200, margin: "5px auto", marginBottom: 26 }} />}
        {!isExpanded && <img src={Logo} alt="img" style={{ width: 50, height: 50, margin: "5px auto" }} />}


        {userRole == 'Substore Admin' &&
          <>
        
            <div>
              <p className="itemRound">{totalArray.length}</p>
              <select onChange={(e) => handleStoreChange(e)}
                value={itemSelect} style={{ width: "89%" }}>
                  {totalArray.length>1&&
               <option value={null}>Select</option>
                }
                {totalArray.map((item, index) => {
                  return (
                    <option value={index}>{item.SubstoreName}</option>
                  )
                })}

              </select>
              <hr />
            </div>
          </>
        }

        {sidebar.map((item, key) => <MenuItem key={key} item={item} />)}
      </div>

    </Wrapper >
  );
};

export default Sidebar;
