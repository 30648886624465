import money from  "./images/money-sack.png";
const navstock = [
  {
    title: "Stock Register",
    to: "/stock-register",
    icon:money,
    subitems:[]
  },
  ];
  
  export default navstock;
  