

import styled from "styled-components";

export const Wrapper = styled.div`
background:white;
margin:20px;
border-radius:6px;
.row_table_div {
  background:#ea9ca1;
  color:#000044;
  display:flex;
  flex-direction:row;
  border-radius:4px;
  cursor:pointer; 
  float:right;
}

.row_title{
  margin:10px;
  padding:20px 10px 0px;
  p{
    color:black;
    font-weight:500;
    font-size:24px;
    margin:10px 0px;
    font-family:Roboto;
  }
  h5{
    color:black;
    font-weight:500;
    font-size:24px;
    margin:10px -10px;
    font-family:Roboto;
  }
}

.alert-message{
  color: red;
  margin-left: 15px;
}
.row_div {
    margin:10px;
  }
  .row_div_id {
  display:flex;
  flex-direction:row;
  padding: 8px;
  }
  .input_div {
    border: 2px solid #DFDFDF;
    width: 100%;
    color:var(--black);
    font-family: 'ARIAL';
    height:45px;
    border-radius:6px;
    margin:5px;
    background:white;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    input,select{
       border:none;
       :focus {
         outline: none !important;
         border-color:none;  
       }
      }
     
  }
   .tablelist {
    margin-right:5px;;
  }
   
  thead{
    color:black;
    background:#c0d3ef;
  border:1px solid #c0d3ef;
    border-radius:10px;
  }
  td{
    color:black;
    font-weight:400;
    font-size:16px;
    padding:12px;
    height:50px;
    font-family:Roboto;
    text-transform: capitalize;
  }
  th{
    font-size:16px;
    height:50px;
    padding:14px;
    font-weight:500;
    font-family:Roboto;
  
  }
  .table-head-content {
    padding: 13px 13px 0 13px;
    background: white;
    border-bottom: 1px solid #E2E2E2;   
  }  
  
 
  
  .add_button {
    display:flex;
    flex-direction:row;
    background:#6EDC8F;
    color:black;
    border:#6EDC8F;
    padding:15px;
    height:50px;
    margin:15px;
    text-align:center;
    justify-content:center;
    align-item:center;
    box-shadow: 0px 2px 2px #00000030;
  border-radius: 6px;
  opacity: 1;
  }
.title{
    color:black;
  font-weight:500;
  font-size:24px;
  margin: 20px;
  padding: 20px 0px 0px 0px;
}
.sub_title{
  color:black;
  font-weight:500;
  font-size:18px;
  margin: 20px;
  padding: 20px 0px 0px 0px;
}
.add_vendor_btn{
  color: black;
    margin-left: 15px;
    font-weight: bold;
    font-size: 14px;
    font-family: Roboto;
}
a{
  cursor: pointer;
    color: #000044;
}
.inputdiv {
    margin:20px 0px;
}
input,select {
    padding-left: 10px;
    border: 2px solid #DFDFDF;
    width: 100%;
    color:var(--black);
    font-family: 'ARIAL';
    height:45px;
    border-radius:6px;
    margin:15px;
  }
  :focus {
      outline: none !important;
      border-color:none;  
    }
     
`
export const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

export const Switch = styled.div`
   position: relative;
  width: 60px;
  height: 28px;
  background: #b3b3b3;
  border-radius: 32px;
  padding: 4px;
  transition: 300ms all;

  &:before {
    transition: 300ms all;
    content: "";
    position: absolute;
    width: 28px;
    height: 28px;
    border-radius: 35px;
    top: 50%;
    left: 4px;
    background: lightgray;
    transform: translate(0, -50%);
  }

`;

export const Input = styled.input`
  opacity: 0;
  position: absolute;
  margin-left:20px;

  &:checked + ${Switch} {
    background: green;

    &:before {
      transform: translate(32px, -50%);
    }
  }
`;
