import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Row, Container, Col, OverlayTrigger, Tooltip, Button } from "react-bootstrap"
import { Wrapper, Label, Input, Switch } from "./styles.js"

import API_Auth from "../../API_Auth";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Title } from "../../GlobalStyle.js"
import Table from "react-bootstrap/Table";
import moment from "moment"
import InfoIcon from '@mui/icons-material/Info';
import { IoIosArrowDown, IoIosArrowUp, IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const AssetConsumption = () => {
    const navigate = useNavigate();
    // let {StorID,assetID} = useParams();
    const location = useLocation();

    const {
        isExpanded,
    } = useContext(UserContext);

    const [asset_data, setasset_data] = useState(location.state ?.asset_data);
    const [StorID, setStoreID] = useState(location.state ?.StoreID);
    const [formTitle, setFormTitle] = useState('Asset Consumption');
    const [patientID, setPatientID] = useState('');
    const [patientIDErr, setPatientIDErr] = useState('');

    const [qunatity, setQunaity] = useState(asset_data.Quantity);
    const [consume_date, setDate] = useState('');
    const [dateErr, setDateErr] = useState('');
    const [consume_qty, setConsumed] = useState('');
    const [comments, setComments] = useState('')
    const [formButtonValue, setFormButtonValue] = useState('UPDATE');
    const [no_investigations, setno_investigations] = useState('')
    const [no_patients, setno_patients] = useState('')
    const [no_patientsErr, setNo_patientsErr] = useState('')
    const [typeChecked, setChecked] = useState('');
    const [typeCheckedErr, setCheckedErr] = useState(false);

    const [qunatityErr, setQunatityErr] = useState('')
    const [consumedErr, setConsumedErr] = useState('')
    const [commentsErr, setCommentsErr] = useState('');
    const [batchNo, setBatchNo] = useState('');
    const [batchErr, setBatchErr] = useState('')
    const [viewAssetsData, setviewAssetsData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);


    const handleInput = (e) => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;
        if (name === "consume_date") setDate(value);
        if (name === "patientID") setPatientID(value);
        if (name === "qunatity") setQunaity(value);
        if (name === "consume_qty") setConsumed(value);
        if (name === "comments") setComments(value);
        if (name === "no_patients") setno_patients(value);
        if (name === "no_investigations") setno_investigations(value);
        if (name === "batchNo") setBatchNo(value);
    };
    const handleChange = (e) => {
        setChecked(e.currentTarget.value);
    };

    useEffect(() => {
        setIsLoading(true);
        const date = new Date();
        const futureDate = date.getDate();
        date.setDate(futureDate);
        const defaultValue = date.toLocaleDateString('en-CA');
        setDate(defaultValue)
        const fetchAsset = async () => {
            let result = await API_Auth.getConsumeAssetView(StorID, asset_data.AssetID,"Consumable");
            result.sort((a, b) => b.TDate.localeCompare(a.TDate))

            setviewAssetsData(result);

            setIsLoading(false);




        };
        // call the function
        fetchAsset()
            // make sure to catch any error
            .catch(console.error);
    }, []);
    const handleAddAsset = async () => {
        setPatientIDErr('');
        setQunatityErr('');
        setConsumedErr('');
        setDateErr('');
        setNo_patientsErr('');
        setCommentsErr('');
        setBatchErr('');

        let error = false;

        if (no_patients != '') {
            if (patientID == '') {
                setPatientIDErr('This field is required');
                error = true;
            }
        }
        if (qunatity == '') {
            setQunatityErr('This field is required');
            error = true;
        }
        if (consume_qty == '') {
            setConsumedErr('This field is required');
            error = true;
        }
        if (consume_date == '') {
            setDateErr('This field is required');
            error = true;
        }
        if (batchNo == "") {
            setBatchErr('This field is required');
            error = true;
        }
        if (no_patients == '' && no_investigations == '') {
            setNo_patientsErr('This field is required');
            error = true;
        }
        else {
            setNo_patientsErr('');
            error = false;
        }
        if (comments == '') {
            setCommentsErr('This field is required');
            error = true;
        }
        if (!error) {
            let result = await API_Auth.addConsumeAsset(asset_data.AssetID, StorID, patientID, consume_qty, no_patients || no_investigations, comments, batchNo, consume_date);
            let res = result[0];
            if (res.Flag == 'Success') {
                toast.success('Consumption successful');
                setTimeout(() => {
                    navigate("/assets-management/"+StorID);

                }, 2000);
            } else {
                toast.error('Something went wrong please try again');
            }
        }
    }
    const handleBackClick = () => {
        navigate(-1);
    }

    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            <Container>
                <Row className="row_title">
                    <Col lg={4}><p>{formTitle}</p></Col>
                    <Col lg={6}></Col>
                    <Col lg={2}>
                        <div className="back_button" onClick={() => handleBackClick()}>
                            <IoIosArrowBack size={18} />
                            <h6>Back</h6>
                        </div>
                    </Col>
                </Row>
            </Container>               <hr />
            <Container>
                <Row>
                    <Col lg={10}>
                        <Row>
                            <Row>
                                <Col lg={7}>
                                    <p style={{ marginLeft: 20, marginTop: 24, fontFamily: 'Roboto', fontWeight: '600' }}>
                                        {'Asset'}</p>
                                </Col>
                                <Col lg={7}>
                                    <p style={{ marginLeft: 20, fontFamily: 'Roboto', fontWeight: '600' }}>
                                        {asset_data.AssetName}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={5}>
                                    <div className="inputdiv">
                                        <label>Qunatity</label>
                                        <br />
                                        <input type="text"
                                            name="qunatity"
                                            value={qunatity}
                                            placeholder="Qunatity"
                                        //onChange={handleInput}
                                        />
                                        {qunatityErr != '' && <p className="alert-message">{qunatityErr}</p>}
                                    </div>
                                </Col>
                                <Col lg={5}>
                                    <div className="inputdiv">
                                        <label>Date</label>
                                        <br />
                                        <input type="date"
                                            name="consume_date"
                                            value={consume_date}
                                            placeholder="dd/mm/yyyy"
                                            max={moment().format("DD MMM YYYY")}
                                            onChange={handleInput} />
                                        {dateErr != '' && <p className="alert-message">{dateErr}</p>}
                                    </div>
                                </Col>
                            </Row>
                        </Row>
                        <Row>
                            <Col lg={5}>
                                <div className="inputdiv">
                                    <label>Consumed Quantity</label>
                                    <br />
                                    <input type="text" min="0"
                                        name="consume_qty"
                                        value={consume_qty}
                                        placeholder="Enter Quantity"
                                        onChange={handleInput} />
                                    {consumedErr != '' && <p className="alert-message">{consumedErr}</p>}
                                </div>
                            </Col>
                            <Col lg={5}>
                                <div className="inputdiv">
                                    <label>Batch No</label>
                                    <br />
                                    <input type="text"
                                        name="batchNo"
                                        value={batchNo}
                                        placeholder="Batch No"
                                        onChange={handleInput} />
                                    {batchErr != '' && <p className="alert-message">{batchErr}</p>}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                          
                            <Col lg={5}>
                                <input type="radio" id="no_of_patients" name="radio" value="no_of_patients" style={{ width: 25, height: 15 }}
                                    onChange={handleChange}
                                />
                                <label for="no_of_patients" className="radiolabel">No of Patients</label>
                            </Col>
                            <Col lg={5}>
                                <input type="radio" id="no_of_patients" name="radio" value="no_of_investigations" style={{ width: 25, height: 15 }}
                                    onChange={handleChange}
                                />
                                <label for="no_of_patients" className="radiolabel">No of Investigations</label>
                            </Col>
                            {typeChecked == 'no_of_patients' &&
                                <>
                                    <Col lg={5}>
                                        <div className="inputdiv">
                                            <label>No of Patients</label>
                                            <br />
                                            <input type="text" min="0"
                                                name="no_patients"
                                                value={no_patients}
                                                placeholder="No of Patients"
                                                onChange={handleInput} />
                                            {no_patientsErr != '' && <p className="alert-message">{no_patientsErr}</p>}
                                        </div>
                                    </Col>
                                    <Col lg={5}>
                                        <div className="inputdiv">
                                            <label>Patient IDs</label>
                                            <br />
                                            <div className="row_div_id">
                                                <div className="input_div">
                                                    <input type="text"
                                                        name="patientID"
                                                        style={{ margin: 0, background: 'transparent' }}
                                                        value={patientID}
                                                        placeholder="Patient ID"
                                                        onChange={handleInput} />
                                                    <OverlayTrigger overlay={
                                                        <Tooltip id={`tooltip-top`}>Ex: ID1,ID2,ID3,ID4</Tooltip>}>
                                                        <InfoIcon size={24} style={{ margin: 10, cursor: 'pointer' }} color="lightgray" />
                                                    </OverlayTrigger>
                                                    {patientIDErr != '' && <p className="alert-message">{patientIDErr}</p>}
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </>
                            }
                        </Row>
                        <Row>
                            {typeChecked == 'no_of_investigations' &&
                                <Col lg={5}>
                                    <div className="inputdiv">
                                        <label>No of Investigations</label>
                                        <br />
                                        <input type="text" min="0"
                                            name="no_investigations"
                                            value={no_investigations}
                                            placeholder="No of Investigations"
                                            onChange={handleInput} />
                                        {no_patientsErr != '' && <p className="alert-message">{no_patientsErr}</p>}
                                    </div>
                                </Col>
                            }
                            <Col lg={5}>
                                <div className="inputdiv">
                                    <label>Comments</label>
                                    <br />
                                    <textarea
                                        value={comments}
                                        onChange={handleInput}
                                        name="comments" placeholder="Type Something"
                                        rows={5}
                                        style={{ height: 100, }}
                                        cols={5}
                                    />
                                    {commentsErr != '' && <p className="alert-message">{commentsErr}</p>}
                                </div></Col>
                        </Row>
                    </Col>
                    <Col lg="4">
                        <div className="row_div">
                            <button className="add_button" onClick={() => handleAddAsset()}>
                                <p className="pcat_title">{formButtonValue}</p></button>
                        </div>
                    </Col>
                    <Row className="row_title">
                        <h5>Transaction Log</h5>
                    </Row>
                    <Container>

                        <Row>


                            <Col lg={12} sm={12} md={12} className="table_head">
                                <Table>
                                    <thead>
                                        <th>From</th>
                                        <th>To</th>
                                        <th>Qty</th>
                                        <th>Date</th>
                                        <th>Batch No</th>
                                        <th>Vendor</th>
                                        <th>MFD</th>
                                        <th>Expiry</th>
                                        <th>Price</th>
                                        <th>Comments</th>
                                    </thead>
                                    {viewAssetsData.length != 0 && <>

                                        <tbody>
                                            {viewAssetsData.map((item, index) => {
                                                return (
                                                    <tr key={index} >
                                                        <td>{item ?.FromStoreName || item ?.FromStoreID}</td>
                                                        <td>{item.ToStoreName || item ?.ToStoreID}</td>
                                                        <td>{item.Quantity}</td>
                                                        <td>{item.TDate}</td>
                                                        <td>{item.BatchNo}</td>
                                                        <td>{item.VendorName}</td>
                                                        {item.MFD != '' && <td>{moment(item.MFD).format("DD MMM YYYY  h:mm A")}</td>}
                                                        {item.MFD == '' && <td>{moment(item.MFD).format("DD MMM YYYY  h:mm A")}</td>}

                                                        {item.EXPD != '' && <td>{moment(item.EXPD).format("DD MMM YYYY  h:mm A")}</td>}
                                                        {item.EXPD == '' && <td>{moment(item.EXPD).format("DD MMM YYYY  h:mm A")}</td>}
                                                        <td>{item.Price}</td>
                                                        {item.Tcpmment == '' &&
                                                            <td>{item.Tcpmment}</td>
                                                        }
                                                        {item.Tcpmment != '' &&
                                                            <OverlayTrigger
                                                                key={item.Tcpmment}
                                                                overlay={
                                                                    <Tooltip id={`tooltip-top`}>
                                                                        {item.Tcpmment}.
        </Tooltip>
                                                                }
                                                            >
                                                                <td className="table_text_overflow">{item.Tcpmment}</td>
                                                            </OverlayTrigger>
                                                        }
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </>
                                    }
                                    {viewAssetsData.length == 0 &&
                                        <tbody>
                                            <tr>
                                                <td colSpan="12" >
                                                    <p style={{ textAlign: 'center', fontFamily: 'Robot', fontWeight: 'bold' }}>No Data Found </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    }
                                </Table>
                            </Col>
                        </Row>
                    </Container>



                </Row>

            </Container>
            <ToastContainer />

        </Wrapper>
    )
}
export default AssetConsumption