import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Wrapper } from "./styles"
import { Container, Row, Col } from "react-bootstrap";
import { Title } from "../../GlobalStyle"
import about1 from "../../images/about1.png";
import about2 from "../../images/about2.png";

const Aboutus = () => {
    const {
        isExpanded,
    } = useContext(UserContext);
    
    return (

        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            <Container>
                <Row>
                <Col lg={4}>
                        <Title>About us</Title>
                    </Col>
                    </Row>
                    <Row>

                    <Col className="conotent_text" lg={6}>
                    <img src={about2} style={{ width: "100%", height: "100%" }} />
                    </Col>
                    <Col className="conotent_text" lg={6}>
                    <div>
                    <p>
                    Department of Medical Education, Uttarakhand
The department of medical education plays a pivotal role in developing medical,
 Nursing and para-medical personal to cater to the health needs of the state of Uttarakhand. 
 The department also plays an important role in establishment and maintenance of well-equipped 
 teaching institutions, which are the premier referral centres from peripheral hospitals. 
For proper functioning of the hospitals and medical colleges, manpower, infrastructure and 
consumable equipment and medicines all play an important part. 
</p>
</div>
<div>
                    <p>
To ensure
 that patients are treated well and on time, it is critical for administrators to
  be able to have the right visibility in the supply chain of medical assets.
   Medical asset supply chain traceability was an improvement area which was
    further highlighted during the testing CoVID-19 times.
    </p>
    </div>
    <div>
                    <p>
Blockchain is one of the premier technologies which provides an immutable,
 decentralized record keeping mechanism. With this project Department of Medical Education,
  Uttarakhand is doing a pilot of a “Blockchain based Medical Asset Tracking” system which will provide
   stakeholders such as Central Stores, Sub-stores and various Approvers and Administrators a platform to take
    actions, and record transactions related to movement of medical assets and supplies using a private permissioned
     blockchain. This project is being executed by Global Blockchain leader SettleMint (www.settlemint.com) and uses
      Layer 2 Enterprise Ethereum chain on Polygon network. 
                    </p>
                    </div>
                    </Col>
                    </Row>
                    <Row>
                    <Col lg={12}className="conotent_text" >
                    <p>
                    Main components of this platform will be – 
• Administration Module
• Central Store Module
• Sub-store Module
• Approver’s Modules
• Dashboard 
Using this platform, various Hospitals and Medical colleges will be able to track the movement of Medical Supplies and assets in real time, will gain valuable insights based on the rich data set generated and will benefit from the Blockchain powered immutable record keeping and smart contract. With this, Uttarakhand Becomes the first state in India to Pilot a “Blockchain Driven Medical Assets Supply Chain Solution”
                    </p>
                    </Col>
                    <Col style={{ textAlign: "center"}}>
            <img src={about1} style={{ width: "60%", height: "100%" }} />
                    </Col>
                </Row>
            </Container>
        </Wrapper>
    )
}
export default Aboutus