import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Wrapper } from "./styles"
import { Row, Container, Col } from "react-bootstrap"
import Table from "react-bootstrap/Table";
import {useParams } from "react-router-dom";
import { AiOutlineSearch } from "react-icons/ai";
import API_Auth from "../../API_Auth";
import moment from "moment"
import { LoadingSpinner } from "../LoadingSpinner/index";
import ReactPaginate from 'react-paginate';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Title } from "../../GlobalStyle"

const ConsumptionLog = () => {
    const {
        isExpanded
    } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const [searchData, setSearchData] = useState([])
    let { StorID, SubstoreName } = useParams();
    const [offset, setOffSet] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [orgtableData, setOrgTableData] = useState([]);
    const [perPage, setPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [searchKey, setSearchKey] = useState('n');
    const [searchKeyTable, setSearchKeyTable] = useState('');
    const[subStoreName,setSubstoreName]=useState('');
    const[start_date,setStartDate]=useState('');
    const[end_date,setEndDate]=useState('');



    useEffect(() => {
        setIsLoading(true);
        const fetchRequests = async () => {

            const date = new Date();

            const futureDate = date.getDate();
            date.setDate(futureDate);
            const defaultValue = date.toLocaleDateString('en-CA');
            let today = new Date();
            today.setDate(today.getDate() - 7);
            let startCurrentDate = new Date(today).toLocaleDateString("en-CA");
            setStartDate(defaultValue);
            setEndDate(defaultValue);
            const startdate=moment(defaultValue).format("MM/DD/YYYY");
            const enddate=moment(defaultValue).format("MM/DD/YYYY")


            let data = await API_Auth.getTransactionConsumable(StorID,startdate,enddate);
            setIsLoading(false);
            var slice = data.slice(offset, offset + perPage);
            setPageCount(Math.ceil(data.length / perPage));
            setOrgTableData(data);
            setTableData(slice);
            setSearchData(data);

            let store_det = await API_Auth.getSubstoreById(StorID);
            let name = store_det.substore[0].SubStoreName;
            setSubstoreName(name);

        };
        // call the function
        fetchRequests()
            // make sure to catch any error
            .catch(console.error);
    }, [StorID])

    const handleInput = async(e) => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;

        if (name == "start_date") {setStartDate(value)
            const startdate=moment(value).format("MM/DD/YYYY");
            const enddate=moment(end_date).format("MM/DD/YYYY")
            setIsLoading(true);


            let data = await API_Auth.getTransactionConsumable(StorID,startdate,enddate);     
            setIsLoading(false);
            var slice = data.slice(offset, offset + perPage);
            setPageCount(Math.ceil(data.length / perPage));
            setOrgTableData(data);
            setTableData(slice);
            setSearchData(data);
        };
        if (name == "end_date") {
            
            setEndDate(value);
            const startdate=moment(start_date).format("MM/DD/YYYY");
            const enddate=moment(value).format("MM/DD/YYYY")

            setIsLoading(true);

            let data = await API_Auth.getTransactionConsumable(StorID,startdate,enddate);
            setIsLoading(false);
            var slice = data.slice(offset, offset + perPage);
            setPageCount(Math.ceil(data.length / perPage));
            setOrgTableData(data);
            setTableData(slice);
            setSearchData(data);  
        }

    }


    const handleSearch = (e) => {
        let filterValue = e.target.value
        setCurrentPage(0);
        if (filterValue == '') {
            let offsetsearch = 0;
            var slice = orgtableData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(orgtableData.length / perPage));
            setOrgTableData(orgtableData);
            setTableData(slice);
            setOffSet(offsetsearch);
            setSearchData(orgtableData);
            setSearchKey('n');
            setCurrentPage(0);
        }
        else {
            let value1 = filterValue.toLowerCase();
            let offsetsearch = 0;
            const filteredData = orgtableData.filter(item => {
                return (item ?.AssetName ?.toLowerCase() ?.includes(value1) ||item ?.FromStoreName ?.toLowerCase() ?.includes(value1) || item ?.Price ?.toLowerCase() ?.includes(value1) ||
                item ?.ToStoreName ?.toLowerCase().includes(value1) ||item ?.BatchNo ?.toLowerCase() ?.includes(value1) ||
                item ?.VendorName ?.toLowerCase() ?.includes(value1) ||item ?.TDate ?.toLowerCase() ?.includes(value1) ||
                 item ?.Quantity?.toString().toLowerCase().includes(value1) ||
                moment(item.TDate).format('DD-MM-YYYY').includes(value1) || item ?.RquestID ?.includes(value1))


            });
            const slice = filteredData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(filteredData.length / perPage))
            setSearchKey('y');
            setOffSet(offsetsearch);
            setSearchKeyTable(filteredData);
            setTableData(slice);
            setSearchData(filteredData);
        }
 

    }
    const handlerowsperPage = (e) => {
        let showperpage = e.currentTarget.value
        setPerPage(parseInt(showperpage));
        setCurrentPage(0);
        setOffSet(0);

        if(searchKey=="y"){
            const slice = searchKeyTable.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(searchKeyTable.length / e.currentTarget.value))
            setTableData(slice); 
            setSearchData(searchKeyTable);
   
        }
        else{
        const slice = orgtableData.slice(0, 0 + e.currentTarget.value);
        setPageCount(Math.ceil(orgtableData.length / e.currentTarget.value))
        setTableData(slice);
        setSearchData(orgtableData);
        }
    }
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offsetdata = selectedPage * perPage;
        setCurrentPage(selectedPage);
        setOffSet(offsetdata);
        loadMoreData(offsetdata);
    }

    const loadMoreData = (offsetdata) => {
        const data = orgtableData;
        setOffSet(offsetdata);
        if (searchKey == "y") {
            const slice = searchKeyTable.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(searchKeyTable.length / perPage))
            setTableData(slice);
            setSearchData(searchKeyTable);
        } else {
            const slice = data.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(data.length / perPage))
            setTableData(slice);
            setSearchData(data);
        }

    }   
    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            {isLoading && <LoadingSpinner />}

<Container>
    <Row>
        <Col lg={4} style={{ paddingLeft: 0 }}>
            <Title>Consumption Log</Title>
        </Col>
        <Col lg={5} >
            </Col>
        <Col lg={3}style={{ paddingRight: 0 }}>
            <div className="row_div">
                {/*                             <p className="title">Filter By:</p> */}
                <div className="input_div" >
                    <input type="type" style={{ margin: 0, background: 'transparent' }}
                        name="filterValue"
                        //    value={filterValue}
                        onChange={(e) => handleSearch(e)}
                        placeholder="Search" />
                    <AiOutlineSearch size={24} style={{ margin: 10, cursor: 'pointer' }} color="lightgray" />
                </div>
            </div>
        </Col>
       
    </Row>
</Container>
<Container>
                <Row>
                    <Col lg={12} sm={12} md={12} className="table_head">
                    <Row>
                    <Col lg={6}>
                        <div className="inputdiv">
                            <label style={{marginTop:15,}}>Start Date</label>
                            <br />
                            <input type="date"
                                name="start_date"
                                value={start_date}
                                placeholder="dd/mm/yyyy"

                                onChange={handleInput} />
{/*                             {startDateErr != '' && <p className="alert-message">{startDateErr}</p>}
 */}
                        </div>


                    </Col>
                    <Col lg={6}>
                        <div className="inputdiv">
                            <label style={{marginTop:15,}}>End Date</label>
                            <br />
                            <input type="date" style={{width:"90%"}}
                                name="end_date"
                                value={end_date}
                                placeholder="dd/mm/yyyy"

                                onChange={handleInput} />
{/*                             {endDateErr != '' && <p className="alert-message">{endDateErr}</p>}
 */}
                        </div>
                    </Col>
                    </Row>
                        
                        <Table className="tablelist">
                            <thead>
                                <tr>
                                    <th>Item Name</th>
                                    <th>From</th>
                                    <th>To </th>
                                    <th>Qty</th>
                                    <th>Consumption Date</th>
{/*                                     <th>Record Date</th> */}

                                {/*     <th>Request Id</th>
                                    <th>Bill No</th> */}
                                    <th>Batch No</th>
                                    <th>Vendor</th>
                                    {/* <th>MFD</th>
                                    <th>Expiry</th> */}
                                    <th>Price</th>
                                    <th>Comments</th>
                                </tr>
                            </thead>
                            {tableData.length == 0 && <tbody>
                                <tr >
                                    <td colSpan="12">
                                        <p className="no_Data_table">No Data Found</p>
                                    </td>
                                </tr>
                            </tbody>
                            }
                            {tableData.length != 0 && <tbody>
                                {tableData.map((obj, index) => {
                                    return (
                                        <>
                                            <tr key={index}>
                                                <td>{obj.AssetName}</td>
                                                <td>{obj.FromStoreName}</td>
                                                <td>{obj.ToStoreName}</td>
                                                <td>{obj.Quantity}</td>
                                                <td> { moment.utc(obj.UDate).format("DD MMM YYYY")}</td>

{/*                                                 <td> { moment.utc(obj.TDate).format("DD MMM YYYY")}</td> */}
                                               {/*  <td>{obj.Status}</td>
                                                            <td>{obj.Status}</td> */}
                                                            <td>{obj.BatchNo}</td>
                                                            <td>{obj.VendorName}</td>
                                                            {/* <td>{obj.Status}</td>
                                                            <td>{obj.Status}</td> */}
                                                            <td>{obj.Price}</td>
                                                            <td>{obj.Tcpmment}</td>
                                            </tr>
                                        </>
                                    );
                                })}
                            </tbody>
                            }


                        </Table>
                        {tableData.length != 0 &&
                            <div>
                                <div className="pagination_row_div">
                                    <p className="pagination_title">Showing items per page:</p>
                                    <div className="pagination_input_div" >
                                        <select className="pagination_select"
                                            style={{ margin: 0, background: 'transparent' }}
                                            name="pagination_value"
                                            value={perPage}
                                            onChange={handlerowsperPage} >
                                            <option value={4}>4</option>
                                            <option value={8}>8</option>
                                            <option value={10}>10</option>
                                            <option value={20}>20</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="pagination_row_right_div">
                        <p className="pagination_total">Showing {offset+1} to {searchData.length<offset+perPage&&
                        <span>{searchData.length}</span> }
                        {searchData.length>offset+perPage&&
                        <span>{offset+perPage }</span>} of {searchData.length} items</p>
                                <ReactPaginate
                                    previousLabel={<ArrowBackIosIcon className="pagination_prev_btn" color="#2438a7" />}
                                    nextLabel={<ArrowForwardIosIcon className="pagination_next_btn" color="#2438a7" />}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"}
                                    forcePage={currentPage} />
                            </div>
                            </div>
                        }
                    </Col>
                </Row>
            </Container>

        </Wrapper>
    )
}
export default ConsumptionLog