import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Wrapper } from "./styles"
import { Row, Container, Col ,OverlayTrigger, Tooltip,} from "react-bootstrap"
import Table from "react-bootstrap/Table";
import Edit from "../../images/user-edit.png";
import { useNavigate, Link,useParams } from "react-router-dom";
import API_Auth from "../../API_Auth";
import "react-toastify/dist/ReactToastify.css";
import { Title } from "../../GlobalStyle"
import { AiOutlineSearch } from "react-icons/ai";
import { LoadingSpinner } from "../LoadingSpinner/index";
import ReactPaginate from 'react-paginate';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import moment from "moment"
import Modal from 'react-bootstrap/Modal';

const TransactionLog = () => {
    const {
        isExpanded,
    } = useContext(UserContext);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [searchData, setSearchData] = useState([])
    let { StorID, SubstoreName } = useParams();
    const [offset, setOffSet] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [orgtableData, setOrgTableData] = useState([]);
    const [perPage, setPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [searchKey, setSearchKey] = useState('n');
    const [searchKeyTable, setSearchKeyTable] = useState('');
    const[subStoreName,setSubstoreName]=useState('');

    const[start_date,setStartDate]=useState('');
    const[end_date,setEndDate]=useState('');
    const[record_date,SetRecordDate]=useState('')
    const[showTransDetails,setShowTransDetails]=useState(false)
    const[transDetails,setTransDetails]=useState('')

    
    const handleCloseTrasactionHistory = () => setShowTransDetails(false)



    useEffect(() => {
        setIsLoading(true);
        const fetchRequests = async () => {
            
            const date = new Date();

            const futureDate = date.getDate();
            date.setDate(futureDate);
            const defaultValue = date.toLocaleDateString('en-CA');
            let today = new Date();
            today.setDate(today.getDate() - 7);
            let startCurrentDate = new Date(today).toLocaleDateString("en-CA");
            setStartDate(defaultValue);
            setEndDate(defaultValue);
            const startdate=moment(defaultValue).format("MM/DD/YYYY");
            const enddate=moment(defaultValue).format("MM/DD/YYYY")
            const recorddate=moment(defaultValue).format("MM/DD/YYYY")
            SetRecordDate(defaultValue)


            let data = await API_Auth.getTransactionLogAdmin(startdate,enddate);
            setIsLoading(false);
            var slice = data.slice(offset, offset + perPage);
            setPageCount(Math.ceil(data.length / perPage));
            setOrgTableData(data);
            setTableData(slice);
            setSearchData(data);
        };
        // call the function
        fetchRequests()
            // make sure to catch any error
            .catch(console.error);
    }, [StorID])

    const handleGoToTrans_history=async(obj)=>{
        setShowTransDetails(true); 
        let data = await API_Auth.getTransactionDetailsView(obj.Txhash);
        console.log(data)
        setTransDetails(data); 
       };
    const handleInput = async(e) => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;
        if(name=="record_date" ) SetRecordDate(value);

        if (name == "start_date") {setStartDate(value)
            const startdate=moment(value).format("MM/DD/YYYY");
            const enddate=moment(end_date).format("MM/DD/YYYY")
            setIsLoading(true);


            let data = await API_Auth.getTransactionLogAdmin(startdate,enddate);
            
            setIsLoading(false);
            var slice = data.slice(offset, offset + perPage);
            setPageCount(Math.ceil(data.length / perPage));
            setOrgTableData(data);
            setTableData(slice);
            setSearchData(data);
        };
        if (name == "end_date") {
            
            setEndDate(value);
            const startdate=moment(start_date).format("MM/DD/YYYY");
            const enddate=moment(value).format("MM/DD/YYYY")

            setIsLoading(true);

            let data = await API_Auth.getTransactionLogAdmin(startdate,enddate);
            setIsLoading(false);
            var slice = data.slice(offset, offset + perPage);
            setPageCount(Math.ceil(data.length / perPage));
            setOrgTableData(data);
            setTableData(slice);
            setSearchData(data);  
        }

    }

   

    const handleSearch = (e) => {
        let filterValue = e.target.value
        setCurrentPage(0);
        if (filterValue == '') {
            let offsetsearch = 0;
            var slice = orgtableData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(orgtableData.length / perPage));
            setOrgTableData(orgtableData);
            setTableData(slice);
            setOffSet(offsetsearch);
            setSearchData(orgtableData);
            setSearchKey('n');
            setCurrentPage(0);
        }
        else {
            let value1 = filterValue.toLowerCase();
            let offsetsearch = 0;
            const filteredData = orgtableData.filter(item => {
                return (item ?.AssetName ?.toLowerCase() ?.includes(value1) ||item ?.FromStoreName ?.toLowerCase() ?.includes(value1) ||
                    item ?.ToStoreName ?.toLowerCase().includes(value1) ||item ?.Txhash ?.toLowerCase() ?.includes(value1) ||
                    item ?.Quantity?.toString().toLowerCase().includes(value1) ||
                    item ?.AssetType?.toLowerCase().includes(value1) || item ?.cid ?.includes(value1)
                    ||moment(item.UDate).format('DD MMM YYYY').includes(value1))
            });
            const slice = filteredData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(filteredData.length / perPage))
            setSearchKey('y');
            setOffSet(offsetsearch);
            setSearchKeyTable(filteredData);
            setTableData(slice);
            setSearchData(filteredData);
        }
 

    }
    const handlerowsperPage = (e) => {
        let showperpage = e.currentTarget.value
        setPerPage(parseInt(showperpage));
        setCurrentPage(0);
        setOffSet(0);

        if(searchKey=="y"){
            const slice = searchKeyTable.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(searchKeyTable.length / e.currentTarget.value))
            setTableData(slice); 
            setSearchData(searchKeyTable);
   
        }
        else{
        const slice = orgtableData.slice(0, 0 + e.currentTarget.value);
        setPageCount(Math.ceil(orgtableData.length / e.currentTarget.value))
        setTableData(slice);
        setSearchData(orgtableData);
        }
    }
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offsetdata = selectedPage * perPage;
        setCurrentPage(selectedPage);
        setOffSet(offsetdata);
        loadMoreData(offsetdata);
    }

    const loadMoreData = (offsetdata) => {
        const data = orgtableData;
        setOffSet(offsetdata);
        if (searchKey == "y") {
            const slice = searchKeyTable.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(searchKeyTable.length / perPage))
            setTableData(slice);
            setSearchData(searchKeyTable);
        } else {
            const slice = data.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(data.length / perPage))
            setTableData(slice);
            setSearchData(data);
        }

    }
    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            {isLoading && <LoadingSpinner />}

            <Container>
                <Row>
                    <Col lg={4} style={{ paddingLeft: 0 }}>
                        <Title>Blockchain Transaction Log</Title>
                    </Col>
                    <Col lg={4}>
                    </Col>
                    <Col lg={4} style={{ paddingRight: 0 }}>
                        <div className="row_div_header">
                            {/*                             <p className="title">Filter By:</p> */}
                            <div className="input_div" >
                                <input type="type" style={{ margin: 0, background: 'transparent' }}
                                    name="filterValue"
                                    //    value={filterValue}
                                    onChange={(e) => handleSearch(e)}
                                    placeholder="Search" />
                                <AiOutlineSearch size={24} style={{ margin: 10, cursor: 'pointer' }} color="lightgray" />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col lg={12} sm={12} md={12} className="table_head">
                    <Row>
                       <Col >
                        <div className="inputdiv">
                            <label style={{marginTop:15,}}>Start Date</label>
                            <br />
                            <input type="date"
                                name="start_date"
                                value={start_date}
                                placeholder="dd/mm/yyyy"

                                onChange={handleInput} />
{/*                             {startDateErr != '' && <p className="alert-message">{startDateErr}</p>}
 */}
                        </div>


                    </Col>
                    <Col >
                        <div className="inputdiv">
                            <label style={{marginTop:15,}}>End Date</label>
                            <br />
                            <input type="date"  style={{width:"90%"}}
                                name="end_date"
                                value={end_date}
                                placeholder="dd/mm/yyyy"
                                onChange={handleInput} />
                        </div>
                    </Col>
                           </Row>
                       
                        <Table className="tablelist">
                            <thead>
                                <tr>
                                    <th style={{textAlign:'center'}}>Item name</th>
                                    <th style={{textAlign:'center'}}>From Store</th>
                                    <th style={{textAlign:'center'}}>To Store</th>
                                    <th style={{textAlign:'center'}}>Asset Type</th>
                                    <th style={{textAlign:'center'}}>Quantity</th>
                                    <th style={{textAlign:'center'}}>Date</th>
                                    <th style={{textAlign:'center'}}>CID</th>
                                    <th style={{textAlign:'center'}}>Transaction Hash</th>
                                </tr>
                            </thead>
                            {tableData.length == 0 && <tbody>
                                <tr >
                                    <td colSpan="12">
                                        <p className="no_Data_table">No Data Found</p>
                                    </td>
                                </tr>
                            </tbody>
                            }
                            {tableData.length != 0 && <tbody>
                                {tableData.map((obj, index) => {
                                    return (
                                        <>
                                            <tr key={index}>
                                                <td>{obj.AssetName} </td>
                                                <td style={{textAlign:'center'}}>{obj.FromStoreName}</td>
                                                <td style={{textAlign:'center'}}>{obj.ToStoreName}</td>
                                                <td style={{textAlign:'center'}}>{obj.AssetType}</td>
                                                <td style={{textAlign:'center'}}>{obj.Quantity}</td>
                                                <td style={{textAlign:'center'}}> { moment.utc(obj.UDate).format("DD MMM YYYY")}</td>

                                 {obj.cid == '' &&
                                                    <td>{obj.cid}</td>
                                                }
                                                {obj.cid != '' &&
                                                <td style={{textAlign:'center'}}> <OverlayTrigger
                                                        key={obj.cid}
                                                        overlay={<Tooltip id={`tooltip-top`}>
                                                                {obj.cid}</Tooltip>}>
                                                 <span className="table_text_overflow">{obj.cid}</span>
                                                    </OverlayTrigger></td>
                                }
                                                {obj.Txhash == '' &&
                                                    <td>{obj.Txhash}</td>
                                                }
                                                {obj.Txhash != '' &&
                                                <td style={{textAlign:'center'}}> <OverlayTrigger
                                                        key={obj.Txhash}
                                                        overlay={<Tooltip id={`tooltip-top`}>
                                                                {obj.Txhash}</Tooltip>}>
                                                 <span onClick={()=>handleGoToTrans_history(obj,"Active")} className="table_text_txhash">{obj.Txhash}</span>
                                                    </OverlayTrigger></td>
                                }
                                            </tr>
                                        </>
                                    );
                                })}
                            </tbody>
                            }
                        </Table>
                        <div>
                            <div className="pagination_row_div">
                                <p className="pagination_title">Showing items per page:</p>
                                <div className="pagination_input_div" >
                                    <select className="pagination_select"
                                        style={{ margin: 0, background: 'transparent' }}
                                        name="pagination_value"
                                        value={perPage}
                                        onChange={handlerowsperPage} >
                                        <option value={4}>4</option>
                                        <option value={8}>8</option>
                                        <option value={10}>10</option>
                                        <option value={20}>20</option>
                                    </select>
                                </div>
                            </div>
                            <div className="pagination_row_right_div">
                                <p className="pagination_total">Showing {offset + 1} to {searchData.length < offset + perPage &&
                                    <span>{searchData.length}</span>}
                                    {searchData.length > offset + perPage &&
                                        <span>{offset + perPage}</span>} of {searchData.length} items</p>
                                <ReactPaginate
                                    previousLabel={<ArrowBackIosIcon className="pagination_prev_btn" color="#2438a7" />}
                                    nextLabel={<ArrowForwardIosIcon className="pagination_next_btn" color="#2438a7" />}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"}
                                    forcePage={currentPage} />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Modal show={showTransDetails} onHide={handleCloseTrasactionHistory}
                    aria-labelledby="contained-modal-title-vcenter"
                    dialogclassname="modal-content">
                    <Modal.Header closeButton>
                        <Modal.Title>Blockchain Transaction Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <div className="row_div">
                    <Row>
                     <Col lg={3}>
                     <p className="pcat_title">Block No:</p>
                     </Col>
                      <Col>
                      <p className="pcat_text">{transDetails.blockNumber}</p>
                      </Col>
                         </Row> 
                        <Row>
                     <Col lg={3}>
                      <p className="pcat_title">Nonce :</p>
                      </Col>
                      <Col>
                      <p className="pcat_text">{transDetails.nonce}</p>
                      </Col>
                         </Row>
                         <Row>
                     <Col lg={3}>
                     <p className="pcat_title">Gas Price:</p>
                     </Col>
                      <Col>
                      <p className="pcat_text">{transDetails.gasPrice}</p>
                      </Col>
                         </Row> 
                         <Row>
                     <Col lg={3}>
                     <p className="pcat_title">Gas Used:</p>
                     </Col>
                      <Col>
                      <p className="pcat_text">{transDetails.gas}</p>
                      </Col>
                         </Row> 
                         <Row>
                     <Col lg={3}>
                     <p className="pcat_title">From:</p>
                     </Col>
                      <Col>
                      <p className="pcat_text">{transDetails.from}</p>
                      </Col>
                         </Row> 
                         <Row>
                     <Col lg={3}>
                     <p className="pcat_title">To:</p>
                     </Col>
                      <Col>
                      <p className="pcat_text">{transDetails.to}</p>
                      </Col>
                         </Row> 
                         <Row>
                     <Col lg={3}>
                     <p className="pcat_title">Input:</p>
                     </Col>
                      <Col>
                      <OverlayTrigger
                                                        key={transDetails.input}
                                                        overlay={<Tooltip id={`tooltip-bottom`}>
                                                                {transDetails.input}</Tooltip>}>
                                     <span style={{ display: 'inline-block',
  width: 350,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'}}>{transDetails.input}</span>
                                                    </OverlayTrigger>
                      </Col>
                         </Row> 
{/*                          <Row>
                     <Col lg={3}>
                     <p className="pcat_title">S:</p>
                     </Col>
                      <Col>
                      <OverlayTrigger
                                                        key={transDetails.s}
                                                        overlay={<Tooltip id={`tooltip-top`}>
                                                                {transDetails.s}</Tooltip>}>
                                     <span style={{ display: 'inline-block',
  width: 350,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'}}>{transDetails.s}</span>
                                                    </OverlayTrigger>
                      </Col>
                         </Row> 
 */}                       
                         <Row>
                     <Col lg={3}>
                     <p className="pcat_title">Block Hash:</p>
                     </Col>
                      <Col>
                      <OverlayTrigger
                                                        key={transDetails.blockHash}
                                                        overlay={<Tooltip id={`tooltip-top`}>
                                                                {transDetails.blockHash}</Tooltip>}>
                                     <span style={{ display: 'inline-block',
  width: 350,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'}}>{transDetails.blockHash}</span>
                                                    </OverlayTrigger>
                      </Col>
                         </Row>

                        </div>
                    </Modal.Body>
                </Modal>
            </Container>
        </Wrapper>
    )
}
export default TransactionLog