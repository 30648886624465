import styled from "styled-components";

export const Wrapper = styled.div`

.status_div {
  background:#FD7E1433;
  text-align:center;
  border-radius:4px;
  font-family:Roboto;
  height:25px;
  padding:2px;
  font-size:13px;
}

.status_div1 {
  background:#19875433;
  text-align:center;
  border-radius:4px;
  font-family:Roboto;
  height:25px;
  padding:2px;
  font-size:13px;

}
.status_div2 {
  background:#F1154133;
  text-align:center;
  border-radius:4px;
  font-family:Roboto;
  height:25px;
  font-size:13px;

  padding:2px;
}

.title{
    font-family:Roboto;
    font-size:16px;
    font-weight:600;
    margin-left:10px;
    margin-bottom:0 !important;
    margin-top:15px;

}
.subtitle{
    font-family:Roboto;
    font-size:24px;
    font-weight:600;
    margin-left:10px;
    margin-top:0 !important
}
.rowdiv {
    display:flex;
    flex-direction:row;
}
.input_div {
    border: 1px solid #00001F24;
    width: 70%;
    color:var(--black);
    font-family: 'ARIAL';
    height:45px;
    border-radius:6px;
    margin:5px;
    background:white;
    display:flex;
    flex-direction:row;
    justify-content:space-between
}

select{
    border: 1px solid #00001F24;
    width: 70%;
    color:var(--black);
    font-family: 'ARIAL';
    height:45px;
    border-radius:6px;
    color:lightgray;
    padding-left:10px;
    margin:10px;  
    :focus {
        outline: none !important;
        border-color:none;  
      }
      option:{
          color:black
      }
  
}
input{
    border:none;
    margin-top:5px;
    margin-left:8px;
  }
  :focus {
      outline: none !important;
      border-color:none;  
    }
   
}


.table_head {
  background:white;
  margin-top:15px;

}
.tablelist {
  margin-top:15px;
  margin-right:5px;;
}
 
thead{
  color:black;
  background:#EBEBEE;
  border:1px solid #EBEBEE;
  border-radius:10px;
}
td{
  color:black;
  font-weight:400;
  font-size:14px;
  height:60px;
  padding:15px;
  font-family:Roboto;
  text-transform: capitalize;
}
th{
  font-size:16px;
  height:50px;
  padding:14px;
  font-weight:500;
  font-family:Roboto;

}
.table-head-content {
  padding: 13px 13px 0 13px;
  background: white;
  border-bottom: 1px solid #E2E2E2;   
}  


`