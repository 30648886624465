import React, { useState, useEffect, useContext } from "react";
// Images
import Logo from "../../images/header_logo.png";
import search from "../../images/search.svg";
import notification from "../../images/notification.svg";
import human from "../../images/human.svg";
// Styles
import {
  Wrapper,
  Content,
  LCLogoImg,
  SettlemintLogoImg,
} from "./styles";
// Routing
import { Link, useNavigate } from "react-router-dom";
// Context
import { Justify } from "react-bootstrap-icons";
import { PropTypes } from "prop-types";
import { FaRegBell, FaUserAlt } from "react-icons/fa";
import {AiOutlineMail} from "react-icons/ai";
//import {InProgress} from "../../images/InProgress.gif";

export const LoadingSpinner = () => {  

  
  return (
    <Wrapper>
     <div className="spinner-container">
       <div className="loading-spinner"></div> 
{/* <img src={require("../../images/InProgress.gif")} alt="InProgress" style={{height:"100%",width:"100%"}}/> */}
    </div>
    
    </Wrapper>
  );
};

