import React, { useState, useEffect, useContext } from "react";
import { Wrapper } from "./StockRegister.styles";
import { UserContext } from "../../context";
import { LoadingSpinner } from "../LoadingSpinner/index";
import { Row, Container, Col, Table } from "react-bootstrap"
import { Title } from "../../GlobalStyle";
import API_Auth from "../../API_Auth";
import moment from "moment";
import { AiOutlineSearch } from "react-icons/ai";
import ReactPaginate from 'react-paginate';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Modal from 'react-bootstrap/Modal';

import * as FileSaver from 'file-saver';

import * as XLSX from 'xlsx';



const StockRegister = () => {
    const {
        isExpanded, mainStoreId
    } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const [start_date, setStartDate] = useState('');
    const [startDateErr, setStartDateErr] = useState('');
    const [end_date, setEndDate] = useState('');
    const [endDateErr, setEndDateErr] = useState('');
    const [storeid, setStoreId] = useState('');
    const [subStores, setSubStores] = useState('');
    const [stockData, setStockData] = useState('');
    const [storeErr, setStoreErr] = useState('');
    const [offset, setOffSet] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [orgtableData, setOrgTableData] = useState([]);
    const [perPage, setPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [searchKey, setSearchKey] = useState('n');
    const [searchKeyTable, setSearchKeyTable] = useState('');

    const [searchData, setSearchData] = useState([]);
    const [show, setShow] = useState(false);
    const [assetData, setAssetData] = useState([]);

    const [qunatity, setQuantity] = useState('');
    const[assetName,setAssetName]=useState('');

    useEffect(() => {
        const date = new Date();
        setIsLoading(true)


        const futureDate = date.getDate();
        date.setDate(futureDate);
        const defaultValue = date.toLocaleDateString('en-CA');
        
        setStartDate(defaultValue);
        setEndDate(defaultValue);
        const fetchStores = async () => {
            let subStores = await API_Auth.getSubstores();
            subStores.sort((a, b) => a.StoreName.localeCompare(b.StoreName))
            setSubStores(subStores);

            setStoreId('all');
            var data = await API_Auth.getStockAll(defaultValue, defaultValue);
            setIsLoading(false)
            setStockData(data);
            var slice = data.slice(offset, offset + perPage);
            setPageCount(Math.ceil(data.length / perPage));
            setOrgTableData(data);
            setTableData(slice);
            setSearchData(data);


        };


        // call the function
        fetchStores()
            // make sure to catch any error
            .catch(console.error);
    }, []);
    const handleSearch = (e) => {
        let filterValue = e.target.value
        
        setCurrentPage(0);
        if (filterValue == '') {
            let offsetsearch = 0;
            var slice = orgtableData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(orgtableData.length / perPage));
            setOrgTableData(orgtableData);
            setTableData(slice);
            setOffSet(offsetsearch);
            setSearchData(orgtableData);
            setSearchKey('n');
            setCurrentPage(0);
        }
        else {
            let value1 = filterValue.toLowerCase();
            let offsetsearch = 0;
            const filteredData = orgtableData.filter(item => {
                return (item ?.AssetName ?.toLowerCase() ?.includes(value1) ||
                    item ?.AssetType ?.toLowerCase().includes(value1) ||item ?.TotalQTY ?.toString().toLowerCase() ?.includes(value1) ||
                        item ?.UOM ?.toLowerCase() ?.includes(value1) || item ?.CategoryName ?.toLowerCase() ?.includes(value1) ||
                            item ?.SubCategoryName ?.toLowerCase() ?.includes(value1))
            });
            const slice = filteredData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(filteredData.length / perPage))
            setSearchKey('y');
            
            setOffSet(offsetsearch);
            setSearchKeyTable(filteredData);
            setTableData(slice);
            setSearchData(filteredData);
        }
    }
    const handlerowsperPage = (e) => {
        
        let showperpage = e.currentTarget.value
        setPerPage(parseInt(showperpage));
        setCurrentPage(0);
        setOffSet(0);
        if (searchKey == "y") {
            const slice = searchKeyTable.slice(0, 0 + e.currentTarget.value);
            
            setPageCount(Math.ceil(searchKeyTable.length / e.currentTarget.value))
            setTableData(slice);
            setSearchData(searchKeyTable);

        }
        else {
            const slice = orgtableData.slice(0, 0 + e.currentTarget.value);
            
            setPageCount(Math.ceil(orgtableData.length / e.currentTarget.value))
            setTableData(slice);
            setSearchData(orgtableData);
        }
    }
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offsetdata = selectedPage * perPage;
        setCurrentPage(selectedPage);
        setOffSet(offsetdata);
        loadMoreData(offsetdata);
    }

    const loadMoreData = (offsetdata) => {
        
        const data = orgtableData;
        
        setOffSet(offsetdata);
        if (searchKey == "y") {
            const slice = searchKeyTable.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(searchKeyTable.length / perPage))
            setTableData(slice);
            setSearchData(searchKeyTable);
        } else {
            const slice = data.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(data.length / perPage));
            setTableData(slice);
            setSearchData(data);
        }
    }
    const handleInput = (e) => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;
        setTableData([])
        if (name == "start_date") setStartDate(value);
        if (name == "end_date") setEndDate(value);
        if (name == "store") setStoreId(value);

    }

    const handleStock = async () => {
        let error = false;
        setStartDateErr("");
        setEndDateErr("");
        setStoreErr("");
        setIsLoading(true)


        if (start_date == "") {
            setStartDateErr("Please Select Start Date");
            error = true
        }
        if (end_date == "") {
            setEndDateErr("Please Select End Date");
            error = true
        }
        if (storeid == "") {
            setStoreErr("Please Select Store");
            error = true
        }
        if (!error) {
            const startdate = moment(start_date).format("MM/DD/YYYY");
            const enddate = moment(end_date).format("MM/DD/YYYY");
            var data;
            if (storeid == "all") {
                data = await API_Auth.getStockAll(startdate, enddate);

            }
            else {

                data = await API_Auth.getStock(startdate, enddate, storeid);
            }
            setIsLoading(false)
            setStockData(data);
            var slice = data.slice(offset, offset + perPage);
            setPageCount(Math.ceil(data.length / perPage));
            setOrgTableData(data);
            setTableData(slice);
            setSearchData(data);
        }

    }


    const exportToCSV = (csvData) => {

        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

        const fileExtension = '.xlsx';
        const fileName = 'StockDetails'

        const ws = XLSX.utils.json_to_sheet(csvData);

        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

        const data = new Blob([excelBuffer], { type: fileType });

        FileSaver.saveAs(data, fileName + fileExtension);

    }
    const handleView = async (obj, qty,name) => {
        let data = await API_Auth.getStockByAssetId(obj.AssetID);
        setAssetData(data);
        setShow(true);
        setQuantity(qty);
        setAssetName(name);
    }
    const handleClose = () => {
        setShow(false)
    }

    return (

        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>

            {isLoading && <LoadingSpinner />}
            <Container>
                <Row>
                    <Col lg={4} >
                        <Title>Stock Register</Title>
                    </Col>
                    <Col lg={5}></Col>
                    {/* <Col lg={3}>
                        <div className="row_table_div " onClick={() => ExportToExcel()} style={{ width: 150, margin: 15, fontWeight: 'bold', float: 'left' }}>
                            <p style={{ marginLeft: 20 }}>EXPORT EXCEL</p>
                        </div></Col> */}
                </Row>
                <Row>
                    <Col lg={5}>
                        <div className="inputdiv">
                            <label>Start Date</label>
                            <br />
                            <input type="date"
                                name="start_date"
                                value={start_date}
                                placeholder="dd/mm/yyyy"

                                onChange={handleInput} />
                            {startDateErr != '' && <p className="alert-message">{startDateErr}</p>}

                        </div>


                    </Col>
                    <Col lg={5}>
                        <div className="inputdiv">
                            <label>End Date</label>
                            <br />
                            <input type="date"
                                name="end_date"
                                value={end_date}
                                placeholder="dd/mm/yyyy"

                                onChange={handleInput} />
                            {endDateErr != '' && <p className="alert-message">{endDateErr}</p>}

                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={5}>
                        <div className="inputdiv">
                            <label>Stores</label>
                            <br />
                            <select
                                value={storeid}
                                name="store"
                                onChange={handleInput} >
                                {subStores.length != 0 && <>
                                    <option value="">Select Store</option>
                                    <option value="all">All Stores</option>

                                    {subStores.map((item, index) => {
                                        return (
                                            <option value={item.StoreID}>{item.StoreName}</option>
                                        )
                                    })}
                                </>
                                }


                            </select>
                            {storeErr != '' && <p className="alert-message">{storeErr}</p>}

                        </div>
                    </Col>
                 
                </Row>
                <Row>
                    <Col lg={4}></Col>
                    <Col lg={4}>
                        <div className="row_div">
                            <button className="add_button" onClick={() => handleStock()}>

                                <p className="pcat_title">SUBMIT</p></button>
                        </div></Col>

                    <Col lg={4}></Col>

                </Row>
            </Container>
            <Title style={{ marginLeft: 20 }}>Stock Details</Title>
            <Row>
            <Col lg={4}>
                            <label style={{marginTop:10,}}>Search</label>

                                <div className="row_div">
                                    <div className="input_div" >
                                        <input type="type" style={{ margin: 0, background: 'transparent' }}
                                            name="filterValue"
                                            onChange={(e) => handleSearch(e)}
                                            placeholder="Search" />
                                        <AiOutlineSearch size={24} style={{ margin: 10, cursor: 'pointer' }} color="lightgray" />
                                    </div>

                                </div></Col>
                   
                </Row>

            {tableData.length == 0 && <div>
                <p style={{ textAlign: 'center', margin: 15, fontFamily: 'Roboto', fontWeight: 500, padding: 20 }}>No Data Found</p>
            </div>
            }
            {tableData.length != 0 && <div>

                <Row>
                                <Col lg={9}></Col>
                    <Col lg={2} style={{marginTop:20}}>
                        <button
                            className="export_button"
                            onClick={(e) => exportToCSV(stockData)}>
                            <p className="pcat_title">Export Excel</p>
                        </button>

                    </Col>
                </Row>

                <Table style={{ marginTop: 10 }}>
                    <thead>
                        <th style={{ textAlign: 'center' }}>Asset Name</th>
                        <th style={{ textAlign: 'center' }}> Asset Type</th>
                        <th style={{ textAlign: 'center' }}> UOM</th>
                      <th style={{ textAlign: 'center' }}> Category</th>
                      <th style={{ textAlign: 'center' }}> Sub Category</th>

                        {storeid != "all" && <th style={{ textAlign: 'center' }} >Opening Quantity</th>}
                        {storeid != "all" && <th style={{ textAlign: 'center' }}>Closing Quantity</th>}
                        {storeid == "all" && <th style={{ textAlign: 'center' }}> Quantity</th>}
                    

                        {storeid == "all" &&   <th style={{ textAlign: 'center' }}> Action</th>}




                    </thead>
                    <tbody>
                        {tableData.map((item, index) => {
                            return (
                                <tr>
                                    <td style={{ textAlign: 'center' }}>{item.AssetName}</td>
                                    <td style={{ textAlign: 'center' }}> {item.AssetType}</td>
                                    <th style={{ textAlign: 'center' }}> {item.UOM}</th>
                                    <td style={{ textAlign: 'center' }}> {item.CategoryName}</td>
                                <td style={{ textAlign: 'center' }}> {item.SubCategoryName}</td>

                                    {storeid != "all" && <td style={{ textAlign: 'center' }}>{item.Opening}</td>}
                                    {storeid != "all" && <td style={{ textAlign: 'center' }}>{item.Closing}</td>}
                                    {storeid == "all" && <td style={{ textAlign: 'center' }}>{item.TotalQTY}</td>}
                                

                                     {storeid == "all" && <td>
                                        <div className="row_table_edit " onClick={() => handleView(item, item.TotalQTY,item.AssetName)}>
                                            <p>VIEW</p>
                                        </div>
                                    </td>
                                     }
                                    

                                </tr>
                            )
                        })}
                    </tbody>
                </Table>

            </div>}
            {tableData.length != 0 &&
                <div>
                    <div className="pagination_row_div">
                        <p className="pagination_title">Showing items per page:</p>
                        <div className="pagination_input_div" >
                            <select className="pagination_select"
                                style={{ margin: 0, background: 'transparent' }}
                                name="pagination_value"
                                value={perPage}
                                onChange={handlerowsperPage} >
                                <option value={4}>4</option>
                                <option value={8}>8</option>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                            </select>
                        </div>
                    </div>
                    <div className="pagination_row_right_div">
                        <p className="pagination_total">Showing {offset + 1} to {searchData.length < offset + perPage &&
                            <span>{searchData.length}</span>}
                            {searchData.length > offset + perPage &&
                                <span>{offset + perPage}</span>} of {searchData.length} items</p>
                        <ReactPaginate
                            previousLabel={<ArrowBackIosIcon className="pagination_prev_btn" color="#2438a7" />}
                            nextLabel={<ArrowForwardIosIcon className="pagination_next_btn" color="#2438a7" />}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                            forcePage={currentPage} />
                    </div>
                </div>
            }


            <Modal show={show}
                aria-labelledby="contained-modal-title-vcenter"
                dialogclassname="modal-content">
                <Modal.Header onClick={() => handleClose()} closeButton>
                    <Modal.Title> Store Wise Details</Modal.Title>
                </Modal.Header>

                <Modal.Body >
                    <>
                        <div>
                            <p style={{
                                color:"black",
                                fontSize:18,
                                marginTop:10,
                                fontWeight:"700",
                                textAlign:'center'
                            }}>{assetName}</p>
                            </div>
                        <Table style={{ marginTop: 10 }}>
                            <thead>
                                <th style={{ textAlign: 'center' }}>Store Name</th>
                                <th style={{ textAlign: 'center' }} > Quantity</th  >




                            </thead>
                            {assetData.length != 0 && <>
                                < tbody >
                                    {
                                        assetData.map((item, index) => {
                                            return (
                                                <tr>

                                                    <td style={{ textAlign: 'center' }}>{item.StoreName}</td>
                                                    <td style={{ textAlign: 'center' }} > {item.Quantity}</td >
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                             
                            </>
                            }

                            {assetData.length == 0 && <>
                                <p style={{ textAlign: 'center', margin: 15, fontFamily: 'Roboto', fontWeight: 500, padding: 20 }}>No Data Found</p>

                            </>
                            }
                        </Table>
                        <Row style={{padding:0,margin:0,}}>
                                 
                                 <Col lg={8} style={{ textAlign: 'center',marginLeft:-10 }}><label>Total</label></Col>
                                 <Col  lg={4} style={{ textAlign: 'center',marginLeft:-10 }}><label>{qunatity}</label></Col>
                               
                         </Row>

                    </>
                </Modal.Body>

            </Modal>



        </Wrapper>
    )
}
export default StockRegister