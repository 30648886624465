import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context"; import { Container, Row, Col } from "react-bootstrap";
import { Wrapper } from "./AddAdminVendor.styles";
import API_Auth from "../../API_Auth";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { LoadingSpinner } from "../LoadingSpinner/index";
import {IoIosArrowBack} from "react-icons/io";

const AddAdminVendor = () => {
    let { vendorID } = useParams();
    const navigate = useNavigate();
    const {
        isExpanded,
    } = useContext(UserContext);
    const [vendorName, setVendorName] = useState('');
    const [email, setEmail] = useState('');
    const [contact, setContact] = useState('');
    const [address, setAddress] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const [typeChecked, setChecked] = useState(true);



    const [formTitle, setFormTitle] = useState('Add Vendor');
    const [formButtonValue, setFormButtonValue] = useState('ADD VENDOR');

    const [vendorNameErr, setVendorNameErr] = useState('');
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [status, setStatus] = useState('')
    const [satusErr, setStatusErr] = useState('')



    const handleInput = (e) => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;
        if (name === "vendorName") setVendorName(value);
        if (name === "email") setEmail(value);
        if (name === "contact") setContact(value);
        if (name === "address") setAddress(value);
        if (name === "status") setStatus(value)
    };
    const handleAddAsset = async () => {
        let error = false;
        if (vendorName == '') {
            setVendorNameErr('This field is required');
            error = true;
        }
        else {
            setVendorNameErr('  ');
        }
        if (!error) {
            setIsLoading(true)
            setDisableSubmit(true);
            if (vendorID == 'add') {
                let result = await API_Auth.addVendor(vendorName, contact, email, address, status);
                let res = result[0];
                if (res.Flag == 'Success') {
                    setIsLoading(false);

                    toast.success('Vendor added successfully');
                    setTimeout(() => {
                        navigate("/admin-vendors");
                    }, 2000);

                } else {
                    setIsLoading(false);

                    setDisableSubmit(false);
                    toast.error('Something went wrong please try again');
                }
            } else {
                let result = await API_Auth.updateVendor(vendorName, contact, email, address, vendorID, status);
                let res = result[0];
                if (res.Flag == 'Success') {
                    setIsLoading(false);

                    toast.success('Vendor updated successfully');
                    setTimeout(() => {
                        navigate("/admin-vendors");
                    }, 2000);
                } else {
                    setIsLoading(false);
                    setDisableSubmit(false);
                    toast.error('Something went wrong please try again');
                }
            }
        }
    }

    useEffect(() => {
        const fetchVendor = async () => {

            if (vendorID != 'add') {
                let res = await API_Auth.getVendorById(vendorID);
                let vendor_det = res[0];
                setVendorName(vendor_det.VendorName);
                setContact(vendor_det.VendorCno);
                setEmail(vendor_det.VendorEmail);
                setAddress(vendor_det.VendorAdd);
                setFormTitle('Edit Vendor');
                setFormButtonValue('UPDATE VENDOR');
            }
        };
        // call the function
        fetchVendor()
            // make sure to catch any error
            .catch(console.error);
    }, []);
    const handleBackClick = () => {
        navigate("/admin-vendors");
        navigate(0);

    }
    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            <Container>
                <Row className="row_title">
                    <Col lg={4}><p>{formTitle}</p></Col>
                    <Col lg={6}></Col>
                    <Col lg={2}>
                        <div className="back_button" onClick={() => handleBackClick()}>
                            <IoIosArrowBack size={18} />
                            <h6>Back</h6>
                        </div>
                    </Col>
                </Row>
            </Container>
            {isLoading && <LoadingSpinner />}

            <hr />

            <Container>
                <Row>
                    <Col lg={10}>
                        <Row>
                            <Col>
                                <div className="inputdiv">
                                    <label>Vendor Name</label>
                                    <br />
                                    <input type="text"
                                        name="vendorName"
                                        value={vendorName}
                                        placeholder="Vendor Name"
                                        onChange={handleInput} />
                                    {vendorNameErr != '' && <p className="alert-message">{vendorNameErr}</p>}
                                </div>
                            </Col>
                            <Col>
                                <div className="inputdiv">
                                    <label>Phone no</label>
                                    <br />
                                    <input type="text"
                                        name="contact"
                                        value={contact}
                                        placeholder="Enter Contact No"
                                        onChange={handleInput} />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={10}>
                        <Row>
                            <Col>
                                <div className="inputdiv">
                                    <label>Email Id</label>
                                    <br />
                                    <input type="email"
                                        name="email"
                                        value={email}
                                        placeholder="Enter Email "
                                        onChange={handleInput} />
                                </div>
                            </Col>
                            <Col>
                                {/*  <div className="inputdiv">
                                    <label>Status</label>
                                    <br />

                                    <select name="status"
                                        value={status}
                                        placeholder="Select Status "

                                        onChange={handleInput}>
                                        <option value="Select">Select Status</option>
                                        <option value="Active"> Active</option>
                                        <option value="InActive"> In Active</option>


                                    </select>
                                    {satusErr != '' && <p className="alert-message">{satusErr}</p>}

                                </div>
 */}
                                <div className="inputdiv">
                                    <label>Address</label>
                                    <br />
                                    <textarea
                                    type="text"
                                        name="address"
                                        value={address}
                                        placeholder="Enter Address"
                                        rows={5}
                                        style={{ height: 100, }}
                                        cols={5}
                                        onChange={handleInput} />
                                </div>
                            </Col>
                        </Row>
                    </Col>

                </Row>
                <Row>
                    <Col lg={5}>

                    </Col>

                </Row>
                <Row>
                    <Col lg={5}>
                        <div className="row_div">
                            <button className="add_button" disabled={disableSubmit} onClick={() => handleAddAsset()}>
{/*                                 <img src={plus} style={{ margin: 5 }} /> */}
                                <p className="pcat_title">{formButtonValue}</p></button>
                        </div>
                    </Col>
                </Row>

            </Container>
            <ToastContainer />
        </Wrapper>
    )
}
export default AddAdminVendor