

import styled from "styled-components";

export const Wrapper = styled.div`
background:white;
margin:20px;
border-radius:6px;
width:70%;
.rowdiv {
  display:flex;
  flex-direction:row;
}
.input_div {
  border: 2px solid #DFDFDF;
  width: 70%;
  color:var(--black);
  font-family: 'ARIAL';
  height:45px;
  border-radius:6px;
  margin: 10px ;
        background:white;
  display:flex;
  flex-direction:row;
  justify-content:space-between
}

.inputdiv{
  flex-direction:row;
  display:flex;
  border: 2px solid #DFDFDF;
  height: 40px;
  border-radius: 6px;
  margin: 15px ;
  padding: 10px; 

}
.textareaclass{
border: 2px solid #00001F24;
padding-left:15px;
margin-top:10px;
border-radius: 4px;
font-size: 16px;
resize: none;
:focus {
  outline: none !important;
  border-color:none;  
}
}

.row_table_divs {

  background:#6EDC8F;
  color:black;
  border:#6EDC8F;
  display:flex;
  flex-direction:row;
  border-radius:4px;
  cursor:pointer; 
  float:right;
  p{
    margin:10px 0px;
    font-size:12px;
    font-weight:bold;

  }
}

.subtitle1{
  color:black;
  font-family:Roboto;
  font-weight:500;
  margin-top:18px;
  font-size:17px;
  margin-left:2px;
  text-transform:capitalize;
}
.print_div {
  display:flex;
  flex-direction:row;
  background:#000044;
  color:white;
  border:#4EACFB;
  padding:2px;
  height:30px;
  margin:15px;
  width:30%;
  text-align:center;
  justify-content:center;
  align-item:center;
  box-shadow: 0px 2px 2px #00000030;
border-radius: 6px;
opacity: 1;
}
.div_Container {
  margin:20px;
  border:2px solid #DFDFDF;
  border-radius:12px;
  opacity:1;
  padding:10px;
  p{
    font-family:Roboto;
    color:black;
    font-size:13px;
    font-weight:800;
    margin-top:10px;
    margin-left:15px;
    font-weight:bold;
    
  }  
}
.alert-message{
  color: red;
  text-align:center;
  font-weight:700
}
.title{
  font-family:Roboto;
  font-size:18px;
  font-weight:600;
  margin-left:20px;
  margin-bottom:0 !important;
  margin-top:16px;
  text-transform:capitalize;

}
.subtitle{
  font-family:Roboto;
  font-size:24px;
  font-weight:600;
  margin-left:20px;
  margin-top:0 !important
}
.row_div {
    margin:10px;
  
  }
select{
    border: 1px solid #00001F24;
    padding-left: 10px;

    width: 90%;
    color:var(--black);
    font-family: 'ARIAL';
    height:45px;
    border-radius:6px;
    margin:10px;
    :focus {
        outline: none !important;
        border-color:none;  
      }
}




.check_input {
  height:20px;
  margin:10px;
  width: 12%;


}
.check_div{
  flex-direction: row;
   display: flex;
   margin:10px;
   p{
    color:black;
    margin-left:1px;
    margin-top:10px;
    font-weight:bold;
    font-size:14px;
    font-family:Roboto
   }

}
.add_button {
  display:flex;
  flex-direction:row;
  background:#000044;
  color:white;
  border:#4EACFB;
  padding:15px;
  height:50px;
  margin:15px;
  width:45%;
  text-align:center;
  justify-content:center;
  align-item:center;
  box-shadow: 0px 2px 2px #00000030;
border-radius: 6px;
opacity: 1;  
}
.tableHead{
  th{
    font-size:15px;
  }
}
.table_head {
  background:white;
  margin-top:15px;
}
.tablelist {
  margin-top:15px;
  margin-right:5px;;
}
 
thead{
  color:black;
  background:#c0d3ef;
  border:1px solid #c0d3ef;
  border-radius:10px;
}
td{
  color:black;
  font-weight:400;
  font-size:16px;
  padding:15px;
  font-family:Roboto;
  text-transform: capitalize;
  border:none;
}
.custom-file-signature{
    border:1px solid  #00000029;
    border-radius:6px;
    width:80%;
    height:60px;
    margin-top:45px;
    p{
        text-align:center;
        margin-top:20px;
        font-weight:400
    }
}

.add_buttons{
  background:#6EDC8F;
  cursor:pointer;
  color:black;
  border:#6EDC8F;
  padding:10px;
  height:40px;
  margin:15px;
  width:15%;
  text-align:center;
  justify-content:center;
  align-item:center;
  box-shadow: 0px 2px 2px #00000030;
border-radius: 6px;
opacity: 1;

}
.send_button {
   
  background:#6EDC8F;
  cursor:pointer;
  color:black;
  border:#6EDC8F;
  padding:10px;
  height:40px;
  margin:15px;
  width:15%;
  text-align:center;
  justify-content:center;
  align-item:center;
  box-shadow: 0px 2px 2px #00000030;
border-radius: 6px;
opacity: 1;

}

th{
  font-size:18px;
  height:50px;
  padding:14px;
  font-weight:500;
  font-family:Roboto;

}
.table-head-content {
  padding: 13px 13px 0 13px;
  background: white;
  border-bottom: 1px solid #E2E2E2;   
}  



input {
    border: 2px solid #DFDFDF;
    width: 100%;
    padding-left: 10px;

    color:var(--black);
    font-family: 'ARIAL';
    height:45px;
    border-radius:6px;
    margin:10px;
  }
  input[type="file"] {
    display: none;
}


  :focus {
      outline: none !important;
      border-color:none;  
    }
  
   
`
export const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;
export const RowInput = styled.div`
  border:2px solid #DFDFDF;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  width: 50%;
  height: 50px;
  margin :20px auto 10px;
  @media screen and (max-width: 1365px) {
    width:55%;

  }
  @media screen and (max-width: 1199px) {
    width: 65%;

  }
  @media screen and (max-width: 991px) {
    width: 85%;

  }
  @media screen and (max-width: 768px) {
    width: 90%;
  }
  input {
    border: none;
    width: 90%;
    color:var(--black);
    font-family: 'ARIAL';
    margin:15px;
    ::placeholder,
   ::-webkit-input-placeholder {
    color: #A09E9E;
    font: normal normal normal 14px/19px Roboto!important;
    letter-spacing: 0px;
  }
  :-ms-input-placeholder {
    color: #A09E9E;
    font: normal normal normal 14px/19px Roboto!important;
    letter-spacing: 0px;

  }
  :focus {
      outline: none !important;
      border-color:none;  
    }
  }


`

export const Switch = styled.div`
   position: relative;
  width: 60px;
  height: 28px;
  background: #b3b3b3;
  border-radius: 32px;
  padding: 4px;
  transition: 300ms all;

  &:before {
    transition: 300ms all;
    content: "";
    position: absolute;
    width: 28px;
    height: 28px;
    border-radius: 35px;
    top: 50%;
    left: 4px;
    background: white;
    transform: translate(0, -50%);
  }
`;

export const Input = styled.input`
  opacity: 0;
  position: absolute;
  margin-left:20px;

  &:checked + ${Switch} {
    background: green;

    &:before {
      transform: translate(32px, -50%);
    }
  }
`;
