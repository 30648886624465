import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Container, Row, Col, OverlayTrigger, Tooltip, } from "react-bootstrap";
import { Wrapper } from "./styles.js"
import API_Auth from "../../API_Auth";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Title } from "../../GlobalStyle.js"
import Table from "react-bootstrap/Table";
import moment from "moment"
import { IoIosArrowDown, IoIosArrowUp, IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { LoadingSpinner } from "../LoadingSpinner/index";

const AssetAdjustment = () => {
    const navigate = useNavigate();
    // let {StorID,assetID } = useParams();
    const location = useLocation();

    const {
        isExpanded,
    } = useContext(UserContext);
    const [asset_data, setasset_data] = useState(location.state ?.asset_data);
    const [StorID, setStoreID] = useState(location.state ?.StoreID);
    const [isLoading, setIsLoading] = useState(false);

    const [formTitle, setFormTitle] = useState('Item Adjustment');
    const [qunatity, setQunaity] = useState(asset_data.Quantity);
    const [adjust_date, setDate] = useState(new Date());
    const [dateErr, setDateErr] = useState('');
    const [adjust_qty, setAdjust] = useState('');
    const [comments, setComments] = useState('')
    const [formButtonValue, setFormButtonValue] = useState('UPDATE');
    const [qunatityErr, setQunatityErr] = useState('')
    const [adjustErr, setAdjustErr] = useState('')
    const [commentsErr, setCommentsErr] = useState('');
    const [batchNo, setBatchNo] = useState('');
    const [batchErr, setBatchErr] = useState('')
    const [viewAssetsData, setviewAssetsData] = useState([]);
    const [defaultDate, setDefaultDate] = useState([]);
    const [radioValue, setRadioValue] = useState('');
    const [radioErr, setRadioErr] = useState('');
    const [disableSubmit, setDisableSubmit] = useState(false);

    const handleInput = (e) => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;
        if (name === "adjust_date") setDate(value);
        if (name === "qunatity") setQunaity(value);
        if (name === "adjust_qty") setAdjust(value);
        if (name === "comments") setComments(value);
        if (name === "batchNo") setBatchNo(value);
        if (name === "radio") setRadioValue(value);

    };

    useEffect(() => {

        const date = new Date();
        const futureDate = date.getDate();
        date.setDate(futureDate);
        const defaultValue = date.toLocaleDateString('en-CA');
        setDate(defaultValue)


        setIsLoading(true);


        const fetchAsset = async () => {
            let result = await API_Auth.getConsumeAssetView(StorID, asset_data.AssetID,"Consumable");
            result.sort((a, b) => b.TDate.localeCompare(a.TDate))
            setviewAssetsData(result);
            setIsLoading(false);

        };
        // call the function
        fetchAsset()
            // make sure to catch any error
            .catch(console.error);
    }, []);

    const handleAddAsset = async () => {
        setQunatityErr('');
        setAdjustErr('');
        setCommentsErr('');
        setDateErr('');
        setBatchErr('');
        setRadioErr('');
        setDisableSubmit(true);
        setIsLoading(true)

        let error = false;
     /*    if (qunatity == '') {
            setQunatityErr('This field is required');
            error = true;
        } */
        if (adjust_qty == '') {
            setAdjustErr('This field is required');
            error = true;
            setDisableSubmit(false);

        }
        if (adjust_date == '') {
            setDateErr('This field is required');
            error = true;
            setDisableSubmit(false);

        }
        if (radioValue == "") {
            setRadioErr('This field is required');
            error = true;
            setDisableSubmit(false);

        }
        /* if (batchNo == "") {
            setBatchErr('This field is required');
            error = true;
        }
        if (comments == '') {
            setCommentsErr('This field is required');
            error = true;
        } */
        if (!error) {
            let assetType='consumable'
            let result = await API_Auth.adjustConsumeAsset(asset_data.AssetID, StorID, comments, batchNo, assetType,adjust_qty, adjust_date, radioValue);
            let res = result[0];
            if (res.Flag == 'Success') {
                toast.success('Adjustment successful');
                setIsLoading(false)

                setTimeout(() => {
                    navigate("/assets-management/" + StorID);
                }, 2000);
            } else {
                setDisableSubmit(false);
                setIsLoading(false)

                toast.error('Something went wrong please try again');
            }
        }

    }
    const handleBackClick = () => {
        navigate(-1);
    }

    return (


        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
                        {isLoading && <LoadingSpinner />}

            <Container>
                <Row className="row_title">
                    <Col lg={4}><p>{formTitle}</p></Col>
                    <Col lg={6}></Col>
                    <Col lg={2}>
                        <div className="back_button" onClick={() => handleBackClick()}>
                            <IoIosArrowBack size={18} />
                            <h6>Back</h6>
                        </div>
                    </Col>
                </Row>
            </Container>            <hr />
            <Container>
                <Row>
                    <Col lg={10}>
                        <Row>
                            <Col lg={7}>
                                <p style={{ marginLeft: 20, marginTop: 24, fontFamily: 'Roboto', fontWeight: '600' }}>
                                    {'Item'}</p>
                                <br />

                                <p style={{ marginLeft: 20, marginTop: -25, fontFamily: 'Roboto', fontWeight: '600' }}>
                                    {asset_data.AssetName}</p>
                            </Col>
                            <Col lg={5}>
                                <div>
                                    <label style={{ marginTop: 24 }}>Add/Substract</label>
                                    <br />
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <input type="radio" id="add" name="radio" value="Add" style={{ width: 25, height: 15, margin: 20 }}
                                            onChange={handleInput}
                                        />
                                        <label for="add" className="radiolabel">ADD</label>
                                        <input type="radio" id="sub" name="radio" value="Sub" style={{ width: 25, height: 15, margin: 20 }}
                                            onChange={handleInput}

                                        />
                                        <label for="sub" className="radiolabel">SUBSTRACT</label>

                                    </div>
                                    {radioErr != '' && <p className="alert-message">{radioErr}</p>}

                                </div>
                            </Col>
                        </Row>
                        <Row>
                        <Col lg={5}>
                                <div className="inputdiv">
                                    <label>Date</label>
                                    <br />
                                    <input type="date"
                                        name="adjust_date"
                                        value={adjust_date}
/*                                         placeholder={new Date()}
 */                                        onChange={handleInput} />
                                    {dateErr != '' && <p className="alert-message">{dateErr}</p>}
                                </div>
                            </Col>
                            <Col lg={5}>
                                <div className="inputdiv">
                                    <label> Current Quantity</label>
                                    <br />
                                    <input type="text"
                                        name="qunatity"
                                        value={qunatity}
                                        disabled
                                        placeholder="Quantity"
                                    // onChange={handleInput}
                                    />
                                    {qunatityErr != '' && <p className="alert-message">{qunatityErr}</p>}
                                </div>
                            </Col>
                           
                        </Row>
                        <Row>
                            <Col lg={5}>
                                <div className="inputdiv">
                                    <label>Adjust Quantity</label>
                                    <br />
                                    <input type="text"
                                        name="adjust_qty"
                                        value={adjust_qty}
                                        placeholder="Adjust"
                                        onChange={handleInput} />
                                    {adjustErr != '' && <p className="alert-message">{adjustErr}</p>}
                                </div>
                            </Col>
                            <Col lg={5}>
                              {/*   <div className="inputdiv">
                                    <label>Batch No</label>
                                    <br />
                                    <input type="text"
                                        name="batchNo"
                                        value={batchNo}
                                        placeholder="Batch No"
                                        onChange={handleInput} />
                                    {batchErr != '' && <p className="alert-message">{batchErr}</p>}
                                </div> */}
                                 <div className="inputdiv">
                                    <label>Comments</label>
                                    <br />
                                    <textarea
                                        value={comments}
                                        onChange={handleInput}
                                        name="comments" placeholder="Type Something"
                                        rows={5}
                                        style={{ height: 100, }}
                                        cols={5}
                                    />
                                    {commentsErr != '' && <p className="alert-message">{commentsErr}</p>}
                                </div>
                            </Col>
                        </Row>
                        <Row>

                            <Col lg={5}>
                               {/*  <div className="inputdiv">
                                    <label>Comments</label>
                                    <br />
                                    <textarea
                                        value={comments}
                                        onChange={handleInput}
                                        name="comments" placeholder="Type Something"
                                        rows={5}
                                        style={{ height: 100, }}
                                        cols={5}
                                    />
                                    {commentsErr != '' && <p className="alert-message">{commentsErr}</p>}
                                </div> */}</Col>
                        </Row>
                    </Col>
                    <Col lg={4}>
                        <div className="row_div">
                            <button className="add_button" disabled={disableSubmit}  onClick={() => handleAddAsset()}>
                                <p className="pcat_title">{formButtonValue}</p></button>
                        </div>
                    </Col>
                    <Container>
                        <Row className="row_title">
                        <h5>Transaction Log</h5>

                        </Row>
                        <Row>

                            <Col lg={12} sm={12} md={12} className="table_head">
                                <Table>
                                    <thead>
                                        <th>From</th>
                                        <th>To</th>
                                        <th>Qty</th>
                                        <th>Date</th>
                                        <th>Request Id</th>
                                        <th>Bill No</th>


                                        <th>Batch No</th>
                                        <th>Vendor</th>
                                        <th>MFD</th>
                                        <th>Expiry</th>
                                        <th>Price</th>
                                        <th>Comments</th>
                                    </thead>
                                    {viewAssetsData.length != 0 && <>

                                        <tbody>
                                            {viewAssetsData.map((item, index) => {
                                                return (
                                                    <tr key={index} >
                                                        <td>{item ?.FromStoreName || item ?.FromStoreID}</td>
                                                        <td>{item.ToStoreName || item ?.ToStoreID}</td>
                                                        <td>{item.Quantity}</td>
                                                        <td>{item.TDate}</td>
                                                        {item ?.requestID != '' && <td>REQ{item ?.requestID }</td>
                                                }
                                                {item ?.requestID == '' && <td>{item ?.requestID }</td>
                                                }  
                                                
                                                        <td>{item.BatchNo}</td>
                                                        <td>{item.VendorName}</td>
                                                        {item.MFD != '' && <td>{moment(item.MFD).format("DD MMM YYYY  h:mm A")}</td>}
                                                        {item.MFD == '' && <td>{moment(item.MFD).format("DD MMM YYYY  h:mm A")}</td>}

                                                        {item.EXPD != '' && <td>{moment(item.EXPD).format("DD MMM YYYY  h:mm A")}</td>}
                                                        {item.EXPD == '' && <td>{moment(item.EXPD).format("DD MMM YYYY  h:mm A")}</td>}
                                                        <td>{item.Price}</td>
                                                        {item.Tcpmment == '' &&
                                                            <td>{item.Tcpmment}</td>
                                                        }
                                                        {item.Tcpmment != '' &&
                                                            <OverlayTrigger
                                                                key={item.Tcpmment}
                                                                overlay={
                                                                    <Tooltip id={`tooltip-top`}>
                                                                        {item.Tcpmment}.
        </Tooltip>
                                                                }
                                                            >
                                                                <td className="table_text_overflow">{item.Tcpmment}</td>
                                                            </OverlayTrigger>
                                                        }
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </>
                                    }
                                    {viewAssetsData.length == 0 &&
                                        <tbody>
                                            <tr>
                                                <td colSpan="12" >
                                                    <p style={{ textAlign: 'center', fontFamily: 'Robot', fontWeight: 'bold' }}>No Data Found </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    }
                                </Table>
                            </Col>
                        </Row>
                    </Container>


                </Row>
            </Container>
            <ToastContainer />
        </Wrapper >
    )
}
export default AssetAdjustment