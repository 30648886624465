import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Wrapper } from "./styles"
import { Row, Container, Col } from "react-bootstrap"
import Table from "react-bootstrap/Table";
import Edit from "../../images/edit.png";
import plus from "../../images/white_plus.png"
import { useNavigate, Link } from "react-router-dom";
import API_Auth from "../../API_Auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Title} from "../../GlobalStyle"
import AssetData from "../json/CentralStoreConsumable.json"
import { FaPlus } from "react-icons/fa";
import { AiOutlineSearch } from "react-icons/ai";

const SubStoreConsumbleAssetList = () => {
    const {
        isExpanded,
    } = useContext(UserContext);
    const [assets, setAssets] = useState(AssetData.consumable);
    const [status, setStatus] = useState('');
    const [assets_types, setAssetTypes] = useState([{value:'adjust',asset_name:'Adjust'},
    {value:'consumble',asset_name:'Consumed'},
    {value:'issue',asset_name:'Issue'},
    {value:'requestItem',asset_name:'Request Item'},
    {value:'viewDetails',asset_name:'View Details'}]);
    
    const navigate = useNavigate();
    const[filterValue,setFilterValue]=useState("")
    const[asset_type,setAssetValue]=useState("")
    
    const [searchData,setSearchData] = useState([]);
    
    const handleSearch=(e)=>{
        let filterValue=e.target.value;
        
       if (filterValue == '') {
           setSearchData(assets)
       }
       else {
           const filteredData = assets.filter(item => {
                return (item.AssetName.toLowerCase().includes(filterValue.toLowerCase())||
                item.AssetSpacs.toLowerCase().includes(filterValue.toLowerCase())||
                item.AssetType.toLowerCase().includes(filterValue.toLowerCase())||
                item.SubCategoryName.toLowerCase().includes(filterValue.toLowerCase())||
                item.CategoryName.toLowerCase().includes(filterValue.toLowerCase()))
          });
           setSearchData(filteredData);
       }
   }
    const handleIssueAsset = (obj) => {
       // navigate("/add-admin-asset/"+obj.id);
    }
    const handleAddAsset=()=>{
        navigate("/add-admin-asset/add");

    }
    const handleAddItem=()=>{

    }
    const handleViewAssets=()=>{

    }


    const handleInput=(e,obj,index_value)=>{
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;
        if (name == "asset_type"){
/*         {assets.filter((asset,index) =>{
     if(index==index_value){
        setAssetValue(value)
        }
        })
        } */
        setAssetValue(value)
    }
/*         for(assets) */
/*         if (name == "asset_type") setAssetValue(value); */
        
        if (name == "status") {
            setStatus(status);
            let value1 = value.toLowerCase();
            let result = [];

            const filteredData = assets.filter(item => {
                if (item.status.toLowerCase().includes(value1) ) {
                    result.push(item)
                }
            });
            setAssets(result);
            setFilterValue("")

        }

       

    }
  /*   useEffect(() => {  
        const fetchAsset = async () => {           
            let result = await API_Auth.getAsset();                    
            setAssets(result);
          };                  
           // call the function
           fetchAsset()
                    // make sure to catch any error
                    .catch(console.error);    
    },[]); */
    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            <Container>
                <Row>
                    <Col lg={4}>
                        <Title>Asset List</Title>
                    </Col>
                    <Col lg={5}>
                    <div className="row_div">
{/*                             <p className="title">Filter By:</p>
 */}                            <div className="input_div" >
                                <input type="type" style={{margin:0,background:'transparent'}}
                                name="filterValue"
                            //    value={filterValue}
                                onChange={(e)=>handleSearch(e)}
                                placeholder="Search"/>
                                <AiOutlineSearch size={24} style={{ margin: 10,cursor:'pointer' }} color="lightgray"/>
                            </div>

                        </div>
                    </Col>             
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col lg={12} sm={12} md={12} className="table_head">
                        <Table className="tablelist">
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th> Name</th>
                                    <th>Quantity</th>
                                    <th>Re Order Level</th>
                                    <th>Red Alert Level</th>
                                    <th>Action</th>
                                   
                                </tr>
                            </thead>
                            <tbody>
                                {assets.map((obj, index) => {
                                    return (
                                        <>
                                            <tr key={index}class="dropdown">
                                                <td>{obj.id}</td>
                                                <td>{obj.item_name}</td>
                                                <td>{obj.quantity}</td>
                                                <td>{obj.reorder}</td>
                                                <td>{obj.redalert}</td>
                                                <td>
                                                <div className="inputdiv">
                                                <select
                                        value={asset_type}
                                        name="asset_type"
                                        onChange={(e) => handleInput(e, obj,index)}>
                                        <option >Select</option>
                                        {assets_types.map((item, index) => {
                                    return (
                                        <option  value={item.value+'_'+obj.id}>{item.asset_name}</option>
                                    )
                                })}
                                    </select>
                                    </div> 
                                  
                                                </td> 
                                                 {/* <td class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown">John <span class="caret"></span>
      <ul class="dropdown-menu">
        <li><a href="#">HTML</a></li>
        <li><a href="#">CSS</a></li>
        <li><a href="#">JavaScript</a></li>
      </ul>
    </td> */}
                                            </tr>
                                        </>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
            <ToastContainer />
        </Wrapper>
    )
}
export default SubStoreConsumbleAssetList