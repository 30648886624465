import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Container, Row, Col } from "react-bootstrap";
import { Wrapper } from "./UserProfiles.styles"
import plus from "../../images/white_plus.png"
import Edit from "../../images/white_eddit.png"
import { Title } from "../../GlobalStyle.js"
import API_Auth from "../../API_Auth";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoadingSpinner } from "../LoadingSpinner/index";
import { base_url } from "../../config"
import { IoIosArrowDown, IoIosArrowUp, IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Multiselect from 'multiselect-react-dropdown';

const UserProfile = () => {
    let { userID } = useParams();
    const navigate = useNavigate();
    const [disableSubmit, setDisableSubmit] = useState(false);
    const {
        isExpanded,
    } = useContext(UserContext);
    const [allAprrovers, setAllAprrovers] = useState([]);

    const [firstName, setFirstName] = useState('');
    const [firstNameErr, setFirstNameErr] = useState('');

    const [UserID, setUserID] = useState('');
    const [lastName, setLastName] = useState('');
    const [lastNameErr, setLastNameErr] = useState('');

    const [email, setEmail] = useState('');
    const [emailErr, setEmailErr] = useState('');

    const [password, setPassword] = useState('');
    const [passwordErr, setPasswordErr] = useState('');

    const [mobile, setMobile] = useState('');
    const [mobileErr, setMobileErr] = useState('');

    const [designation, setDesignation] = useState('');
    const [designationErr, setDesignationErr] = useState('');

    const [approver, setApprover] = useState('');
    const [approverText, setApproverText] = useState(" ");

    const [userPic, setuserPic] = useState('');
    const [userPicShow, setuserPicShow] = useState('');
    const [userPicErr, setuserPicErr] = useState('');

    const [signaturePic, setSignaturePic] = useState('');
    const [signaturePicShow, setSignaturePicShow] = useState('');
    const [signaturePicErr, setSignaturePicErr] = useState('');

    const [formTitle, setFormTitle] = useState('Add New User');
    const [formButtonValue, setFormButtonValue] = useState('ADD USER');

    const [isLoading, setIsLoading] = useState(false);
    const [typeChecked, setChecked] = useState('');
    const [approverDesignationData, setApproverDesignationData] = useState([]);


    const [subStores, setSubStores] = useState([]);
    const [allSubStores, setAllSubStores] = useState([]);
    const [selectedSubstores, setSelectedSubstores] = useState([]);
    const [selectedSubstoresEdit, setSelectedSubstoresEdit] = useState([]);

    const handleInput = (e) => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;
        if (name === "firstName") setFirstName(value);
        if (name === "lastName") setLastName(value);
        if (name === "email") setEmail(value);
        if (name === "mobile") setMobile(value);
        if (name === "designation") setDesignation(value);
        if (name === "approver") {
            setApprover(value)
            const data = allAprrovers.filter(item => {
                return item.id == value
            })
            setApproverText(data[0].ApproverDesignation)
        };
        if (name === "mobile") setPassword(value);
    };
    const handleAddAsset = async () => {
        setDisableSubmit(true);
        let error = false;
        const uppercaseRegExp = /(?=.*?[A-Z])/;
        const lowercaseRegExp = /(?=.*?[a-z])/;
        const digitsRegExp = /(?=.*?[0-9])/;
        const specialCharRegExp = /(?=.*?[?!@$%^&*-])/;
        const minLengthRegExp = /.{8,}/;
        const uppercasePassword = uppercaseRegExp.test(password);
        const lowercasePassword = lowercaseRegExp.test(password);
        const digitsPassword = digitsRegExp.test(password);
        const specialCharPassword = specialCharRegExp.test(password);
        const minLengthPassword = minLengthRegExp.test(password);

        if (firstName == '') {
            setFirstNameErr('First name is required.');
            error = true;
        } else {
            setFirstNameErr('');
        }
        if (email != '') {
            let pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
            if (!pattern.test(email)) {
                setEmailErr('Please enter valid email.');
                error = true;
            } else {
                setEmailErr('');
            }

        } else if (email == '') {
            setEmailErr('Email is required.');
            error = true;
        }
        else {
            setEmailErr('');
        }
        /* if (password == '') {
            setPasswordErr('password is required.');
            error = true;
        } else if (!uppercasePassword) {
            setPasswordErr("At least one Uppercase");
            error = true;
        } else if (!lowercasePassword) {
            setPasswordErr("At least one Lowercase");
            error = true;
        } else if (!digitsPassword) {
            setPasswordErr("At least one digit");
            error = true;
        } /* else if (!specialCharPassword && password.indexOf('#') != -1) {
            setPasswordErr("At least one Special Characters");
            error = true;
        } else if (!minLengthPassword) {    
            setPasswordErr("At least minumum 8 characters");
            error = true;
        } else {
            setPasswordErr('');
        } */
        /*  if(mobile !=''){
             
             var pattern = new RegExp(/^[0-9\b]+$/);
 
                 if (!pattern.test(mobile)) {
                         
                     error = true;
                     setMobileErr('Please enter only number.');                    
 
                 }else if(mobile.length != 10){
                     error = true;
                     setMobileErr('Please enter valid phone number.');   
                 } else{
                     setMobileErr(''); 
                 }           
         }else if(mobile ==''){
             error = true;
             setMobileErr('Mobile is required.'); 
         }
         else{
             setMobileErr('');  
         } */
        if (!error) {
            setIsLoading(true)
            if (userID == 'add') {

            } else {
                let result = await API_Auth.updateUser(firstName, lastName, email, mobile, designation, approver, userPic, signaturePic, password, userID);
                let res = result[0];
                if (res.Flag == 'Success') {
                    setIsLoading(false);

                    toast.success('User updated successfully');
                    setInterval(() => {
                        navigate("/admin-users");
                        navigate(0);
                    }, 2000);

                } else {
                    setIsLoading(false);

                    setDisableSubmit(false);
                    toast.error('Something went wrong please try again');
                }
            }

        } else {
            setDisableSubmit(false);
        }

        /* if (approverText == "STORE INCHARGE") {
            console.log("STORE", selectedSubstoresEdit, selectedSubstores);

            selectedSubstoresEdit.map(async function (app, key) {
                let radmin_res = await API_Auth.addRemoveAdminToSubstore(UserID, app.StoreID, 'remove');
            });

            selectedSubstoresEdit.map(async function (app, key) {
                let radmin_res = await API_Auth.addRemoveApproverToSubstore(UserID, app.StoreID, 'remove');
            });
            selectedSubstores.map(async function (app, key) {
                let adm_res = await API_Auth.addRemoveAdminToSubstore(UserID, app.StoreID, 'add');
            });
        } else if (approverText == "HOD" || approverText == "NS") {
            console.log("hod", selectedSubstoresEdit, selectedSubstores);
            selectedSubstoresEdit.map(async function (app, key) {
                let radmin_res = await API_Auth.addRemoveAdminToSubstore(UserID, app.StoreID, 'remove');
            });
            selectedSubstoresEdit.map(async function (app, key) {
                let radmin_res = await API_Auth.addRemoveApproverToSubstore(UserID, app.StoreID, 'remove');
            });
            selectedSubstores.map(async function (app, key) {
                let adm_res = await API_Auth.addRemoveApproverToSubstore(UserID, app.StoreID, 'add');
            });
        } else if (approverText == "MS" || approverText == "PRINCIPAL") {
            console.log("asdlaksdjl", selectedSubstoresEdit, selectedSubstoresEdit)
            selectedSubstoresEdit.map(async function (app, key) {
                let radmin_res = await API_Auth.addRemoveApproverToSubstore(UserID, app.StoreID, 'remove');
            });
            selectedSubstoresEdit.map(async function (app, key) {
                let radmin_res = await API_Auth.addRemoveAdminToSubstore(UserID, app.StoreID, 'remove');
            });

        }
 */
    }
    const handleChange = (e) => {
        setChecked(e.currentTarget.value);
    };
    const handleFileupload = async (type) => {
        if (type == 'userpic') {
            var formData = new FormData();
            var imagefile = document.querySelector('#file-upload');
            formData.append("userPic", imagefile.files[0]);
            let upload_res = await API_Auth.uploadImage(formData);
            if (upload_res.data == 'File is uploaded') {
                var file_name = '';
                for (var key of formData.entries()) {
                    file_name = key[1].name;
                }
                setuserPic(file_name);
                setuserPicShow(base_url + "/images?filename=" + file_name);
            }
        }
        if (type == 'signature') {
            var formData = new FormData();
            var imagefile = document.querySelector('#file-signature');
            formData.append("userPic", imagefile.files[0]);
            let upload_res = await API_Auth.uploadImage(formData);
            if (upload_res.data == 'File is uploaded') {
                var file_name = '';
                for (var key of formData.entries()) {
                    file_name = key[1].name;
                }
                setSignaturePic(file_name);
                setSignaturePicShow(base_url + "/images?filename=" + file_name);

            }
        }


    }
    const handleSelectSubstore = (selectedList, selectedItem) => {
        setSelectedSubstores(selectedList);
    };
    useEffect(() => {
        const fetchAsset = async () => {

            if (userID != 'add') {
                let res = await API_Auth.getUserById(userID);
                let user_det = res.userData[0];
                var user_admin_det = [];
                setUserID(user_det.id);
                setFirstName(user_det.FirstName);
                setLastName(user_det.LastName);
                setEmail(user_det.EmailId);
                setMobile(user_det.UserCNo);
                setDesignation(user_det.UserDesignation);
                setuserPic(user_det.ProfilePic);
                setSignaturePic(user_det.SignPic);
                setuserPic(user_det.ProfilePic);
                setSignaturePic(user_det.SignPic);
                setApprover(user_det.approverDesignationID);
                setPassword(user_det.Password);
                setApproverText(user_det.ApproverDesignation)
                if (user_det.ApproverDesignation == "STORE INCHARGE") {
                    user_admin_det = res.Admin;
                } else if (user_det.ApproverDesignation == "HOD" || user_det.ApproverDesignation == "NS") {
                    user_admin_det = res.Approver
                }
                else if (user_det.ApproverDesignation == "MS" || user_det.ApproverDesignation == "PRINCIPAL") {
                    if (res.Approver.length == 0 && res.Admin.length == 0) {
                        user_admin_det = []
                    }
                    else if (res.Approver.length == 0 && res.Admin.length != 0) {
                        user_admin_det = res.Admin
                    }
                    else if (res.Approver.length != 0 && res.Admin.length == 0) {
                        user_admin_det = res.Approver
                    }
                }
                setFormTitle('Edit User');
                setFormButtonValue('UPDATE USER');
                if (user_det.ProfilePic != '') {
                    setuserPicShow(base_url + "/images?filename=" + user_det.ProfilePic);
                }
                if (user_det.SignPic != '') {
                    setSignaturePicShow(base_url + "/images?filename=" + user_det.SignPic);
                }
                if (user_admin_det.length > 0) {
                    setSelectedSubstores(user_admin_det);
                    setSelectedSubstoresEdit(user_admin_det);
                } else {
                    setSelectedSubstores([]);
                    setSelectedSubstoresEdit([])

                }
            }

            let approvers = await API_Auth.getAprrovedUsers();
            // approvers.sort((a, b) => a.ApproverDesignation.localeCompare(b.ApproverDesignation));
            setAllAprrovers(approvers);
            if (userID == 'add') {
                setApproverText(approvers[0].ApproverDesignation)

                setApprover(approvers[0].id);
            }

            let subStores = await API_Auth.getSubstores();
            setSubStores(subStores);
            subStores.sort((a, b) => a.StoreName.localeCompare(b.StoreName))
            setAllSubStores(subStores);
            let designationDaata = await API_Auth.getDesignations();
            setApproverDesignationData(designationDaata)
        };
        // call the function
        fetchAsset()
            // make sure to catch any error
            .catch(console.error);
    }, []);
    const removePic = (type) => {
        if (type == 'userpic') {
            setuserPic('');
            setuserPicShow('');
            document.getElementById("file-upload").value = '';
        }
        if (type == 'signature') {
            setSignaturePic('');
            setSignaturePicShow('');
            document.getElementById("file-signature").value = '';
        }

    }
    const handleBackClick = () => {
        navigate("/admin-users");
        navigate(0);

    }
    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            <Container>
                <Row className="row_title">
                    <Col lg={4}><p>User Profile</p></Col>
                    <Col lg={6}></Col>
                    <Col lg={2}>
                        <div className="back_button" onClick={() => handleBackClick()}>
                            <IoIosArrowBack size={18} />
                            <h6>Back</h6>
                        </div>
                    </Col>
                </Row>
            </Container>
            {isLoading && <LoadingSpinner />}

            <hr />
            <Container>
                <Row>
                    <Title style={{ marginLeft: 40 }}>User Details</Title>
                </Row>
                <Row>
                    <Col lg={6} ms={6} sm={6}>
                        <div className="inputdiv">
                            <label>First Name</label>
                            <br />
                            <input type="text"
                                style={{ width: "80%" }}
                                name="firstName"
                                value={firstName}
                                placeholder="Enter First Name"
                                onChange={handleInput} />
                            {firstNameErr != '' && <p className="alert-message">{firstNameErr}</p>}
                        </div>
                    </Col>
                    <Col lg={6} ms={6} sm={6}>
                        <div className="inputdiv">
                            <label>Last Name</label>
                            <br />
                            <input type="text"
                                name="lastName"
                                value={lastName}
                                onChange={handleInput}
                                placeholder="Enter Last name"
                                style={{ width: "80%" }}
                            />
                        </div>
                    </Col>
                    <Col lg={6} ms={6} sm={6}>
                        <div className="inputdiv">
                            <label>Email Id</label>
                            <br />
                            <input type="email"
                                name="email"
                                value={email}
                                placeholder="Enter a email address"
                                onChange={handleInput}
                                style={{ width: "80%" }}
                            />
                            {emailErr != '' && <p className="alert-message">{emailErr}</p>}
                        </div>

                    </Col>
                    <Col lg={6} ms={6} sm={6}>

                        <div className="inputdiv">
                            <label>Phone No</label>
                            <br />
                            <input type="text"
                                name="mobile"
                                value={mobile}
                                onChange={handleInput}
                                placeholder="Enter a valid mobile number"
                                style={{ width: "80%" }}
                            />
                            {/*{mobileErr != '' && <p className="alert-message">{mobileErr}</p>} */}
                        </div>

                    </Col>
                    <Col lg={6} ms={6} sm={6}>
                        <div className="inputdiv">
                            <label>Password</label>
                            <br />
                            <input type="password"
                                name="password"
                                value={password}
                                placeholder="Enter a password"
                                onChange={handleInput}
                                style={{ width: "80%" }}
                            />
                            {passwordErr != '' && <p className="alert-message">{passwordErr}</p>}
                        </div>

                    </Col>
                    <Col lg={6} ms={6} sm={6}>
                        <div className="inputdiv">
                            <label>Designation</label>
                            <br />
                            {/*  <input type="text"
                                name="designation"
                                value={designation}
                                onChange={handleInput}
                                placeholder="Enter Designation"
                                style={{ width: "80%" }}
                            /> */}
                            <select style={{ width: "80%" }}

                                value={designation}
                                name="designation"
                                onChange={handleInput} >
                                <option value="">Select Designation</option>
                                {approverDesignationData.map((item, index) => {
                                    return (
                                        <option value={item.Designation}>{item.Designation}</option>
                                    )
                                })}


                            </select>
                        </div>
                    </Col>

                </Row>
                <Row>
                    <Col lg={5} md={6} sm={6}>


                        <div className="custom_div">
                            {userPic == '' && <label for="file-upload" class="custom-file-upload">
                                <div className="edit_photo_div">
                                    <p>Edit Photo</p>
                                    <img src={Edit} />
                                </div>
                            </label>}
                            {userPicShow != '' &&
                                <label className="custom-file-uploaded" >
                                    <div>
                                        <img src={userPicShow} />
                                        <a onClick={() => removePic('userpic')}> Remove</a>
                                    </div>
                                </label>}
                            <input id="file-upload" onChange={() => handleFileupload('userpic')} type="file" style={{ display: "none" }} />
                        </div>

                    </Col>
                    <Col lg={5} md={6} sm={6}>

                        <Col>


                        </Col>
                        <div className="inputdiv">
                            <label>Signature</label>
                            <br />
                            {signaturePicShow == '' &&
                                <label for="file-signature" className="custom-file-signature">
                                    <p>Upload Signature</p>
                                </label>
                            }
                            {signaturePicShow != '' &&
                                <>
                                    <label className="custom-file-uploaded" >
                                        <div>
                                            <img src={signaturePicShow} className="sigImg" />
                                            <a onClick={() => removePic('signature')}> Remove</a>
                                        </div>
                                    </label>
                                </>
                            }
                            <input id="file-signature" onChange={() => handleFileupload('signature')} type="file" style={{ display: "none", width: "80%" }} />
                            {/*                             {signaturePicErr != '' && <p className="alert-message">{signaturePicErr}</p>} */}
                        </div>

                    </Col>

                </Row>
                <Row>
                    <Col lg={4}></Col>
                    <Col lg={4}>
                        <div className="row_div">
                            <button className="add_button" disabled={disableSubmit} onClick={() => handleAddAsset()}>

                                <p className="pcat_title">Update Profile</p></button>
                        </div></Col>
                </Row>
            </Container>
            <ToastContainer />
        </Wrapper>
    )
}
export default UserProfile