

import styled from "styled-components";

export const Wrapper = styled.div`
background:white;
margin:20px;
border-radius:6px;
.row_title{
  margin:10px;
  padding:20px 10px 0px;
  p{
    color:black;
    font-weight:500;
    font-size:24px;
    margin:10px 0px;
    font-family:Roboto;
  }

.alert-message{
  color: red;
  margin-left: 15px;
}
.row_div {
    margin:10px;
  
  }
  .add_button {
    display:flex;
    flex-direction:row;
    background:#000044;
    color:white;
    border:#4EACFB;
    padding:15px;
    height:50px;
    margin:15px;
    text-align:center;
    justify-content:center;
    align-item:center;
    box-shadow: 0px 2px 2px #00000030;
  border-radius: 6px;
  opacity: 1;
  
  
    
  }
.title{
    color:black;
  font-weight:500;
  font-size:24px;
  margin: 20px;
  padding: 20px 0px 0px 0px;
}
.inputdiv {
    margin:20px 0px;
}

    
   
`