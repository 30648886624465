import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Wrapper } from "./styles"
import { Row, Container, Col } from "react-bootstrap"
import Table from "react-bootstrap/Table";
import Edit from "../../images/edit.png";
import plus from "../../images/white_plus.png"
import { useNavigate, Link } from "react-router-dom";
import API_Auth from "../../API_Auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Title } from "../../GlobalStyle"
import { LoadingSpinner } from "../LoadingSpinner/index";
import { AiOutlineSearch } from "react-icons/ai";
import ReactPaginate from 'react-paginate';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const AdminAsset = () => {
    const {
        isExpanded,
    } = useContext(UserContext);
    const [assets, setAssets] = useState([]);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [searchData, setSearchData] = useState([]);
  

    const [offset, setOffSet] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [orgtableData, setOrgTableData] = useState([]);
    const [perPage, setPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [searchKey,setSearchKey]=useState('n');
    const[searchKeyTable,setSearchKeyTable]=useState('');

    const handleSearch = (e) => {
        let filterValue = e.target.value;
        setCurrentPage(0);     
        if (filterValue == '') {
            let offsetsearch=0;
            var slice = orgtableData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(orgtableData.length / perPage));
            setOrgTableData(orgtableData);
            setTableData(slice);
            setSearchData(orgtableData);  
            setOffSet(offsetsearch);
            setCurrentPage(0);
            setSearchKey('n');
        }
        else {
            let offsetsearch=0;
            const filteredData = orgtableData.filter(item => {
                return (item?.AssetName?.toLowerCase().includes(filterValue.toLowerCase()) ||
                    item?.AssetSpacs?.toLowerCase().includes(filterValue.toLowerCase()) ||
                        item?.AssetType?.toLowerCase().includes(filterValue.toLowerCase()) ||
                        item?.Notes?.toLowerCase().includes(filterValue.toLowerCase()) ||                       
                            item?.SubCategoryName?.toLowerCase().includes(filterValue.toLowerCase()) ||
                                item?.CategoryName?.toLowerCase().includes(filterValue.toLowerCase()))
            });

            const slice = filteredData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(filteredData.length / perPage))
            setSearchKey('y');
            setOffSet(offsetsearch);
            setSearchKeyTable(filteredData);
            setTableData(slice);
            setSearchData(filteredData);  
        }
    }

    const handleEditAssets = (obj) => {
        navigate("/add-admin-asset/" + obj.id + "/admin");
    }
    const handleAddAsset = () => {
        navigate("/add-admin-asset/add/admin");

    }
    useEffect(() => {
        setIsLoading(true);

        const fetchAsset = async () => {
            let result = await API_Auth.getAsset();
            setAssets(result);
            setSearchData(result);
            setIsLoading(false);
            //seting pagenation data
            var slice = result.slice(offset, offset + perPage);
            setPageCount(Math.ceil(result.length / perPage));
            setOrgTableData(result);
            setTableData(slice);
        };
        // call the function
        fetchAsset()
            // make sure to catch any error
            .catch(console.error);
    }, []);
    const handlerowsperPage = (e) => {
        let showperpage = e.currentTarget.value
        setPerPage(parseInt(showperpage));
        setCurrentPage(0);
        if(searchKey=="y"){
            const slice = searchKeyTable.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(searchKeyTable.length / e.currentTarget.value))
            setTableData(slice);  
            setOffSet(0);  
            setSearchData(searchKeyTable);
        }
        else{
        const slice = orgtableData.slice(0, 0 + e.currentTarget.value);
        setPageCount(Math.ceil(orgtableData.length / e.currentTarget.value))
        setTableData(slice);
        setOffSet(0);
        setSearchData(orgtableData);
        }
    }
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offsetdata = selectedPage * perPage;
        setCurrentPage(selectedPage);
        setOffSet(offsetdata);
        loadMoreData(offsetdata);
    }

    const loadMoreData = (offsetdata) => {
        const data = orgtableData;
        if(searchKey=="y"){
            const slice = searchKeyTable.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(searchKeyTable.length / perPage))
            setTableData(slice);
            setOffSet(offsetdata);
            setSearchData(searchKeyTable);
        }else{
        const slice = data.slice(offsetdata, offsetdata + perPage);
        setPageCount(Math.ceil(data.length / perPage))
        setOffSet(offsetdata);
        setTableData(slice);
        setSearchData(data);
        }
    }

    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            {isLoading && <LoadingSpinner />}

            <Container>
                <Row>
                    <Col lg={4}style={{paddingLeft:0}}>
                        <Title>Asset List</Title>
                    </Col>
                    <Col lg={5}>
                        <div className="row_div">
{/*                             <p className="title">Filter By:</p> */}
                            <div className="input_div" >
                                <input type="type" style={{ margin: 0, background: 'transparent' }}
                                    name="filterValue"
                                    //    value={filterValue}
                                    onChange={(e) => handleSearch(e)}
                                    placeholder="Search" />
                                <AiOutlineSearch size={24} style={{ margin: 10, cursor: 'pointer' }} color="lightgray" />
                            </div>

                        </div>
                    </Col>
                    <Col lg={3}style={{paddingRight:0}}>
                        <div>
                            <button className="add_button" onClick={() => handleAddAsset()}>
{/*                                 <img src={plus} style={{ margin: 5 }} /> */}
                                <p className="pcat_title"> ADD A NEW ITEM</p></button>
                        </div></Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col lg={12} sm={12} md={12} className="table_head">
                        <Table className="tablelist">
                            <thead>
                                <tr>
                                    <th>Item Name</th>
                                    <th>UOM</th>
                                    <th>Type</th>
                                    <th>Category</th>
                                    <th>Sub Category</th>
                                    <th>Specs</th>
                                     <th>Notes</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            {tableData.length == 0 && <tbody>
                                <tr >
                                    <td colSpan="12">
                                        <p className="no_Data_table">No Data Found</p>
                                    </td>
                                </tr>
                            </tbody>
                            }
                            {tableData.length != 0 && <tbody>
                                {tableData.map((obj, index) => {
                                    return (
                                        <>
                                            <tr key={index}>
                                                <td>{obj.AssetName}</td>
                                                <td>{obj.UOM}</td>

                                                <td>{obj.AssetType}</td>
                                                <td>{obj.CategoryName}</td>
                                                <td>{obj.SubCategoryName}</td>
                                                <td>{obj.AssetSpacs}</td>
                                                 <td>{obj.Notes}</td>
                                                <td>
                                                    <div className="row_table_div" onClick={() => handleEditAssets(obj)} style={{ width: 85 }}>
                                                        <img src={Edit} />
                                                        <p>EDIT</p>
                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    );
                                })}
                            </tbody>
                            }
                        </Table>
                        {tableData.length != 0 &&
                        <div>
                        <div className="pagination_row_div">
                            <p className="pagination_title">Showing items per page:</p>
                            <div className="pagination_input_div" >
                                <select className="pagination_select"
                                 style={{ margin: 0, background: 'transparent' }}
                                    name="pagination_value"
                                    value={perPage}
                                    onChange={handlerowsperPage} >
                                    <option value={4}>4</option>
                                    <option value={8}>8</option>
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                </select>
                                </div> 
                        </div>
                        <div className="pagination_row_right_div">
                        <p className="pagination_total">Showing {offset+1} to {searchData.length<offset+perPage&&
                        <span>{searchData.length}</span> }
                        {searchData.length>offset+perPage&&
                        <span>{offset+perPage } </span>} of {searchData.length} items</p>
                        <ReactPaginate
                            previousLabel={<ArrowBackIosIcon  className="pagination_prev_btn" color="#2438a7"/>}
                            nextLabel={<ArrowForwardIosIcon className="pagination_next_btn" color="#2438a7"/>}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"} 
                            forcePage={currentPage} />
                        </div>
                    </div>
                    }                    </Col>
                </Row>
            </Container>
            <ToastContainer />
        </Wrapper>
    )
}
export default AdminAsset