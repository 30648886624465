import styled from "styled-components";

export const Wrapper = styled.div`
.conotent_text{
    margin: 12px 0px;
    p{
        color:black;
        font-size:45px;
        font-family:Roboto;
        letter-spacing: 0.4px;
        margin:0;
      }
      h6{
        font-size: 26px;
      }
}
.css-46bh2p-MuiCardContent-root:last-child {
     padding-bottom: 0;
     text-align: center;
}
.css-bhp9pd-MuiPaper-root-MuiCard-root {
    background-color: aquamarine;
    border-radius: 14px;
    }
.add_cat_button {
  background:#000044;
  color:white;
  border:#4EACFB;
  padding:10px;
  height:40px;
  width:85%;
  margin:100px 25px 0px;
  font-weight:bold;
  border-radius:6px;
}
`