import styled from "styled-components";
import Logo from "../../images/uk-login-banner1.png"

export const Wrapper = styled.div`
overflow: hidden;


.error {
  color: red;
  text-align:center;
}
.copyright_powered{
  text-align: center;
  margin-top: 8%;
}
.copyright{
  width: 50%;
position: absolute;
bottom: 0px; 
left: 0px; 
font-family:'Roboto';
font-size:30px;
font-weight:600;
color:white;
text-align: center; 
  }
  .logo_span_text{
    font-size: 23px;
    font-weight: 100;
  }
  .logo_side_text{
    font-weight: bold;
    font-size: 18px;
    font-family: 'Roboto';
    color: darkgray;
  }
  .logo_sub_text{
  font-weight: bold;
    font-size: 18px;
    font-family: 'Roboto';
    letter-spacing: 1px;
  }
.logoimg{
  height: 100vh;
  background-image:  url(${Logo});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  overflow: hidden;
}
.sign-page-left{
  height: 100vh;
 }
.signin-logo-img{
  display: flex;
  margin: 20% 20%;
  align-items:center;
  justify-content:center;
}
.text-sign-align{
  padding:22% 10%;
  padding-bottom: 0;
}
.reset_section{
  width: 78%;
}
.reset_title{
  float: right;
  margin-left:10px;
  font-family:Roboto;
  font-weight:500;
  color: #2438a7;
  font-size: 16px;
  cursor: pointer;
  margin-top: 12px;
  margin-bottom: 46px;
}
.add_button {
  display:flex;
  flex-direction:row;
  background:#000044;
  color:white;
  border:#4EACFB;
  padding:10px;
  height:45px;
  width: 80%;
  margin: 0.1em;  
  text-align:center;
  justify-content:center;
  align-item:center;
  box-shadow: 0px 2px 2px #00000030;
border-radius: 6px;
opacity: 1;  
}

.ffat-font-size-30{
  font-size:30px ;
  font-family:'Roboto';
  font-Weight:600;
}
.ffat-font-size-40{
  font-size:40px;
  font-family:'Roboto';
  font-Weight:bold;


}


.container{
  max-width: 700px;
}
.formfield{
  position: relative;
  box-sizing: border-box;
  float: left;
  margin: auto;
  padding: 0px;
  border: 0px;
  font-size: 1em;
}
.formfield *{
  box-sizing: border-box;
}
#firstnamefield, #lastnamefield{
  width: 90%;
  min-width: 250px;
}

.formfield>input, .formfield>label{
  padding: 0.8em;
  margin: 0.5em;
  width: 100%;
  font-family:'Roboto';
  font-weight:400;

}
.formfield>input{
  border-bottom: 1px solid #D1D1D1;
  outline: none;
  border-top: none;
  border-left: none;
  border-right: none;

}
.formfield>label{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  color: #9AA2AC;
}
input:focus {
  border-bottom: 1px solid black;
  border-top: none;
  border-left: none;
  border-right: none;
  box-shadow: none!important;
}
input:focus + label, input:valid + label{
  color: gray;
  font-size: 12px;;
  width: auto;
  height: 1.3em;
  padding: 0px 0.5em 0px 0.5em;
  margin: 0px;
  /*outline: 1px solid red; /*for debugigng purposes only*/
  transition: 0.2s ease-in-out;
  background:white;
  top: 0em;
  left: 2em;
  font-family:'Roboto';
  font-weight:600;
}

.row_div {
  display:flex;
  flex-direction:row;
  padding: 8px 0px;
  margin: 5px 10px;
}
.input_div {
  border: 2px solid lightgray;
  width: 80%;
  color:var(--black);
  font-family: 'ARIAL';
  height:45px;
  border-radius:6px;
  margin:5px 0px;
  background:white;
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  input,select{
     border:none;
     :focus {
       outline: none !important;
       border-color:none; 
       box-shadow: none!important; 
     }
    }
   
}
.circle-publish {
  width: 30px;
  height: 30px;
  margin-right: 5px;
  padding: 5px 8.5px ;
  background-color: #fff;
  border-radius:50%;
  font-size:16px;
  color:#020a09;
  align-items: center;
  text-align: center;
  margin: 1% auto;
}
.checkout-box-publish{
  margin: 23px 0px 13px 0px;
  line-height: 1.5;
}
.ffat-font-color-0{
  color: #020a09;
}
.ffat-font-size-12{
  font-size:12px;
}
.font-style-use{
  font-weight: normal;
  font-family: 'Roboto', sans-serif;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.check-style-publish{
  border-radius: 3px;
  width: 20px;
  height: 20px;
  border: solid 1px #cbd6e2;
}
.next-btun-publish{
  width: 175px;
  height: 50px;
  margin: 13px 0px 6.5% 0px;
  border-radius: 5px;
  box-shadow: 0 15px 25px 0 rgb(12 12 12 / 16%);
  border: none;
  text-align: center;
  line-height: 2.14;
  letter-spacing: normal;
}
.next-btun-publ-border-btn{
  margin: 13px 0px 6.5% 0px;
  padding: 8px 14px;
  text-align: center;
  line-height: 2.14;
  letter-spacing: normal;
  border-radius: 5px;
  border: solid 2px #ff6b6b;
  background-color: #fff;
}
.next-btun-publish a{
  text-decoration: none;
  text-transform: uppercase;color: #ffffff !important;
}
.arrow-style-publish{
  color: #fff;
  font-size: 14px;
  font-weight: 500 !important;
}


.text-color-dataset{
  margin: 15px 0 0 0 ;
  padding: 0px;
}
.hover-a a{
  color: #8c9196;
}
.hover-aa a{
  color: #020a09;
}
.hover-aa1 a{
  color: #3F51B5;
}

.ffat-font-color-6{
  color: #ff6b6b;
}

.font-style-use{
  font-weight: normal;
  font-family: 'Roboto', sans-serif;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}


.leftdiv{
  position: fixed;
  left: 0;
  width: 45%;
  height: 100%;
  align-items:center;
  justify-content:center;
  padding: 1em;
  background-color:var(--darkwhite);
}

.alert-message{
    color:red;
    margin:5px auto;
    text-align:center;
    font-family:Roboto;
    font-weight:400
}

.rightdiv {
  position: fixed;
  right: 0;
  width: 55%;
  height: 100%;
  background-color:var(--darkwhite);
  align-items:center;
  justify-content:center;
}

.icon_list {
  margin:15px;
}


.title {
  font-size: 30px;
  color:var(--black);
  font-family:Roboto;
  font-weight:600;
  margin: 80px 75px 10px 200px;
}
.head_title {
font-size: 42px;
color:var(--black);
font-family:Roboto;
font-weight:bold;
margin: 40px 75px 30px 200px;

}
.rowdiv {
  display:flex;
  justify-content: space-between;
  margin: 20px 75px 30px 215px;


}
.label_Checkbox {
  font-size:13px;
  font-family:Roboto;
}
.forget_pwd {
  color:#3F51B5;
  font-size:13px;
  text-decoration:none;
  margin-right:150px;
  font-weight:bold;
}
`
export const Form = styled.div`
  margin-top:30px;
  .error {
    color: red;
    text-align:center;
}

`

export const RowInput = styled.div`
  border:2px solid #D1D1D1;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  width: 50%;
  height: 50px;
  margin :20px auto 10px;
  @media screen and (max-width: 1365px) {
    width:55%;

  }
  @media screen and (max-width: 1199px) {
    width: 65%;

  }
  @media screen and (max-width: 991px) {
    width: 85%;

  }
  @media screen and (max-width: 768px) {
    width: 90%;
  }
  input {
    border: none;
    width: 90%;
    color:var(--black);
    font-family: 'ARIAL';
    margin:15px;
    ::placeholder,
   ::-webkit-input-placeholder {
    color: #A09E9E;
    font: normal normal normal 14px/19px Roboto!important;
    letter-spacing: 0px;
  }
  :-ms-input-placeholder {
    color: #A09E9E;
    font: normal normal normal 14px/19px Roboto!important;
    letter-spacing: 0px;

  }
  :focus {
      outline: none !important;
      border-color:none; 
      -webkit-box-shadow: none;
      box-shadow: none!important;
    }
  }


`

export const UserImage = styled.img`
width: 16px;
height: 16px;
margin:10px;
`
export const Button=styled.button`
  display: block;
  background: var(--lightblue);
  width:73%;
  min-width: 200px;
  height: 40px;
  border-radius: 4px;
  color: var(--darkwhite);
  border: 0;
  font-size: 18px;
  padding:5px;
  margin: 13px 65px;
  transition: all 0.3s;
  outline: none;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
    `