import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Wrapper } from "./ApprovalManagement.styles"
import { Row, Container, Col } from "react-bootstrap"
import Table from "react-bootstrap/Table";
import { useNavigate, Link, useParams } from "react-router-dom";
import { AiOutlineSearch } from "react-icons/ai";
import FixedApprovalRequestData from "../json/FixedApproverRequest.json";
import plus from "../../images/white_plus.png"
import API_Auth from "../../API_Auth";
import moment from "moment"
import { LoadingSpinner } from "../LoadingSpinner/index";
import ReactPaginate from 'react-paginate';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Title } from "../../GlobalStyle"

const CompleteApprovalManagement = () => {
    const {
        isExpanded, mainStoreId,title
    } = useContext(UserContext);
    const [assetType, setAssetType] = useState('Consumable');
    const [isLoading, setIsLoading] = useState(false);
    const [requestData, setRequestData] = useState([]);
    const [searchData, setSearchData] = useState([])
    const [status, setStatus] = useState('');
    let { StorID, SubstoreName, RequestType,ApproverD } = useParams();
    const [offset, setOffSet] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [orgtableData, setOrgTableData] = useState([]);
    const [perPage, setPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [searchKey, setSearchKey] = useState('n');
    const [searchKeyTable, setSearchKeyTable] = useState('');
    const [subStoreName, setSubstoreName] = useState('')
    const navigate = useNavigate();

    const [start_date, setStartDate] = useState('');
    const [end_date, setEndDate] = useState('');


    useEffect(() => {
        setIsLoading(true);
        const fetchRequests = async () => {
            const user_id = localStorage.getItem("UserID")
            const date = new Date();

            let today = new Date();
            today.setDate(today.getDate() - 7);
            let startCurrentDate = new Date(today).toLocaleDateString("en-CA");

            const futureDate = date.getDate();
            date.setDate(futureDate);
            const defaultValue = date.toLocaleDateString('en-CA');
            setStartDate(defaultValue);
            setEndDate(defaultValue);

            const startdate = moment(defaultValue).format("MM/DD/YYYY");
            const enddate = moment(defaultValue).format("MM/DD/YYYY");
             var data;

             //data = await API_Auth.getCompleteApproversByStore(user_id, assetType, StorID, startdate, enddate);

             if (ApproverD == "2") {
                 if(StorID=="all"){
                    data = await API_Auth.getCompleteApproversByStore(user_id, assetType, StorID, startdate, enddate);

                 }else{
                    data = await API_Auth.getCompleteApproversByStoreId(user_id, assetType, StorID, startdate, enddate);

                 }

            } else if (ApproverD == "3") {
                if(StorID=="all"){

                data = await API_Auth.getCompleteApproversByStoreNS(user_id, assetType, StorID, startdate, enddate);
                }
                else{
                    data = await API_Auth.getCompleteApproversByStoreNSId(user_id, assetType, StorID, startdate, enddate);

                }

            }
            else if (ApproverD == "4") {
                if(StorID=="all"){

                data = await API_Auth.getCompleteApproversByStoreMS(user_id, assetType, StorID, startdate, enddate);
                }
                else{
                    data = await API_Auth.getCompleteApproversByStoreMSId(user_id, assetType, StorID, startdate, enddate);

                }


            }
            else if (ApproverD == "5") {
                if(StorID=="all"){

                data = await API_Auth.getCompleteApproversByStorePrincipal(user_id, assetType, StorID, startdate, enddate);
                }
                else{
                    data = await API_Auth.getCompleteApproversByStorePrincipalId(user_id, assetType, StorID, startdate, enddate);

                }


            }
            setRequestData(data);
            // setSearchData(data);
            setIsLoading(false);
            var slice = data.slice(offset, offset + perPage);
            setPageCount(Math.ceil(data.length / perPage));
            setOrgTableData(data);
            setTableData(slice);
            setSearchData(data);

            if (StorID != "all") {


            let store_det = await API_Auth.getSubstoreById(StorID);
            let name = store_det.substore[0].SubStoreName;
            setSubstoreName(name);
            }
        };
        // call the function
        fetchRequests()
            // make sure to catch any error
            .catch(console.error);
    }, [StorID])

    const handleAssetType = (type) => {
        setAssetType(type)
        setIsLoading(true);
        const fetchRequests = async () => {
            const user_id = localStorage.getItem("UserID");
            const startdate = moment(start_date).format("MM/DD/YYYY");
            const enddate = moment(end_date).format("MM/DD/YYYY")


          //  let data = await API_Auth.getCompleteApproversByStore(user_id, type, StorID, startdate, enddate);
          var data;
          if (ApproverD == "2") {
            if(StorID=="all"){
               data = await API_Auth.getCompleteApproversByStore(user_id, type, StorID, startdate, enddate);

            }else{
               data = await API_Auth.getCompleteApproversByStoreId(user_id, type, StorID, startdate, enddate);

            }

       } else if (ApproverD == "3") {
           if(StorID=="all"){

           data = await API_Auth.getCompleteApproversByStoreNS(user_id, type, StorID, startdate, enddate);
           }
           else{
               data = await API_Auth.getCompleteApproversByStoreNSId(user_id, type, StorID, startdate, enddate);

           }

       }
       else if (ApproverD == "4") {
           if(StorID=="all"){

           data = await API_Auth.getCompleteApproversByStoreMS(user_id, type, StorID, startdate, enddate);
           }
           else{
               data = await API_Auth.getCompleteApproversByStoreMSId(user_id, type, StorID, startdate, enddate);

           }


       }
       else if (ApproverD == "5") {
           if(StorID=="all"){

           data = await API_Auth.getCompleteApproversByStorePrincipal(user_id, type, StorID, startdate, enddate);
           }
           else{
               data = await API_Auth.getCompleteApproversByStorePrincipalId(user_id, type, StorID, startdate, enddate);

           }
       }  
            setRequestData(data);
            // setSearchData(data);
            setIsLoading(false);
            var slice = data.slice(offset, offset + perPage);
            setPageCount(Math.ceil(data.length / perPage));
            setOrgTableData(data);
            setTableData(slice);
            setSearchData(data);

        };
        // call the function
        fetchRequests()
            // make sure to catch any error
            .catch(console.error);

    }
    const handleSearch = (e) => {
        let filterValue = e.target.value
        setCurrentPage(0);
        if (filterValue == '') {
            let offsetsearch = 0;
            var slice = orgtableData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(orgtableData.length / perPage));
            setOrgTableData(orgtableData);
            setTableData(slice);
            setSearchData(orgtableData);
            setOffSet(offsetsearch);
            setSearchKey('n');
            setCurrentPage(0);
        }
        else {
            let value1 = filterValue.toLowerCase();
            let offsetsearch = 0;
            const filteredData = orgtableData.filter(item => {
                return (item ?.Status ?.toLowerCase() ?.includes(value1) || item ?.FromStoreName ?.toLowerCase() ?.includes(value1) ||
                    item ?.ToStoreName ?.toLowerCase().includes(value1) ||
                        item ?.CName ?.toLowerCase() ?.includes(value1) || item ?.Asset ?.includes(value1) ||
                            moment(item.DATE).format('DD-MM-YYYY').includes(value1) || item ?.RquestID.toString() ?.includes(filterValue))


            });
            const slice = filteredData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(filteredData.length / perPage))
            setSearchKey('y');
            setOffSet(offsetsearch);
            setSearchKeyTable(filteredData);
            setTableData(slice);
            setSearchData(filteredData);
        }


    }

    const handleInput = async (e) => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;
        const user_id = localStorage.getItem("UserID")

        if (name == "start_date") {
            setStartDate(value)
            const startdate = moment(value).format("MM/DD/YYYY");
            const enddate = moment(end_date).format("MM/DD/YYYY");
            setIsLoading(true);


           // let data = await API_Auth.getCompleteApproversByStore(user_id, assetType, StorID, startdate, enddate);
           var data;
           if (ApproverD == "2") {
            if(StorID=="all"){
               data = await API_Auth.getCompleteApproversByStore(user_id, assetType, StorID, startdate, enddate);

            }else{
               data = await API_Auth.getCompleteApproversByStoreId(user_id, assetType, StorID, startdate, enddate);

            }

       } else if (ApproverD == "3") {
           if(StorID=="all"){

           data = await API_Auth.getCompleteApproversByStoreNS(user_id, assetType, StorID, startdate, enddate);
           }
           else{
               data = await API_Auth.getCompleteApproversByStoreNSId(user_id, assetType, StorID, startdate, enddate);

           }

       }
       else if (ApproverD == "4") {
           if(StorID=="all"){

           data = await API_Auth.getCompleteApproversByStoreMS(user_id, assetType, StorID, startdate, enddate);
           }
           else{
               data = await API_Auth.getCompleteApproversByStoreMSId(user_id, assetType, StorID, startdate, enddate);

           }


       }
       else if (ApproverD == "5") {
           if(StorID=="all"){

           data = await API_Auth.getCompleteApproversByStorePrincipal(user_id, assetType, StorID, startdate, enddate);
           }
           else{
               data = await API_Auth.getCompleteApproversByStorePrincipalId(user_id, assetType, StorID, startdate, enddate);

           }


       }
            setRequestData(data);
            setIsLoading(false);
            var slice = data.slice(offset, offset + perPage);
            setPageCount(Math.ceil(data.length / perPage));
            setOrgTableData(data);
            setTableData(slice);
            setSearchData(data);
        };
        if (name == "end_date") {

            setEndDate(value);
            const startdate = moment(start_date).format("MM/DD/YYYY");
            const enddate = moment(value).format("MM/DD/YYYY")
            setIsLoading(true);


            //let data = await API_Auth.getCompleteApproversByStore(user_id, assetType, StorID, startdate, enddate);
            var data;
            if (ApproverD == "2") {
                if(StorID=="all"){
                   data = await API_Auth.getCompleteApproversByStore(user_id, assetType, StorID, startdate, enddate);

                }else{
                   data = await API_Auth.getCompleteApproversByStoreId(user_id, assetType, StorID, startdate, enddate);

                }

           } else if (ApproverD == "3") {
               if(StorID=="all"){

               data = await API_Auth.getCompleteApproversByStoreNS(user_id, assetType, StorID, startdate, enddate);
               }
               else{
                   data = await API_Auth.getCompleteApproversByStoreNSId(user_id, assetType, StorID, startdate, enddate);

               }

           }
           else if (ApproverD == "4") {
               if(StorID=="all"){

               data = await API_Auth.getCompleteApproversByStoreMS(user_id, assetType, StorID, startdate, enddate);
               }
               else{
                   data = await API_Auth.getCompleteApproversByStoreMSId(user_id, assetType, StorID, startdate, enddate);

               }


           }
           else if (ApproverD == "5") {
               if(StorID=="all"){

               data = await API_Auth.getCompleteApproversByStorePrincipal(user_id, assetType, StorID, startdate, enddate);
               }
               else{
                   data = await API_Auth.getCompleteApproversByStorePrincipalId(user_id, assetType, StorID, startdate, enddate);

               }


           }
            setRequestData(data);
            setIsLoading(false);
            var slice = data.slice(offset, offset + perPage);
            setPageCount(Math.ceil(data.length / perPage));
            setOrgTableData(data);
            setTableData(slice);
            setSearchData(data);
        }

    }
    const handlerowsperPage = (e) => {
        let showperpage = e.currentTarget.value
        setPerPage(parseInt(showperpage));
        setCurrentPage(0);
        setOffSet(0);
        if (searchKey == "y") {
            const slice = searchKeyTable.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(searchKeyTable.length / e.currentTarget.value))
            setTableData(slice);
            setSearchData(searchKeyTable);
        }
        else {
            const slice = orgtableData.slice(0, 0 + e.currentTarget.value);
            setPageCount(Math.ceil(orgtableData.length / e.currentTarget.value))
            setTableData(slice);
            setSearchData(orgtableData);
        }
    }


    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offsetdata = selectedPage * perPage;
        setCurrentPage(selectedPage);
        setOffSet(offsetdata);
        loadMoreData(offsetdata);
    }

    const loadMoreData = (offsetdata) => {
        const data = orgtableData;
        setOffSet(offsetdata);
        if (searchKey == "y") {
            const slice = searchKeyTable.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(searchKeyTable.length / perPage))
            setTableData(slice);
            setSearchData(searchKeyTable);
        } else {
            const slice = data.slice(offsetdata, offsetdata + perPage);
            setPageCount(Math.ceil(data.length / perPage))
            setTableData(slice);
            setSearchData(data);
        }

    }
    const handleViewDetails = (obj) => {
        const user_id = localStorage.getItem("UserID")
        // navigate("/fixed-request-view/" + StorID + "/" + user_id + "/" + obj.RquestID + "/" + assetType + "/" + "Approve")
        navigate("/fixed-request-view/" + StorID + "/" + user_id + "/" + obj.RquestID + "/" + assetType + "/" + "Approve", { state: { status: obj.Status } });

    }
    return (
        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            {isLoading && <LoadingSpinner />}
            <Container>
                <Row>
                    <Col lg={4} style={{ paddingLeft: 0 }}>
                        <Title>Approval List</Title>
                    </Col>
                    <Col lg={5}></Col>
                    <Col lg={3} style={{ paddingRight: 0 ,flexDirection:'row',display:'flex',marginTop:24}}>
                    
                    <p>Curently View : </p>
                    
                    <p className="store_div">{title}</p>
                    </Col>                </Row>
            </Container>
            <Container>
                <Row>
                    <Col lg={12} sm={12} md={12} className="table_head">
                        <Row>
                            <Col lg={5}>
                                <label style={{ marginTop: 15, }}>Type</label>

                                <Row className="row_type">
                                    <Col className={assetType == "Consumable" ?
                                        "button_asset_type" : "button_asset_type_fixed"} onClick={() => handleAssetType('Consumable')}>Consumable </Col>
                                    <Col className={assetType == "Fixed" ?
                                        "button_asset_type" : "button_asset_type_fixed"} onClick={() => handleAssetType('Fixed')}>Fixed</Col>
                                </Row>
                            </Col>
                            <Col lg={4}>
                                <Row>
                                    <Col lg={6}>
                                        <div className="inputdiv">
                                            <label style={{ marginTop: 15, }}>Start Date</label>
                                            <br />
                                            <input type="date"
                                                name="start_date"
                                                value={start_date}
                                                placeholder="dd/mm/yyyy"

                                                onChange={handleInput} />
                                            {/*                             {startDateErr != '' && <p className="alert-message">{startDateErr}</p>}
 */}
                                        </div>


                                    </Col>
                                    <Col lg={6}>
                                        <div className="inputdiv">
                                            <label style={{ marginTop: 15, }}>End Date</label>
                                            <br />
                                            <input type="date"
                                                name="end_date"
                                                value={end_date}
                                                placeholder="dd/mm/yyyy"

                                                onChange={handleInput} />
                                            {/*                             {endDateErr != '' && <p className="alert-message">{endDateErr}</p>}
 */}
                                        </div>
                                    </Col>
                                </Row></Col>
                            <Col lg={3}>
                                <label style={{ marginTop: 15, }}>Search</label>

                                <div className="row_div">
                                    <div className="input_div" >
                                        <input type="type" style={{ margin: 0, background: 'transparent' }}
                                            name="filterValue"
                                            onChange={(e) => handleSearch(e)}
                                            placeholder="Search" />
                                        <AiOutlineSearch size={24} style={{ margin: 10, cursor: 'pointer' }} color="lightgray" />
                                    </div>

                                </div></Col>
                        </Row>
                        <Table className="tablelist">
                            <thead>
                                <tr>
                                    <th>Request Id</th>
                                    <th>From</th>
                                    <th>To </th>
                                    <th>Status</th>
                                    <th>Date</th>
                                    <th>Requested By</th>

                                    <th>Assets</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            {tableData.length == 0 && <tbody>
                                <tr >
                                    <td colSpan="12">
                                        <p className="no_Data_table">No Data Found</p>
                                    </td>
                                </tr>
                            </tbody>
                            }
                            {tableData.length != 0 && <tbody>
                                {tableData.map((obj, index) => {
                                    return (
                                        <>
                                            <tr key={index}>
                                                <td>REQ{obj.RquestID}</td>
                                                <td>{obj.FromStoreName}</td>
                                                <td>{obj.ToStoreName}</td>

                                                <td>
                                                    {obj.Status == "open" &&

                                                        <div className="status_div">
                                                            <p>{obj.Status}</p>
                                                        </div>
                                                    }

                                                    {obj.Status == "assignedToNs" &&

                                                        <div className="status_div">
                                                            <p>{obj.Status}</p>
                                                        </div>
                                                    }

                                                    {obj.Status == "assignedToMs" &&

                                                        <div className="status_div1">
                                                            <p>{obj.Status}</p>
                                                        </div>
                                                    }
                                                    {obj.Status == "assigned" &&

                                                        <div className="status_div1">
                                                            <p>{obj.Status}</p>
                                                        </div>
                                                    }
                                                    {obj.Status == "assignedToprincipal" &&

                                                        <div className="status_div1">
                                                            <p>{obj.Status}</p>
                                                        </div>
                                                    }
                                                    {obj.Status == "approved" &&

                                                        <div className="status_div2">
                                                            <p>{obj.Status}</p>
                                                        </div>
                                                    }
                                                    {(obj.Status == "reject"  ||  obj.Status == "rejectByApp"  || obj.Status == "rejectByNs"  ||obj.Status == "rejectByMs" ||obj.Status == "rejectByPr") &&

                                                        <div className="status_div2">
                                                            <p>{obj.Status}</p>
                                                        </div>
                                                    }
                                                    {obj.Status == "issue" &&

                                                        <div className="status_div1">
                                                            <p>{obj.Status}</p>
                                                        </div>
                                                    }

                                                </td>

                                                <td> { moment.utc(obj.DATE).format("DD MMM YYYY  h:mm A")}</td>
                                                <td>{obj.CName}</td>
                                                <td>{obj.Asset}</td>{}


                                                <td>
                                                    <div className="row_table_div" onClick={() => handleViewDetails(obj)} style={{ width: 100 }}>
                                                        <p style={{ marginLeft: 10 }}>VIEW DETAILS</p>
                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    );
                                })}
                            </tbody>
                            }


                        </Table>
                        {tableData.length != 0 &&
                            <div>
                                <div className="pagination_row_div">
                                    <p className="pagination_title">Showing items per page:</p>
                                    <div className="pagination_input_div" >
                                        <select className="pagination_select"
                                            style={{ margin: 0, background: 'transparent' }}
                                            name="pagination_value"
                                            value={perPage}
                                            onChange={handlerowsperPage} >
                                            <option value={4}>4</option>
                                            <option value={8}>8</option>
                                            <option value={10}>10</option>
                                            <option value={20}>20</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="pagination_row_right_div">
                        <p className="pagination_total">Showing {offset+1} to {searchData.length<offset+perPage&&
                        <span>{searchData.length}</span> }
                        {searchData.length>offset+perPage&&
                        <span>{offset+perPage }</span>} of {searchData.length} items</p>
                                <ReactPaginate
                                    previousLabel={<ArrowBackIosIcon className="pagination_prev_btn" color="#2438a7" />}
                                    nextLabel={<ArrowForwardIosIcon className="pagination_next_btn" color="#2438a7" />}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"}
                                    forcePage={currentPage} />
                            </div>
                            </div>
                        }
                    </Col>
                </Row>
            </Container>

        </Wrapper>
    )
}
export default CompleteApprovalManagement