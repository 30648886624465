import React, { useState, createContext } from "react";

export const UserContext = createContext();

const UserProvider = ({ children }) => {
  // can use reducer to manage states
  const [sessionIsLoggedIn, setSessionIsLoggedIn] = useState(undefined);
  const [sessionLoggedRole, setSessionLoggedRole] = useState(undefined);
  const [sessionLoggedUser, setSessionLoggedUser] = useState(undefined);
  const [loggedUserName, setLoggedUserName] = useState(undefined);
  const [UserEmail, setUserEmail] = useState(undefined);
  const [isExpanded, setIsExpanded] = useState(true);
  const[mainStoreId,setMainStoreId]=useState('')
  const [title,setTitle]=useState('')
  return (
    <UserContext.Provider
      value={{
        sessionIsLoggedIn,
        setSessionIsLoggedIn,
        sessionLoggedRole,
        setSessionLoggedRole,
        sessionLoggedUser,
        setSessionLoggedUser,
        loggedUserName,
        setLoggedUserName,
        UserEmail,
        setUserEmail,
        isExpanded,
        setIsExpanded,title,
        setTitle,
        mainStoreId,
        setMainStoreId

      }}
    >       
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
