import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context";
import { Wrapper } from "./Store.styles"
import { Row, Container, Col } from "react-bootstrap"
import Table from "react-bootstrap/Table";
import { useNavigate, Link, useParams } from "react-router-dom";
import { AiOutlineSearch } from "react-icons/ai";
import FixedApprovalRequestData from "../json/FixedApproverRequest.json";
import API_Auth from "../../API_Auth";
import moment from "moment"
import ReactPaginate from 'react-paginate';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


const Store = () => {
    const {
        isExpanded,
    } = useContext(UserContext);
    const navigate = useNavigate();
    const [receivedData, setReceived] = useState(FixedApprovalRequestData.data);
    const [searchData, setSearchData] = useState(FixedApprovalRequestData.data)
    const [status, setStatus] = useState('');
    let { StorID, SubstoreName, assetType } = useParams()
    const [offset, setOffSet] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [orgtableData, setOrgTableData] = useState([]);
    const [perPage, setPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [searchKey, setSearchKey] = useState('n');
    const [searchKeyTable, setSearchKeyTable] = useState('');


    useEffect(() => {
        const fetchRequests = async () => {
            let data = await API_Auth.getReceivedRequests(StorID, assetType);
            
            setReceived(data);
            

            var slice = data.slice(offset, offset + perPage);
            setPageCount(Math.ceil(data.length / perPage));
            setOrgTableData(data);
            setTableData(slice);
            setSearchData(data);
        };
        // call the function
        fetchRequests()
            // make sure to catch any error
            .catch(console.error);
    }, [])
    const handlerowsperPage = (e) => {
        
        let showperpage = e.currentTarget.value
        setPerPage(parseInt(showperpage));
        setCurrentPage(0);
        setOffSet(0);

        if(searchKey=="y"){
            const slice = searchKeyTable.slice(0, 0 + e.currentTarget.value);
            
            setPageCount(Math.ceil(searchKeyTable.length / e.currentTarget.value))
            setTableData(slice); 
            setSearchData(searchKeyTable);  
        }
        else{
        const slice = orgtableData.slice(0, 0 + e.currentTarget.value);
        
        setPageCount(Math.ceil(orgtableData.length / e.currentTarget.value))
        setTableData(slice);
        setSearchData(orgtableData);  
        }
    }
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offsetdata = selectedPage * perPage;
        setCurrentPage(selectedPage);
        setOffSet(offsetdata);
        loadMoreData(offsetdata);
    }

    const loadMoreData = (offsetdata) => {
        
        const data = orgtableData;
        setOffSet(offsetdata);
        
        if (searchKey == "y") {
            
            const slice = searchKeyTable.slice(offsetdata, offsetdata + perPage);
            
            setPageCount(Math.ceil(searchKeyTable.length / perPage))
            setTableData(slice);
            setSearchData(searchKeyTable);
        } else {
            
            const slice = data.slice(offsetdata, offsetdata + perPage);
            
            setPageCount(Math.ceil(data.length / perPage))
            setTableData(slice);
            setSearchData(data);
        }

    }

    const handleViewDetails = (obj) => {
        const user_id = localStorage.getItem("UserID")
        navigate("/fixed-request-view/" + StorID + "/" + user_id + "/" + obj.RquestID + "/" + assetType + "/" + "Received");
        // navigate("/fixed-request-view/" + StorID + "/" + obj.RquestID + "/" + assetType + "/" + "Request")
    }


    const handleSearch = (e) => {
        let filterValue = e.target.value
        setCurrentPage(0);
        if (filterValue == '') {
            let offsetsearch = 0;
            
            var slice = orgtableData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(orgtableData.length / perPage));
            setOrgTableData(orgtableData);
            setTableData(slice);
            setSearchData(orgtableData);
            setSearchKey('n');
            setOffSet(offsetsearch);
            setCurrentPage(0);
        }
        else {
            let value1 = filterValue.toLowerCase();
            let offsetsearch = 0;
            const filteredData = orgtableData.filter(item => {
                return (item ?.Status ?.toLowerCase() ?.includes(value1) ||
                    item ?.ToSubstoreName ?.toLowerCase() ?.includes(value1) ||
                        item ?.CRName ?.toLowerCase() ?.includes(value1) ||
                            item ?.RquestID ?.includes(value1))
            });
            const slice = filteredData.slice(offsetsearch, offsetsearch + perPage);
            setPageCount(Math.ceil(filteredData.length / perPage))
            setSearchKey('y');
            
            setSearchData(filteredData);
            setSearchKeyTable(filteredData);
            setOffSet(offsetsearch);
            setTableData(slice);

        }
    }
    return (

        <Wrapper className={isExpanded ? "content-box" : "collapse-content-box"}>
            <Container>
                <Row>
                    <Col lg={4}>
                        <p className="subtitle">{SubstoreName} Store</p>

                    </Col>
                    <Col lg={4}></Col>
                    <Col lg={4}>
                        <div className="rowdiv">
{/*                             <p className="title">Filter By:</p>
 */}                            <div className="input_div" >
                                <input type="type" style={{ margin: 0, background: 'transparent' }}
                                    name="filterValue"
                                    onChange={handleSearch}
                                    placeholder="Search" />
                                <AiOutlineSearch size={24} style={{ margin: 10 }} color="lightgray" />
                            </div>

                        </div>
                    </Col>

                </Row>

            </Container>
            <Container>
                <Row>
                    <Col lg={12} sm={12} md={12} className="table_head">
                        <Table className="tablelist">
                            <thead>
                                <tr>
                                    <th>Request Id</th>
                                    <th>From</th>

                                    <th>To </th>

                                    <th>Status</th>
                                    <th>Date</th>
                                    <th>Requested By</th>
                                    <th>Assets</th>

                                    <th>Action</th>

                                </tr>
                            </thead>
                            {tableData.length == 0 && <tbody>
                                <tr >
                                    <td colSpan="12">
                                        <p className="no_Data_table">No Data Found</p>
                                    </td>
                                </tr>
                            </tbody>
                            }

                            {tableData.length != 0 && <tbody>
                                {tableData.map((obj, index) => {
                                    return (
                                        <>
                                            <tr key={index}>
                                                <td>REQ{obj.RquestID}</td>
                                                <td>{obj.ToSubstoreName}</td>

                                                <td>{obj.FromSubstoreName}</td>
                                                <td>
                                                    {obj.Status == "open" &&

                                                        <div className="status_div">
                                                            <p>{obj.Status}</p>
                                                        </div>
                                                    }
                                                    {obj.Status == "issue" &&

                                                        <div className="status_div1">
                                                            <p>{obj.Status}</p>
                                                        </div>
                                                    }

                                                    {obj.Status == "assignedToMs" &&

                                                        <div className="status_div1">
                                                            <p>{obj.Status}</p>
                                                        </div>
                                                    }
                                                     {obj.Status == "assigned" &&

<div className="status_div1">
    <p>{obj.Status}</p>
</div>
}
                                                    {obj.Status == "assignedToprincipal" &&

                                                        <div className="status_div1">
                                                            <p>{obj.Status}</p>
                                                        </div>
                                                    }
                                                    {obj.Status == "approved" &&

                                                        <div className="status_div2">
                                                            <p>{obj.Status}</p>
                                                        </div>
                                                    }
                                                    {obj.Status == "reject" &&

                                                        <div className="status_div2">
                                                            <p>{obj.Status}</p>
                                                        </div>
                                                    }
                                                </td>
                                                {/*                                                 <td>{moment(obj.date).format('DD-MM-YYYY')}</td>
 */}                                                 <td> { moment.utc(obj.Date).format("DD MMM YYYY  h:mm A")}</td>
                                                <td>{obj.CRName}</td>
                                                <td>{obj.Asset}</td>{}


                                                <td>
                                                    <div className="row_table_div" onClick={() => handleViewDetails(obj)} style={{ width: 100 }}>
                                                        <p style={{ marginLeft: 10 }}>VIEW DETAILS</p>
                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    );
                                })}
                            </tbody>
                            }
                        </Table>
                        {tableData.length != 0 &&
                        <div>
                        <div className="pagination_row_div">
                            <p className="pagination_title">Showing items per page:</p>
                            <div className="pagination_input_div" >
                                <select className="pagination_select"
                                 style={{ margin: 0, background: 'transparent' }}
                                    name="pagination_value"
                                    value={perPage}
                                    onChange={handlerowsperPage} >
                                    <option value={4}>4</option>
                                    <option value={8}>8</option>
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                </select>
                                </div>
                        </div>
                        <div className="pagination_row_right_div">
                        <p className="pagination_total">Showing {offset+1} to {searchData.length<offset+perPage&&
                        <span>{searchData.length}</span> }
                        {searchData.length>offset+perPage&&
                        <span>{offset+perPage }</span>} of {searchData.length} items</p>
                         <ReactPaginate
                            previousLabel={<ArrowBackIosIcon  className="pagination_prev_btn" color="#2438a7"/>}
                            nextLabel={<ArrowForwardIosIcon className="pagination_next_btn" color="#2438a7"/>}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"} 
                            forcePage={currentPage} />
                    </div>
                    </div>
                    }    
                    </Col>
                </Row>
            </Container>
        </Wrapper>
    )
}
export default Store