import React, { useState, useEffect, useContext } from "react";
// Images
import Logo from "../../images/uklogo.png";
import search from "../../images/search.svg";
import notification from "../../images/notification.svg";
import human from "../../images/human.svg";
import LogoutIcon from '@mui/icons-material/Logout';
// Styles
import {
  Wrapper,
  LCLogoImg
} from "./Header.styles";
// Routing
import { Link, useNavigate } from "react-router-dom";
// Context
import { Justify } from "react-bootstrap-icons";
import { PropTypes } from "prop-types";
import { UserContext } from "../../context";

const Header = ({ callback, showImage }) => {
  let isloggedin, user,logged_role,user_email,logged_user;
  const {
      isExpanded,
      setIsExpanded,
      sessionLoggedRole,sessionIsLoggedIn,UserEmail,
      setSessionIsLoggedIn,loggedUserName,setLoggedUserName,setSessionLoggedRole,
      setUserEmail,setSessionLoggedUser,sessionLoggedUser,title,setTitle
  } = useContext(UserContext);
  const [userRole, setUserRole] = useState('');


  isloggedin = sessionIsLoggedIn;
  user = loggedUserName;
  user_email=UserEmail;
  logged_role = sessionLoggedRole;
  
  const isloggedin_ls = localStorage.getItem("isLogged");
  const user_name_ls = localStorage.getItem("loggedUserName");
  const logged_role_ls = localStorage.getItem("loggedRole");
  const User_email = localStorage.getItem("UserEmail");
  if (!isloggedin || !logged_role) {
      isloggedin = isloggedin_ls;
      user = user_name_ls;
      user_email=User_email
      logged_role = logged_role_ls;
  }
 
  const navigate = useNavigate();

  useEffect(() => {    
      isloggedin = sessionIsLoggedIn;
      user = loggedUserName;
      user_email = UserEmail;
      logged_role = sessionLoggedRole;
      const user_role = localStorage.getItem("loggedRole");
      setUserRole(user_role)
      // if token and user missing in context
      if (!sessionIsLoggedIn && !loggedUserName&&!UserEmail) {        

          // set for future context,
          setSessionIsLoggedIn(isloggedin_ls);
          setLoggedUserName(user_name_ls);
          setUserEmail(User_email);
          setSessionLoggedRole(logged_role_ls);
          isloggedin = isloggedin_ls;
          user = user_name_ls;
         user_email= User_email
          logged_role = logged_role_ls;
    
          if (isloggedin_ls != 'yes' && isloggedin_ls !== 'Admin') {
              // redirect to login page
              navigate("/login");
          }
      }
  },[]);
  const handleToggler = () => {
    if (isExpanded) {
      setIsExpanded(false);
      return;
    }
    setIsExpanded(true);
  };
  
  const handlegotoAbout = (e) => {
    navigate("/aboutus");
  }
 const logOut = (e) => {
   localStorage.clear();
   navigate("/login");
 }

 const handleUserProfile=()=>{
  var id= localStorage.getItem("UserID")

  navigate("/user-profile/" +id);

 }
  
  return (
    <Wrapper>

      
      <div className="sidebar-header">
     {/*    <Link to="/">
        <LCLogoImg
            src={Logo}
            alt="lc-header-logo"
          />  
        </Link> */}
         {/*  <Justify
            className="sidebar-icon toggle-icon"
            onClick={handleToggler} 
          /> */}

          <p className="store_name">Blockchain based Medical Asset Tracking
</p>
<br/>          

      {userRole == 'Substore Admin' &&
<p className="store_name_title">{title}</p>
      }


        
      </div>
        <div className="header-right">
          
           <div className="section-one"> 
           {/*  <Link to="/">           
              <img src={search} className="icon"/>
            </Link>
            <Link to="/">
            <img src={notification} className="icon"/>
            </Link> */}

          </div> 
          <div className="section-two"> 
          {/*   <Link to="/"> 
              <img src={human} className="icon"/>
            </Link> */}
             <div className='profile' onClick={(e) => handlegotoAbout(e)}>
            <h6 className='name' style={{cursor:'pointer'}}>{"About Project"}</h6>
            </div>
            <div className='profile'>
            <h6 style={{color:'lightgray'}}>|</h6>
              <h6 className='name' onClick={()=>handleUserProfile()}>{loggedUserName}</h6>
{/*               <h6 className='role'> [{sessionLoggedRole}]</h6>
 */}          </div>   
            <div className='logout'  onClick={(e) => logOut(e)}>    
            <h6 style={{color:'lightgray'}}>|</h6>
            <h6 style={{cursor:'pointer'}}>Logout</h6>
          </div>               
          </div>
         
        </div>
    
    </Wrapper>
  );
};
Header.propTypes = {
  callback: PropTypes.func,
};

export default Header;
