// Configuration for LC APIs
let base_url = "http://165.232.180.142:3011/ukh";
let base_url1 = "http://165.232.180.142:4000/fixAssetApi"; 
 

//category
const AUTH_LOGIN_API_URL = base_url+"/regCheck";
const GET_CATEGORY_API_URL = base_url+"/insertCategory?mode=SELECTCATEGORY";
const ADD_NEW_CATEGORY_API_URL = base_url+"/insertCategory";

// Sub category
const GET_SUB_CATEGORY_API_URL = base_url+'/insertSubCategory?mode=SELECTSUBCATEGORY';
const ADD_NEW_SUB_CATEGORY_API_URL = base_url+"/insertSubCategory?mode=INSERTSUBCATEGORY";
const UPDATE_NEW_SUB_CATEGORY_API_URL = base_url+"/insertSubCategory?mode=UPDATESUBCATEGORY";

//assets
const GET_ASSET_API_URL= base_url+"/insertAsset?mode=SHOWALLASSET";
const ADD_ASSET_API_URL= base_url+"/insertAsset?mode=INSERTASSET";
const GET_ASSET_BY_ID_API_URL= base_url+"/insertAsset?mode=SHOWINDASSET";
const UPDATE_ASSET_API_URL= base_url+"/insertAsset?mode=UPDATEASSET";

//users 
const GET_USERS_API_URL= base_url+"/userDetail?mode=SELECTUSERS1";
const UPLOAD_IMAGE_API_URL = base_url+"/uploadApi";
const ADD_USER_API_URL= base_url+"/userDetail?mode=INSERTUSER";
//const GET_USER_BY_ID_API_URL= base_url+"/userDetail?mode=SELECTINDUSER";
const GET_USER_BY_ID_API_URL= base_url+"/userDetailUpdated?mode=SELECTINDUSER";

const UPDATE_USER_BY_API_URL= base_url+"/userDetail?mode=UPDATEUSER";

//substores
const GET_SUBSTORE_API_URL= base_url+"/insertSubstore?mode=SHOWSUBSTORES";
const ADD_SUBSTORE_API_URL= base_url+"/insertSubstore?mode=INSERTSUBSTORE";
const GET_SUBSTORE_BY_ID_API_URL= base_url+"/showIndSubstore?mode=SHOWINDSUBSTORE";
const UPDATE_SUBSTORE_API_URL= base_url+"/insertSubstore?mode=UPDATESUBSTORE";
const ADD_APPROVER_SUBSTORE_API_URL= base_url+"/insertApproverSubstore?mode=INSERTSUBSTOREAPPROVER";
const REMOVE_APPROVER_SUBSTORE_API_URL= base_url+"/insertApproverSubstore?mode=DELETESUBSTOREAPPROVER";
const ADD_ADMIN_SUBSTORE_API_URL= base_url+"/insertAdminSubstore?mode=INSERTSUBSTOREADMIN";
const REMOVE_ADMIN_SUBSTORE_API_URL= base_url+"/insertAdminSubstore?mode=DELETESUBSTOREADMIN";


const GET_APPROVERS_API_URL = base_url+"/approverDesignation?mode=SHOWAPPROVER";
const GET_APPROVED_API_URL = base_url+"/approverDesignation?mode=SHOWAPPROVERD";

//Vendors
const GET_VENDORS_API_URL= base_url+"/insertvendor?mode=SELECTVENDOR";
const ADD_VENDORS_API_URL= base_url+"/insertvendor?mode=INSERTVENDOR";
const GET_VENDORS_BY_ID_API_URL=base_url+"/insertvendor?mode=SELECTINDVENDOR";
const UPDATE_VENDORS_API_URL=base_url+"/insertvendor?mode=UPDATEVENDOR";
const DELETE_VENDORS_API_URL=base_url+"/insertvendor?mode=DELETEVENDOR";

const GET_CENTRAL_STORE_ASSET_API_URL= base_url+"/substoreAssetDetail?mode=SUBSTOREDETAIL";

const ADD_ITEM_TO_ASSET_API_URL = base_url1+"/insertItem?mode=INSERTASSETINCENTRAL";
const UPDATE_ITEM_TO_ASSET_API_URL = base_url1+"/updateItem?";
const DELETE_ITEM_TO_ASSET_API_URL = base_url1+"/deleteItem?";


const UPDATE_VENDOR_ACTIVE_STATUS_API_URL= base_url+"/insertvendor?mode=UPDATEACTIVE";


//Requests

const GET_REQUESTS_API_URL=base_url+"/requestProcess?mode=SHOWSENDREQUESTS";
const ADD_REQUESTS_API_URL=base_url+"/requestProcess?mode=INSERTREQUEST";
const GET_APPROVER_BY_STORE_ID_API_URL=base_url+"/requestProcess?mode=GETSTOREAPPROVER";
const GET_REQUESTS_API_URL_BY_ID=base_url+"/requestProcessInd?mode=SHOWREQUESTSIND";
const ISSUE_REQUESTS_API_URL_BY_FIXED=base_url1+ "/batchtr";
const ISSUE_REQUESTS_API_URL_BY_CONSUMABLE=base_url+"/issueAsset";
const ISSUE_REQUESTS_API_URL_BY_APPROVED_ASSIGNED=base_url+ "/requestProcess"




//Approvers


const GET_APPROVERS_API_URL_BY_ASSET_TYPE=base_url+"/requestProcess?mode=SHOWREQUESTAPPROVER";
const GET_APPROVERS_API_URL_BY_REQUEST_ID=base_url+"/requestProcessInd?mode=SHOWREQUESTSINDAPP";

const GET_RECEIVED_REQUESTS_API_URL=base_url+ "/requestProcess?mode=SHOWRECREQUESTS";
const GET_SEND_REQUESTS_API_URL=base_url+ "/requestProcess?mode=SHOWSENDREQUESTS";
const GET_APPROVERS_BY_STORE_API_URL=base_url+"/requestProcess?mode=SHOWREQUESTAPPROVERSTOREWISE"

const GET_PENDING_APPROVERS_BY_STORE_API_URL=base_url+"/requestProcess?mode=SHOWREQUESTAPPROVERPENDING"


const GET_PENDING_APPROVERS_BY_STORE_API_URL_NS=base_url+"/requestProcess?mode=SHOWREQUESTAPPROVERPENDINGNS"
const GET_PENDING_APPROVERS_BY_STORE_API_URL_MS=base_url+"/requestProcess?mode=SHOWREQUESTAPPROVERPENDINGMS"
const GET_PENDING_APPROVERS_BY_STORE_API_URL_PRINCIPAL=base_url+"/requestProcess?mode=SHOWREQUESTAPPROVERPENDINGPR"


const GET_APPROVED_APPROVERS_BY_STORE_API_URL=base_url+"/requestProcess?mode=SHOWREQUESTAPPROVERAPPROVED"
const GET_APPROVED_APPROVERS_BY_STORE_API_URL_NS=base_url+"/requestProcess?mode=SHOWREQUESTAPPROVERAPPROVEDNS"
const GET_APPROVED_APPROVERS_BY_STORE_API_URL_MS=base_url+"/requestProcess?mode=SHOWREQUESTAPPROVERAPPROVEDMS"
const GET_APPROVED_APPROVERS_BY_STORE_API_URL_PRINCIPAL=base_url+"/requestProcess?mode=SHOWREQUESTAPPROVERAPPROVEDPR"









//SendRequest
const SHOW_REQUEST_APPROVER_API_URL=base_url+"/requestProcess?mode=SHOWREQUESTAPPROVER";
const SHOW_SEND_IND_REQUEST_APPROVER =base_url+"/requestProcessInd?mode=SHOWREQUESTSINDAPP";
//ConsumableAsset 
const SHOW_CONSUME_ASSET_VIEW =base_url+"/inserttransactionLog?mode=SHOWINDASSETCONSUME";
const INSERT_CONSUME_ASSET =base_url+"/inserttransactionLog?mode=INSERTTRACONSUMEDBY";
const INSERT_TRACONSUMED_BY_ADJUST =base_url+"/inserttransactionLog?mode=INSERTTRACONSUMEDBYADJUST";
const SUBSTORE_CONSUMED_ASSET_LIST =base_url+"/substoreAssetDetail?mode=SUBSTOREDETAILCONSUMABLE";
const ISSUE_ASSET_TO_SUBSTORE =base_url+"/inserttransactionLog?mode=INSERTTRANFORREQ";
const ADD_ITEM_IN_CENTRAL_CONSUME =base_url+"/inserttransactionLog?mode=INSERTASSETINCENTRAL";
const GET_CONSUMED_ASSET_QUANTITY =base_url+"/inserttransactionLog?mode=GETQUNTITYOFASSET";
const GET_CONSUMED_ASSET_QUANTITY_ISSUE =base_url+"/inserttransactionLog?mode=GETQUNTITYOFASSETFORISSUE";

const UPDATE_CONSUMED_RE_ORDER_AND_RED_ALERT =base_url+"/inserttransactionLog?mode=UPDATEREDANDRECORDCONSUME";
const INSERT_TRANFOR_REQ_WITH_REQID =base_url+"/issueAsset?mode=INSERTTRANFORREQWITHREQID";
const SELECT_SUBCATEGORY_CATID_WISE =base_url+"/insertSubCategory?mode=SELECTSUBCATEGORYCATIDWISE";
/* http://165.232.180.142:3011/ukh/?mode=SELECTSUBCATEGORYCATIDWISE&catID=16 */

// dashboard api
const GET_ADMIN_DASHBOARD =base_url+"/dashboard?mode=Admin";
const GET_APPROVER_DASHBOARD =base_url+"/dashboard?mode=STOREAPPROVER";
const GET_STORE_ADMIN_DASHBOARD =base_url+"/dashboard?mode=STOREADMIN";

const GET_STORE_ADMIN_ALL_DASHBOARD =base_url+"/dashboard?mode=STOREADMINALL";



const GET_CONSUMABLE_BATCH=base_url+"/requestProcess?mode=GETBATCHNOCONSUMABLE"


const PENDING_REQUESTS_API_URL=base_url+"/requestProcess?mode=SHOWSENDREQUESTSPENDING"
const ISSUE_REQUESTS_API_URL=base_url+"/requestProcess?mode=SHOWSENDREQUESTSISSUE"

const ISSUE_OUTGOING_REQUESTS_API_URL=base_url+"/requestProcess?mode=SHOWRECREQUESTSISSUE";
const PENDING_OUTGOING_REQUESTS_API_URL=base_url+"/requestProcess?mode=SHOWRECREQUESTSPENDING";

const STOCK_REGISTER_API_URL=base_url+"/stockReg?mode=STOCK";
const STOCK_REGISTER_API_URL_ALL=base_url+"/substoreAssetDetail?mode=SUBSTOREDETAILALL";





//UOM

const GET_UOM_API_URL=base_url+"/approverDesignation?mode=SHOWUOM";
const INSERT_UOM_API_URL=base_url+"/approverDesignation?mode=INSERTUOM";
const UPDATE_UOM_API_URL=base_url+"/approverDesignation?mode=UPDATEUOM";

const DELETE_UOM_API_URL=base_url+"/approverDesignation?mode=DELETEUOM";


//Designations

const APPROVER_DESIGNATION_API_URL=base_url+"/approverDesignation?mode=SHOWDESIGNATION";


const INSERT_DESIGNATION_API_URL=base_url+"/approverDesignation?mode=INSERTDESIGNATION"

const UPDATE_DESIGNATION_API_URL=base_url+"/approverDesignation?mode=UPDATEDESIGNATION"

const DELETE_DESIGNATION_API_URL=base_url+"/approverDesignation?mode=DELETEDESIGNATION"


const APPROVER_DESIGNATION_NS_API_URL=base_url+"/approverDesignation?mode=SHOWALLNS";

const APPROVER_DESIGNATION_MS_API_URL=base_url+"/approverDesignation?mode=SHOWALLMS"

const APPROVER_DESIGNATION_PRINCIPAL_API_URL=base_url+"/approverDesignation?mode=SHOWALLPR"



const APPROVERS_ALL_RECORDS=base_url+"/requestProcess?mode=SHOWREQUESTAPPROVERSTOREWISEALL"

const APPROVER_BY_STORE_ID_PENDING=base_url+"/requestProcess?mode=SHOWREQUESTAPPROVERPENDINGSTOREID";
const APPROVER_BY_STORE_ID_APPROVED=base_url+"/requestProcess?mode=SHOWREQUESTAPPROVERAPPROVEDSTOREID";

const APPROVER_BY_STORE_ID_PENDING_NS=base_url+"/requestProcess?mode=SHOWREQUESTAPPROVERPENDINGNSSTOREID";
const APPROVER_BY_STORE_ID_APPROVED_NS=base_url+"/requestProcess?mode=SHOWREQUESTAPPROVERAPPROVEDNSSTOREID";


const APPROVER_BY_STORE_ID_PENDING_MS=base_url+"/requestProcess?mode=SHOWREQUESTAPPROVERPENDINGMSSTOREID";
const APPROVER_BY_STORE_ID_APPROVED_MS=base_url+"/requestProcess?mode=SHOWREQUESTAPPROVERAPPROVEDMSSTOREID";

const APPROVER_BY_STORE_ID_PENDING_PRINCIPAL=base_url+"/requestProcess?mode=SHOWREQUESTAPPROVERPENDINGPRSTOREID";
const APPROVER_BY_STORE_ID_APPROVED_PRINCIPAL=base_url+"/requestProcess?mode=SHOWREQUESTAPPROVERAPPROVEDPRSTOREID";


const STOCK_REGISTER_ASSET_ID=base_url+"/inserttransactionLog?mode=SHOWINDASSETSTORE";


//transactionLog

const SHOW_TRAN_ADJEST_LOG=base_url+"/inserttransactionLog?mode=SHOWTRANADJEST";

const SHOW_TRAN_CONSUME_LOG=base_url+"/inserttransactionLog?mode=SHOWTRANCONSUME";

const TRANSACTION_LOG_API_URL = base_url+"/inserttransactionLog?mode=SHOWALLTRANS";
const TRANSACTION_DETAILS_API_URL = base_url1+"/transactiondetail?";

//bill details
const SHOWBILLDETAILS_INFORMATION=base_url+"/billdetail?mode=SHOWBILLDETAILS"

export {
  TRANSACTION_LOG_API_URL,
  TRANSACTION_DETAILS_API_URL,
  SHOW_TRAN_CONSUME_LOG,
  SHOW_TRAN_ADJEST_LOG,
  GET_ADMIN_DASHBOARD,
  GET_APPROVER_DASHBOARD,
  GET_STORE_ADMIN_DASHBOARD,
  GET_STORE_ADMIN_ALL_DASHBOARD,
  AUTH_LOGIN_API_URL,
  GET_CATEGORY_API_URL,
  ADD_NEW_CATEGORY_API_URL,
  GET_SUB_CATEGORY_API_URL,
  ADD_NEW_SUB_CATEGORY_API_URL,
  UPDATE_NEW_SUB_CATEGORY_API_URL,

  SELECT_SUBCATEGORY_CATID_WISE,

  GET_ASSET_API_URL,
  ADD_ASSET_API_URL,
  GET_ASSET_BY_ID_API_URL,
  UPDATE_ASSET_API_URL,

  GET_USERS_API_URL,
  GET_VENDORS_API_URL,
  ADD_VENDORS_API_URL,
  DELETE_VENDORS_API_URL,
  GET_VENDORS_BY_ID_API_URL,
  UPDATE_VENDORS_API_URL,
  UPDATE_VENDOR_ACTIVE_STATUS_API_URL,

  UPLOAD_IMAGE_API_URL,
  ADD_USER_API_URL,
  GET_USER_BY_ID_API_URL,
  UPDATE_USER_BY_API_URL,

  GET_SUBSTORE_API_URL,
  ADD_SUBSTORE_API_URL,
  GET_SUBSTORE_BY_ID_API_URL,
  UPDATE_SUBSTORE_API_URL,
  ADD_APPROVER_SUBSTORE_API_URL,
  REMOVE_APPROVER_SUBSTORE_API_URL,
  ADD_ADMIN_SUBSTORE_API_URL,
  REMOVE_ADMIN_SUBSTORE_API_URL,

  GET_APPROVERS_API_URL,
  GET_APPROVED_API_URL,
  
  GET_CENTRAL_STORE_ASSET_API_URL,

  ADD_ITEM_TO_ASSET_API_URL,
  UPDATE_ITEM_TO_ASSET_API_URL,
  DELETE_ITEM_TO_ASSET_API_URL,

  GET_REQUESTS_API_URL,
  ADD_REQUESTS_API_URL,
  GET_REQUESTS_API_URL_BY_ID,
  ISSUE_REQUESTS_API_URL_BY_FIXED,
  ISSUE_REQUESTS_API_URL_BY_CONSUMABLE,
  ISSUE_REQUESTS_API_URL_BY_APPROVED_ASSIGNED,
  
  GET_APPROVERS_API_URL_BY_ASSET_TYPE,
  GET_APPROVERS_API_URL_BY_REQUEST_ID,
  GET_RECEIVED_REQUESTS_API_URL,
  GET_SEND_REQUESTS_API_URL,
  
  SHOW_REQUEST_APPROVER_API_URL,
  SHOW_SEND_IND_REQUEST_APPROVER,
  SHOW_CONSUME_ASSET_VIEW,
  INSERT_CONSUME_ASSET,
  INSERT_TRACONSUMED_BY_ADJUST,
  SUBSTORE_CONSUMED_ASSET_LIST,
  GET_CONSUMED_ASSET_QUANTITY,
  GET_CONSUMED_ASSET_QUANTITY_ISSUE,
  ADD_ITEM_IN_CENTRAL_CONSUME,
  ISSUE_ASSET_TO_SUBSTORE,
  INSERT_TRANFOR_REQ_WITH_REQID,
  UPDATE_CONSUMED_RE_ORDER_AND_RED_ALERT,
  GET_APPROVER_BY_STORE_ID_API_URL,
  GET_APPROVERS_BY_STORE_API_URL,
  base_url,
  GET_CONSUMABLE_BATCH,
  PENDING_REQUESTS_API_URL,
  ISSUE_REQUESTS_API_URL,
  ISSUE_OUTGOING_REQUESTS_API_URL,
  PENDING_OUTGOING_REQUESTS_API_URL,
  STOCK_REGISTER_API_URL,
  STOCK_REGISTER_API_URL_ALL,
  APPROVER_DESIGNATION_API_URL,
  GET_UOM_API_URL,
  INSERT_UOM_API_URL,
  UPDATE_UOM_API_URL,
  DELETE_UOM_API_URL,

  INSERT_DESIGNATION_API_URL,
  UPDATE_DESIGNATION_API_URL,
  DELETE_DESIGNATION_API_URL,

  APPROVER_DESIGNATION_NS_API_URL,
  APPROVER_DESIGNATION_MS_API_URL,
  APPROVER_DESIGNATION_PRINCIPAL_API_URL,

  GET_PENDING_APPROVERS_BY_STORE_API_URL,
  GET_APPROVED_APPROVERS_BY_STORE_API_URL,


  GET_PENDING_APPROVERS_BY_STORE_API_URL_NS,
  
  GET_PENDING_APPROVERS_BY_STORE_API_URL_MS,
  GET_PENDING_APPROVERS_BY_STORE_API_URL_PRINCIPAL,
GET_APPROVED_APPROVERS_BY_STORE_API_URL_NS,
GET_APPROVED_APPROVERS_BY_STORE_API_URL_MS,
GET_APPROVED_APPROVERS_BY_STORE_API_URL_PRINCIPAL,
APPROVERS_ALL_RECORDS,

APPROVER_BY_STORE_ID_PENDING, 
APPROVER_BY_STORE_ID_APPROVED,
APPROVER_BY_STORE_ID_PENDING_NS,
APPROVER_BY_STORE_ID_APPROVED_NS,
APPROVER_BY_STORE_ID_PENDING_MS,
APPROVER_BY_STORE_ID_APPROVED_MS,
APPROVER_BY_STORE_ID_PENDING_PRINCIPAL,
APPROVER_BY_STORE_ID_APPROVED_PRINCIPAL,
STOCK_REGISTER_ASSET_ID,
SHOWBILLDETAILS_INFORMATION



};
